import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/DataProvider";

function Storage({ setData }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const { tableData, setTableData } = useContext(DataContext);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableData.patientList
                    .filter((item) => item.status === "예약하기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !== tableData.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StorageScenario6({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 1,
      reservation: "9:00",
      chartNumber: "005258",
      nameAgeGender: "정호걸(51/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 2,
      reservation: "9:30",
      chartNumber: "111011",
      nameAgeGender: "박세로이(25/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                              {data.action}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StorageScenario7({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 1,
      reservation: "9:00",
      chartNumber: "005258",
      nameAgeGender: "정호걸(51/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action2: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 2,
      reservation: "9:30",
      chartNumber: "111011",
      nameAgeGender: "박세로이(25/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action2: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },

    {
      id: 3,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action2: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 4,
      reservation: "10:30",
      chartNumber: "207010",
      nameAgeGender: "오찬욱(61/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action2: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 5,
      reservation: "11:00",
      chartNumber: "103000",
      nameAgeGender: "김선희(62/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action2: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                              {data.action2}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function StorageScene6({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 1,
      reservation: "9:00",
      chartNumber: "005258",
      nameAgeGender: "정호걸(51/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action1: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 2,
      reservation: "9:30",
      chartNumber: "111011",
      nameAgeGender: "박세로이(25/남)",
      doctor: "최윤호",
      status: "접수대기",
      action1: "예약하기",
      action: "접수대기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },

    {
      id: 3,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action1: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    // {
    //   id: 4,
    //   reservation: "10:30",
    //   chartNumber: "207010",
    //   nameAgeGender: "오찬욱(61/남)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
    // {
    //   id: 5,
    //   reservation: "11:00",
    //   chartNumber: "103000",
    //   nameAgeGender: "김선희(62/여)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                              {data.action1}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function StorageScene9({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 1,
      reservation: "13:30",
      chartNumber: "102010",
      nameAgeGender: "심우민(30/남)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      action4: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    // {
    //   id: 2,
    //   reservation: "9:30",
    //   chartNumber: "111011",
    //   nameAgeGender: "박세로이(25/남)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   action4: "예약하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },

    // {
    //   id: 3,
    //   reservation: "10:00",
    //   chartNumber: "120020",
    //   nameAgeGender: "이혜빈(40/여)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   action4: "예약하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
    // {
    //   id: 4,
    //   reservation: "10:30",
    //   chartNumber: "207010",
    //   nameAgeGender: "오찬욱(61/남)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
    // {
    //   id: 5,
    //   reservation: "11:00",
    //   chartNumber: "103000",
    //   nameAgeGender: "김선희(62/여)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                              {data.action4}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StorageScene8({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 1,
      reservation: "9:00",
      chartNumber: "005258",
      nameAgeGender: "정호걸(51/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action3: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 2,
      reservation: "9:30",
      chartNumber: "111011",
      nameAgeGender: "박세로이(25/남)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action3: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },

    {
      id: 3,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      action3: "예약하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    // {
    //   id: 4,
    //   reservation: "10:30",
    //   chartNumber: "207010",
    //   nameAgeGender: "오찬욱(61/남)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
    // {
    //   id: 5,
    //   reservation: "11:00",
    //   chartNumber: "103000",
    //   nameAgeGender: "김선희(62/여)",
    //   doctor: "최윤호",
    //   status: "접수대기",
    //   action: "접수하기",
    //   nextReservation: "",
    //   totalMedicalExpense: "",
    //   patientContribution: "",
    //   paymentAmount: "",
    //   amountReceivable: "",
    // },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                              {data.action3}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export {
  Storage,
  StorageScenario6,
  StorageScenario7,
  StorageScene6,
  StorageScene9,
  StorageScene8,
};
