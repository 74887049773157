import React, { useState } from "react";
import SelectQuestion from "./selectComponent";
import Checkbox from "./checkboxComponent";
import RadioQuestion from "./radioComponent";

const Step3 = ({ data, setData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData((prev) => ({
      ...prev,
      step2: {
        ...prev.step2,
        [name]: value,
      },
    }));
  };
  const [checked, setChecked] = useState([]);

  // Define a function to handle checkbox changes
  const toggleCheckbox = (index) => {
    // Add or remove the index from the checked array based on your logic
    if (checked.includes(index)) {
      setChecked(checked.filter((item) => item !== index));
    } else {
      setChecked([...checked, index]);
    }
  };

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex gap-[9px]">
        <div className="text-[#317580] font-['Pretendard'] text-[18px] font-[600] leading-[26px]">
          40%
        </div>
        <div className="flex items-center">
          <div className="relative w-[575px]  h-[8px] rounded-[8px] bg-[#D4D4D8]">
            <div className="absolute max-w-[230px] w-full h-[8px] mx-auto rounded-[8px] bg-[#317580]"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[48px]">
        <div className="flex flex-col gap-[40px]">
          <div className=" flex flex-col  gap-2 text-[111] font-[Pretendard] text-[16px] font-[600] ">
            <RadioQuestion
              handleChange={handleChange}
              name="question2"
              id="question2"
              question="전신질환이 있으신가요?  "
              answers={["예", "아니요"]}
            />
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="text-[111] font-[Pretendard] text-[16px] font-[600] leading-[-0.4px]">
              무슨 약을 복용하고 있으신가요?(복수선택가능)
            </div>

            <div className="flex flex-col font-['Pretendard'] gap-[12px]">
              <Checkbox
                answers={["고혈압"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["당뇨"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["골다공증"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["치매"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["기타"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
