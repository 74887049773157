import React, { useState } from "react";
import "./index.css";
import Calender from "../../../components/Calender";
import { format, startOfToday } from "date-fns";
function MakeReservation({ setModalOpen }) {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(startOfToday());
  const openModal = () => {
    setModalOpen(true);
  };

  const openCaleneder = () => {
    setOpenCal(true);
  };
  const rowData = [];

  for (let hour = 9; hour <= 19; hour++) {
    rowData.push({
      col1: `${hour}시`,
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
    });

    if (hour < 19) {
      rowData.push({
        col1: `${hour}시 30분`,
        col2: "",
        col3: "",
        col4: "",
        col5: "",
        col6: "",
        col7: "",
      });
    }
  }

  // Now rowData contains data for hours and minutes from 9시 to 19시 30분

  return (
    <div>
      {" "}
      <div className="flex flex-row items-center justify-center   ">
        <div className="modal-title flex-1">예약하기</div>
        <div className="cursor-pointer">
          <img src="/close.png" alt="" />
        </div>
      </div>
      <div className="bg-[#FAFAFA] px-8 w-full">
        <div className="max-w-[836px] w-full mx-auto pt-4 flex flex-col">
          <div className="flex justify-center mb-3">
            <div
              className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]"
              style={{ width: "max-content" }}
            >
              <div style={{ position: "relative" }}>
                <div
                  onClick={openCaleneder}
                  className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.333 6L7.33301 10L11.333 14"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                    {`${format(currentDate, "yyyy")}년 ${format(
                      currentDate,
                      "MM"
                    )}월  ${format(currentDate, "dd")}일(월)`}
                  </span>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66699 14L12.667 10L8.66699 6"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>

                {/* Add your SVG icon here */}
              </div>
              <div className="absolute z-[0] bg-white">
                {openCal === true && (
                  <Calender
                    selectedDate={currentDate}
                    setCalendarState={setOpenCal}
                    onDateSelect={setCurrentDate}
                    buttonVisible={true}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
              <div className=" ">
                <table className="w-full  ">
                  <thead className="mr-1">
                    <tr className="bg-[#F1F8FA] text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#767676] ">
                      <th className="w-[9.4%] text-left pl-3 border-r "></th>

                      <th className="w-[13.2%]  text-left pl-2 py-[9px] border-r">
                        8월 7일(월)
                      </th>
                      <th className="w-[13.1%]  text-left pl-2 border-r">
                        8월 8일(화)
                      </th>
                      <th className="w-[13.1%]  text-left pl-2 border-r">
                        8월 9일(수)
                      </th>
                      <th className="w-[13.1%]  text-left pl-2 border-r">
                        8월 10일(목)
                      </th>

                      <th className="w-[13.2%]  text-left pl-2 border-r">
                        8월 11일(금)
                      </th>
                      <th className="w-[13.1%]  text-left pl-2 border-r">
                        8월 12일(토)
                      </th>
                      <th className="w-[13%]  text-left pl-2 border-r">
                        8월 13일(일)
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div
                className="rounded-b-lg"
                style={{ maxHeight: "424px", overflow: "scroll" }}
              >
                <table className="w-full">
                  <tbody>
                    {rowData.map((data, index) => (
                      <tr key={index} className="border-t py-[9px]">
                        <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                          {" "}
                          {data.col1}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3 border-r">
                          {data.col2}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3 border-r">
                          {data.col3}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3 border-r">
                          {data.col4}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3 border-r">
                          {data.col5}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3 border-r">
                          {data.col6}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3 border-r">
                          {data.col6}
                        </td>
                        <td className="w-[13.2%] py-[9px] px-3">{data.col7}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MakeReservation;
