import React, { useContext, useEffect, useState } from "react";
import { Input, Button, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Pagination from "react-paginate"; // Import Pagination component
import DataContext from "../../../context/DataProvider";
import SearchableDropdown from "../../../components/SearchableDropdown";

const Step1 = ({ data, setData }) => {
  const { tableData, setTableData, setTableDataScenario10 } =
    useContext(DataContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      step1: {
        ...prev.step1,
        [name]: value,
      },
    }));
  };
  const [value, setValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for managing the current page

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      major: value,
    }));
    console.log(value);
  }, [value]);

  return (
    <div>
      <div className="flex flex-col shrink-0 bg-[#FFF] max-w-[1280px] w-full mx-auto  px-[24px]  gap-[32px] rounded-[8px]">
        <form>
          <div className="flex flex-col shrink-0 bg-[#FFF] max-w-[1280px] w-full mx-auto  px-[24px] pt-[32px]  gap-[32px] rounded-[8px]">
            <div className="flex flex-col gap-[12px]">
              <label
                className="flex flex-col gap-[16px] font-[pretendard] text-[16px] font-[600] leading-[24px] tracking-[-0.4]"
                htmlFor="name"
              >
                이름
              </label>
              <input
                className=" border border-gray-300 p-1 rounded-[8px] w-full text-[#767676] leading-[24px] tracking-[-0.4px]"
                type="text"
                id="name"
                name="name"
                placeholder="이름을 입력해주세요."
                style={{ padding: "11px 16px 11px 16px" }}
                value={data.name}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-[12px]">
              <label
                className="flex flex-col gap-[16px] font-[pretendard] text-[16px] font-[600] leading-[24px] tracking-[-0.4]"
                htmlFor="contact"
              >
                주민번호
              </label>
              <input
                className=" border border-gray-300 p-1 rounded-[8px] w-full text-[#767676] leading-[24px] tracking-[-0.4px]"
                type="text"
                id="contact"
                name="contact"
                placeholder="주민번호를 입력해주세요."
                style={{ padding: "11px 16px 11px 16px" }}
                value={data.contact}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-[12px]">
              <label
                className="flex flex-col gap-[16px] font-[pretendard] text-[16px] font-[600] leading-[24px] tracking-[-0.4]"
                htmlFor="age"
              >
                전화번호
              </label>
              <input
                className=" border border-gray-300 p-1 rounded-[8px] w-full text-[#767676] leading-[24px] tracking-[-0.4px]"
                type="text"
                id="age"
                name="age"
                placeholder="휴대폰 번호를 입력해주세요."
                style={{ padding: "11px 16px 11px 16px" }}
                value={data.age}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-[12px] ">
              <label
                className="flex flex-colfont-[pretendard] text-[16px] font-[600] leading-[24px] tracking-[-0.4]"
                htmlFor="major"
              >
                주소(Address)
              </label>
              <div className="relative">
                <div
                  className="absolute inset-y-0 right-0 pr-2 flex items-center"
                  onClick={showModal}
                >
                  <SearchOutlined />
                </div>
                <input
                  className=" border border-gray-300 p-1 rounded-[8px] w-full text-[#767676] leading-[24px] tracking-[-0.4px] "
                  type="text"
                  id="major"
                  name="major"
                  placeholder="주소를 입력해주세요."
                  style={{ padding: "11px 16px 11px 16px" }}
                  value={data.major}
                  onChange={handleChange}
                />
              </div>

              <input
                className=" border border-gray-300 p-1 rounded-[8px] w-full text-[#767676] leading-[24px] tracking-[-0.4px]"
                type="text"
                id="major"
                name="major"
                placeholder="상세주소를 입력해주세요."
                style={{ padding: "11px 16px 11px 16px" }}
                value={data.step1.major1}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </div>
      <Modal
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"524px"}
        footer={null}
        // height={"1133px"}

        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "32px",
              flexShrink: 0,
              color: "var(--800, #154D57)",
              fontFamily: "SF Pro, Pretendard",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "40px", // 142.857%
              letterSpacing: "-0.7px",
              textAlign: "center",
            }}
            className="gap-[300px]"
          >
            주소 검색
          </div>
        }
      >
        <div className="flex flex-col rounded-[8px] mb-[300px]  bg-[#FAFAFA] ">
          <div className="flex flex-col  py-[16px]">
            <div className="flex flex-col px-[16px] gap-[100px]">
              <div className="flex bg-[#65852] justify-between gap-[203px] border-b-[1px] border-[#154D57]">
                <div class="relative w-full">
                  <SearchableDropdown
                    options={[
                      { id: 0, name: "" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 2" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 8" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 26" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 28" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 29" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 30" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 31" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 32" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 33" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 34" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 35" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 36" },
                      { id: 0, name: "경기도 고양시 일산서구 일현로 32" },
                      {
                        id: 0,
                        name: "경기도 고양시 일산서구 일현로 97-8 (탄현동)",
                      },
                      {
                        id: 0,
                        name: "경기도 고양시 일산서구 일현로 97-11 (탄현동, 일산 위브너제니스)",
                      },
                      {
                        id: 0,
                        name: "경기도 고양시 일산서구 일현로 97-24 (탄현동)",
                      },
                      {
                        id: 0,
                        name: "경기도 고양시 일산서구 일현로 지하97-27 (탄현동)",
                      },
                    ]}
                    label="name"
                    id="id"
                    className={
                      "border-[1px] border-[#D4D4D8] bg-[#FFF] focus:outline-none text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full   :outline-none placeholder-[#111111]"
                    }
                    selectedVal={value}
                    handleChange={(val) => setValue(val)}
                  />
                  {/* <input
                          id="helper-text"
                          aria-describedby="helper-text-explanation"
                          class="border-[1px] border-[#D4D4D8] bg-[#FFF] focus:outline-none text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full py-2 px-3 focus:outline-none placeholder-[#111111]"
                          placeholder="박덴탈(951010)"
                        /> */}
                  <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M13.2148 13.2148L17.5004 17.5004"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex justify-center gap-[17px] ">
          <div className="flex gap-[2px]">
            <button className=" flex-shrink-0 bg-transparent border-none cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  className="stroke-1 stroke-gray-400"
                  d="M21 21L16 16L21 11"
                />
                <path
                  className="stroke-1 stroke-gray-400"
                  d="M17 21L12 16L17 11"
                />
                <rect
                  x="0.5"
                  y="-0.5"
                  width="31"
                  height="31"
                  rx="3.5"
                  transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 -4.37114e-08 31)"
                  className="stroke-gray-400"
                />
              </svg>
            </button>

            <button className=" flex-shrink-0 bg-transparent border-none cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  className="stroke-1 stroke-gray-400"
                  d="M17 21L12 16L17 11"
                />
                <rect
                  x="0.5"
                  y="-0.5"
                  width="31"
                  height="31"
                  rx="3.5"
                  transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 -4.37114e-08 31)"
                  className="stroke-gray-400"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center font-[Pretendard] text-[14px] font-[700]">
            <div className="font-[700]">1</div>
            <div className="font-[400]">/ 40</div>
          </div>
          <div className=" flex gap-[2px] ">
            <button className=" flex-shrink-0 bg-transparent border-none cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path d="M14 21L19 16L14 11" stroke="#999999" />
                <rect
                  x="0.5"
                  y="-0.5"
                  width="31"
                  height="31"
                  rx="3.5"
                  transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 -4.37114e-08 31)"
                  className="stroke-gray-400"
                />
              </svg>
            </button>
            <button className=" flex-shrink-0 bg-transparent border-none cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path d="M11 21L16 16L11 11" stroke="#999999" />
                <path d="M15 21L20 16L15 11" stroke="#999999" />
                <rect
                  x="0.5"
                  y="-0.5"
                  width="31"
                  height="31"
                  rx="3.5"
                  transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 -4.37114e-08 31)"
                  className="stroke-gray-400"
                />
              </svg>
            </button>
          </div>
        </div> */}
          </div>
        </div>
        <div className="flex justify-center items-center gap-[8px] px-[148px] py-[32px]">
          <div className="flex w-200 px-86  ">
            <Button
              onClick={handleCancel}
              className="flex w-[110px] h-[36px] bg-[#EBF5F2]  justify-center items-center rounded-[8px]"
            >
              <div className="text-[#154D57] font-['Pretendard'] text-[13px] font-[600] leading-[24px] tracking-[-0.4px]">
                취소
              </div>
            </Button>
          </div>
          <div className="flex w-200 px-86  justify-center items-center ">
            <Button
              onClick={handleOk}
              className="flex w-[110px] h-[36px] bg-[#317580] py-[9px] px-[43px]  justify-center items-center rounded-[8px]"
            >
              <div className="text-white font-['Pretendard'] text-[13px] font-[600] leading-[24px] tracking-[-0.4px]">
                확인
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Step1;
