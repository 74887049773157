import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../context/DataProvider";

function DuringTreatment({ searchTerm, setData }) {
  const { tableData, setTableData, setPatientIsProcessing } =
    useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableData.patientList
  );
  const [selectedRow, setSelectedRow] = useState(null);
  useEffect(() => {
    setTableDataFiltered(
      tableData.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full  text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57] ">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "진료중")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#C29800] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessing(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}


function DuringTreatmentScenario7({ searchTerm, setData }) {
  const { tableDataScenario7, setTableDataScenario7, setPatientIsProcessingScenario7,setPatientIsCompletedScenario7 } =
    useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario7.patientList
  );
  const [selectedRow, setSelectedRow] = useState(null);
  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario7.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full  text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57] ">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "진료중")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#C29800] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsCompletedScenario7(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataFiltered.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function DuringTreatmentScene6({ searchTerm, setData }) {
  const { tableDataScenario7, setTableDataScenario7, setPatientIsProcessingScenario7,setPatientIsCompletedScenario7 } =
    useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario7.patientList
  );
  const [selectedRow, setSelectedRow] = useState(null);
  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario7.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full  text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57] ">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "진료중")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#C29800] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsCompletedScenario7(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataFiltered.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export {DuringTreatment,DuringTreatmentScenario7,DuringTreatmentScene6};
