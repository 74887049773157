import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../context/DataProvider";

function PatientList({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    tableData,
    setTableData,
    setPatientIsProcessing,
    setPatientIsProcessingScenario10,
    tableDataScenario10,
    setTableDataScenario10,
    tableDataScenario12,
    setPatientDisplayNoneScenario12,
    setPatientIsProcessing12,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState();

  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario12.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const tableDataS12 = [
    {
      id: 1,
      reservation: "14:00",
      chartNumber: "320010",
      nameAgeGender: "전상구(48/남)",
      dobAgeGender: "320010/남(48)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 2,
      reservation: "14:00",
      chartNumber: "314010",
      nameAgeGender: "이윤혁(19/남)",
      dobAgeGender: "314010/남(19)",
      doctor: "최원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 3,
      reservation: "14:00",
      chartNumber: "110030",
      nameAgeGender: "정현주(42/여)",
      dobAgeGender: "110030/남(42)",
      doctor: "김원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 3,
      reservation: "14:00",
      chartNumber: "005258",
      nameAgeGender: "정호걸(52/남)",
      dobAgeGender: "005258/남(52)",
      doctor: "심원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 4,
      reservation: "14:30",
      chartNumber: "107010",
      nameAgeGender: "김연경(50/여)",
      dobAgeGender: "107010/남(50)",
      doctor: "심원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 5,
      reservation: "14:30",
      chartNumber: "112020",
      nameAgeGender: "김택(44/남)",
      dobAgeGender: "112020/남(44)",
      doctor: "최원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 6,
      reservation: "15:00",
      chartNumber: "330250",
      nameAgeGender: "최용민(37/남)",
      dobAgeGender: "330250/남(37)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 7,
      reservation: "15:00",
      chartNumber: "113010",
      nameAgeGender: "이창선(30/남)",
      dobAgeGender: "113010/남(30)",
      doctor: "김원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 8,
      reservation: "15:30",
      chartNumber: "111021",
      nameAgeGender: "박서윤(13/여)",
      dobAgeGender: "111021/남(13)",
      doctor: "최원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 9,
      reservation: "15:30",
      chartNumber: "111022",
      nameAgeGender: "김서준(7/남)",
      dobAgeGender: "111022/남(7)",
      doctor: "김원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 10,
      reservation: "15:30",
      chartNumber: "111023",
      nameAgeGender: "김민서(13/여)",
      dobAgeGender: "111023/남(13)",
      doctor: "심원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 11,
      reservation: "16:00",
      chartNumber: "111024",
      nameAgeGender: "박시은(13/여)",
      dobAgeGender: "111024/남(13)",
      doctor: "김원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 12,
      reservation: "16:00",
      chartNumber: "111025",
      nameAgeGender: "이유나(13/여)",
      dobAgeGender: "111025/남(13)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 13,
      reservation: "16:30",
      chartNumber: "111026",
      nameAgeGender: "박지원(13/여)",
      dobAgeGender: "111026/남(13)",
      doctor: "최원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 14,
      reservation: "16:30",
      chartNumber: "111027",
      nameAgeGender: "김지후(7/남)",
      dobAgeGender: "111026/남(13)",
      doctor: "심원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 15,
      reservation: "17:00",
      chartNumber: "111028",
      nameAgeGender: "김윤아(13/여)",
      dobAgeGender: "111028/남(13)",
      doctor: "김원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },

    {
      id: 16,
      reservation: "17:00",
      chartNumber: "111029",
      nameAgeGender: "이민지(13/여)",
      dobAgeGender: "111029/남(13)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 16,
      reservation: "17:30",
      chartNumber: "111030",
      nameAgeGender: "박나은(13/여)",
      dobAgeGender: "111030/남(13)",
      doctor: "김원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 17,
      reservation: "17:30",
      chartNumber: "111031",
      nameAgeGender: "이채은(13/여)",
      dobAgeGender: "111031/남(13)",
      doctor: "최원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
    {
      id: 18,
      reservation: "18:00",
      chartNumber: "111032",
      nameAgeGender: "김덴투(13/여)",
      dobAgeGender: "111032/남(13)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },

    {
      id: 19,
      reservation: "18:00",
      chartNumber: "111033",
      nameAgeGender: "박우진(7/남)",
      dobAgeGender: "111033/남(7)",
      doctor: "홍원장",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "-",
      amountReceivable: "",
    },
  ];

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
    localStorage.setItem("completed", "true");
    localStorage.setItem("visible", "false");
  };

  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px" }}>
            <table className="w-full">
              <tbody>
                {tableDataScenario12.patientList
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "hover:bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                      >
                        <td
                          className="  w-[8.33%] text-center"
                          onClick={() => handleRowClick(data)}
                        >
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td
                          className=" pl-4  w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.reservation}
                        </td>
                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.doctor}
                        </td>
                        <td
                          className="w-[8.33%] text-[#767676] font-semibold"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessing12(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.nextReservation}
                        </td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.patientContribution}
                        </td>
                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.paymentAmount}
                        </td>
                        <td
                          className="w-[8.33%]"
                          onClick={() => handleRowClick(data)}
                        >
                          {data.amountReceivable}
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientList;
