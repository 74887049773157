import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import { Fragment, useState, useEffect } from "react";

const meetings = [
  {
    id: 1,
    name: "Leslie Alexander",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    startDatetime: "2022-05-11T13:00",
    endDatetime: "2022-05-11T14:30",
  },
  {
    id: 2,
    name: "Michael Foster",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    startDatetime: "2022-05-20T09:00",
    endDatetime: "2022-05-20T11:30",
  },
  {
    id: 3,
    name: "Dries Vincent",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    startDatetime: "2022-05-20T17:00",
    endDatetime: "2022-05-20T18:30",
  },
  {
    id: 4,
    name: "Leslie Alexander",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    startDatetime: "2022-06-09T13:00",
    endDatetime: "2022-06-09T14:30",
  },
  {
    id: 5,
    name: "Michael Foster",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    startDatetime: "2022-05-13T14:00",
    endDatetime: "2022-05-13T14:30",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Calender({
  onDateSelect,
  setCalendarState,
  selectedDate,
  buttonVisible = true,
  months,
  setMonth = new Date("2023-10-31").getMonth(),
}) {
  let today = new Date("2023-10-31");
  let [selectedDay, setSelectedDay] = useState(new Date("2023-10-31"));
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());

  useEffect(() => {
    setCurrentMonth(format(new Date().setMonth(setMonth), "MMM-yyyy"));
  }, [setMonth]);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDay(new Date(selectedDate));
    }
  }, [selectedDate]);
  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  // useEffect(() => {
  //   console.log("Selected Date: ", format(selectedDay, "d")); // Log the selected date
  //   console.log(
  //     "Selected Month (as number): ",
  //     parseInt(format(selectedDay, "M"), 10)
  //   ); // Log the selected month as a number
  //   console.log("Selected Year: ", format(selectedDay, "yyyy")); // Log the selected year
  // }, [selectedDay]);

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  let selectedDayMeetings = meetings.filter((meeting) =>
    isSameDay(parseISO(meeting.startDatetime), selectedDay)
  );

  function close() {
    setCalendarState(false);
  }

  function selectDate() {
    onDateSelect(selectedDay);
    setCalendarState(false);
  }
  useEffect(() => {
    setSelectedDay(new Date("2023-10-31"));
  }, []);

  return (
    <div className=" max-w-[383px] border-[1px] border-[#D4D4D8]  rounded-[8px]">
      <div className="p-[20px]">
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={previousMonth}
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon
              className="w-5 h-5 text-[#317580]"
              aria-hidden="true"
            />
          </button>
          <div>
            <h2 className="flex-auto font-semibold text-[#154D57] text-[16px] leading-[24px] font-semibold">
              <a href={months}> {format(firstDayCurrentMonth, " yyyy.MM")}</a>
            </h2>
          </div>
          <button
            onClick={nextMonth}
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon
              className="w-5 h-5 text-[#317580]"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="grid grid-cols-7 mt-4 text-xs leading-6 text-center text-[#111]">
          <div>일</div>
          <div>월</div>
          <div>화</div>
          <div>수</div>
          <div>목</div>
          <div>금</div>
          <div>토</div>
        </div>
        <div className="grid grid-cols-7 mt-2 text-sm">
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              className={classNames(
                dayIdx === 0 && colStartClasses[getDay(day)]
              )}
            >
              <button
                type="button"
                onClick={() => {
                  setSelectedDay(day);
                  !buttonVisible && onDateSelect(day);
                }}
                className={classNames(
                  isEqual(day, selectedDay) && "text-white",
                  isToday(day) && "",
                  isSameDay(day, new Date("2023-10-31")) &&
                    "bg-[#317580] text-white", // Background color for October 31st
                  !isEqual(day, selectedDay) &&
                    selectedDate === "2023-10-31" &&
                    "text-red-500",
                  !isEqual(day, selectedDay) &&
                    !isToday(day) &&
                    isSameMonth(day, firstDayCurrentMonth) &&
                    "text-gray-900",
                  !isEqual(day, selectedDay) &&
                    !isToday(day) &&
                    !isSameMonth(day, firstDayCurrentMonth) &&
                    "text-gray-400",
                  isEqual(day, selectedDay) && "bg-[#317580] rounded-full",
                  isEqual(day, selectedDay) && "bg-[#317580]  rounded-full",
                  !isEqual(day, selectedDay) && "",
                  (isEqual(day, selectedDay) || isToday(day)) &&
                    "font-semibold",
                  "mx-auto flex h-[40px] w-[40px] items-center justify-center rounded-full"
                )}
              >
                <time dateTime={format(day, "yyyy-MM-dd")}>
                  {format(day, "d")}
                </time>
              </button>

              <div className="w-1 h-1 mx-auto mt-1">
                {meetings.some((meeting) =>
                  isSameDay(parseISO(meeting.startDatetime), day)
                ) && <div className="w-1 h-1 rounded-full bg-sky-500"></div>}
              </div>
            </div>
          ))}
        </div>
        {buttonVisible && (
          <div className="gap-2 justify-center flex ">
            <button
              className="bg-[#EBF5F2] text-[#154D57] leading-[19px] font-semibold tracking-[-0.325px] px-[44px] py-[9px] rounded-[8px]"
              onClick={close}
            >
              닫기
            </button>
            <button
              className="bg-[#317580] text-[#FFFFFF] leading-[19px] font-semibold tracking-[-0.325px] px-[44px] py-[9px] rounded-[8px]"
              onClick={selectDate}
            >
              확인
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];
