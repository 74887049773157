import React, { useState } from 'react';

function Checkbox({ question, answers, name, id, handleChange }) {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      <div className="">{question}</div>
      {answers.map((answer, index) => (
        <div className="flex items-center space-x-2" key={answer + index}>
          {isChecked ? (
            // Checked SVG
            <svg
              onClick={toggleCheckbox}
              style={{ cursor: 'pointer' }}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <circle cx="9" cy="9" r="9" fill="#317580" />
              <path d="M4 8L8 12L14 6" stroke="white" strokeWidth="1.5" />
            </svg>
          ) : (
            // Non-checked SVG
            <svg
              onClick={toggleCheckbox}
              style={{ cursor: 'pointer' }}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <circle cx="9" cy="9" r="8.5" stroke="#D4D4D8" />
            </svg>
          )}
          <label
            className="custom-checkbox-label text-[111] font-[Pretendard] text-[16px] font-[400] tracking-[-0.4px] leading-[24px]"
            onClick={toggleCheckbox}
            style={{ cursor: 'pointer' }}
          >
            {answer}
          </label>
        </div>
      ))}
    </div>
  );
}

export default Checkbox;
