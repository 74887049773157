import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import { Input } from "antd";
import { Button } from "antd";
import Upload12 from "../../../../../Uploads/uploads";
import SuccessAlert from "../../../../components/SuccessAlert";
import { useNavigate } from "react-router-dom";
import StoreAlertBoxSignUp from "../../../../components/SuccessAlertSignup";

function FileManagement({ data, onDataChange }) {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(""); // State for username error message
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // State for password error message\
  const [passwordError1, setPasswordError1] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState(""); // State for same password error message
  const [success, setSuccess] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [formData, setFormData] = useState(data);

  const navigate = useNavigate();
  useEffect(() => {
    onDataChange(formData);
  }, [formData]);
  //file upload
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);

    const urls = files.map((file) => URL.createObjectURL(file));
    setPreviewUrls(urls);
  };

  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    const updatedUrls = [...previewUrls];

    updatedFiles.splice(index, 1);
    updatedUrls.splice(index, 1);

    setSelectedFiles(updatedFiles);
    setPreviewUrls(updatedUrls);
  };

  //validation
  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    // Validation logic for 아이디 field
    const isValidUsername = /^[a-z0-9A-Z]{5,20}$/.test(value); // Only lowercase letters and numbers, 5 to 20 characters

    if (isValidUsername) {
      setUsernameError("");

      if (value === "DEN2BIO" || value === "doctor") {
        setUsernameError(
          "아이디: 사용할 수 없는 아이디입니다. 다른 아이디를 입력해 주세요."
        );
      }
    } else {
      setUsernameError("아이디: 5~20자의 영문 소문자, 숫자만 사용 가능합니다.");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validation logic for 비밀번호 field
    const isValidPassword =
      /^[a-zA-Z0-9!@#$%^&*()_+}{":;'?/><,.\|]{8,16}$/.test(value);

    const newPassword = e.target.value;
    setPassword(newPassword);

    // if (newPassword === passwordConfirmation) {
    //   // Passwords match, clear the error message
    //   setPasswordError("");
    // } else {
    //   // Passwords don't match, set the error message
    //   setPasswordError("비밀번호: 비밀번호와 비밀번호 확인이 일치하지 않습니다.");
    // }
    if (isValidPassword) {
      setPasswordError("");
    } else {
      setPasswordError(
        "비밀번호: 8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요."
      );
    }
  };

  const handleSuccess = () => {
    let valid = 0;
    Object.keys(formData).map((key) => {
      if (formData[key] === "" && key !== "imageLink") {
        valid++;
      }
    });
    if (valid === 0) {
      setSuccess(true);
      return;
    } else {
      setEmptyFields(true);
      return;
    }
  };

  const handleSuccessClose = () => {
    navigate("/");
    setSuccess(false);
  };

  const handleCodeSentClose = () => {
    setCodeSent(false);
  };
  const handleInvalidCodeClose = () => {
    setCodeSent(false);
  };

  const handleSendCode = () => {
    // implement otp
    setCodeSent(true);
  };

  const handleFormDataChange = (data, name) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
  };

  const handlePasswordConfirmationChange = (e) => {
    const newConfirmation = e.target.value;
    setPasswordConfirmation(newConfirmation);

    if (newConfirmation === password) {
      // Passwords match, clear the error message
      setPasswordError1("");
    } else {
      // Passwords don't match, set the error message
      setPasswordError1(
        "비밀번호: 비밀번호와 비밀번호 확인이 일치하지 않습니다."
      );
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <form
      className=" flex justify-center flex-col max-w-[1040px] w-full "
      onSubmit={(e) => e.preventDefault()}
    >
      <div className=" flex justify-center flex-col max-w-[1040px] w-full ">
        <div className="formContent">
          <div className="input1">
            <div className="Q1">
              <div className="text">아이디</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="아이디를 입력해주세요."
                value={username}
                onChange={(e) => {
                  handleUsernameChange(e);
                  handleFormDataChange(e.target.value, "id");
                }}
              />
              {usernameError && (
                <div className="text-red-500 text-left pt-3">
                  {usernameError}
                </div>
              )}
            </div>
          </div>

          <div className="input1">
            <div className="Q1">
              <div className="text">비밀번호</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="비밀번호 입력해주세요."
                type="password"
                value={password}
                onChange={(e) => {
                  handlePasswordChange(e);
                }}
              />
              {passwordError && (
                <div className="text-red-500 text-left pt-3">
                  {passwordError}
                </div>
              )}
            </div>
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">비밀번호 확인</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="비밀번호 확인"
                type="password"
                value={passwordConfirmation}
                onChange={(e) => {
                  handlePasswordConfirmationChange(e);
                  handleFormDataChange(e.target.value, "password");
                }}
              />
            </div>
            {passwordError1 && (
              <div className="text-red-500 text-left pt-3">
                {passwordError1}
              </div>
            )}
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">치과의사명</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="소속회사명을 입력해주세요."
                onChange={(e) =>
                  handleFormDataChange(e.target.value, "companyName")
                }
              />
            </div>
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">치과이름</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="치과이름을 입력해주세요."
                onChange={(e) =>
                  handleFormDataChange(e.target.value, "dentistName")
                }
              />
            </div>
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">전화번호</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <div className="input-container-number">
                <div className="div1">
                  <div className="max-w-[922px] w-full">
                    <Input
                      className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      placeholder="휴대폰 번호를 입력해주세요.(-제외)"
                      onChange={(e) =>
                        handleFormDataChange(e.target.value, "phoneNumber")
                      }
                    />
                  </div>
                  <div>
                    <button
                      disabled={formData.phoneNumber === "" ? true : false}
                      onClick={handleSendCode}
                      className="text-[14px] bg-[#EBF5F2] text-[#154D57] leading-[20px] font-normal tracking-[-0.35px] py-[13px] px-[17px] rounded-lg"
                    >
                      인증번호 받기
                    </button>
                  </div>
                </div>
                <div className="div2">
                  <Input
                    className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                    placeholder="인증번호 6자리 숫자 입력"
                    onChange={(e) =>
                      handleFormDataChange(e.target.value, "otp")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">면허번호</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="면허번호를 입력해주세요."
                onChange={(e) =>
                  handleFormDataChange(e.target.value, "licenseNumber")
                }
              />
            </div>
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">이메일주소</div>
              <div className="star">*</div>
            </div>
            <div className="A1">
              <Input
                className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                placeholder="이메일주소를 입력해주세요."
                onChange={(e) => handleFormDataChange(e.target.value, "email")}
                type="email"
              />
            </div>
          </div>
          <div className="input1">
            <div className="Q1">
              <div className="text">사업자등록증</div>
              <div className="star">*</div>
            </div>
            <div>
              <label
                htmlFor="fileInput"
                className="flex flex-col  justify-center w-full border-[1px]  border-[#D4D4D8] rounded-lg cursor-pointer"
                style={{ background: "rgba(255, 255, 255, 0.05)" }}
              >
                <input
                  type="file"
                  id="fileInput"
                  name="avatar"
                  accept=".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {previewUrls.length === 0 && (
                  <div className="flex flex-row items-center justify-center py-[47px] gap-4">
                    <img src="/static/images/uploadimage.svg" alt="" />
                    <p className="font-['Pretendard'] font-normal text-[16px] leading-[24px]  items=center justify-center tracking-[-0.4px]">
                      여기로 이미지를 드래그하거나 파일을 업로드 해주세요.
                    </p>
                  </div>
                )}
                <div className="flex flex-wrap gap-4">
                  {previewUrls.map((url, index) => (
                    <div key={index} className="relative left-0 p-4">
                      <img
                        src={url}
                        alt={`File ${index}`}
                        style={{ maxWidth: "100px" }}
                      />
                      <button
                        className="absolute top-0 right-0 p-2 text-red-500"
                        onClick={() => handleDelete(index)}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 1.5C13.1475 1.5 16.5 4.8525 16.5 9C16.5 13.1475 13.1475 16.5 9 16.5C4.8525 16.5 1.5 13.1475 1.5 9C1.5 4.8525 4.8525 1.5 9 1.5ZM11.6925 5.25L9 7.9425L6.3075 5.25L5.25 6.3075L7.9425 9L5.25 11.6925L6.3075 12.75L9 10.0575L11.6925 12.75L12.75 11.6925L10.0575 9L12.75 6.3075L11.6925 5.25Z"
                            fill="#D4D4D8"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className="l">
          <div className="star">*</div>
          <div className="w">필수입력사항</div>
        </div>
        <div className="buttoncont">
          <button className="lbutton" type="submit" onClick={handleSuccess}>
            확인
          </button>
        </div>

        {codeSent === true && (
          <StoreAlertBoxSignUp
            title="회원가입"
            subtitle="인증번호를 발송했습니다."
            onConfirm={handleCodeSentClose}
          />
        )}
        {codeInvalid === true && (
          <StoreAlertBoxSignUp
            title="회원가입"
            subtitle="인증번호가 올바르지 않습니다."
            onConfirm={handleInvalidCodeClose}
          />
        )}
        {success === true && (
          <StoreAlertBoxSignUp
            title="회원가입"
            subtitle="가입이 완료되었습니다."
            onConfirm={handleSuccessClose}
          />
        )}
        {emptyFields === true && (
          <StoreAlertBoxSignUp
            title="회원가입"
            subtitle="Fill all the inputs."
            onConfirm={() => setEmptyFields(false)}
          />
        )}
      </div>
    </form>
  );
}

export default FileManagement;
