import React, { useState } from "react";
import LoggedHeader from "../../components/LoggedHeader";
import SubHeader from "../../components/SubHeader";
import PatientInfo from "./components/PatientInfo";
import StorageInfo from "./components/StorageInfo";

import PatientList from "./components/PatientList/WaitingList.js";
import WaitingList from "./components/PatientList/WaitingList.js";
import PreparingList from "./components/PatientList/PreparingList";
import TreatmentList from "./components/PatientList/TreatmentList";
import Storage from "./components/PatientList/Storage";
import PatientsHeader from "../../components/PatientsHeader";
import TreatmentPlan from "./components/TreatmentPlan";
import TreatmentCompleted from "./components/PatientList/TreatmentCompleted";

function Desk() {
  const [data, setData] = useState("");
  return (
    <div className=" ">
      <div className="px-[32px] py-[48px] flex flex-row gap-[28px] w-full">
        <div className="max-w-[462px] w-full gap-[28px]  flex flex-col ">
          <div className="w-full">
            <PatientInfo data={data} />
          </div>
          <div className="w-full">
            <StorageInfo />
          </div>
          {/* <div className="w-full">
            <TreatmentPlan />
          </div> */}
          <div className="w-full"></div>
        </div>
        <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
          <PatientsHeader />
          <div className="flex flex-row gap-[40px] w-full">
            <div className=" w-[50%] ">
              <WaitingList setData={setData} />
            </div>
            <div className="  w-[50%]">
              <PreparingList setData={setData} />
            </div>
          </div>
          <div className="flex flex-row gap-[40px] pt-10">
            <div className="  w-full">
              <TreatmentList setData={setData} />
            </div>
            <div className="  w-full">
              <Storage setData={setData} />
            </div>
          </div>

          {/* <div className="pt-10">
            <TreatmentCompleted />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Desk;
