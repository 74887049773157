import React, { useEffect, useState } from "react";
import NextTreatmentModal from "../NextTreatment";
import ClinicTab from "../ClinicTab";
import SuccessAlert from "../SuccessAlert";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import StoreAlertBoxSignUp from "../../../components/SuccessAlertSignup";
export default function TreatmentplanS122() {
  const [Salary, setSalary] = useState(true);
  const [nonBenefit, setNonBenefit] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [injuryVisible, setInjuryVisible] = useState(false);
  const [selectedTooth, setSelectedTooth] = useState(false);
  const [injuryTypeSelector, setInjuryTypeSelector] = useState("CU");
  const [priceAmount, setPriceAmount] = useState(500000);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [priceAmountTotal, setPriceAmountTotal] = useState(0);
  const [reset, setReset] = useState(false);

  const [view, setView] = useState(false);

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    if (item === "salary") {
      // Remove the extra space in " salary"
      setSalary(true);
      setNonBenefit(false);
    } else if (item === "nonBenefit") {
      setSalary(false);
      setNonBenefit(true);
    }
  };

  const openModal = () => {
    setAlertModal(true);
  };

  const closeModal = (e) => {
    setAlertModal(false);
    setReset(true);
    setView(true);
    setInjuryTypeSelector("12");
    localStorage.setItem("completed", "true");
    localStorage.setItem("visible", true);
    localStorage.setItem("scenario", 122);
    alert("fefe");
    // switch (step) {
    //   case 2:
    //     navigate("/doctor/treatmentplan/3");
    //     break;
    //   case 3:
    //     navigate("/doctor/treatmentplan/4");
    //     break;
    //   case 4:
    //     navigate("/doctor/treatmentplan/5");
    //     break;
    //   case 5:
    //     navigate("/doctor/treatmentplan/6");
    //     break;
    //   case 6:
    //     navigate("/doctor/treatmentplan/7");
    //     break;
    //   default:
    //     break;
    // }
  };

  useEffect(() => {
    document.querySelectorAll(".trt-btn").forEach((item) => {
      item.addEventListener("click", setActive);
    });
    document.querySelectorAll(".injury-item").forEach((item) => {
      item.addEventListener("click", setInjuryActive);
    });

    return () => {
      document.querySelectorAll(".trt-btn").forEach((item) => {
        item.removeEventListener("click", setActive);
      });
      document.querySelectorAll(".injury-item").forEach((item) => {
        item.removeEventListener("click", setInjuryActive);
      });
    };
  }, [Salary, nonBenefit, injuryVisible, injuryTypeSelector]);

  function setActive(e) {
    document.querySelectorAll(".trt-btn").forEach((item) => {
      if (item === e.target) {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        } else {
          item.classList.add("active");
          setInjuryTypeSelector(item.innerText);
          setInjuryVisible(true);
        }
      } else {
        item.classList.remove("active");
      }
    });
  }
  useEffect(() => {
    if (reset) {
      setInjuryVisible(false);
      setInjuryTypeSelector("");
      setReset(false);
      document.querySelectorAll(".trt-btn").forEach((elem) => {
        elem.classList.remove("active");
      });
    }
  }, [reset]);

  function setInjuryActive(e) {
    document.querySelectorAll(".injury-item").forEach((item) => {
      if (item === e.target.parentElement) {
        item.classList.toggle("bg-[#EBF5F2]");
      } else {
        item.classList.remove("bg-[#EBF5F2]");
      }
    });
  }

  function calculateDiscount(percentage) {
    if (percentage * 1 === NaN) {
      alert("hello");
    }
    console.log(typeof percentage * 1);
    if (percentage > 100) {
      setDiscountAmount(0);
      setPriceAmountTotal(priceAmount);
      return;
    }
    if (percentage === 0) {
      setPriceAmountTotal(priceAmount);
      return;
    }
    setDiscountAmount(percentage);
    setPriceAmountTotal(priceAmount - (priceAmount / 100) * percentage);
  }
  return (
    <div className="flex  gap-[27px]  h-auto px-[32px] my-[48px] w-full">
      <div className=" flex flex-col gap-[27px] w-[50%]">
        <div className="flex flex-col gap-[27px]  w-full">
          <div className="flex flex-col gap-[21px] rounded-lg bg-white shadow-md p-[20px] flex flex-row">
            <div className="flex justify-between">
              <div className="text-[#111] font-['pretendard'] text-[18px] font-[600] leading-[26px] tracking-[-0.45]">
                환자정보
              </div>
              <div class="relative flex  border rounded-full">
                <input
                  type=""
                  name=""
                  class="max-w-[200px] w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                  placeholder="이름을 입력해주세요."
                />
                <button type="submit" class="ml-2 px-3">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M13.2148 13.2148L17.5004 17.5004"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex justify-between gap-[8px]">
              <div class="  w-full border-[1px] border-[#D4D4D8] rounded-[8px]">
                <table className="w-full flex-shrink">
                  <tbody className="w-full  ">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          정호걸(005258)
                        </div>
                      </td>
                      <td className="px-3 py-2  bg-[#F1F8FA] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          720408/남(51)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        전화번호
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-5351-0064
                        </div>
                      </td>
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          -
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col gap-[8px]">
                <button className="bg-[#DBEEF9] text-[13px] font-[600] leading-[19px] tracking-[-0.325] px-[16px] py-4   text-[#1778B0] rounded-[4px] max-w-[110px] h-auto">
                  서류 발급
                </button>
                <button className="bg-[#D0E7DC] px-[16px] py-4 text-[#154D57] text-[13px] font-[600] leading-[19px] tracking-[-0.325] rounded-[4px] w-[110px] h-[50px]">
                  구환추가
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full  h-auto rounded-lg bg-white   p-[20px]">
          <ClinicTab step={view} />
        </div>
      </div>

      <div className="w-[50%] ">
        <div className="flex flex-col  rounded-lg bg-white max-w-[874px] w-full shadow-md p-[20px] border rounded-md border-gray-300 ">
          <div className="flex w-full  ">
            <NextTreatmentModal reset={reset} setReset={setReset} />
          </div>

          <div className="flex gap-[16px]  ">
            <div className="flex  gap-[16px] w-[50%] max-w-[456px] h-auto p-[20px] border rounded-md border-gray-300 bg-white">
              <div className="flex flex-col items-start h-[421px]">
                <div className="flex flex-row gap-[30px] mb-4">
                  <div
                    onClick={() => handleItemClick("salary")}
                    active={Salary ? "true" : "false"}
                    className={`cursor-pointer ${
                      Salary
                        ? "text-[#154D57] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] border-b border-[#154D57] border-b-[3px] w-[35px] text-center"
                        : "text-[#767676] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-center"
                    }`}
                  >
                    급여
                  </div>
                  <div
                    onClick={() => handleItemClick("nonBenefit")}
                    active={nonBenefit ? "true" : "false"}
                    className={`cursor-pointer ${
                      nonBenefit
                        ? "text-[#154D57] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] border-b border-[#154D57] border-b-[3px]  w-[55px] text-center "
                        : "text-[#767676] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-center"
                    }`}
                  >
                    비급여
                  </div>
                </div>

                <div>
                  {Salary && (
                    <div>
                      <div className="flex flex-col gap-4 pb-[124px]">
                        <div className="flex flex-row gap-[6px]">
                          <div className="bg-[#EBF5F2] text-[#154D57] text-[12px] font-normal leading-[18px] text-[#154D57] font-semibold leading-[19px] py-1 px-[17px] border-[#D4D4D8] rounded-[4px] text-[13px] items-center justify-content tracking-[-0.325px]">
                            치주
                          </div>
                          <div className="gap-2 flex flex-col">
                            <div className="flex flex-row gap-[6px]">
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  RP
                                </button>
                              </div>
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  CU
                                </button>
                              </div>
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  Flap
                                </button>
                              </div>
                              <div>
                                <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  CLP
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div className="bg-[#EBF5F2] text-[#154D57] font-normal text-[#154D57] font-semibold leading-[19px] py-[20px] px-[17px] border-[#D4D4D8] rounded-[4px] text-[13px] tracking-[-0.325px]">
                            외과
                          </div>
                          <div className="gap-2 flex flex-col">
                            <div className="flex flex-row gap-[6px]">
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  발치
                                </button>
                              </div>
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  I&D
                                </button>
                              </div>
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  Apicoectomy
                                </button>
                              </div>
                            </div>
                            <div className="flex flex-row gap-[6px]">
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  치조골성형술
                                </button>
                              </div>
                              <div>
                                <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                  치은판절제술
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div className="bg-[#EBF5F2] text-[#154D57] text-[12px] font-normal leading-[18px] text-[#154D57] font-semibold leading-[19px] py-1  px-[17px]  border-[#D4D4D8] rounded-[4px] text-[13px] items-center justify-content tracking-[-0.325px]">
                            보존
                          </div>
                          <div className="flex flex-row gap-[6px]">
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                GI 충전
                              </button>
                            </div>
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                레진충전
                              </button>
                            </div>
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                근관치료
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div className="bg-[#EBF5F2] text-[#154D57] text-[12px] font-normal leading-[18px] text-[#154D57] font-semibold leading-[19px] py-1  px-[17px]  border-[#D4D4D8] rounded-[4px] text-[13px] items-center justify-content tracking-[-0.325px]">
                            보철
                          </div>
                          <div className="flex flex-row gap-[6px]">
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                임플란트
                              </button>
                            </div>
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                부분틀니
                              </button>
                            </div>
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                완전틀니
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div className="bg-[#EBF5F2] text-[#154D57] text-[12px] font-normal leading-[18px] text-[#154D57] font-semibold leading-[19px] py-1 px-[17px] border-[#D4D4D8] rounded-[4px] text-[13px] items-center justify-content tracking-[-0.325px]">
                            소치
                          </div>
                          <div className="flex flex-row gap-[6px]">
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                유치발치
                              </button>
                            </div>
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                실런트
                              </button>
                            </div>
                            <div>
                              <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                                신경치료
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {nonBenefit === true && (
                    <div className="flex flex-col gap-[16px]">
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            임플란트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            골이식술
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            부분틀니
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            완전틀니
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            크라운
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            브릿지
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            코어
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            포스트
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            인레이
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            온레이
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            레진충전
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            미백
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            라미네이트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            교정상담
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            불소도포
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex  w-[50%] max-w-[402px] h-auto mx-auto p-[20px] border rounded-md border-gray-300 bg-white ">
              <div className="flex w-full flex-col gap-[16px]">
                <div className="text-[#111] font-['pretendard'] text-[18px] font-[600] leading-[26px] tracking-[-0.45]">
                  {!nonBenefit ? "가능 상병명" : "금액"}
                </div>
                <div className="overflow-scroll w-full scrollbarhidden">
                  {injuryVisible && injuryTypeSelector === "CU" ? (
                    <table className="w-full border-[1px] border-[#D4D4D8] rounded-[8px] h-auto">
                      <tbody className="w-full  ">
                        <tr className="border-b border-gray-300 bg-[#F1F8FA]">
                          <td className="px-3 py-2 rounded-tl-[8px] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                            코드
                          </td>
                          <td className="px-3 py-2 rounded-tr-[8px] ">
                            <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              가능 상병명
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.30
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 단순치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item ">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.31
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 복합치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2 font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.32
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 치관치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2 font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.38
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              기타 명시된 만성 치주염
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : injuryTypeSelector === "발치" ? (
                    <table className="w-full border-[1px] border-[#D4D4D8] rounded-[8px] h-auto">
                      <tbody className="w-full  ">
                        <tr className="border-b border-gray-300 bg-[#F1F8FA]">
                          <td className="px-3 py-2 rounded-tl-[8px] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                            코드
                          </td>
                          <td className="px-3 py-2 rounded-tr-[8px] ">
                            <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              가능 상병명
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.30
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 단순치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.31
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 복합치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K07.38
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치아위치의 기타 명시된 이상
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K08.3
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              잔류치근
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            S02.54
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수침범이 있는 치관의 파절
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            S02.55
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치근의 파절
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            S02.56
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치근을 포함한 치관의 파절
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            S02.57
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치아의 다발성 파절
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : injuryTypeSelector === "근관치료" ? (
                    <table className="w-full border-[1px] border-[#D4D4D8] rounded-[8px] h-auto">
                      <tbody className="w-full  ">
                        <tr className="border-b border-gray-300 bg-[#F1F8FA]">
                          <td className="px-3 py-2 rounded-tl-[8px] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                            코드
                          </td>
                          <td className="px-3 py-2 rounded-tr-[8px] ">
                            <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              가능 상병명
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.01
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              비가역적 치수염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.1
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수의 괴사
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.2
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수변성
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.3
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수내의 이상경조직형성
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.4
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수기원의 급성 근단치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.5
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 근단치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.60
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              상악동으로 연결된 동
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.61
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              비강으로 연결된 동
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    nonBenefit &&
                    injuryTypeSelector === "크라운" && (
                      <div className="price-cal">
                        <table>
                          <tbody>
                            <tr>
                              <td className="col-names">금액</td>
                              <td align="left">{priceAmount}</td>
                            </tr>
                            <tr>
                              <td className="col-names">
                                할인율(%) or 할인금액
                              </td>
                              <td align="left">
                                <input
                                  min={0}
                                  max={100}
                                  value={discountAmount}
                                  onChange={(e) =>
                                    calculateDiscount(e.target.value)
                                  }
                                  type="text"
                                  placeholder="할인율(%) or 할인금액을 입력해주세요"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-names">최종금액</td>
                              <td
                                align="left"
                                className="font-semibold text-[#154D57]"
                              >
                                {priceAmountTotal}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-[16px] pb-[20px] bottom-0 right-0">
            <button
              onClick={openModal}
              className=" flex inline-flex py-2 px-[42px] rounded-[8px] bg-[#317580] text-[#FFF] text-[13px] font-[600] "
            >
              추가
            </button>
          </div>

          {alertModal === true && (
            <StoreAlertBoxSignUp
              title="치료계획"
              subtitle="치료계획이 추가되었습니다."
              onConfirm={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}
