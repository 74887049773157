import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import "./App.scss";

import Header from "./app/components/Header";
import SignIn from "./app/Pages/SignIn";
import FindID from "./app/Pages/FindID";
import ChangePassword from "./app/Pages/ChangePassword";

import LoggedHeader from "./app/components/LoggedHeader";
import SubHeader from "./app/components/SubHeader";
import Desk from "./app/Pages/Desk";
import {
  ReceptionWaitingScenario6,
  ReceptionWaitingScenario7,
  ReceptionWaitingScenario8,
  ReceptionWaitingScenario9,
  ReceptionWaitingScene6,
} from "./app/Pages/ReceptionWaiting";
import { ReceptionWaiting } from "./app/Pages/ReceptionWaiting";
import Test from "./app/Pages/Desk/components/PatientList/Test";
import Membership from "./app/Pages/Join-membership";
import {
  DeskReservation,
  DeskReservationExactScenario6,
  DeskReservationExactScenario6Calender,
  DeskReservationExactScenario6CalenderView,
  DeskReservationScenario12,
  DeskReservationScenario6,
  DeskReservationScenario6Calender,
  DeskReservationScenario6CalenderView,
} from "./app/Pages/DeskReservation";
import Questionnaire from "./app/Pages/Desk-Questionnaire";
import QuestionnaireS2 from "./app/Pages/Questionnaire2.2";
import QuestionnaireS3 from "./app/Pages/Questionnaire3";
import QuestionnaireS4 from "./app/Pages/Questionnaire4";
import LastPage from "./app/Pages/LastPage";
import Questionnaire2 from "./app/Pages/Questionnaire2";
import MainPage from "./app/Pages/components/main";
import PatientInfo from "./app/Pages/Desk/components/PatientInfo";
import PatientInformation from "./app/Pages/PatientInfo";
import { useEffect } from "react";
import { useState } from "react";
import Treatmentplan from "./app/Pages/TreatmentPlan";
import { DataProvider } from "./app/context/DataProvider";
import ClinicTab14 from "./app/Pages/Scenario5/ClinicTab14";
import ClinicTab2 from "./app/Pages/Scenario5/ClinicTab14-1";
import ClinicTab3 from "./app/Pages/Scenario5/ClinicTab-14-2";
import ClinicTab4 from "./app/Pages/Scenario5/ClinicTab14-3";
import ClinicTab5 from "./app/Pages/Scenario5/ClinicTab-14-4";
import ClinicTab6 from "./app/Pages/Scenario5/ClinicTab-14-5";
import ClinicTab7 from "./app/Pages/Scenario5/CinicTab-14-6";
import ClinicTab8 from "./app/Pages/Scenario5/ClinicTab-14-7";
import ClinicTab9 from "./app/Pages/Scenario5/ClinicTab-14-8";

import ClinicTabScenarioSix from "./app/Pages/Scenario6/ClinicTab6";
import ClinicTabScenarioSix1 from "./app/Pages/Scenario6/ClinicTab6-1";
import ClinicTabScenarioSix2 from "./app/Pages/Scenario6/ClinicTab6-2";
import ClinicTabScenarioSix3 from "./app/Pages/Scenario6/ClinicTab6-3";
import ClinicTabScenarioSix4 from "./app/Pages/Scenario6/ClinicTab6-4";
import ClinicTabScenarioSix5 from "./app/Pages/Scenario6/ClinicTab6-5";
import ClinicTabScenarioSix6 from "./app/Pages/Scenario6/ClinicTab6-6";
import ClinicTabScenarioSix7 from "./app/Pages/Scenario6/ClinicTab6-7";
// import ClinicTabScenarioSix8 from "./app/Pages/Scenario6/ClinicTab6-8";
// import ClinicTabScenarioSix9 from "./app/Pages/Scenario6/ClinicTab6-9";

import ClinicTab from "./app/Pages/Clinic Tab";
import {
  DeskReservationScenario7,
  DeskReservationScenario7Calender,
  DeskReservationScenario7CalenderView,
} from "./app/Pages/DeskReservation/Scenario7";

import {
  DeskReservationScenario8,
  DeskReservationScenario8Calender,
  DeskReservationScenario8CalenderView,
} from "./app/Pages/DeskReservation/Scenario8";

import {
  DeskReservationScenario9,
  DeskReservationScenario9Calender,
  DeskReservationScenario9CalenderView,
} from "./app/Pages/DeskReservation/Scenario9";

import ClinicTab7_1 from "./app/Pages/Scenario7/ClinicTab14";
import ClinicTab7_2 from "./app/Pages/Scenario7/ClinicTab14-1";
import ClinicTab7_3 from "./app/Pages/Scenario7/ClinicTab-14-2";
import ClinicTab7_4 from "./app/Pages/Scenario7/ClinicTab14-3";
import ClinicTab7_5 from "./app/Pages/Scenario7/ClinicTab-14-4";
import ClinicTab7_6 from "./app/Pages/Scenario7/ClinicTab-14-5";
import ClinicTab7_7 from "./app/Pages/Scenario7/CinicTab-14-6";
import ClinicTab7_8 from "./app/Pages/Scenario7/ClinicTab-14-7";
import ClinicTab7_9 from "./app/Pages/Scenario7/ClinicTab-14-8";

import ClinicTab9_1 from "./app/Pages/Scenario9/ClinicTab14";
import ClinicTab9_2 from "./app/Pages/Scenario9/ClinicTab14-1";
import ClinicTab9_3 from "./app/Pages/Scenario9/ClinicTab-14-2";
import ClinicTab9_4 from "./app/Pages/Scenario9/ClinicTab14-3";
import ClinicTab9_5 from "./app/Pages/Scenario9/ClinicTab-14-4";
import ClinicTab9_6 from "./app/Pages/Scenario9/ClinicTab-14-5";
import ClinicTab9_7 from "./app/Pages/Scenario9/CinicTab-14-6";
import ClinicTab9_8 from "./app/Pages/Scenario9/ClinicTab-14-7";
import ClinicTab9_9 from "./app/Pages/Scenario9/ClinicTab-14-8";
import ClinicTabScenarioEight from "./app/Pages/Scenario8/ClinicTab14";
import ClinicTabScenarioEight1 from "./app/Pages/Scenario8/ClinicTab14-1";
import ClinicTabScenarioEight2 from "./app/Pages/Scenario8/ClinicTab-14-2";
import ClinicTabScenarioEight3 from "./app/Pages/Scenario8/ClinicTab14-3";
import ClinicTabScenarioEight4 from "./app/Pages/Scenario8/ClinicTab-14-4";
import ClinicTabScenarioEight5 from "./app/Pages/Scenario8/ClinicTab-14-5";
import ClinicTabScenarioEight7 from "./app/Pages/Scenario8/ClinicTab-14-7";
import ClinicTabScenarioEight6 from "./app/Pages/Scenario8/CinicTab-14-6";
import TreatmentplanScene10 from "./app/Pages/TreatmentPlanScene10";
import ReceptionWaitingS12 from "./app/Pages/Scenario12-1";
import MainPageS12 from "./app/Pages/Scenario12-1/Questionnaire/main";
import PatientInformationS12 from "./app/Pages/Scenario12-1/PatientInfo";
import ReceptionWaitingS122 from "./app/Pages/Scenario12-2";
import TreatmentplanS122 from "./app/Pages/Scenario12-2/TreatmentPlanS12";
import {
  DeskReservationScenario12Calender,
  DeskReservationScenario12CalenderView,
} from "./app/Pages/DeskReservation/Scenario12";

function App() {
  const [isDeskPage, setIsDeskPage] = useState("");
  const [helpVisible, setHelpVisible] = useState(false);
  // Define a state to track whether the Desk page is displayed
  // useEffect(() => {
  //   setIsDeskPage(
  //     window.location.pathname === "/doctor/receptionwaiting"
  //       ? true
  //       : // : window.location.pathname === "/deskreservation"
  //       // ? true
  //       window.location.pathname === "/patientInfo"
  //       ? true
  //       : window.location.pathname === "/doctor/treatmentplan"
  //       ? true
  //       : false
  //   );
  // }, [isDeskPage]);

  return (
    <DataProvider>
      <Router>
        {/* Conditionally render the Header based on the route */}
        {window.location.pathname === "/" ||
        window.location.pathname === "/findid" ||
        window.location.pathname === "/resetpassword" ||
        window.location.pathname === "/signup" ? (
          <Header />
        ) : (
          <>
            <LoggedHeader />
            <SubHeader />
          </>
        )}

        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/findid" element={<FindID />} />
          <Route path="/resetpassword" element={<ChangePassword />} />
          <Route path="/desk" element={<Desk />} />
          <Route path="/signup" element={<Membership />} />
          <Route
            path="/doctor/receptionwaitingDesk"
            element={<ReceptionWaitingScenario6 />}
          />
          <Route path="/Questionnaire" element={<MainPage />} />
          <Route
            path="/doctor/receptionwaiting"
            element={<ReceptionWaiting />}
          />
          <Route
            path="/doctor/calendar"
            element={<DeskReservationScenario6 />}
          />
          <Route
            path="/doctor/receptionwaitingDesk"
            element={<ReceptionWaitingScenario6 />}
          />
          <Route path="/doctor/receptionwaiting/1" element={<ClinicTab14 />} />
          <Route path="/doctor/receptionwaiting/2" element={<ClinicTab2 />} />
          <Route path="/doctor/receptionwaiting/3" element={<ClinicTab3 />} />
          <Route path="/doctor/receptionwaiting/4" element={<ClinicTab4 />} />
          <Route path="/doctor/receptionwaiting/5" element={<ClinicTab5 />} />
          <Route path="/doctor/receptionwaiting/6" element={<ClinicTab6 />} />
          <Route path="/doctor/receptionwaiting/7" element={<ClinicTab7 />} />
          <Route path="/doctor/receptionwaiting/8" element={<ClinicTab8 />} />
          <Route path="/doctor/receptionwaiting/9" element={<ClinicTab9 />} />
          <Route
            path="/doctor/scene6/receptionwaiting"
            element={<ReceptionWaitingScene6 />}
          />
          <Route path="/doctor/scenario6" element={<ClinicTabScenarioSix />} />
          <Route
            path="/doctor/scenario6/1"
            element={<ClinicTabScenarioSix1 />}
          />
          <Route
            path="/doctor/scenario6/2"
            element={<ClinicTabScenarioSix2 />}
          />
          <Route
            path="/doctor/scenario6/3"
            element={<ClinicTabScenarioSix3 />}
          />
          <Route
            path="/doctor/scenario6/4"
            element={<ClinicTabScenarioSix4 />}
          />
          <Route
            path="/doctor/scenario6/5"
            element={<ClinicTabScenarioSix5 />}
          />
          <Route
            path="/doctor/scenario6/6"
            element={<ClinicTabScenarioSix6 />}
          />
          <Route
            path="/doctor/scenario6/7"
            element={<ClinicTabScenarioSix7 />}
          />
          <Route
            path="/doctor/scenario6/8"
            element={<DeskReservationExactScenario6 />}
          />
          <Route
            path="/doctor/scenario6/9"
            element={<DeskReservationExactScenario6Calender />}
          />
          <Route
            path="/doctor/scenario6/10"
            element={<DeskReservationExactScenario6CalenderView />}
          />
          <Route
            path="/doctor/scenario7/8"
            element={<DeskReservationScenario7 />}
          />
          <Route
            path="/doctor/scenario7/9"
            element={<DeskReservationScenario7Calender />}
          />
          <Route
            path="/doctor/scenario7/10"
            element={<DeskReservationScenario7CalenderView />}
          />
          {/* Scenario 8 */}
          <Route
            path="/doctor/scenario8/8"
            element={<DeskReservationScenario8 />}
          />
          <Route
            path="/doctor/scenario8/9"
            element={<DeskReservationScenario8Calender />}
          />
          <Route
            path="/doctor/scenario8/10"
            element={<DeskReservationScenario8CalenderView />}
          />
          {/* Scenario 9 */}
          <Route
            path="/doctor/scenario9/8"
            element={<DeskReservationScenario9 />}
          />
          <Route
            path="/doctor/scenario9/9"
            element={<DeskReservationScenario9Calender />}
          />
          <Route
            path="/doctor/scenario9/10"
            element={<DeskReservationScenario9CalenderView />}
          />
          {/* <Route path="/doctor/scenaio6/8" element={<ClinicTabScenarioSix8 />} /> */}
          {/* <Route path="/doctor/scenario6/9" element={<ClinicTabScenarioSix9 />} /> */}
          <Route
            path="/doctor/scene8/receptionwaiting/"
            element={<ReceptionWaitingScenario8 />}
          />
          <Route
            path="/doctor/scenario8"
            element={<ClinicTabScenarioEight />}
          />
          <Route
            path="/doctor/scenario8/1"
            element={<ClinicTabScenarioEight1 />}
          />
          <Route
            path="/doctor/scenario8/2"
            element={<ClinicTabScenarioEight2 />}
          />
          <Route
            path="/doctor/scenario8/3"
            element={<ClinicTabScenarioEight3 />}
          />
          <Route
            path="/doctor/scenario8/4"
            element={<ClinicTabScenarioEight4 />}
          />
          <Route
            path="/doctor/scenario8/5"
            element={<ClinicTabScenarioEight5 />}
          />
          <Route
            path="/doctor/scenario8/6"
            element={<ClinicTabScenarioEight6 />}
          />
          <Route
            path="/doctor/scenario8/7"
            element={<ClinicTabScenarioEight7 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/"
            element={<ReceptionWaitingScenario7 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/1"
            element={<ClinicTab7_1 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/2"
            element={<ClinicTab7_2 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/3"
            element={<ClinicTab7_3 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/4"
            element={<ClinicTab7_4 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/5"
            element={<ClinicTab7_5 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/6"
            element={<ClinicTab7_6 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/7"
            element={<ClinicTab7_7 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/8"
            element={<ClinicTab7_8 />}
          />
          <Route
            path="/doctor/scene7/receptionwaiting/9"
            element={<ClinicTab7_9 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/"
            element={<ReceptionWaitingScenario9 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/1"
            element={<ClinicTab9_1 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/2"
            element={<ClinicTab9_2 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/3"
            element={<ClinicTab9_3 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/4"
            element={<ClinicTab9_4 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/5"
            element={<ClinicTab9_5 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/6"
            element={<ClinicTab9_6 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/7"
            element={<ClinicTab9_7 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/8"
            element={<ClinicTab9_8 />}
          />
          <Route
            path="/doctor/scene9/receptionwaiting/9"
            element={<ClinicTab9_9 />}
          />
          {/* <Route
            path="/doctor/receptionwaiting/3"
            element={<ReceptionWaiting />}
          /> */}
          <Route
            path="/deskreservation"
            element={<DeskReservationScenario6 />}
          />{" "}
          <Route
            path="/deskreservationscenario6Calender"
            element={<DeskReservationScenario6Calender />}
          />
          <Route
            path="/deskreservationscenario6CalenderView"
            element={<DeskReservationScenario6CalenderView />}
          />
          <Route path="/Questionnaire" element={<MainPage />} />
          <Route path="/Questionnaire" element={<MainPage />} />
          <Route path="/doctor/patientInfo" element={<PatientInformation />} />
          <Route path="/doctor/treatmentplan" element={<Treatmentplan />} />
          <Route
            path="/doctor/treatmentplan/1"
            element={<Treatmentplan step={1} />}
          />
          <Route
            path="/doctor/treatmentplan/2"
            element={<Treatmentplan step={2} />}
          />
          <Route
            path="/doctor/treatmentplan/3"
            element={<Treatmentplan step={3} />}
          />
          <Route
            path="/doctor/treatmentplan/4"
            element={<Treatmentplan step={4} />}
          />
          <Route
            path="/doctor/treatmentplan/5"
            element={<Treatmentplan step={5} />}
          />
          <Route
            path="/doctor/treatmentplan/6"
            element={<Treatmentplan step={6} />}
          />
          {/* //navigate to 1222 */}
          <Route path="/scenario122" element={<ReceptionWaitingS122 />} />
          <Route
            path="/doctor/scene10/treatmentplan"
            element={<TreatmentplanScene10 />}
          />
          <Route
            path="/doctor/scene10/treatmentplan/1"
            element={<TreatmentplanScene10 step={1} />}
          />
          <Route
            path="/doctor/scene10/treatmentplan/2"
            element={<TreatmentplanScene10 step={2} />}
          />
          <Route
            path="/doctor/scene10/treatmentplan/3"
            element={<TreatmentplanScene10 step={3} />}
          />
          <Route
            path="/doctor/scenario10"
            element={<ReceptionWaiting scenario={10} />}
          />
          <Route path="/clinictab" element={<ClinicTab />} />
          <Route
            path="/doctor/scenario10"
            element={<ReceptionWaiting scenario={10} />}
          />
          <Route path="/scenario12" element={<ReceptionWaitingS12 />} />
          <Route path="/Questionnaire12" element={<MainPageS12 />} />
          <Route path="/PatientInfoS12" element={<PatientInformationS12 />} />
          {/* Scenario 13 */}
          {/* <Route path="/scenario122" element={<ReceptionWaitingS122 />} /> */}
          <Route
            path="/scenario122/treatmentplan"
            element={<TreatmentplanS122 />}
          />
          <Route
            path="/deskreservationS12"
            element={<DeskReservationScenario12 />}
          />{" "}
          <Route
            path="/deskreservationS12/Calender"
            element={<DeskReservationScenario12Calender />}
          />{" "}
          <Route
            path="/deskreservationS12/CalenderView"
            element={<DeskReservationScenario12CalenderView />}
          />{" "}
        </Routes>

        {/* <div className="help-desk-container">
          <div className="help">
            <strong
              style={{ cursor: "pointer" }}
              onClick={() => setHelpVisible((prev) => !prev)}
            >
              Help
            </strong>
            {
              <div style={{display:helpVisible ? "":"none"}} onClick={()=>setHelpVisible(false)}>
            <a href="/doctor/receptionwaitingDesk" state={true}  onClick={()=>{}}><div className="scenario-item">Scenario 5</div></a>
            <Link to="/doctor/scene6/receptionwaiting" onClick={()=>{}}><div className="scenario-item" >Scenario 6</div></Link>
            <Link to={"/doctor/scene7/receptionwaiting/"} onClick={()=>{window.localStorage.setItem("scenario",7)}}><div className="scenario-item">Scenario 7</div></Link>
            <Link to={"/doctor/scene8/receptionwaiting/"} onClick={()=>{window.localStorage.setItem("scenario",8)}}><div className="scenario-item">Scenario 8</div></Link>
            <Link to={"/doctor/scene9/receptionwaiting/"} onClick={()=>{window.localStorage.setItem("scenario",9)}}><div className="scenario-item">Scenario 9</div></Link>
            <Link to={"/doctor/scenario10"} onClick={()=>{window.localStorage.setItem("scenario",10);window.location.replace("/doctor/scenario10")}}><div className="scenario-item">Scenario 10</div></Link>
              </div>
            }
          </div>
        </div> */}
      </Router>
    </DataProvider>
  );
}

export default App;
