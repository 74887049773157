import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/DataProvider";

function SubHeader() {
  const [selectedTitle, setSelectedTitle] = useState("데스크");
  const { isHome, setIsHome } = useContext(DataContext);
  useEffect(() => {
    if (isHome) {
      setSelectedTitle("치료계획");
      navigate("/doctor/treatmentplan");
    }
  }, [isHome]);

  // useEffect(() => {
  //   if (!isHome) {
  //     setSelectedTitle("데스크");
  //     navigate("/doctor/receptionwaitingDesk");
  //   }
  // }, [isHome]);

  const header2Icons = [
    {
      title: "데스크",
    },
    {
      title: "예약",
    },
    {
      title: "환자정보",
    },
  ];

  const header3Icons = [
    {
      title: "전자차트",
    },
    {
      title: "치료계획",
    },
  ];

  const handleChartClick = (title) => {
    setSelectedTitle(title);
    if (title === "데스크") {
      switch (localStorage.getItem("scenario") * 1) {
        case 5:
          navigate("/doctor/receptionwaitingDesk");
          localStorage.setItem("completed", "true");
          break;
        case 6:
          setPatientIsPaymentScenario6(5);
          navigate("/doctor/scene6/receptionwaiting");
          localStorage.setItem("completed", "true");
          break;

        case 12:
          navigate("/scenario122");
          localStorage.setItem("completed", "true");
          break;
        case 7:
          setPatientIsProcessingScenario7(9);
          setPatientIsPaymentScenario7(7);
          setPatientIsPaymentScenario7(6);
          setPatientDisplayNoneScenario7(1);
          setPatientDisplayNoneScenario7(2);
          setPatientDisplayNoneScenario7(20);
          setPatientDisplayNoneScenario7(5);
          localStorage.setItem("completed", "true");
          navigate("/doctor/scene7/receptionwaiting");
          break;
        case 8:
          setPatientDisplayNoneScenario8(1);
          setPatientDisplayNoneScenario8(2);
          setPatientDisplayNoneScenario8(3);
          setPatientDisplayNoneScenario8(5);
          setPatientDisplayNoneScenario8(6);
          localStorage.setItem("completed", "true");

          setPatientDisplayNoneScenario8(20);
          setPatientIsCompletedScenario8(9);
          setPatientIsProcessingScenario8(10);
          setPatientIsProcessingScenario8(11);
          setPatientIsPaymentScenario8(7);
          setPatientIsPaymentScenario8(8);
          navigate("/doctor/scene8/receptionwaiting");

          break;

        case 9:
          localStorage.setItem("completed", "true");
          // setPatientDisplayNoneScenario9(1);
          // setPatientDisplayNoneScenario9(2);
          // setPatientDisplayNoneScenario9(3);
          // setPatientDisplayNoneScenario9(5);
          // setPatientDisplayNoneScenario9(6);
          // setPatientDisplayNoneScenario9(7);
          // setPatientDisplayNoneScenario9(8);
          // setPatientDisplayNoneScenario9(20);
          // setPatientDisplayNoneScenario9(1);
          // setPatientIsPaymentScenario9(9);
          // setPatientIsPaymentScenario9(10);
          // setPatientIsPaymentScenario9(11);
          // setPatientIsPaymentScenario9(12);
          // setPatientIsProcessingScenario9(14);
          navigate("/doctor/scene9/receptionwaiting");
          break;
        case 10:
          localStorage.setItem("completed", "true");
          navigate("doctor/scenario10");
          break;
        default:
          // localStorage.setItem("completed","true")
          navigate("/doctor/receptionwaiting/");
          break;
        case 122:
          navigate("/scenario122");
          break;
      }
    } else if (title === "예약") {
      navigate("/doctor/calendar");
    } else {
      navigate("/doctor/patientInfo");
    }
  };

  const handleHomesubClick = (title) => {
    setSelectedTitle(title);
    if (title === "전자차트") {
      switch (localStorage.getItem("scenario") * 1) {
        case 5:
          navigate("/doctor/receptionwaiting/1");
          break;
        case 6:
          navigate("/doctor/Scenario6");
          break;
        case 7:
          navigate("/doctor/scene7/receptionwaiting/1");
          break;
        case 8:
          navigate("/doctor/Scenario8");
          break;
        case 9:
          navigate("/doctor/scene9/receptionwaiting/1");
          break;

        default:
          navigate("/clinictab");
          break;
      }
    } else if (title === "치료계획") {
      switch (localStorage.getItem("scenario") * 1) {
        case 10:
          navigate("/doctor/scene10/treatmentplan");
          break;

        case 12:
          navigate("/scenario122/treatmentplan");
          break;

        default:
          navigate("/doctor/treatmentplan");
          break;
      }
    }
  };

  useEffect(() => {
    if (isHome) {
      // handleHomesubClick("치료계획")
    } else {
      console.log(window.location.pathname);
      switch (window.location.pathname) {
        case "/deskreservation":
          setSelectedTitle("예약");
          break;
        case "/deskreservationscenario6Calender":
          setSelectedTitle("예약");
          break;
        case "/deskreservationscenario6CalenderView":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario6/8":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario6/9":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario6/10":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario7/8":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario7/9":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario7/10":
          setSelectedTitle("예약");
          break;

        case "/doctor/scenario8/8":
          setSelectedTitle("예약");
          break;
        case "/doctor/Scenario8/8":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario8/9":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario8/10":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario9/8":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario9/9":
          setSelectedTitle("예약");
          break;
        case "/doctor/scenario9/10":
          setSelectedTitle("예약");
          break;
        case "/doctor/receptionwaitingDesk":
          setSelectedTitle("데스크");
          break;
        case "/doctor/scene6/receptionwaiting":
          setSelectedTitle("데스크");
          break;
        case "/doctor/scene7/receptionwaiting":
          setSelectedTitle("데스크");
          break;
        case "/doctor/scene8/receptionwaiting":
          setSelectedTitle("데스크");
          break;
        case "/doctor/scene9/receptionwaiting":
          setSelectedTitle("데스크");
          break;
        case "/doctor/calendar":
          setSelectedTitle("예약");
          break;
        case "/doctor/patientInfo":
          setSelectedTitle("환자정보");
          break;
        case "/Questionnaire":
          setSelectedTitle("환자정보");
          break;

        case "/doctor/treatmentplan":
          setSelectedTitle("환자정보");
          break;
        case "/scenario12":
          // setSelectedTitle("환자정보");
          break;
        case "/Questionnaire12":
          // setSelectedTitle("환자정보");
          break;
        case "/PatientInfoS12":
          // setSelectedTitle("환자정보");
          break;
        case "/scenario122":
          // setSelectedTitle("환자정보");
          break;
        case "/scenario122/treatmentplan":
          // setSelectedTitle("환자정보");
          break;
        case "/DeskReservationScenarioS12":
          // setSelectedTitle("환자정보");
          break;
        case "/scenario122/deskReservation":
          // setSelectedTitle("환자정보");
          break;
        case "/deskreservationS12":
          // setSelectedTitle("환자정보");
          break;
        case "/deskreservationS12/Calender":
          // setSelectedTitle("환자정보");
          break;
        case "/deskreservationS12/CalenderView":
          // setSelectedTitle("환자정보");
          break;
        default:
          handleChartClick("데스크");
          break;
      }
    }
  }, [isHome, window.location.pathname]);
  useEffect(() => {
    if (isHome) {
      handleHomesubClick("치료계획");
    }
  }, [isHome]);
  const navigate = useNavigate();
  const {
    setPatientDisplayNoneScenario7,
    setPatientDisplayNoneScenario9,
    setPatientIsPaymentScenario8,
    setPatientIsProcessingScenario8,
    setPatientIsCompletedScenario8,
    setPatientDisplayNoneScenario8,
    setPatientIsProcessingScenario7,
    setPatientIsProcessingScenario9,
    setPatientIsPaymentScenario9,
    setPatientIsCompletedScenario7,
    setPatientIsPaymentScenario7,
    setPatientIsPaymentScenario6,
    tableDataScenario7,
    setTableDataScenario7,
  } = useContext(DataContext);
  return isHome ? (
    <header className="bg-[#DEEAE8]  flex justify-between items-center  border-[#F1F1F5] w-full justify-end">
      <div className="text-[#154D57] text-[14px] leading-[20px] font-semibold flex justify-between items-center gap-[20px] ml-[179px] h-[46px]">
        {header3Icons.map((iconObj, index) => (
          <div
            key={index}
            className={`cursor-pointer flex items-center h-full`}
            style={{
              position: "relative",
              backgroundColor:
                selectedTitle === iconObj.title ? "#" : "transparent",
              color: selectedTitle === iconObj.title ? "#" : "#",
              // borderBottom: selectedTitle === iconObj.title ? "10px" : "",
              borderBottom:
                selectedTitle === iconObj.title ? "2px solid #154D57" : "",
            }}
            onClick={() => handleHomesubClick(iconObj.title)}
          >
            <span className="text-sm">{iconObj.title}</span>
          </div>
        ))}
      </div>
    </header>
  ) : (
    <header className="bg-[#DEEAE8]  flex justify-between items-center  border-[#F1F1F5] w-full justify-end">
      <div className="text-[#154D57] text-[14px] leading-[20px] font-semibold flex justify-between items-center gap-[20px] ml-[179px] h-[46px]">
        {header2Icons.map((iconObj, index) => (
          <div
            key={index}
            className={`cursor-pointer flex items-center h-full`}
            style={{
              position: "relative",
              backgroundColor:
                selectedTitle === iconObj.title ? "#" : "transparent",
              color: selectedTitle === iconObj.title ? "#" : "#",
              // borderBottom: selectedTitle === iconObj.title ? "10px" : "",
              borderBottom:
                selectedTitle === iconObj.title ? "2px solid #154D57" : "",
            }}
            onClick={() => handleChartClick(iconObj.title)}
          >
            <span className="text-sm">{iconObj.title}</span>
          </div>
        ))}
      </div>
    </header>
  );
}

export default SubHeader;
