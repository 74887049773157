import React, { useEffect } from "react";
import Swal from "sweetalert2";
import "./index.css"; // Import the CSS file

function FindIDAlert({ title, subtitle, secondarybutton, confirmbuttton }) {
  useEffect(() => {
    const showSuccessAlert = () => {
      Swal.fire({
        html: `
          <div class="success-alert">
            <div class="success-title">${title}</div>
            <div class="success-subtitle">${subtitle}</div>
            <div class="button-container">
              <button class="secondary-button">${secondarybutton}</button>
              <button class="confirm-button ">${confirmbuttton}</button>
            </div>
          </div>
        `,
        showConfirmButton: false, // Hide the default "확인" button
        showCloseButton: false,
        customClass: {
          popup: "success-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the "확인" button click
        }
      });
    };

    showSuccessAlert(); // Call the function when the component is rendered
  }, [title, subtitle]);

  return null; // Return null because you don't need to render anything in this component
}

export default FindIDAlert;
