import React, { useState } from "react";
import "./index.css";
import NextTreatmentModal from "./NextTreatmentModal";
import ReservationDetails from "./ReservationDetails";
import EditReservation from "./EditReservation";
import MakeReservation from "./MakeReservation";
import { Link, useNavigate } from "react-router-dom";

function TreatmentModal({ setIsModalOpen, treatmentModelOpen }) {
  const [sectionIndex, setSectionIndex] = useState(0);
  // const [selectedOption, setSelectedOption] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePrevSection = () => {
    if (sectionIndex > 0) {
      setSectionIndex(sectionIndex - 1); // Decrement sectionIndex to go to the previous section.
    }
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); // Initialize with an empty string or your default option

  const toggleSelect = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false); // Close the dropdown when an option is selected
  };

  const sections = [
    // Define your sections here
    // <div>
    //   <div className="flex flex-row items-center justify-center   ">
    //     <div className="modal-title flex-1">다음 진료 입력</div>
    //     <div className="cursor-pointer">
    //       <img src="/close.png" alt="" />
    //     </div>
    //   </div>
    //   <div className="flex flex-row justify-between p-[16px] mt-[20px]">
    //     <div className="flex flex-row items-center">
    //       <div>
    //         {" "}
    //         <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
    //           <tr className="h-[20px] text-center">
    //             <td className="border-r w-[63px] border-[#999]"></td>
    //             <td className="border-l w-[63px] border-[#999]">5</td>
    //           </tr>
    //           <tr className="h-[20px]">
    //             <td className="border-t border-r w-[63px] border-[#999]"></td>
    //             <td className="border-t border-l w-[63px] border-[#999]"></td>
    //           </tr>
    //         </table>
    //       </div>
    //       <div className="text-[#111111] font-normal leading-[19px] text-[13px] tracking-[-0.325px] pl-4">
    //         파노라마
    //       </div>
    //       <div className="text-[13px] font-semibold leading-[19px] tracking-[-0.325px] pl-12 text-[#1778B0]">
    //         완료
    //       </div>
    //     </div>
    //     <div className="flex gap-[20px] flex row items-center ">
    //       <div className="flex flex-row gap-2">
    //         <div className="text-[#767676] text-[12px] font-normal leading-[18px] tracking-[-0.3px] cursor-pointer">
    //           수정
    //         </div>
    //         <div className="text-[#767676] text-[12px] font-normal leading-[18px] tracking-[-0.3px] cursor-pointer">
    //           삭제
    //         </div>
    //       </div>
    //       <div className="flex flex-col">
    //         <div>
    //           <svg
    //             width="20"
    //             height="20"
    //             viewBox="0 0 20 20"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M14 11.9976L10 7.99756L6 11.9976"
    //               stroke="#317580"
    //               stroke-width="1.5"
    //               stroke-linecap="round"
    //             />
    //           </svg>
    //         </div>
    //         <div>
    //           <svg
    //             width="20"
    //             height="20"
    //             viewBox="0 0 20 20"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M6 8.00244L10 12.0024L14 8.00244"
    //               stroke="#317580"
    //               stroke-width="1.5"
    //               stroke-linecap="round"
    //             />
    //           </svg>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>,
    <div>
      <NextTreatmentModal />
    </div>,
    // <div>
    //   <ReservationDetails />
    // </div>,
    // <div>
    //   <EditReservation />
    // </div>,
    // <div>
    //   <div className="flex flex-row items-center justify-center mb-[20px]  ">
    //     <div className="modal-title flex-1">예약하기</div>
    //     <div className="cursor-pointer">
    //       <img src="/close.png" alt="" />
    //     </div>
    //   </div>
    //   <div className="w-full max-w-[596px] mx-auto h-[488px]">
    //     <div className="text-[#111111] font-semibold text-[14px] leading-[20px] tracking-[-0.35px] mb-3">
    //       아래 항목으로 수정하시겠습니까?
    //     </div>
    //     <div className="relative">
    //       <div
    //         className={`bg-[#FFF] border-[1px] border-[#D4D4D8] p-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] cursor-pointer ${
    //           isDropdownOpen ? "rounded-tl-lg rounded-tr-lg" : "rounded-lg"
    //         }`}
    //         onClick={toggleSelect}
    //       >
    //         <div className="flex flex-row items-center justify-between px-4 ">
    //           <div className="flex flex-row items-center">
    //             <div>
    //               <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //                 <tr className="h-[20px] text-center">
    //                   <td className="border-r w-[63px] border-[#999]"></td>
    //                   <td className="border-l w-[63px] border-[#999]"></td>
    //                 </tr>
    //                 <tr className="h-[20px]">
    //                   <td className="border-t border-r w-[63px] border-[#999]"></td>
    //                   <td className="border-t border-l w-[63px] border-[#999] text-right">
    //                     7
    //                   </td>
    //                 </tr>
    //               </table>
    //             </div>
    //             <div className="">{selectedOption}</div>
    //           </div>
    //           <div>
    //             <svg
    //               width="32"
    //               height="32"
    //               viewBox="0 0 32 32"
    //               fill="none"
    //               xmlns="http://www.w3.org/2000/svg"
    //             >
    //               <path
    //                 d="M9.6001 13.8667L16.0001 20.2667L22.4001 13.8667"
    //                 stroke="#317580"
    //                 stroke-width="1.5"
    //                 stroke-linecap="round"
    //               />
    //             </svg>
    //           </div>
    //         </div>
    //       </div>
    //       {isDropdownOpen && (
    //         <div className="dropdown-content border-[1px] border-t-0">
    //           <div onClick={() => handleOptionClick("파노라마 ")}>
    //             {" "}
    //             <div className="flex flex-row items-center justify-between px-6 py-3">
    //               <div className="flex flex-row items-center">
    //                 <div>
    //                   <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //                     <tr className="h-[20px] text-center">
    //                       <td className="border-r w-[63px] border-[#999]"></td>
    //                       <td className="border-l w-[63px] border-[#999]"></td>
    //                     </tr>
    //                     <tr className="h-[20px]">
    //                       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //                       <td className="border-t border-l w-[63px] border-[#999] text-right">
    //                         7
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //                 <div className="">파노라마</div>
    //               </div>
    //             </div>
    //           </div>
    //           <div onClick={() => handleOptionClick("발수, 근간확대")}>
    //             {" "}
    //             <div className="flex flex-row items-center justify-between px-6 py-3">
    //               <div className="flex flex-row items-center">
    //                 <div>
    //                   <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //                     <tr className="h-[20px] text-center">
    //                       <td className="border-r w-[63px] border-[#999]"></td>
    //                       <td className="border-l w-[63px] border-[#999]"></td>
    //                     </tr>
    //                     <tr className="h-[20px]">
    //                       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //                       <td className="border-t border-l w-[63px] border-[#999] text-right">
    //                         7
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //                 <div className="">발수, 근간확대</div>
    //               </div>
    //             </div>
    //           </div>
    //           <div onClick={() => handleOptionClick("파노라마")}>
    //             {" "}
    //             <div className="flex flex-row items-center justify-between px-6 py-3">
    //               <div className="flex flex-row items-center">
    //                 <div>
    //                   <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //                     <tr className="h-[20px] text-center">
    //                       <td className="border-r w-[63px] border-[#999]"></td>
    //                       <td className="border-l w-[63px] border-[#999]"></td>
    //                     </tr>
    //                     <tr className="h-[20px]">
    //                       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //                       <td className="border-t border-l w-[63px] border-[#999] text-right">
    //                         7
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //                 <div className="">파노라마</div>
    //               </div>
    //             </div>{" "}
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>,
    <div>
      <MakeReservation />
    </div>,
  ];

  const handleNextSection = () => {
    if (sectionIndex < sections.length - 1) {
      setSectionIndex(sectionIndex + 1);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const openTreatmentmodal = () => {
    treatmentModelOpen();
  };

  const navigate = useNavigate();

  const handleClick = () => {
    handleClose();
    switch (localStorage.getItem("scenario") * 1) {
      case 5:
        navigate("/doctor/receptionwaiting/4");
        break;
      case 6:
        navigate("/doctor/scenario6/3");
        break;
      case 7:
        navigate("/doctor/scene7/receptionwaiting/4");
        break;
      case 8:
        navigate("/doctor/Scenario8/3");
        break;
      case 9:
        navigate("/doctor/scene9/receptionwaiting/4");
        break;

      default:
        break;
    }
  };

  return (
    <div className="modal">
      <div className="modal-content p-[32px] h-[680px]">
        {/* <div className="flex flex-row items-center justify-center   ">
          <div className="modal-title flex-1">다음 진료 입력</div>
          <div className="cursor-pointer">
            <img src="/close.png" alt="" onClick={handleClose} />
          </div>
        </div>
        <div className="flex flex-row justify-between p-[16px] mt-[20px]">
          <div className="flex flex-row items-center">
            <div>
              {" "}
              <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                <tr className="h-[20px] text-center">
                  <td className="border-r w-[63px] border-[#999]"></td>
                  <td className="border-l w-[63px] border-[#999]">5</td>
                </tr>
                <tr className="h-[20px]">
                  <td className="border-t border-r w-[63px] border-[#999]"></td>
                  <td className="border-t border-l w-[63px] border-[#999]"></td>
                </tr>
              </table>
            </div>
            <div className="text-[#111111] font-normal leading-[19px] text-[13px] tracking-[-0.325px] pl-4">
              파노라마
            </div>
            <div className="text-[13px] font-semibold leading-[19px] tracking-[-0.325px] pl-12 text-[#1778B0]">
              완료
            </div>
          </div>
          <div className="flex gap-[20px] flex row items-center ">
            <div className="flex flex-row gap-2">
              <div className="text-[#767676] text-[12px] font-normal leading-[18px] tracking-[-0.3px] cursor-pointer">
                수정
              </div>
              <div className="text-[#767676] text-[12px] font-normal leading-[18px] tracking-[-0.3px] cursor-pointer">
                삭제
              </div>
            </div>
            <div className="flex flex-col">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 11.9976L10 7.99756L6 11.9976"
                    stroke="#317580"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 8.00244L10 12.0024L14 8.00244"
                    stroke="#317580"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div> */}

        {sections[sectionIndex]}
        <div className="">
          <div className="flex gap-2 justify-center pt-[20px] items-end">
            <button
              onClick={handlePrevSection}
              className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              취소
            </button>

            <button
              className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
              onClick={handleClick}
            >
              추가
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TreatmentModal;
