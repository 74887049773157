import React from "react";

function PatientInfo({ data }) {
  return (
    <div className="bg-white w-full rounded-lg shadow-md  p-[20px]  ">
      <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
        환자정보
      </h2>
      <div class="w-full border-[1px] border-[#D4D4D8] rounded-lg">
        <table className="w-full">
          <tbody className="rounded-lg">
            <tr className="border-b border-gray-300 ">
              <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                이름(차트번호)
              </td>
              <td className="px-3 py-2">
                <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                  {data.chart}
                </div>
              </td>
            </tr>
            <tr className="border-b border-gray-300">
              <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                생년월일/성별
              </td>
              <td className="px-3 py-2">
                <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                  {data.details}
                </div>
              </td>
            </tr>
            <tr>
              <td className="px-3 py-2 rounded-bl-[8px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                구분
              </td>
              <td className="px-3 py-[9px]">
                <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                  건강보험
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex mt-3 gap-2">
        <button className="bg-[#DBEEF9] text-[12.5px] text-[#1778B0] py-2 px-[80px] leading-[19px]  rounded-lg font-semibold font-['Pretendard'] tracking[-0.325px]">
          서류 발급
        </button>
        <button className="bg-[#D0E7DC] text-[#154D57] text-[13px] px-[74px] py-2 rounded-lg  leading-[19px]  font-semibold tracking[-0.325px]">
          수진자 조회
        </button>
      </div>
    </div>
  );
}

export default PatientInfo;
