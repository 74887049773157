import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/DataProvider";

function WaitingList({ setData }) {
  const { tableData, setTableData, setPatientIsProcessing } =
    useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",

                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableData.patientList
                    .filter((item) => item.status === "접수대기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                                onClick={() => {
                                  setPatientIsProcessing(data.id);
                                }}
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !== tableData.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WaitingListScenario6({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 6,
      reservation: "12:00",
      chartNumber: "320010",
      nameAgeGender: "전상구(48/남)",
      dobAgeGender: "005258/남(48)",
      doctor: "심우현",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 7,
      reservation: "14:00",
      chartNumber: "314010",
      nameAgeGender: "이윤혁(19/남)",
      dobAgeGender: "314010/남(19)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 8,
      reservation: "14:30",
      chartNumber: "110030",
      nameAgeGender: "정현주(42/여)",
      dobAgeGender: "110030/여(42)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 9,
      reservation: "15:00",
      chartNumber: "107010",
      nameAgeGender: "김연경(50/여)",
      dobAgeGender: "107010/여(50)",
      doctor: "심우현",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 10,
      reservation: "15:30",
      chartNumber: "112020",
      nameAgeGender: "김택(44/남)",
      dobAgeGender: "112020/남(44)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 11,
      reservation: "16:00",
      chartNumber: "330250",
      nameAgeGender: "최용민(37/남)",
      dobAgeGender: "330250/남(37)",
      doctor: "심우현",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 12,
      reservation: "17:00",
      chartNumber: "113010",
      nameAgeGender: "이창선(30/남)",
      dobAgeGender: "113010/남(30)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
    {
      id: 13,
      reservation: "17:00",
      chartNumber: "111021",
      nameAgeGender: "박서윤(13/여)",
      dobAgeGender: "111021/여(13)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "100000",
      patientContribution: "100000",
      paymentAmount: "100000",
      amountReceivable: "100000",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                              {data.action}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WaitingListScenario7({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario7,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableDataScenario7.patientList
                    .filter((item) => item.status === "접수대기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsProcessingScenario7(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario7.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WaitingListScene6({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario6,
    tableDataScenario6,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableDataScenario6.patientList
                    .filter((item) => item.status === "접수대기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsProcessingScenario6(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario6.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WaitingListScene9({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario9,
    tableDataScenario9,
    setTableDataScenario7,
  } = useContext(DataContext);
  const data = [];
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableDataScenario9.patientList
                    .filter((item) => item.status === "접수대기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsProcessingScenario9(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario9.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WaitingListScene8({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario8,
    tableDataScenario8,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableDataScenario8.patientList
                    .filter((item) => item.status === "접수대기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsProcessingScenario8(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario8.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  WaitingList,
  WaitingListScenario6,
  WaitingListScenario7,
  WaitingListScene6,
  WaitingListScene9,
  WaitingListScene8,
};
