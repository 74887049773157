import React, { useState } from "react";
import { Input } from "antd";
import "./questionnaire.scss";
import StoreAlertBox from "../components/SuccessAlertS12/index";

import { Navigate, useNavigate } from "react-router-dom";
import StoreAlertBoxS121 from "../components/SuccessAlertS12/index";

const Questionnaire = () => {
  const [editMode, setEditMode] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="patient-form-container">
      <h1>문진표보기</h1>
      <div className="patient-form">
        <div className="form">
          <div className="main-question-container">
            <h2 className="main-question">1. 정보를 입력해주세요.</h2>

            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question">이름</h3>
                <div className="answer">
                  {editMode ? (
                    <Input
                      className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      value={"정호걸"}
                    />
                  ) : (
                    "정호걸"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">주민번호</h3>
                <div className="answer">
                  {editMode ? (
                    <Input
                      className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      value={"720408-1403117"}
                    />
                  ) : (
                    "720408 - ∙∙∙∙∙∙∙"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">나이</h3>
                <div className="answer">
                  {editMode ? (
                    <Input
                      className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      value={"52"}
                    />
                  ) : (
                    "52"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">성별</h3>
                <div className="answer radio-bool">
                  {editMode ? (
                    <>
                      <div>
                        <input
                          type="radio"
                          name="sub22"
                          id="sub22"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          남자
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub2" id="sub1" />
                        <label htmlFor="sub1" style={{ marginLeft: "6px" }}>
                          여자
                        </label>
                      </div>
                    </>
                  ) : (
                    "남자"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">전화번호</h3>
                <div className="answer">
                  {editMode ? (
                    <Input
                      className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      value={"010-5351-0064"}
                    />
                  ) : (
                    "010-5351-0064"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">주소</h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <Input
                        className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                        value={
                          "경기도 고양시 일산서구 일현로 97-11 (탄현동, 일산 위브너제니스)"
                        }
                      />
                      <Input
                        className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                        value={"101동 3층 301호"}
                      />
                    </>
                  ) : (
                    "경기도 고양시 일산서구 일현로 97-11 (탄현동, 일산 위브너제니스) 101동 3층 301호"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">담당의사</h3>
                <div className="answer">
                  {editMode ? (
                    <Input
                      className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      value={"심원장"}
                    />
                  ) : (
                    "심원장"
                  )}
                </div>
              </div>

              <div className="sub-question-container">
                <h3 className="sub-question">차트번호</h3>
                <div className="answer">
                  {editMode ? (
                    <Input
                      className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                      value={"005258"}
                    />
                  ) : (
                    "005258"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">2. 내원이유(복수선택가능)</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input
                          type="radio"
                          name="sub23"
                          id="sub23"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          충치가 있어요
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub2-2" />
                        <label htmlFor="sub2-2" style={{ marginLeft: "6px" }}>
                          이가 아파요.
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub2-3" />
                        <label htmlFor="sub2-3" style={{ marginLeft: "6px" }}>
                          검진하고 싶어요
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub2-4" />
                        <label htmlFor="sub2-4" style={{ marginLeft: "6px" }}>
                          턱관절이 아파요
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="sub2"
                          id="sub2"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          잇몸이 부었어요
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub2-6" />
                        <label htmlFor="sub2-6" style={{ marginLeft: "6px" }}>
                          잇몸에서 피가 나요
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub2-7" />
                        <label htmlFor="sub2-7" style={{ marginLeft: "6px" }}>
                          입에서 냄새가 나요
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub2-8" />
                        <label htmlFor="sub2-8" style={{ marginLeft: "6px" }}>
                          <p>기타 </p>{" "}
                          <Input
                            className=" border border-gray-300  rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                            placeholder="내용을 입력해주세요."
                          />
                        </label>{" "}
                      </div>
                    </>
                  ) : (
                    "충치가 있어요 / 잇몸이 부었어요"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">3. 약을 복용하고 있으신가요?</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input type="radio" name="sub3" id="sub3-1" checked />
                        <label htmlFor="sub3-1" style={{ marginLeft: "6px" }}>
                          예
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub3" id="sub3-2" />
                        <label htmlFor="sub3-2" style={{ marginLeft: "6px" }}>
                          아니요.
                        </label>
                      </div>
                    </>
                  ) : (
                    "예"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">
              3-1. 위에서 예에 답변했을 때, 무슨 약을 복용하고
              있으신가요?(복수선택가능)
            </h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input
                          type="radio"
                          name="sub24"
                          id="sub24"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          고혈압
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="sub25"
                          id="sub25"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          당뇨
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub4-3" />
                        <label htmlFor="sub4-3" style={{ marginLeft: "6px" }}>
                          골다공증
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub4-4" />
                        <label htmlFor="sub4-4" style={{ marginLeft: "6px" }}>
                          치매
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub4-5" />
                        <label htmlFor="sub4-5" style={{ marginLeft: "6px" }}>
                          기타
                        </label>
                      </div>
                    </>
                  ) : (
                    "고혈압 / 당뇨"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">4. 전신질환이 있으신가요?</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input type="radio" name="sub5" id="sub5-1" checked />
                        <label htmlFor="sub5-1" style={{ marginLeft: "6px" }}>
                          예
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub5" id="sub5-2" />
                        <label htmlFor="sub5-2" style={{ marginLeft: "6px" }}>
                          아니요.
                        </label>
                      </div>
                    </>
                  ) : (
                    "예"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">
              4-1. 위에서 예에 답변했을 때, 어떤 전신질환이
              있으신가요?(복수선택가능)
            </h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input
                          type="radio"
                          name="sub26"
                          id="sub26"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          고혈압
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="sub27"
                          id="sub27"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          당뇨
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub6-3" />
                        <label htmlFor="sub6-3" style={{ marginLeft: "6px" }}>
                          고지혈증
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub6-4" />
                        <label htmlFor="sub6-4" style={{ marginLeft: "6px" }}>
                          심장질환
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub6-5" />
                        <label htmlFor="sub6-5" style={{ marginLeft: "6px" }}>
                          간질환(간염 등)
                        </label>
                      </div>
                      <div>
                        <input type="checkbox" name="sub" id="sub6-6" />
                        <label htmlFor="sub6-6" style={{ marginLeft: "6px" }}>
                          호흡기 질환(천식, 축농증, 결핵 등)
                        </label>
                      </div>
                    </>
                  ) : (
                    " 고혈압 / 당뇨"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">
              5. 전신마취를 하여 수술받으신 적이 있나요?
            </h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input type="radio" name="sub7" id="sub7-1" />
                        <label htmlFor="sub7-1" style={{ marginLeft: "6px" }}>
                          예
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub7" id="sub7-2" checked />
                        <label htmlFor="sub7-2" style={{ marginLeft: "6px" }}>
                          아니요.
                        </label>
                      </div>
                    </>
                  ) : (
                    "아니요"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">6. 약 부작용(알레르기)이 있나요?</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input type="radio" name="sub8" id="sub8-1" />
                        <label htmlFor="sub8-1" style={{ marginLeft: "6px" }}>
                          예
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub8" id="sub8-2" checked />
                        <label htmlFor="sub8-2" style={{ marginLeft: "6px" }}>
                          아니요.
                        </label>
                      </div>
                    </>
                  ) : (
                    "아니요"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">7. 흡연을 하시나요?</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input type="radio" name="sub9" id="sub9-1" />
                        <label htmlFor="sub9-1" style={{ marginLeft: "6px" }}>
                          예
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub9" id="sub9-2" checked />
                        <label htmlFor="sub9-2" style={{ marginLeft: "6px" }}>
                          아니요.
                        </label>
                      </div>
                    </>
                  ) : (
                    "아니요"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">8. 임신 중이신가요?</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input type="radio" name="sub10" id="sub10-1" />
                        <label htmlFor="sub10-1" style={{ marginLeft: "6px" }}>
                          예
                        </label>
                      </div>
                      <div>
                        <input type="radio" name="sub10" id="sub10-2" checked />
                        <label htmlFor="sub10-2" style={{ marginLeft: "6px" }}>
                          아니요.
                        </label>
                      </div>
                    </>
                  ) : (
                    "아니요"
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-question-container">
            <h2 className="main-question">9. 내원경유(복수선택가능)</h2>
            <div className="sub-questions">
              <div className="sub-question-container">
                <h3 className="sub-question"></h3>
                <div className="answer">
                  {editMode ? (
                    <>
                      <div>
                        <input
                          type="radio"
                          name="sub28"
                          id="sub28"
                          className=""
                        />
                        <label htmlFor="sub11-1" style={{ marginLeft: "6px" }}>
                          지인소개
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="sub28"
                          id="sub28"
                          className=""
                        />
                        <label htmlFor="sub11-2" style={{ marginLeft: "6px" }}>
                          블로그
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="sub28"
                          id="sub28"
                          checked
                          className=""
                        />
                        <label htmlFor="sub2" style={{ marginLeft: "6px" }}>
                          인터넷검색
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="sub28"
                          id="sub28"
                          className=""
                        />
                        <label htmlFor="sub11-4" style={{ marginLeft: "6px" }}>
                          그냥
                        </label>
                      </div>
                    </>
                  ) : (
                    "인터넷검색"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button
            onClick={
              editMode
                ? () => setSaveModalOpen(true)
                : () => setEditMode((prev) => !prev)
            }
          >
            {editMode ? "저장" : "수정"}
          </button>
        </div>
      </div>
      {saveModalOpen && (
        <StoreAlertBoxS121
          title={"문진표보기"}
          subtitle={"정보를 저장하시겠습니까?"}
          onConfirm={() => {
            setSaveModalOpen(false);
            setEditMode(false);
            window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default Questionnaire;
