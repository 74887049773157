import React, { useContext, useState, useEffect } from "react";
import {
  Store,
  StoreScenario6,
  StoreScenario7,
  StoreScenario8,
  StoreScenario9,
} from "../Popups/Store/Store";
import FindIDAlert from "../../../components/FIndIdAlertbox";
import StoreAlertBox from "../../../components/StoreAlertBox";
import DataContext from "../../../context/DataProvider";
import {
  MakeReservation,
  MakeReservationScene9,
} from "../../../components/MakereservationScenario6";
import Dropdown from "../../../components/MakereservationScenario6/Dropdown";
import { useNavigate } from "react-router";
import {
  DeskReservation,
  DeskReservationScenario6,
} from "../../DeskReservation";

function TreatmentComplete({ setData }) {
  const { tableData, setTableData, setPatientIsPaymentComplete } =
    useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [reservationOpen, setReservationOpen] = useState(false);

  const [openModalStore, setOpenModalStore] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();

  function openModal() {
    setOpenModalStore(true);
  }

  const closeModal = () => {
    setOpenModalStore(false);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
    setOpenModalStore(false);
  };

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  const handleReservation = () => {
    navigate("/deskreservation");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {tableData.patientList
                  .filter((item) => item.status === "진료완료")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className=" pl-4 w-[9.09%] py-[13px]">
                          {data.reservation}
                        </td>
                        <td className="w-[9.09%]">{data.chartNumber}</td>
                        <td className="w-[9.09%]">{data.nameAgeGender}</td>
                        <td className="w-[9.09%]">{data.doctor}</td>
                        <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                          {data.status}
                        </td>
                        <td className="w-[9.09%]">
                          <div className="items-center py-[6px] flex justify-text ">
                            <button
                              className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                              onClick={openModal}
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                          {data.nextReservation}
                        </td>
                        <td className="w-[9.09%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[9.09%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[9.09%]">{data.paymentAmount}</td>
                        <td className="w-[9.09%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openModalStore === true && (
        <Store setOpenAlert={openAlertModal} setModal={closeModal} />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        // <MakeReservation onCancel={handleCancel} onConfirm={handleConfirm} />
        <DeskReservationScenario6 />
      )}

      {dropDown === true && <Dropdown />}
    </div>
  );
}

function TreatmentCompleteScenario6({ data, setData }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [reservationOpen, setReservationOpen] = useState(false);
  const [openModalStoreTestS6, setOpenModalStoreTestS6] = useState(
    data.chartNumber === "120020" ? true : false
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();

  const dataToMap = [
    {
      id: 1,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "-",
      totalMedicalExpense: "200,000원",
      patientContribution: "200,000원",
      paymentAmount: "16000원",
      amountReceivable: "0원",
    },
  ];

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  useEffect(() => {
    console.log(openModalStoreTestS6, " store open akash");
  }, [openModalStoreTestS6]);

  function openModal() {
    console.log("Open modal button clicked");
    setOpenModalStoreTestS6(true);
  }
  const closeModal = () => {
    console.log("Open modal button clicked");
    setOpenModalStoreTestS6(false);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
  };

  const handleReservation = () => {
    navigate("/deskreservation");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {dataToMap.map((data, index) => (
                  <React.Fragment key={index}>
                    <tr
                      onClick={() => handleRowClick(data)}
                      className={`text-left cursor-pointer ${
                        selectedRow === data
                          ? "bg-[#EBF5F2]"
                          : "hover:bg-[#EBF5F2]"
                      }`}
                    >
                      <td className=" pl-4 w-[9.09%] py-[13px]">
                        {data.reservation}
                      </td>
                      <td className="w-[9.09%]">{data.chartNumber}</td>
                      <td className="w-[9.09%]">{data.nameAgeGender}</td>
                      <td className="w-[9.09%]">{data.doctor}</td>
                      <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                        {data.status}
                      </td>
                      <td className="w-[9.09%]">
                        <div className="items-center py-[6px] flex justify-text ">
                          <button
                            className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                            onClick={openModal}
                          >
                            {data.action}
                          </button>
                        </div>
                      </td>

                      <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                        {data.nextReservation}
                      </td>
                      <td className="w-[9.09%]">{data.totalMedicalExpense}</td>
                      <td className="w-[9.09%]">{data.patientContribution}</td>
                      <td className="w-[9.09%]">{data.paymentAmount}</td>
                      <td className="w-[9.09%]">{data.amountReceivable}</td>
                    </tr>
                    {index !== dataToMap.length - 1 && (
                      <tr className="border-t "></tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openModalStoreTestS6 === true && (
        <Store setOpenAlert={openAlertModal} setModal={closeModal} />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        // <MakeReservation onCancel={handleCancel} onConfirm={handleConfirm} />
        <DeskReservation />
      )}

      {dropDown === true && <Dropdown />}
    </div>
  );
}

function TreatmentCompleteScenario7({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario7,
    setTableDataScenario7,
  } = useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const dataToMap = [
    {
      id: 1,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "-",
      totalMedicalExpense: "200,000원",
      patientContribution: "200,000원",
      paymentAmount: "16000원",
      amountReceivable: "0원",
    },
  ];

  const handleRowClick = (rowData) => {
    setData(rowData);
    setSelectedRow(rowData);
  };
  const [reservationOpen, setReservationOpen] = useState(false);

  const [openModalStoreTest, setOpenModalStoreTest] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(openModalStoreTest, " store open");
  }, [openModalStoreTest]);
  const openModal = () => {
    setOpenModalStoreTest(true);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
  };

  const handleReservation = () => {
    navigate("/doctor/scenario7/8");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {tableDataScenario7.patientList
                  .filter((item) => item.status === "진료완료")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={(e) => {
                          console.log(e.target);
                          if (e.target instanceof HTMLTableCellElement) {
                            handleRowClick(data);
                          }
                        }}
                      >
                        <td className=" pl-4 w-[9.09%] py-[13px]">
                          {data.reservation}
                        </td>
                        <td className="w-[9.09%]">{data.chartNumber}</td>
                        <td className="w-[9.09%]">{data.nameAgeGender}</td>
                        <td className="w-[9.09%]">{data.doctor}</td>
                        <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                          {data.status}
                        </td>
                        <td className="w-[9.09%]">
                          <div className="items-center py-[6px] flex justify-text ">
                            <button
                              className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                              onClick={() => setOpenModalStoreTest(true)}
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                          {data.nextReservation}
                        </td>
                        <td className="w-[9.09%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[9.09%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[9.09%]">{data.paymentAmount}</td>
                        <td className="w-[9.09%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario7.patientList.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openModalStoreTest === true && (
        <StoreScenario7
          setOpenAlert={openAlertModal}
          setModal={setOpenModalStoreTest}
        />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        // <MakeReservation onCancel={handleCancel} onConfirm={handleConfirm} />
        <DeskReservation />
      )}

      {dropDown === true && <Dropdown />}
    </div>
  );
}

function TreatmentCompleteScene6({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario6,
    setTableDataScenario7,
  } = useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const dataToMap = [
    {
      id: 1,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "-",
      totalMedicalExpense: "200,000원",
      patientContribution: "200,000원",
      paymentAmount: "16000원                         ",
      amountReceivable: "0원",
    },
  ];

  const handleRowClick = (rowData) => {
    setData(rowData);
    setSelectedRow(rowData);
  };
  const [reservationOpen, setReservationOpen] = useState(false);

  const [openModalStoreTest, setOpenModalStoreTest] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(openModalStoreTest, " store open");
  }, [openModalStoreTest]);
  const openModal = () => {
    setOpenModalStoreTest(true);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
  };

  const handleReservation = () => {
    navigate("/doctor/scenario6/8");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {tableDataScenario6.patientList
                  .filter((item) => item.status === "진료완료")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className=" pl-4 w-[9.09%] py-[13px]">
                          {data.reservation}
                        </td>
                        <td className="w-[9.09%]">{data.chartNumber}</td>
                        <td className="w-[9.09%]">{data.nameAgeGender}</td>
                        <td className="w-[9.09%]">{data.doctor}</td>
                        <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                          {data.status}
                        </td>
                        <td className="w-[9.09%]">
                          <div className="items-center py-[6px] flex justify-text ">
                            <button
                              className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                              onClick={() => setOpenModalStoreTest(true)}
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                          {data.nextReservation}
                        </td>
                        <td className="w-[9.09%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[9.09%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[9.09%]">{data.paymentAmount}</td>
                        <td className="w-[9.09%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario6.patientList.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openModalStoreTest === true && (
        <StoreScenario6
          setOpenAlert={openAlertModal}
          setModal={setOpenModalStoreTest}
        />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        // <MakeReservation onCancel={handleCancel} onConfirm={handleConfirm} />
        <DeskReservation />
      )}

      {dropDown === true && <Dropdown />}
    </div>
  );
}

function TreatmentCompleteScene9({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario9,
    setTableDataScenario7,
  } = useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const dataToMap = [
    {
      id: 1,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "-",
      totalMedicalExpense: "200,000원",
      patientContribution: "200,000원",
      paymentAmount: "16000원                         ",
      amountReceivable: "0원",
    },
  ];

  const handleRowClick = () => {
    localStorage.setItem("treatmentcomplete9", "true");
  };
  const [reservationOpen, setReservationOpen] = useState(false);

  const [openModalStoreTest, setOpenModalStoreTest] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(openModalStoreTest, " store open");
  }, [openModalStoreTest]);
  const openModal = () => {
    setOpenModalStoreTest(true);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
  };

  const handleReservation = () => {
    navigate("/doctor/scenario9/8");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {tableDataScenario9.patientList
                  .filter((item) => item.status === "진료완료")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        onClick={() => {
                          setData(data);
                          handleRowClick();
                        }}
                        className={`text-left cursor-pointer ${
                          index === 1 && "bg-[#EBF5F2]"
                        } ${
                          selectedRow === data ? "" : "hover:bg-[#EBF5F2]  "
                        }`}
                      >
                        <td className=" pl-4 w-[9.09%] py-[13px]">
                          {data.reservation}
                        </td>
                        <td className="w-[9.09%]">{data.chartNumber}</td>
                        <td className="w-[9.09%]">{data.nameAgeGender}</td>
                        <td className="w-[9.09%]">{data.doctor}</td>
                        <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                          {data.status}
                        </td>
                        <td className="w-[9.09%]">
                          <div className="items-center py-[6px] flex justify-text ">
                            <button
                              className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                              onClick={() => setOpenModalStoreTest(true)}
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                          {data.nextReservation}
                        </td>
                        <td className="w-[9.09%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[9.09%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[9.09%]">{data.paymentAmount}</td>
                        <td className="w-[9.09%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario9.patientList.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openModalStoreTest === true && (
        <StoreScenario9
          setOpenAlert={openAlertModal}
          setModal={setOpenModalStoreTest}
        />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        <MakeReservationScene9
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
        // <DeskReservation />
      )}

      {dropDown === true && <Dropdown />}
    </div>
  );
}

function TreatmentCompleteScene8({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario8,
    setTableDataScenario7,
  } = useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const dataToMap = [
    {
      id: 1,
      reservation: "10:00",
      chartNumber: "120020",
      nameAgeGender: "이혜빈(40/여)",
      doctor: "최윤호",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "-",
      totalMedicalExpense: "200,000원",
      patientContribution: "200,000원",
      paymentAmount: "16000원                         ",
      amountReceivable: "0원",
    },
  ];

  const handleRowClick = (rowData) => {
    setData(rowData);
    setSelectedRow(rowData);
  };
  const [reservationOpen, setReservationOpen] = useState(false);

  const [openModalStoreTest, setOpenModalStoreTest] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(openModalStoreTest, " store open");
  }, [openModalStoreTest]);
  const openModal = () => {
    setOpenModalStoreTest(true);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
  };

  const handleReservation = () => {
    navigate("/doctor/Scenario8/8");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {tableDataScenario8.patientList
                  .filter((item) => item.status === "진료완료")
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        onClick={(e) => {
                          if (e.target instanceof HTMLTableCellElement) {
                            handleRowClick(data);
                          }
                        }}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                      >
                        <td className=" pl-4 w-[9.09%] py-[13px]">
                          {data.reservation}
                        </td>
                        <td className="w-[9.09%]">{data.chartNumber}</td>
                        <td className="w-[9.09%]">{data.nameAgeGender}</td>
                        <td className="w-[9.09%]">{data.doctor}</td>
                        <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                          {data.status}
                        </td>
                        <td className="w-[9.09%]">
                          <div className="items-center py-[6px] flex justify-text ">
                            <button
                              className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                              onClick={() => setOpenModalStoreTest(true)}
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                          {data.nextReservation}
                        </td>
                        <td className="w-[9.09%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[9.09%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[9.09%]">{data.paymentAmount}</td>
                        <td className="w-[9.09%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario8.patientList.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {openModalStoreTest === true && (
        <StoreScenario8
          setOpenAlert={openAlertModal}
          setModal={setOpenModalStoreTest}
        />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        // <MakeReservationScene9 onCancel={handleCancel} onConfirm={handleConfirm} />
        <DeskReservation />
      )}

      {dropDown === true && <Dropdown />}
    </div>
  );
}
export {
  TreatmentComplete,
  TreatmentCompleteScenario6,
  TreatmentCompleteScenario7,
  TreatmentCompleteScene6,
  TreatmentCompleteScene9,
  TreatmentCompleteScene8,
};
