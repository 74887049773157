import React, { useState } from "react";
import Calender from "../../components/Calender";
import {
  TableScenario6,
  Table,
  TableScenario6CalenderView,
  TableExactScenario6,
  TableScenario6ExactCalenderView,
} from "./components/Table";
import TreatmentModal from "./components/TreatmentModal";
import NextTreatmentModal from "./components/NextTreatmentModal";
import ReservationDetails from "./components/ReservationDetails";
import EditReservation from "./components/EditReservation";
import AppointmentBooking from "./components/Test";
import { useEffect } from "react";
import {
  MakeReservation,
  MakeReservationS12,
  MakeReservationS1235,
} from "../../components/MakereservationScenario6";
import Dropdown from "../../components/MakereservationScenario6/Dropdown";
import NextTreatmentPopup from "../../components/MakereservationScenario6/NextTreatmentPopup";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import SuccessAlert from "../../components/SuccessAlert";

import teeth from "../../../../src/app/Assets/images/teeth.jpg";
import teeth1 from "../../../../src/app/Assets/images/teeth1.jpg";
import { useNavigate } from "react-router-dom";
import DropdownS12 from "../../components/MakereservationScenario6/DropDownS12";
import StoreAlertBoxS12Desk from "../../components/SuccessAlertS12Desk";
import StoreAlertBoxSignUp from "../../components/SuccessAlertSignup";

function DeskReservation({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  useEffect(() => {
    setOpenReserve(true);
  }, []);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          김덴투(111044)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          940202/남(30)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            추가
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          구치부 레진
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          특이사항 내용 특이사항 내용 특이사항 내용 특이사항
                          내용 특이사항 내용 특이사항 내용 특이사항 내용
                          특이사항 내용 특이사항 내용 특이사항 내용 특이사항
                          내용 특이사항 내용 특이사항 내용 특...
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender buttonVisible={false} />
            </div>
          </div>
          <div className="w-full pt-4">
            <Table />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationScenario6({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  useEffect(() => {
    setOpenReserve(true);
  }, []);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  const closeModal = () => {
    setOpenReserve(false);
  };
  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          이혜빈(120020)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          830820/여(40)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            입력
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] font-[800] text-[#154D57]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          페니실린 알러지 있음
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender
                buttonVisible={false}
                months="/deskreservationscenario6Calender"
              />
            </div>
          </div>
          <div className="w-full pt-4">
            <TableScenario6 />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationScenario6Calender({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);
  const navigate = useNavigate();

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  const CalenderChange = () => {
    navigate("/deskreservationscenario6CalenderView");
  };

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          이혜빈(120020)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          830820/여(40)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            입력
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#154D57] font-[800]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          페니실린 알러지 있음
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <div className="w-[383px] p-[20px] border-[1px]">
                <div className="flex justify-between">
                  <div>
                    {" "}
                    <ChevronLeftIcon
                      className="w-5 h-5 text-[#317580]"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="text-[#154D57] text-[19px] font-semibold leading-[24px]">
                    2023
                  </div>
                  <div>
                    {" "}
                    <ChevronRightIcon
                      className="w-5 h-5 text-[#317580]"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center text-center">
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      1월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      2월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      3월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      4월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      5월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      6월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      7월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      8월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      9월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      10월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      11월
                    </div>
                    <div
                      className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer"
                      onClick={CalenderChange}
                    >
                      12월
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full pt-4">
            <TableScenario6 />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationScenario6CalenderView({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);
  const navigate = useNavigate();

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);
  const [changeMonth, setChangeMonth] = useState("");

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  // useEffect(() => {
  //   setChangeMonth(new Date(2022, 12, 1));
  // }, [changeMonth]);

  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          이혜빈(120020)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          830820/여(40)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            입력
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] font-[800] text-[#154D57]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          페니실린 알러지 있음
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender
                buttonVisible={false}
                setMonth={11}
                onDateSelect={setSelectedDate}
              />
            </div>
          </div>
          <div className="w-full pt-4">
            <TableScenario6CalenderView selectedDate={selectedDate} />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.08 10시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationExactScenario6({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  useEffect(() => {
    setOpenReserve(true);
  }, []);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          김선희(103000)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          611013/여(62)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          의료급여 1종{" "}
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            입력
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          고혈압, 당뇨약 복용중
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender buttonVisible={false} months="/doctor/scenario6/9" />
            </div>
          </div>
          <div className="w-full pt-4">
            <TableExactScenario6 />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="김선희님 23.12.08 10시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationExactScenario6Calender({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  // useEffect(() => {
  //   setOpenReserve(true);
  // }, []);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  const navigate = useNavigate();

  const CalenderChange = () => {
    navigate("/doctor/scenario6/10");
  };

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          김선희(103000)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          611013/여(62)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          의료급여 1종{" "}
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            추가
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          고혈압, 당뇨약 복용중
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              <div className="w-[383px] p-[20px] border-[1px]">
                <div className="flex justify-between">
                  <div>
                    {" "}
                    <ChevronLeftIcon
                      className="w-5 h-5 text-[#317580]"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="text-[#154D57] text-[19px] font-semibold leading-[24px]">
                    2023s
                  </div>
                  <div>
                    {" "}
                    <ChevronRightIcon
                      className="w-5 h-5 text-[#317580]"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center text-center">
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      1월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      2월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      3월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      4월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      5월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      6월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      7월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      8월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      9월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      10월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      11월
                    </div>
                    <div
                      className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer"
                      onClick={CalenderChange}
                    >
                      12월
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full pt-4">
            <TableScenario6ExactCalenderView />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationExactScenario6CalenderView({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);
  const navigate = useNavigate();

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);
  const [changeMonth, setChangeMonth] = useState("");

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  // useEffect(() => {
  //   setChangeMonth(new Date(2022, 12, 1));
  // }, [changeMonth]);

  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          김선희(103000)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          611013/여(62)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          의료급여 1종
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            추가
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          고혈압, 당뇨약 복용중
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender
                buttonVisible={false}
                setMonth={11}
                onDateSelect={setSelectedDate}
              />
            </div>
          </div>
          <div className="w-full pt-4">
            <TableScenario6ExactCalenderView selectedDate={selectedDate} />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationScenario12({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  const [open35, setOpens35] = useState(false);

  useEffect(() => {
    setOpenReserve(true);
  }, []);

  const openS35Poup = () => {
    setOpenDropDown(false);
    setOpenReserve(false);
    setOpens35(true);
  };

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
    setOpens35(false);
  };

  const closeModal = () => {
    setOpenReserve(false);
  };
  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="w-full">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          정호걸(005258)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          720408/남(52)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-5656-2323
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] min-w-[140px]  font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] w-max rounded-[32px]"
                            onClick={handleOpen}
                          >
                            입력
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] font-[800] ">
                          치주소파술
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          페니실린 알러지 있음
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender
                buttonVisible={false}
                months="/deskreservationS12/Calender"
              />
            </div>
          </div>
          <div className="w-full max-w-max overflow-scroll pt-4">
            <TableScenario6 />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservationS12 onCancel={onCancel} onConfirm={openS35Poup} />
      )}

      {open35 === true && (
        <MakeReservationS1235 onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <DropdownS12
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {/* {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )} */}

      {checkAlert === true && (
        <StoreAlertBoxS12Desk
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function App() {
  const navigator = useNavigate();
  const [showModal, setshowModal] = useState(false);

  const handleButtonClick = () => {
    setshowModal(true);
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <button
          className="inline-flex px-4 py-2 justify-center items-center rounded-[8px] bg-orange-50 max-w-[110px] w-full h-[36px]"
          onClick={handleButtonClick}
        >
          <div className="font-['pretendard'] text-[#DA5F1A] text-[13px] font-[600] leading-[19px] tracking-[-0.325px]">
            전체확인
          </div>
        </button>
      </div>

      {showModal && (
        <StoreAlertBoxSignUp
          title="치료계획 전체확인"
          subtitle="진료차팅이 적용되었습니다."
          onConfirm={() => {
            setshowModal(false);
            navigator("/doctor/receptionwaiting/3");
          }}
        />
      )}
    </div>
  );
}

function MyComponent() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div>
      <div></div>
    </div>
  );
}
const NavigationBar = () => {
  const [activeTab, setActiveTab] = useState("데스크");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="flex flex-col items-start width-full pt-[13px] pr-[564px] pb-[1px] pl-[32px] gap-[9px] border-b-[1px] border-[#F1F1F5] bg-[#DEEAE8]">
      <div>
        <div className="flex gap-[20px] font-['pretendard'] text-[14px] font-[600] leading-[20px] tracking-[-0.35px]">
          <div
            onClick={() => handleTabClick("데스크")}
            className={`cursor-pointer ${
              activeTab === "데스크" ? "text-[#154D57]" : ""
            }`}
          >
            데스크
          </div>
          <div
            onClick={() => handleTabClick("예약")}
            className={`cursor-pointer ${
              activeTab === "예약" ? "text-[#154D57]" : ""
            }`}
          >
            예약
          </div>
          <div
            onClick={() => handleTabClick("환자정보")}
            className={`cursor-pointer ${
              activeTab === "환자정보" ? "text-[#154D57]" : ""
            }`}
          >
            환자정보
          </div>
        </div>
      </div>
      <div
        className="flex bg-[#154D57] w-[36px] h-[3px]"
        style={{
          transform: `translateX(${
            activeTab === "예약"
              ? "calc(410% / 3)"
              : activeTab === "환자정보"
              ? "calc(435% / 3 * 2)"
              : "0"
          })`,
        }}
      ></div>
    </div>
  );
};

export default function ClinicTab2Scenario6() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/3");
  };

  return (
    <>
      <div className="flex flex-col gap-[28px] ">
        <div className="flex  pt-[48px] px-[32px] gap-[28px] md:items-center justify-center">
          <div className="bg-white max-w-[326px] w-[100%] rounded-lg shadow-md  p-[20px]  ">
            <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
              환자정보
            </h2>
            <div class="w-full border-[1px] border-[#D4D4D8] rounded-lg">
              <table className="w-full">
                <tbody className="rounded-lg">
                  <tr className="border-b border-gray-300 ">
                    <td className="px-3 py-3 rounded-tl-[8px] bg-[#F1F8FA] w-[99px] font-semibold text-[13px] leading-[19px] tracking-[-0.325px] text-[#505050]">
                      이름(차트번호)
                    </td>
                    <td className="px-3 py-2">
                      <div className="text-[13px] font-normal leading-[19px] font-[pretendard] tracking-[-0.325px] text-[#111111]">
                        김덴투(111044)
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <td className="px-3 py-3 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                      생년월일/성별{" "}
                    </td>
                    <td className="px-3 py-2">
                      <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                        940202/남(30)
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <td className="px-3 py-3 rounded-bl-[8px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                      구분
                    </td>
                    <td className="px-3 py-[9px]">
                      <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                        건강보험
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-3 py-3 rounded-bl-[8px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                      특이사항
                    </td>
                    <td className="px-3 py-[9px]">
                      <div className="text-[13px] font-[pretendard] font-normal leading-[19px] font-[400] tracking-[-0.325px] text-[#111111]">
                        특이사항 내용 특이사항 특이사항
                        <br />
                        내용 특이사항 내용 특이사항 특<br />
                        이사항 내용 특이사항 내용 특이
                        <br />
                        사항 내용 특이사항 내용 특이사
                        <br />항 내용 특이사항 내용 특이사항...
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col p-[20px] bg-[#FFF] max-w-[326px] w-full h-auto gap-[16px] bg-white max-w-[326px] w-[100%] rounded-lg shadow-md  p-[20px]  ">
            <div className="font-['pretendard'] text-[18px] font-[600] leading-[26px] tracking-[-0.45px]">
              환자정보
            </div>
            <div className="table w-full">
              <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
                <div>
                  {/* Separate div for table head */}
                  <div className="">
                    <table className=" text-sm bg-white w-full">
                      <thead className="font-['pretendard'] text-[13px] font-[600] leading-[19px] tracking-[-0.325px]">
                        <tr className="text-[#505050]">
                          <th
                            className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA]"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            이름/성별/나이
                          </th>
                          <th
                            className="py-[14px]  border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            진료상태
                          </th>
                          <th
                            className="py-[14px]   border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            진료상태변경
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {/* Separate div for table body with max height and overflow */}
                  <div
                    style={{
                      maxHeight: "240px",
                      overflowY: "auto",
                      borderRadius: "8px",
                    }}
                  >
                    <table className=" text-sm bg-white w-full">
                      <tbody className=" font-['pretendard'] text-[12px] font-[400] leading-[19px] tracking-[-0.325px]">
                        <tr className="text-center cursor-pointer items-center bg-[#EBF5F2]">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#C29800] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold border-none">
                            <button className="text-[13px] font-normal text-[#1778B0] font-semibold rounded-[32px] bg-[#DBEEF9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className="py-[9px] text-[13px]  font-normal font-semibold text-[#C29800]"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>
                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김덴투/남/30
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                            <button className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center ">
                              진료완료
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[32px] md:items-center justify-center pb-[48px] gap-[28px]">
          <div className="flex flex-col relative pb-[48px] bg-white max-w-[680px] w-[100%] h-auto rounded-lg shadow-md  pt-[20px] px-[20px] gap-[16px] ">
            <div className="flex gap-[318px]">
              <div className="flex gap-[32px]">
                <div className="flex items-center ">
                  <div className="font-semibold text-[18px] font-normal leading-[26px] font-[pretendard] tracking-[-0.45px] text-[#111]">
                    치료계획
                  </div>
                </div>
                <div className="inline-flex h-36px py-2 px-3 justify-center items-center gap-[21px] flex-shrink-0 rounded-[32px] bg-[#EAF6F3] font-[400] text-[13px] font-normal leading-[19px] font-[pretendard] tracking-[-0.325px] text-[#154D57]">
                  2023년 8월 16일(월)
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z"
                        fill="#317580"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="">
                <button className="absolute flex inline-flex py-2 px-4 justify-center items-center rounded-[8px] bg-[#317580] text-[#FFF] ">
                  추가
                </button>
              </div>
            </div>

            <div>
              <div classname="flex absolute ">
                <div className="flex flex-col gap-[16px] max-w-[640px] w-full h-auto shrink-[0] rounded-[8px] border border-solid border-gray-300 rounded-8 p-[20px] pb-[210px]">
                  <div className="flex flex-col gap-[6px] ">
                    <div className="flex gap-[12px]">
                      <div classname="">
                        <img
                          src={teeth}
                          alt="Teeth1"
                          className="w-[84px] h-[70px]"
                        />
                      </div>
                      <div>
                        <img
                          src={teeth1}
                          alt="Teeth2"
                          className="w-[84px] h-[70px]"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between">
                        <div className="flex gap-[16px] ">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px]"></td>
                                <td className="border-l w-[63px]"></td>
                              </tr>
                              <tr className="h-[20px] text-center">
                                <td className="border-t border-r w-[63px]"></td>
                                <td className="border-t border-l w-[63px]">
                                  7
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div classname="flex gap-[296px]">
                            <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              치주소파술
                            </div>
                            {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              치근단
                            </div> */}
                          </div>
                        </div>
                        <div classname="flex gap-[296px]">
                          <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                            200,000원
                          </div>
                          <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            <div>메모</div>
                            <div>수정</div>
                            <div>삭제</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div classname="flex px-[20px] ">
                    <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <App />
            </div>
          </div>

          <div className="flex flex-col relative pb-[48px] bg-white max-w-[680px] w-[100%] h-auto rounded-lg shadow-md  pt-[20px] px-[20px] gap-[16px] ">
            <div className="flex gap-[448px]">
              <div className="flex items-center font-[600] text-[18px] font-normal leading-[26px] font-semibold font-[pretendard] tracking-[-0.45px] text-[#111]">
                치료 내용
              </div>

              <div className="flex justify-center items-center gap-[8px] ">
                <button className=" flex inline-flex py-2 px-4 rounded-[8px] bg-[#FFEBDE] text-[#DA5F1A] text-[13px] font-[600] ">
                  추가
                </button>

                <button
                  className=" flex inline-flex py-2 px-4 rounded-[8px] bg-[#317580] text-[#FFF] text-[13px] font-[600] "
                  onClick={handleClick}
                >
                  추가
                </button>
              </div>
            </div>

            <div>
              <div classname="flex absolute ">
                <div
                  style={{
                    maxHeight: "375px",
                    overflowY: "auto",
                    borderRadius: "8px",
                  }}
                >
                  <div className="flex flex-col gap-[16px] pb-[200px] max-w-[640px] w-full h-auto shrink-[0] rounded-[8px] border border-solid border-gray-300 rounded-8 p-[20px] ">
                    <div className="flex flex-col gap-[16px]">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px]">
                          <div>
                            <MyComponent />
                          </div>
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676]">
                            23.08.16
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px]">
                          <div className="flex justify-between gap-[185px]">
                            <div className="flex gap-[16px] ">
                              <div>
                                <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r w-[63px]"></td>
                                    <td className="border-l w-[63px]">4567</td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r w-[63px]"></td>
                                    <td className="border-t border-l w-[63px]">
                                      4567
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치주소파술
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[12px]">
                                  <div className="font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    완료
                                  </div>
                                  <div>
                                    {/* <button className="inline-flex px-2 py-2 justify-center items-center rounded-[8px] bg-[#DBEEF9] max-w-[37px] w-full h-[26px] ">
                                      <div className="font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                        완료
                                      </div>
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                19,340원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px]">
                          <div>
                            <MyComponent />
                          </div>
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676]">
                            23.08.16
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px]">
                          <div className="flex justify-between gap-[185px]">
                            <div className="flex gap-[16px] ">
                              <div>
                                <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r w-[63px]"></td>
                                    <td className="border-l w-[63px]">67</td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r w-[63px]"></td>
                                    <td className="border-t border-l w-[63px]"></td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치주소파술
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[12px]">
                                  <div className="font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    완료
                                  </div>
                                  <div>
                                    {/* <button className="inline-flex px-2 py-2 justify-center items-center rounded-[8px] bg-[#DBEEF9] max-w-[37px] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                33,530원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export {
  DeskReservation,
  DeskReservationScenario6,
  DeskReservationScenario6Calender,
  DeskReservationScenario6CalenderView,
  DeskReservationExactScenario6,
  DeskReservationExactScenario6Calender,
  DeskReservationExactScenario6CalenderView,
  DeskReservationScenario12,
};
