import React, { useState, useEffect } from "react";
import Calender from "../../../components/Calender";
import {
  TableExactScenario7,
  TableScenario6,
  TableScenario7ExactCalenderView,
} from "../components/Table";
import TreatmentModal from "../components/TreatmentModal";

import Dropdown from "../../../components/MakereservationScenario6/Dropdown";
import NextTreatmentPopup from "../../../components/MakereservationScenario6/NextTreatmentPopup";
import SuccessAlert from "../../../components/SuccessAlert";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { MakeReservation } from "../../../components/MakereservationScenario6";
import StoreAlertBoxSignUp from "../../../components/SuccessAlertSignup";

function DeskReservationScenario7({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  useEffect(() => {
    setOpenReserve(true);
  }, []);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          전상구(320010)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          750408/남(48)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            입력
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          골다공증 약 복용중
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender buttonVisible={false} months="/doctor/scenario7/9" />
            </div>
          </div>
          <div className="w-full pt-4">
            <TableExactScenario7 />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.05 13시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationScenario7Calender({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  //   useEffect(() => {
  //     setOpenReserve(true);
  //   }, []);

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  const navigate = useNavigate();

  const CalenderChange = () => {
    navigate("/doctor/scenario7/10");
  };

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          전상구(320010)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          750408/남(48)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            추가
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          골다공증 약 복용중
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              <div className="w-[383px] p-[20px] border-[1px]">
                <div className="flex justify-between">
                  <div>
                    {" "}
                    <ChevronLeftIcon
                      className="w-5 h-5 text-[#317580]"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="text-[#154D57] text-[19px] font-semibold leading-[24px]">
                    2023
                  </div>
                  <div>
                    {" "}
                    <ChevronRightIcon
                      className="w-5 h-5 text-[#317580]"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center text-center">
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      1월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      2월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      3월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      4월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      5월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      6월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      7월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      8월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      9월
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      10월
                    </div>
                    <div className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer">
                      11월
                    </div>
                    <div
                      className="flex-1 py-[25px] hover:bg-[#EBF5F2] hover:text-[#154D57] cursor-pointer"
                      onClick={CalenderChange}
                    >
                      12월
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full pt-4">
            <TableExactScenario7 />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

function DeskReservationScenario7CalenderView({ makeReserve }) {
  const [selectedButton, setSelectedButton] = useState("전체");
  const [selectedButtonmenu, setSelectedButtonmenu] = useState("전체");
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);
  const [makeReservemodal, setMakeReserve] = useState(false);
  const navigate = useNavigate();

  const openReserve = () => {
    setMakeReserve(true);
  };

  const handleOpen = () => {
    setOpenmodal(true);
  };

  const handleClose = () => {
    setOpenmodal(false);
  };

  const treatmentModelOpen = () => {
    setTreatmentmodal(true);
    setOpenmodal(false);
    console.log("succsess");
  };

  const treatmentModelClose = () => {
    setTreatmentmodal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setSelectedButton(buttonLabel);
  };

  const buttonLabels = [
    "전체",
    "최원장",
    "심원장",
    "홍원장",
    "이원장",
    "박원장",
  ];

  const handleButtonClickBar = (buttonLabels) => {
    setSelectedButtonmenu(buttonLabels);
  };

  const [openMakeReserve, setOpenReserve] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const [manualReservation, setManualReservation] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);
  const [changeMonth, setChangeMonth] = useState("");

  const onConfirm = () => {
    setOpenDropDown(true);
    setOpenReserve(false);
  };

  const onCancel = () => {
    setOpenReserve(false);
  };

  const openManual = () => {
    setManualReservation(true);
  };

  const cancelManual = () => {
    setManualReservation(false);
  };

  const checkAlertModal = () => {
    setCheckAlert(true);
  };

  const closeCheckAlertModal = () => {
    setCheckAlert(false);
    setManualReservation(false);
  };

  const submitReservation = () => {
    setCheckAlert(false);
    setManualReservation(false);
    setOpenDropDown(false);
    setOpenReserve(false);
  };

  // useEffect(() => {
  //   setChangeMonth(new Date(2022, 12, 1));
  // }, [changeMonth]);

  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div className=" px-8 py-[48px] ">
      <div className="bg-[#FFF]  rounded-[8px] p-[20px] w-full">
        <div>
          <div className="flex flex-row items-center gap-[32px]">
            <div className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-[#111111]">
              예약리스트
            </div>
            <div className="flex gap-2 ">
              <button
                className={`px-4 py-[9px] rounded-[32px]  border-[1px] border-[#D4D4D8] ${
                  selectedButton === "일별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("일별")}
              >
                일별
              </button>
              <button
                className={`px-4 py-[9px] rounded-[32px] border-[1px] border-[#D4D4D8 ] ${
                  selectedButton === "주별"
                    ? "bg-[#317580] text-white border-none"
                    : ""
                }`}
                onClick={() => handleButtonClick("주별")}
              >
                주별
              </button>
            </div>
          </div>
          <div className="flex gap-[28px] pt-4 ">
            <div className="flex flex-row  ">
              <div className=" bg-[#EAF6F3] max-w-[194px] max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[8px] w-full items-center">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.333 6L7.33301 10L11.333 14"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                  2023년 10월 31일(월)
                </span>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66699 14L12.667 10L8.66699 6"
                      stroke="#317580"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex  items-center pl-2">
                <form action="/search" class="max-w-[181px] ">
                  <div class="relative flex  border rounded-full">
                    <input
                      type=""
                      name=""
                      class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                      placeholder="search"
                    />
                    <button type="submit" class="ml-2 px-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M13.2148 13.2148L17.5004 17.5004"
                          stroke="#317580"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex items-end">
              {" "}
              <div className="flex gap-[6px] ">
                {buttonLabels.map((label) => (
                  <button
                    key={label}
                    className={`px-3 py-[4px] text-[12px] rounded-[32px] border-[1px] border-[#D4D4D8] font-semibold  text-[#505050] leading-[18px] tracking-[-0.3px] font-normal ${
                      selectedButtonmenu === label
                        ? "bg-[#317580] text-[#FFFFFF] border-none"
                        : ""
                    }`}
                    onClick={() => handleButtonClickBar(label)}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex  gap-[28px]">
          <div className="max-w-[383px] w-full">
            <div className="bg-white max-w-[383px]   rounded-lg shadow-md  p-[20px] border-[#D4D4D8] mt-4  border-[1px]">
              <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
                환자정보
              </h2>
              <div class=" border-[1px] border-[#D4D4D8] rounded-lg">
                <table className="">
                  <tbody className="rounded-lg">
                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 rounded-tl-[8px] bg-[#F1F8FA] w-[139px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                        이름(차트번호)
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          전상구(320010)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        생년월일/성별
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          750408/남(48)
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        구분
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          건강보험
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                        연락처
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          010-3232-2222
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-gray-300 ">
                      <td className="px-3 py-2 bg-[#F1F8FA] font-semibold leading-[19px] h-[139px]  text-[13px] text-[#505050] flex w-full gap-[6px] items-center">
                        다음 진료{" "}
                        <span>
                          <button
                            className="px-3 py-1 bg-[#D0E7DC] rounded-[32px]"
                            onClick={handleOpen}
                          >
                            추가
                          </button>
                        </span>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#154D57] font-[600]">
                          발치
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 rounded-bl-[8px]  h-[112px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                        특이사항
                      </td>
                      <td className="px-3 py-[9px]">
                        <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                          골다공증 약 복용중
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pt-[28px]">
              {" "}
              <Calender
                buttonVisible={false}
                setMonth={11}
                onDateSelect={setSelectedDate}
              />
            </div>
          </div>
          <div className="w-full pt-4">
            <TableScenario7ExactCalenderView selectedDate={selectedDate} />
          </div>
        </div>
      </div>

      {openModel === true && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={treatmentModelOpen}
        />
      )}
      {treatmentModel === true && <TreatmentModal />}

      {openMakeReserve === true && (
        <MakeReservation onCancel={onCancel} onConfirm={onConfirm} />
      )}

      {openDropDown === true && (
        <Dropdown onConfirm={openManual} onCancel={cancelManual} />
      )}

      {manualReservation === true && (
        <NextTreatmentPopup
          onConfirm={checkAlertModal}
          onCancel={closeCheckAlertModal}
        />
      )}

      {checkAlert === true && (
        <StoreAlertBoxSignUp
          title="예약"
          subtitle="이혜빈님 23.12.06 11시 예약 완료되었습니다."
          onConfirm={submitReservation}
        />
      )}
    </div>
  );
}

export {
  DeskReservationScenario7,
  DeskReservationScenario7Calender,
  DeskReservationScenario7CalenderView,
};
