import React, { useState, useEffect } from "react";

function StorageComplete({ searchTerm, setData }) {
  const tableData = [
    {
      reservationTime: "10:00",
      chart: "111011",
      details: "김덴투(20/여)",
      doctor: "김원장",
      medicalStatus: "수납완료",
      nextReservation: "-",
      totalMedicalExpense: "9,900,000원",
      PatientContribution: "9,000,000원",
      PaymentAmount: "8,000,000원",
      AmountReceivable: "1,000,000원",
    },
    {
      reservationTime: "10:00",
      chart: "biyon",
      details: "김덴투(20/여)",
      doctor: "김원장",
      medicalStatus: "수납완료",
      nextReservation: "-",
      totalMedicalExpense: "9,900,000원",
      PatientContribution: "9,000,000원",
      PaymentAmount: "8,000,000원",
      AmountReceivable: "1,000,000원",
    },
  ];
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableDataFiltered, setTableDataFiltered] = useState(tableData);
  useEffect(() => {
    setTableDataFiltered(
      tableData.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chart} ${item.details} ${item.doctor} ${item.status} ${item.medicalstatus} ${item.patient} ${item.expense} ${item.contribution} ${item.payment}   ${item.amount} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57] ">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full text-[13px]">
              <tbody>
                {tableDataFiltered.map((data, index) => (
                  <React.Fragment key={index}>
                    <tr
                      key={index}
                      className={`text-left cursor-pointer  ${
                        selectedRow === data
                          ? "bg-[#EBF5F2]"
                          : "hover:bg-[#EBF5F2]"
                      }`}
                      onClick={() => handleRowClick(data)}
                    >
                      <td className="  w-[8.33%] text-center py-[13px]">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </td>
                      <td className=" pl-4  w-[8.33%]">
                        {data.reservationTime}
                      </td>
                      <td className="w-[8.33%]">{data.chart}</td>
                      <td className="w-[8.33%]">{data.details}</td>
                      <td className="w-[8.33%]">{data.doctor}</td>
                      <td className="w-[8.33%]">
                        <div className="items-center py-[6px] flex text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                          {data.medicalStatus}
                        </div>
                      </td>

                      <td className="w-[8.33%]">{data.nextReservation}</td>
                      <td className="w-[8.33%]">{data.totalMedicalExpense}</td>
                      <td className="w-[8.33%]">{data.PatientContribution}</td>
                      <td className="w-[8.33%]">{data.PaymentAmount}</td>
                      <td className="w-[8.33%]">{data.AmountReceivable}</td>
                    </tr>
                    {index !== tableData.length - 1 && (
                      <tr className="border-t "></tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StorageComplete;
