import React, { useEffect, useState } from "react";
import "./index.scss";
import { Input } from "antd";
import { Button } from "antd";
import Upload12 from "../../../Uploads/uploads";
import FileManagement from "./components/FileManagement";
import MembershipUserInput from "./components/UserInput";

export default function Membership() {
  const [Manager, setManager] = useState(true);
  const [User, setUser] = useState(false);
  const [managerData, setManagerData] = useState({
    id:"",
    password:"",
    companyName:"",
    dentistName:"",
    phoneNumber:"",
    otp:"",
    licenseNumber:"",
    email:"",
    imageLink:""
    });

  const handleItemClick = (item) => {
    if (item === "manager") {
      setManager(true);
      setUser(false);
    } else if (item === "user") {
      setManager(false);
      setUser(true);
    }
  };

  useEffect(()=>{

  },)
  return (
    <div className="main-container">
      <div className="heading">회원가입</div>
      <div className="formContainerMain">
        <div className="formContainer">
          <div className="headingSelection">
            <div
              active={Manager ? "true" : "false"}
              className={`green cursor-pointer ${
                Manager ? " text-[#FFFFFF]" : "text-[#317580]"
              }`}
              onClick={() => handleItemClick("manager")}
            >
              <div className="text-W">최종관리자</div>
            </div>
            <div
              active={User ? "true" : "false"}
              className={`gray cursor-pointer ${
                User ? " text-[#317580]" : " text-[#FFFFFF]"
              }`}
              onClick={() => handleItemClick("user")}
            >
              <div className="text-G">사용자</div>
            </div>
          </div>
          <div className="max-w-[1440px] w-full  justify-center flex">
            {Manager && <FileManagement data={managerData} onDataChange={setManagerData}/>}

            {User && <MembershipUserInput />}
          </div>
        </div>
      </div>
    </div>
  );
}
