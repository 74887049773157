import React from "react";

function OnDayTreatment({ data }) {
  // Sample data
  // const data = [
  //   // { id: 1, status: "진행중", price: "50,000원" },
  //   // { id: 2, status: "완료", price: "60,000원" },
  //   // { id: 3, status: "완료", price: "60,000원" },
  //   // { id: 4, status: "완료", price: "60,000원" },
  //   // { id: 5, status: "완료", price: "60,000원" },
  //   // { id: 6, status: "완료", price: "60,000원" },
  //   // Add more data as needed
  // ];

  return (
    <div className="flex justify-center h-full">
      <div
        className="bg-white p-[20px] max-w-[460px] rounded-lg max-w-[462px] w-full"
        style={{ boxShadow: " 0px 0px 8px 0px rgba(0, 56, 64, 0.06)" }}
      >
        <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
          당일 치료 내용
        </div>
        <div
          style={{ height: "206px", overflowY: "auto" }}
          className="pr-[15px] py-[20px] pl-[20px] border-[1px]"
        >
          <div className="pb-6 w-max ">
            {localStorage.getItem("visible") === "true" && (
              <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                <div>
                  <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                    <tr className="h-[20px] text-center">
                      <td className="border-r border-[#999]  w-[63px]"></td>
                      <td className="border-l border-[#999]  w-[63px]">5</td>
                    </tr>
                    <tr className="h-[20px]">
                      <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                      <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                    </tr>
                  </table>
                </div>
                <div className="flex flex-row w-full justify-between">
                  <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                    파노라마
                  </div>
                  <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                    완료
                  </div>
                  <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                    25,300원
                  </div>
                </div>
              </div>
            )}

            {/* <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                <div>
                  <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                    <tr className="h-[20px] text-center">
                      <td className="border-r border-[#999]  w-[63px]">7</td>
                      <td className="border-l border-[#999]  w-[63px] text-right"></td>
                    </tr>
                    <tr className="h-[20px]">
                      <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                      <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                    </tr>
                  </table>
                </div>
                <div className="flex flex-row w-full justify-between">
                  <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                    치근단
                  </div>
                  <div className="text-[#C29800] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                    진행중
                  </div>
                  <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                    30,000원
                  </div>
                </div>
              </div> */}

            {/* <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                <div>
                  <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                    <tr className="h-[20px] text-center">
                      <td className="border-r border-[#999]  w-[63px]">7</td>
                      <td className="border-l border-[#999]  w-[63px] text-right"></td>
                    </tr>
                    <tr className="h-[20px]">
                      <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                      <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                    </tr>
                  </table>
                </div>
                <div className="flex flex-row w-full justify-between">
                  <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                    파노라마
                  </div>
                  <div className="text-[#C29800] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                    진행중
                  </div>
                  <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                    50,000원
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
function OnDayTreatmentScenario5({ data }) {
  // Sample data
  // const data = [
  //   // { id: 1, status: "진행중", price: "50,000원" },
  //   // { id: 2, status: "완료", price: "60,000원" },
  //   // { id: 3, status: "완료", price: "60,000원" },
  //   // { id: 4, status: "완료", price: "60,000원" },
  //   // { id: 5, status: "완료", price: "60,000원" },
  //   // { id: 6, status: "완료", price: "60,000원" },
  //   // Add more data as needed
  // ];

  return (
    <div className="flex justify-center h-full">
      <div
        className="bg-white p-[20px] max-w-[460px] rounded-lg max-w-[462px] w-full"
        style={{ boxShadow: " 0px 0px 8px 0px rgba(0, 56, 64, 0.06)" }}
      >
        <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
          당일 치료 내용
        </div>
        <div
          style={{ height: "206px", overflowY: "auto" }}
          className="pr-[15px] py-[20px] pl-[20px] border-[1px]"
        >
          <div className="pb-6 w-max">
            {data?.chartNumber === "120020" &&
              localStorage.getItem("completed") === "true" && (
                <>
                  {" "}
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            4
                          </td>
                          <td className="border-l border-[#999]  w-[63px]"></td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        세라믹이레
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        200,000원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            3
                          </td>
                          <td className="border-l border-[#999]  w-[63px]"></td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        세라믹이레
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        200,000원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]"></td>
                          <td className="border-l border-[#999]  w-[63px]">
                            4567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            4567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치주소피술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

function OnDayTreatmentScene6({ data }) {
  // Sample data
  // const data = [
  //   // { id: 1, status: "진행중", price: "50,000원" },
  //   // { id: 2, status: "완료", price: "60,000원" },
  //   // { id: 3, status: "완료", price: "60,000원" },
  //   // { id: 4, status: "완료", price: "60,000원" },
  //   // { id: 5, status: "완료", price: "60,000원" },
  //   // { id: 6, status: "완료", price: "60,000원" },
  //   // Add more data as needed
  // ];

  return (
    <div className="flex justify-center h-full">
      <div
        className="bg-white p-[20px] max-w-[460px] rounded-lg max-w-[462px] w-full"
        style={{ boxShadow: " 0px 0px 8px 0px rgba(0, 56, 64, 0.06)" }}
      >
        <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
          당일 치료 내용
        </div>
        <div
          style={{ height: "206px", overflowY: "auto" }}
          className="pr-[15px] py-[20px] pl-[20px] border-[1px]"
        >
          <div className="pb-6 w-max">
            {data?.chartNumber === "103000" &&
              localStorage.getItem("completed") === "true" && (
                <>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp; 7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        35,150원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654&nbsp;&nbsp;&nbsp;
                          </td>
                          <td className="border-l border-[#999]  w-[63px]"></td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp;&nbsp;&nbsp;7654
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치주소파술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp;7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        35,150원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp;7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]"></td>
                          <td className="border-l border-[#999]  w-[63px]">
                            4567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] "></td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

function OnDayTreatmentScene7({ data }) {
  // Sample data
  // const data = [
  //   // { id: 1, status: "진행중", price: "50,000원" },
  //   // { id: 2, status: "완료", price: "60,000원" },
  //   // { id: 3, status: "완료", price: "60,000원" },
  //   // { id: 4, status: "완료", price: "60,000원" },
  //   // { id: 5, status: "완료", price: "60,000원" },
  //   // { id: 6, status: "완료", price: "60,000원" },
  //   // Add more data as needed
  // ];

  return (
    <div className="flex justify-center h-full">
      <div
        className="bg-white p-[20px] max-w-[460px] rounded-lg max-w-[462px] w-full"
        style={{ boxShadow: " 0px 0px 8px 0px rgba(0, 56, 64, 0.06)" }}
      >
        <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
          당일 치료 내용
        </div>
        <div
          style={{ height: "206px", overflowY: "auto" }}
          className="pr-[15px] py-[20px] pl-[20px] border-[1px]"
        >
          <div className="pb-6 w-max">
            {data?.chartNumber === "320010" &&
              localStorage.getItem("completed") === "true" && (
                <>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className=" border-r border-[#999]  w-[63px]">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                          <td className="border-b border-l border-[#999]  w-[63px] text-right">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7 &nbsp;
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                          <td className="border-l border-[#999]  w-[63px] text-right">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        임플란트
                      </div>
                      <div className="text-[#C29800] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        진행중
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        850,000원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className=" border-r border-[#999]  w-[63px]">
                            &nbsp; 5 &nbsp; &nbsp; &nbsp;
                          </td>
                          <td className="border-b border-l border-[#999]  w-[63px] text-right">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                          <td className="border-l border-[#999]  w-[63px] text-right">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        완전도재크라운
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        원료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        500,000원
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className=" border-r border-[#999]  w-[63px]">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                          <td className="border-b border-l border-[#999]  w-[63px] text-right">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]">
                            &nbsp; &nbsp; 6 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          </td>
                          <td className="border-l border-[#999]  w-[63px] text-right">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        임플란트
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        850,000원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className=" border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-b border-l border-[#999]  w-[63px] text-right">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]">
                            &nbsp; 7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px] text-right">
                            1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제파술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className=" border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-b border-l border-[#999]  w-[63px] text-right">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]">
                            &nbsp; 7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px] text-right">
                            1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제파술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        35,150원
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
function OnDayTreatmentScene9({ data }) {
  // Sample data
  // const data = [
  //   // { id: 1, status: "진행중", price: "50,000원" },
  //   // { id: 2, status: "완료", price: "60,000원" },
  //   // { id: 3, status: "완료", price: "60,000원" },
  //   // { id: 4, status: "완료", price: "60,000원" },
  //   // { id: 5, status: "완료", price: "60,000원" },
  //   // { id: 6, status: "완료", price: "60,000원" },
  //   // Add more data as needed
  // ];

  return (
    <div className="flex justify-center h-full">
      <div
        className="bg-white p-[20px] max-w-[460px] rounded-lg max-w-[462px] w-full"
        style={{ boxShadow: " 0px 0px 8px 0px rgba(0, 56, 64, 0.06)" }}
      >
        <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
          당일 치료 내용
        </div>
        <div
          style={{ height: "206px", overflowY: "auto" }}
          className="pr-[15px] py-[20px] pl-[20px] border-[1px]"
        >
          <div className="pb-6 w-max">
            {data?.chartNumber === "113010" &&
              localStorage.getItem("completed") === "true" && (
                <>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp; 7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp; 7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        35,150원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row w-full justify-between">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        발치
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        29,370원
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
function OnDayTreatmentScene8({ data }) {
  // Sample data
  // const data = [
  //   // { id: 1, status: "진행중", price: "50,000원" },
  //   // { id: 2, status: "완료", price: "60,000원" },
  //   // { id: 3, status: "완료", price: "60,000원" },
  //   // { id: 4, status: "완료", price: "60,000원" },
  //   // { id: 5, status: "완료", price: "60,000원" },
  //   // { id: 6, status: "완료", price: "60,000원" },
  //   // Add more data as needed
  // ];

  return (
    <div className="flex justify-center h-full">
      <div
        className="bg-white p-[20px] max-w-[460px] rounded-lg max-w-[462px] w-full"
        style={{ boxShadow: " 0px 0px 8px 0px rgba(0, 56, 64, 0.06)" }}
      >
        <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
          당일 치료 내용
        </div>
        <div
          style={{ height: "206px", overflowY: "auto" }}
          className="pr-[15px] py-[20px] pl-[20px] border-[1px]"
        >
          <div className="pb-6 w-max">
            {data?.chartNumber === "110030" &&
              localStorage.getItem("completed") === "true" && (
                <>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            &nbsp;&nbsp;6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        골드인레이
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        300,000원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp; 7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        교정치료
                      </div>
                      <div className="text-[#C29800] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        진행중
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        2,500,000원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp; 7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제파술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        19,340원
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                        <tr className="h-[20px] text-center">
                          <td className="border-r border-[#999]  w-[63px]">
                            7654321
                          </td>
                          <td className="border-l border-[#999]  w-[63px]">
                            1234567
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-[#999]  border-r border-[#999]  w-[63px] ">
                            &nbsp;&nbsp; 7654321
                          </td>
                          <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]">
                            &nbsp;&nbsp;1234567
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        치석제거술
                      </div>
                      <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                        완료
                      </div>
                      <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                        35,150원
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
export {
  OnDayTreatment,
  OnDayTreatmentScenario5,
  OnDayTreatmentScene6,
  OnDayTreatmentScene9,
  OnDayTreatmentScene8,
  OnDayTreatmentScene7,
};
