import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/DataProvider";

function TreatmentList({ setData }) {
  const [selectedRow, setSelectedRow] = useState([]);

  const { tableData, tableDataScenario122, setPatientIsPaymentComplete122 } =
    useContext(DataContext);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData === selectedRow ? null : rowData); // Toggle selection
    setData(rowData);
    localStorage.setItem("completed", "true");
    localStorage.setItem("visible", "true");
  };

  const tableDataS12 = [
    {
      id: 1,
      reservation: "14:00",
      chartNumber: "10006",
      nameAgeGender: "이수만(56/남)",
      dobAgeGender: "10006/남(56)",
      doctor: "최원장",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "예약완료",
      totalMedicalExpense: "9,900,000원",
      patientContribution: "9,000,000원",
      paymentAmount: "-",
      amountReceivable: "8,000,000원",
    },
  ];

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료중
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario122.patientList
                    .filter((item) => item.status === "진료완료")
                    .map((data, index, array) => (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : index === array.length - 1
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() => {
                                  setPatientIsPaymentComplete122(data.id);
                                }}
                                className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                              >
                                진료완료
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !== tableData.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TreatmentListScenario6({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 4,
      reservation: "10:30",
      chartNumber: "207010",
      nameAgeGender: "오찬욱(61/남)",
      doctor: "최윤호",
      status: "진료완료",
      action: "진료완료",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료중
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none">
                              {data.action}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function TreatmentListScenario7({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsPaymentScenario7,
    tableDataScenario7,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료중
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario7.patientList
                    .filter((item) => item.status === "진료중")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.status}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsPaymentScenario7(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario7.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TreatmentListScene6({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsPaymentScenario6,
    tableDataScenario6,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료중
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario6.patientList
                    .filter((item) => item.status === "진료중")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.status}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsPaymentScenario6(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario6.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function TreatmentListScene9({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsPaymentScenario9,
    tableDataScenario9,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료중
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario9.patientList
                    .filter((item) => item.status === "진료중")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.status}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsPaymentScenario9(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario9.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function TreatmentListScene8({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsPaymentScenario8,
    tableDataScenario8,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료중
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario8.patientList
                    .filter((item) => item.status === "진료중")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.status}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsPaymentScenario8(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario8.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  TreatmentList,
  TreatmentListScenario6,
  TreatmentListScenario7,
  TreatmentListScene6,
  TreatmentListScene9,
  TreatmentListScene8,
};
