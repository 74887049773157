"use client";

import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import "./main.css";
import Step6 from "./step6";
import { useNavigate } from "react-router-dom";
const MainPage = () => {
  const [currentActive, setCurrentActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    step1: { name: "", contact: "", age: "", major: "" },
    step2: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      question8: "",
      question9: "",
      question10: "",
      question11: "",
    },
  });

  const navigate = useNavigate();

  const handleNext = () => {
    // Your logic to handle the "Next" button click
    if (currentActive < 6) {
      setCurrentActive(currentActive + 1);
    } else {
      // This is the last index button, so you can trigger your "onChange" event here
      // For example, you can call a function or update a state when the last button is clicked
      handleLastIndexButtonClick();
    }
  };

  const handleLastIndexButtonClick = () => {
    navigate("/doctor/receptionwaiting");
  };

  const handlePrev = () => {
    setCurrentActive(currentActive - 1);
  };

  // const calculatePercent = () => {
  //   let val = 0;
  //   if (currentActive === 2) {
  //     val = 20;
  //   } else if (currentActive === 3) {
  //     val = 40;
  //   } else if (currentActive === 4) {
  //     val = 80;
  //   }
  //   return val + "%";
  // };

  return (
    // <div className="flex justify-center max-w-[1280px] mx-auto ">
    //   <div className="container justify-center py-[48px]">
    //     <div className="flex justify-center px-4">
    //       <div className="flex justify-center flex-col w-full max-w-[640px]">

    <div className=" flex flex-col px-[32px] pt-[48px] pb-[160px] gap-[32px]">
      <div className="header-container ">
        <div className="text-[#154D57] text-center font-[Pretendard] font-[600] text-3xl leading-[48px]">
          문진표 작성
        </div>
      </div>
      <div className="flex flex-col shrink-0 bg-[#FFF] max-w-[1280px] w-full mx-auto  px-[24px] pt-[32px]  gap-[32px] rounded-[8px]">
        {/* {currentActive < 5 && currentActive > 1 && (
          <div className="flex justify-center my-10 items-center px-4">
            <div className="text-neutral-500 text-base font-normal leading-normal mr-3">
              {calculatePercent()}
            </div>
            <div className="bg-[#317580] h-[8px] rounded-[8px] shrink-[0] max-w-[115px]">
              <div
                className="bg-[#317580] h-[8px] rounded-[8px] shrink-[0] max-w-[115px]"
                style={{ width: calculatePercent() }}
              ></div>
            </div>
          </div>
        )} */}
        <div>
          {currentActive === 1 ? (
            <Step1 data={data} setData={setData} />
          ) : currentActive === 2 ? (
            <Step2 data={data} setData={setData} />
          ) : currentActive === 3 ? (
            <Step3 data={data} setData={setData} />
          ) : currentActive === 4 ? (
            <Step4 data={data} setData={setData} />
          ) : currentActive === 5 ? (
            <Step5 data={data} setData={setData} />
          ) : (
            <Step6 data={data} setData={setData} />
          )}
        </div>

        <div className="flex w-200 px-86  justify-center items-center rounded-[8px] pb-[121px]">
          <button
            className={`flex max-w-[200px] aspect-[4.6511] bg-[#317580] py-[11px] px-[86px] rounded-[8px]${
              currentActive === 6 ? " " : "  "
            }`}
            id="next"
            onClick={handleNext}
          >
            <div className="text-[#FFFFFF] font-['Pretendard'] text-[16px] font-[600] leading-[24px] tracking-[-0.4px]">
              {currentActive === 6 ? "다음" : "다음"}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
