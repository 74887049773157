import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../context/DataProvider";
import { useNavigate } from "react-router-dom";
function TreatmentComplete({ setData }) {
  const { tableData, setTableData, setPatientIsPaymentComplete } =
    useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [reservationOpen, setReservationOpen] = useState(false);

  const [openModalStore, setOpenModalStore] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const navigate = useNavigate();

  function openModal() {
    setOpenModalStore(true);
  }

  const closeModal = () => {
    setOpenModalStore(false);
  };

  const openAlertModal = () => {
    setOpenAlert(true);
  };
  const closeAlertModal = () => {
    setOpenAlert(false);
    setOpenModalStore(false);
  };

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  const handleReservation = () => {
    navigate("/deskreservation");
    setReservationOpen(true);
    setOpenAlert(false);
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setReservationOpen(false);
  };

  const handleConfirm = () => {
    setDropDown(true);
  };

  const tableDataS12 = [
    {
      id: 1,
      reservation: "14:00",
      chartNumber: "12036",
      nameAgeGender: "김세희(21/여)",
      dobAgeGender: "12036/남(21)",
      doctor: "홍원장",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "예약완료",
      totalMedicalExpense: "9,900,000원",
      patientContribution: "9,000,000원",
      paymentAmount: "-",
      amountReceivable: "8,000,000원",
    },
    {
      id: 1,
      reservation: "14:00",
      chartNumber: "10344",
      nameAgeGender: "박은영(23/여)",
      dobAgeGender: "10344/남(23)",
      doctor: "김원장",
      status: "진료완료",
      action: "수납하기",
      nextReservation: "-",
      totalMedicalExpense: "22,000,000원",
      patientContribution: "22,000,000원",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];
  return (
    <div className="z-[99999]">
      <div className="table w-full pt-10 text-[13px]">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료완료
        </h3>
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]  overflow-hidden">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] border-b border-gray-300 text-left  w-[9.09%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>
          <div style={{ height: "306px", overflow: "scroll" }}>
            <table className="w-full  overflow-hidden">
              <tbody>
                {tableDataS12.map((data, index) => (
                  <React.Fragment key={index}>
                    <tr
                      className={`text-left cursor-pointer ${
                        selectedRow === data
                          ? "bg-[#EBF5F2]"
                          : "hover:bg-[#EBF5F2]"
                      }`}
                      onClick={() => handleRowClick(data)}
                    >
                      <td className=" pl-4 w-[9.09%] py-[13px]">
                        {data.reservation}
                      </td>
                      <td className="w-[9.09%]">{data.chartNumber}</td>
                      <td className="w-[9.09%]">{data.nameAgeGender}</td>
                      <td className="w-[9.09%]">{data.doctor}</td>
                      <td className="w-[9.09%] text-[#1778B0] font-semibold leading-[19px] tracking-[-0.325px] text-[13px]   ">
                        {data.status}
                      </td>
                      <td className="w-[9.09%]">
                        <div className="items-center py-[6px] flex justify-text ">
                          <button
                            className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none text-[13px]"
                            onClick={openModal}
                          >
                            {data.action}
                          </button>
                        </div>
                      </td>

                      <td className="w-[9.09%] text-[#767676] font-semibold leading-[19px] tracking-[-0.325px] ">
                        {data.nextReservation}
                      </td>
                      <td className="w-[9.09%]">{data.totalMedicalExpense}</td>
                      <td className="w-[9.09%]">{data.patientContribution}</td>
                      <td className="w-[9.09%]">{data.paymentAmount}</td>
                      <td className="w-[9.09%]">{data.amountReceivable}</td>
                    </tr>
                    {index !== tableData.length - 1 && (
                      <tr className="border-t "></tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* {openModalStore === true && (
        <Store setOpenAlert={openAlertModal} setModal={closeModal} />
      )}

      {openAlert === true && (
        <StoreAlertBox
          setOpenAlert={closeAlertModal}
          onConfirm={handleReservation}
        />
      )}

      {reservationOpen === true && (
        // <MakeReservation onCancel={handleCancel} onConfirm={handleConfirm} />
        <DeskReservationScenario6 />
      )}

      {dropDown === true && <Dropdown />} */}
    </div>
  );
}

export default TreatmentComplete;
