import React, { useState,useEffect } from "react";
import "./index.css";
import "./NextTreatmentModal.scss";

function NextTreatmentModal({trigger}) {
  const [injuryVisible, setInjuryVisible] = useState(false);
  const [injuryTypeSelector, setInjuryTypeSelector] = useState("CU");
  const [priceAmount, setPriceAmount] = useState(200000);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [priceAmountTotal, setPriceAmountTotal] = useState(0);
  const [selectItems,setSelectItems] = useState([
    {a:"K00.2",b:"치아의 크기와 형태의 이상"},
    {a:"K02.2",b:"시멘트질의 우식"},
    {a:"K02.8",b:"기타 치아우식"},
    {a:"K04.5",b:"만성 근단치주염"},
    {a:"K04.5",b:"근단 또는 근단주위 육아종"},
    {a:"K04.6",b:"동이 있는 근단주위농양"},
    {a:"K04.6",b:"동이 있는 치아농양"},
    {a:"K04.6",b:"동이 있는 치아치조농양"},
    {a:"K04.6",b:"동이 있는 치수기원의 치주농양"},
    {a:"K04.60",b:"상악동으로 연결된 동"},
    {a:"K04.61",b:"비강으로 연결된 동"},
    {a:"K04.62",b:"구강으로 연결된 동"},
    {a:"K04.63",b:"피부로 연결된 동"},
    {a:"K04.7",b:"동이 없는 근단주위농양"},
    {a:"K05.20",b:"동이 없는 잇몸 기원의 치주농양"},
    {a:"K05.21",b:"동이 없는 잇몸 기원의 치주농양"},
    {a:"K05.30",b:"만성 단순치주염"},
    {a:"K05.31",b:"만성 복합치주염"},
    {a:"K07.38",b:"치아위치의 기타 명시된 이상"},
    {a:"K08.3",b:"잔류치근"},
    {a:"S02.54",b:"치수침범이 있는 치관의 파절"},
    {a:"S02.55",b:"치근의 파절"},
    {a:"S02.56",b:"치근을 포함한 치관의 파절"},
    {a:"S02.57",b:"치아의 다발성 파절"},
    {a:"S02.59",b:"치아의 상세불명 파절"},
    {a:"S03.20",b:"치아의 측방탈구"},
    {a:"S03.21",b:"치아의 함입 또는 탈출"},
    {a:"S03.22",b:"치아의 박리(완전탈구)"},
    {a:"S03.28",b:"기타 및 상세불명의 치아의 탈구"},

  ])
 const [Salary, setSalary] = useState(true);
  const [nonBenefit, setNonBenefit] = useState(false);
  useEffect(() => {
    document.querySelectorAll(".trt-btn").forEach((item) => {
      item.addEventListener("click", setActive);
    });
    document.querySelectorAll(".injury-item").forEach((item) => {
      item.addEventListener("click", setInjuryActive);
    });
 
    return () => {
      document.querySelectorAll(".trt-btn").forEach((item) => {
        item.removeEventListener("click", setActive);
      });
      document.querySelectorAll(".injury-item").forEach((item) => {
        item.removeEventListener("click", setInjuryActive);
      });
    }
  }, [Salary, nonBenefit, injuryVisible, injuryTypeSelector,trigger]);
 
  function setActive(e) {
    document.querySelectorAll(".trt-btn").forEach((item) => {
      if (item === e.target) {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        } else {
          item.classList.add("active");
          setInjuryTypeSelector(item.innerText)
          setInjuryVisible(true);
        }
      } else {
        item.classList.remove("active");
      }
    });
  }
 
  function setInjuryActive(e) {
    document.querySelectorAll(".injury-item").forEach((item) => {
      if (item === e.target.parentElement) {
        item.classList.toggle("bg-[#EBF5F2]")
      } else {
        item.classList.remove("bg-[#EBF5F2]");
      }
    });
  }
 
  function calculateDiscount(percentage){
    if(percentage*1 === NaN){
      alert("hello")
    }
    console.log(typeof percentage*1);
    if(percentage > 100){
      setDiscountAmount(0);
      setPriceAmountTotal(priceAmount)
      return
    }
    if(percentage === 0){
      setPriceAmountTotal(priceAmount)
      return
    }
    setDiscountAmount(percentage);
    setPriceAmountTotal(priceAmount - (priceAmount /100 * percentage))
  }
  const svgDataArray = [
    {
      width: 33,
      height: 25,
      number: 18,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 17,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 16,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 15,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 14,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 13,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 12,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 28,
      number: 11,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
  ];
  const svgDataArray1 = [
    {
      width: 33,
      height: 25,
      number: 55,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 54,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 53,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 26,
      number: 52,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 26,
      number: 51,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
  ];

  const svgDataArray2 = [
    {
      width: 20,
      height: 28,
      number: 21,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 28,
      number: 22,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 23,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 24,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 25,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 26,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 27,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 28,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
  ];

  const svgDataArray3 = [
    {
      width: 20,
      height: 28,
      number: 61,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 28,
      number: 62,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 63,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 64,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 65,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
  ];

  const svgDataArray4 = [
    {
      width: 33,
      height: 25,
      number: 85,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 84,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },

    {
      width: 22,
      height: 28,
      number: 83,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 20,
      height: 26,
      number: 82,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 81,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
  ];

  const svgDataArray5 = [
    {
      width: 33,
      height: 25,
      number: 48,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 47,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 46,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },

    {
      width: 22,
      height: 28,
      number: 45,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 44,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 43,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 20,
      height: 26,
      number: 42,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 41,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
  ];

  const svgDataArray6 = [
    {
      width: 20,
      height: 26,
      number: 71,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 72,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 22,
      height: 28,
      number: 73,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 33,
      height: 25,
      number: 74,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 75,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
  ];

  const svgDataArray7 = [
    {
      width: 20,
      height: 26,
      number: 31,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 32,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 22,
      height: 28,
      number: 33,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 34,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 35,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 33,
      height: 25,
      number: 36,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 37,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 38,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
  ];

  const [clickedArray, setClickedArray] = useState(
    Array(svgDataArray.length).fill(false)
  );
  const [clickedArray1, setClickedArray1] = useState(
    Array(svgDataArray1.length).fill(false)
  );
  const [clickedArray2, setClickedArray2] = useState(
    Array(svgDataArray2.length).fill(false)
  );
  const [clickedArray3, setClickedArray3] = useState(
    Array(svgDataArray3.length).fill(false)
  );

  const [clickedArray4, setClickedArray4] = useState(
    Array(svgDataArray4.length).fill(false)
  );

  const [clickedArray5, setClickedArray5] = useState(
    Array(svgDataArray5.length).fill(false)
  );
  const [clickedArray6, setClickedArray6] = useState(
    Array(svgDataArray6.length).fill(false)
  );
  const [clickedArray7, setClickedArray7] = useState(
    Array(svgDataArray7.length).fill(false)
  );
  const toggleColor = (index) => {
    const newClickedArray = [...clickedArray];
    newClickedArray[index] = !newClickedArray[index];
    setClickedArray(newClickedArray);
  };

  const toggleColor1 = (index) => {
    const newClickedArray1 = [...clickedArray1];
    newClickedArray1[index] = !newClickedArray1[index];
    setClickedArray1(newClickedArray1);
  };
  const toggleColor2 = (index) => {
    const newClickedArray2 = [...clickedArray2];
    newClickedArray2[index] = !newClickedArray2[index];
    setClickedArray2(newClickedArray2);
  };

  const toggleColor3 = (index) => {
    const newClickedArray3 = [...clickedArray3];
    newClickedArray3[index] = !newClickedArray3[index];
    setClickedArray3(newClickedArray3);
  };

  const toggleColor4 = (index) => {
    const newClickedArray4 = [...clickedArray4];
    newClickedArray4[index] = !newClickedArray4[index];
    setClickedArray4(newClickedArray4);
  };

  const toggleColor5 = (index) => {
    const newClickedArray5 = [...clickedArray5];
    newClickedArray5[index] = !newClickedArray5[index];
    setClickedArray5(newClickedArray5);
  };

  const toggleColor6 = (index) => {
    const newClickedArray6 = [...clickedArray6];
    newClickedArray6[index] = !newClickedArray6[index];
    setClickedArray6(newClickedArray6);
  };

  const toggleColor7 = (index) => {
    const newClickedArray7 = [...clickedArray7];
    newClickedArray7[index] = !newClickedArray7[index];
    setClickedArray7(newClickedArray7);
  };

 

  const handleItemClick = (item) => {
    if (item === "salary") {
      // Remove the extra space in " salary"
      setSalary(true);
      setNonBenefit(false);
      console.log("salary changing");
    } else if (item === "nonBenefit") {
      setSalary(false);
      setNonBenefit(true);
      console.log("nonbenefit changing");
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-center   ">
        <div className="modal-title flex-1">다음 진료 입력 추가</div>
        <div className="cursor-pointer">
          <img src="/close.png" alt="" />
        </div>
      </div>
      <div
        style={{
          height: "488px",

          overflowY: "auto",
        }}
      >
        <div className="flex justify-center pt-8">
          <table className=" h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
            <tr className="h-[113px] text-center">
              <td className="border-r w-[246px] border-[#D4D4D8] border-[#999]">
                <div class="flex flex-col">
                  <div class="flex items-center">
                    {svgDataArray.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div class="flex items-center pt-[9px] pb-[12px] ">
                    <div className="flex flex-col gap-1 mr-6">
                      <button className="py-1 px-[22px] bg-[#C7EAE0] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                        전악
                      </button>
                      <button className="py-1 px-[22px] bg-[#FAF6D9] rounded-[4px]  text-[#C29800] text-[12px] leading-[18px] tracking-[-0.3px]">
                        상악
                      </button>
                    </div>
                    <div class="flex items-center">
                      {svgDataArray1.map((svgData, index) => (
                        <div
                          key={index}
                          style={{ position: "relative", marginRight: "4px" }}
                          className="flex items-center"
                        >
                          <svg
                            width={svgData.width}
                            height={svgData.height}
                            viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                            onClick={() => toggleColor1(index)}
                          >
                            <path
                              d={svgData.path}
                              fill={
                                clickedArray1[index]
                                  ? "var(--400, #5A97A3)"
                                  : "url(#paint0_linear_2178_75779)"
                              }
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2178_75779"
                                x1="16.5"
                                y1="25"
                                x2="16.4996"
                                y2="2.27283"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#A0AFB2" />
                                <stop
                                  offset="1"
                                  stop-color="#A0AFB2"
                                  stop-opacity="0.3"
                                />
                              </linearGradient>
                            </defs>
                          </svg>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "13px", // Adjust the font size as needed
                              color: "#FFF", // Adjust the color as needed
                              fontWeight: 600,
                              lineHeight: "19px",
                              fontWeight: "bold",
                            }}
                            className="cursor-pointer"
                            onClick={() => toggleColor1(index)}
                          >
                            {svgData.number}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </td>
              <td className="border-l w-[245px] border-[#D4D4D8]  border-[#999]">
                <div class="flex flex-col ">
                  <div class="flex items-center pl-3">
                    {" "}
                    {svgDataArray2.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor2(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray2[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor2(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div class="flex items-center pt-[9px] pb-[12px] ml-3">
                    {svgDataArray3.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor3(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray3[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor3(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                    <div className="flex flex-col gap-1 ml-6 ">
                      <button className="py-1 px-[12px] bg-[#EBF5F2] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                        유치전악
                      </button>
                      <button className="py-1 px-[12px] bg-[#FAF6D9] rounded-[4px]  text-[#C29800] text-[12px] leading-[18px] tracking-[-0.3px]">
                        유치상악
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="h-[113px]">
              <td className="border-t border-r w-[245px] border-[#D4D4D8]  border-[#999]">
                <div class="flex flex-col">
                  <div class="flex items-center pt-[9px] pb-[12px] ">
                    <div className="flex flex-col gap-1 mr-6">
                      <button className="py-1 px-[22px] bg-[#EBF5F2] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                        하악
                      </button>
                      <button className="py-1 px-[12px] bg-[#DBEEF9] rounded-[4px]  text-[#1778B0] text-[12px] leading-[18px] tracking-[-0.3px]">
                        전체선택
                      </button>
                    </div>
                    {svgDataArray4.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor4(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray4[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor4(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div class="flex items-center">
                    {svgDataArray5.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor4(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray5[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor5(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </td>
              <td className="border-t border-l border-[#D4D4D8]  w-[245px] border-[#999]">
                <div class="flex items-center pt-[9px] pb-[12px]  ml-3">
                  {svgDataArray6.map((svgData, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", marginRight: "4px" }}
                      className="flex items-center"
                    >
                      <svg
                        width={svgData.width}
                        height={svgData.height}
                        viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                        onClick={() => toggleColor6(index)}
                      >
                        <path
                          d={svgData.path}
                          fill={
                            clickedArray6[index]
                              ? "var(--400, #5A97A3)"
                              : "url(#paint0_linear_2178_75779)"
                          }
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2178_75779"
                            x1="16.5"
                            y1="25"
                            x2="16.4996"
                            y2="2.27283"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#A0AFB2" />
                            <stop
                              offset="1"
                              stop-color="#A0AFB2"
                              stop-opacity="0.3"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "13px", // Adjust the font size as needed
                          color: "#FFF", // Adjust the color as needed
                          fontWeight: 600,
                          lineHeight: "19px",
                          fontWeight: "bold",
                        }}
                        className="cursor-pointer"
                        onClick={() => toggleColor6(index)}
                      >
                        {svgData.number}
                      </div>
                    </div>
                  ))}
                  <div className="flex flex-col gap-1 ml-6">
                    <button className="py-1 px-[12px] bg-[#EBF5F2] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                      유치하악
                    </button>
                    <button className="py-1 px-[12px] bg-[#FFEBDE] rounded-[4px]  text-[#DA5F1A] text-[12px] leading-[18px] tracking-[-0.3px]">
                      선택해제
                    </button>
                  </div>
                </div>
                <div class="flex items-center ml-3">
                  {svgDataArray7.map((svgData, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", marginRight: "4px" }}
                      className="flex items-center"
                    >
                      <svg
                        width={svgData.width}
                        height={svgData.height}
                        viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                        onClick={() => toggleColor7(index)}
                      >
                        <path
                          d={svgData.path}
                          fill={
                            clickedArray7[index]
                              ? "var(--400, #5A97A3)"
                              : "url(#paint0_linear_2178_75779)"
                          }
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2178_75779"
                            x1="16.5"
                            y1="25"
                            x2="16.4996"
                            y2="2.27283"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#A0AFB2" />
                            <stop
                              offset="1"
                              stop-color="#A0AFB2"
                              stop-opacity="0.3"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "13px", // Adjust the font size as needed
                          color: "#FFF", // Adjust the color as needed
                          fontWeight: 600,
                          lineHeight: "19px",
                          fontWeight: "bold",
                        }}
                        className="cursor-pointer"
                        onClick={() => toggleColor7(index)}
                      >
                        {svgData.number}
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="my-[20px]">
          <hr className="max-w-[491px]  flex justify-center mx-auto mt-[20px] bg-[#F1F1F5]" />
        </div>
        <div className="flex flex-col justify-center ">
          <div className="max-w-[500px] w-full mx-auto">
            <div className="flex flex-row gap-[30px] mb-4">
              <div
                onClick={() => handleItemClick("salary")}
                active={Salary ? "true" : "false"}
                className={`cursor-pointer ${
                  Salary
                    ? "text-[#154D57] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] border-b border-[#154D57] border-b-[3px] w-[35px] text-center"
                    : "text-[#767676] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-center"
                }`}
              >
                급여
              </div>
              <div
                onClick={() => handleItemClick("nonBenefit")}
                active={nonBenefit ? "true" : "false"}
                className={`cursor-pointer ${
                  nonBenefit
                    ? "text-[#154D57] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] border-b border-[#154D57] border-b-[3px]  w-[55px] text-center "
                    : "text-[#767676] text-[18px] font-semibold leading-[26px] tracking-[-0.45px] text-center"
                }`}
              >
                비급여
              </div>
            </div>

            <div>
              {Salary && (
                <div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[17px] py-[21px] rounded-[4px] w-max  text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        치주
                      </div>
                      <div className="gap-2 flex flex-col">
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              SC(연1회)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              SC(1/3악)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              RP
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              CU
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              Flap(간단)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              Flap(복잡)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              CLP
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              치은절제술
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[17px] py-[36px] rounded-[4px] w-max text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        외과
                      </div>
                      <div className="gap-2 flex flex-col">
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              발치
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              난발치
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              단순매복
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              복잡매복
                            </button>
                          </div>
                          <div>
                            <button className="text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              완전매복
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              치조골성형술
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              I&D(치은)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              I&D(치조)
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              Apicoectomy
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              치은판절제술
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[17px] py-[3px] rounded-[4px] w-max text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        보존
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            GI 즉처
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            GI 충전
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            레진충전
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            근관치료
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            Re-endo
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[17px] py-[3px] rounded-[4px] w-max text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        보철
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            임플란트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            부분틀니
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            완전틀니(레진)
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            완전틀니(금속)
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[17px] py-[3px] rounded-[4px] w-max text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        소치
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            유치발치
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            실런트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            Pulpotomy
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            Pulpectomy
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[12px] py-[3px] rounded-[4px] w-max text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        X-ray
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            파노라마
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            치근단
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            교익
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            구외
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                            CT
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-[6px]">
                      <div className="bg-[#EBF5F2] px-[17px] py-[20px] rounded-[4px] w-max text-[#154D57] font-semibold leading-[19px] tracking-[-0.325px]">
                        마취
                      </div>
                      <div className="flex flex-col gap-[6px]">
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              침윤마취
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              전달마취(하치조신경)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              전달마취(후상치조신경)
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-row gap-[6px]">
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              전달마취(이신경)
                            </button>
                          </div>
                          <div>
                            <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                              전달마취(비구개신경)
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
  <div>
                  {nonBenefit === true && (
                    <div className="flex flex-col gap-[16px]">
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          골드크라운
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          지르코니아
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          완전도재크라운
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          PFG
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          PFM
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          라미네이트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          Pontic
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          골드인레이
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          레진인레이
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          세라믹인레이
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          골드온레이
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          레진온레이
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          세라믹온레이
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          파라포스트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57]  text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          주조형포스트
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          코어
                          </button>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                      <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          임플란트어
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          골이식술
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          부분틀니
                          </button>
                        </div>
                        </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          완전틀니
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          미백
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          스플린트
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          코골이 장치
                          </button>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-[6px]">
                      <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          소아 sedation
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          소아 sedation
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          소아레진
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          실런트
                          </button>
                        </div>
                        </div>
                      <div className="flex flex-row gap-[6px]">
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          SS 크라운
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          공간유지장치
                          </button>
                        </div>
                        <div>
                          <button className="trt-btn text-[#154D57] text-[12px] font-normal leading-[18px] py-1 px-3 border-[1px] border-[#D4D4D8] rounded-[4px]">
                          교정상담
                          </button>
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                      <div className="flex w-full flex-col gap-[16px] mt-[20px]">
                <div className="text-[#111] font-['pretendard'] text-[18px] font-[600] leading-[26px] tracking-[-0.45]">
                  {!nonBenefit ? "" : "금액"}
                </div>
                <div className="overflow-scroll w-full scrollbarhidden">
                  {injuryVisible && injuryTypeSelector === "CU" ? 
                  (
                    <table className="w-full border-[1px] border-[#D4D4D8] rounded-[8px] h-auto">
                      <tbody className="w-full  ">
                        <tr className="border-b border-gray-300">
                          <td className="px-3 py-2 rounded-tl-[8px] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                            코드
                          </td>
                          <td className="px-3 py-2 rounded-tr-[8px] ">
                            <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              가능 상병명
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.30
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 단순치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item ">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.31
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 복합치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2 font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.32
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 치관치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2 font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K05.38
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              기타 명시된 만성 치주염
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : injuryTypeSelector === "발치" ? (
                    <table className="w-full border-[1px] border-[#D4D4D8] rounded-[8px] h-auto">
                      <tbody className="w-full  ">
                        <tr className="border-b border-gray-300 bg-[#F1F8FA]">
                          <td className="px-3 py-2 rounded-tl-[8px] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                            코드
                          </td>
                          <td className="px-3 py-2 rounded-tr-[8px] ">
                            <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              가능 상병명
                            </div>
                          </td>
                        </tr>
                        {selectItems.map((item)=>{
                          return (
<tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            {item.a}
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                             {item.b}
                            </div>
                          </td>
                        </tr>
                          )
                        })}
                        
                      </tbody>
                    </table>
                  ) : injuryTypeSelector === "근관치료" ? (
                    <table className="w-full border-[1px] border-[#D4D4D8] rounded-[8px] h-auto">
                      <tbody className="w-full  ">
                        <tr className="border-b border-gray-300 bg-[#F1F8FA]">
                          <td className="px-3 py-2 rounded-tl-[8px] w-[120px] font-semibold text-[13px] leading-[19px] text-[#505050]">
                            코드
                          </td>
                          <td className="px-3 py-2 rounded-tr-[8px] ">
                            <div className="text-[13px]  font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              가능 상병명
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.01
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              비가역적 치수염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.1
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수의 괴사
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.2
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수변성
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.3
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수내의 이상경조직형성
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.4
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              치수기원의 급성 근단치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.5
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              만성 근단치주염
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.60
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              상악동으로 연결된 동
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-gray-300 injury-item">
                          <td className="px-3 py-2  font-semibold leading-[19px]  text-[13px] text-[#505050]">
                            K04.61
                          </td>
                          <td className="px-3 py-2">
                            <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                              비강으로 연결된 동
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : nonBenefit  &&
                  <div className="price-cal">
                    <table >
                      <tbody>
                        <tr>
                          <td className="col-names">금액</td>
                          <td align="left">{priceAmount}</td>
                        </tr>
                        <tr>
                          <td className="col-names">할인율(%) or 할인금액</td>
                          <td align="left"><input min={0} max={100} value={discountAmount} onChange={(e)=>calculateDiscount(e.target.value)} type="number" placeholder="할인율(%) or 할인금액을 입력해주세요" /></td>
                        </tr>
                        <tr>
                          <td className="col-names">최종금액</td>
                          <td align="left" className="font-semibold text-[#154D57]">{priceAmountTotal}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>}
                </div>
              </div>
          </div>
    
        </div>

        {/* <div className="flex gap-2 justify-center pt-8">
        <button className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
          이전
        </button>
        <button className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
          저장
        </button>
      </div> */}
      </div>
    </div>
  );
}

export default NextTreatmentModal;
