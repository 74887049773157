import React, { useState } from "react";
import PatientInfo from "../PatientInfo";
import "./index.css";

function PreparingList({ setData }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const tableData = [
    {
      reservation: "10:00",
      chart: "1110111",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "22222",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "2424242",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "424242",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "53535",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "2424242",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "424242",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    {
      reservation: "10:00",
      chart: "53535",
      details: "김덴투(20/여)",
      doctor: "김원장",
      process: "진료시작  ",
    },
    // Add more data as needed
  ];

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                maxHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableData.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className="text-center cursor-pointer"
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className="py-[14px] text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className="py-[14px] text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chart}
                        </td>
                        <td
                          className="py-[14px] text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.details}
                        </td>
                        <td
                          className="py-[14px] text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className="py-[14px] text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none">
                              {data.process}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr>
                          <td colSpan="5">
                            <hr />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreparingList;
