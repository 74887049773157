import React, { useState } from "react";

import teeth1 from "../../../Assets/images/xrayteeth.png";
import AddXray from "../TreatmentPlanS12/components/AddXray";

function MyComponent() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </label>
      </div>
    </div>
  );
}
const NavigationBar = () => {
  const [activeTab, setActiveTab] = useState("데스크");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
};

export default function ClinicTab({ step }) {
  const [openModal, setopenModal] = useState(false);
  const [viewXray, setViewXray] = useState(false);

  const handleOpenModal = () => {
    setopenModal(true);
    setViewXray(false);
  };

  function checkSteps(number) {
    if (number <= step) {
      return true;
    }
  }
  return (
    <>
      <NavigationBar />

      <div className="flex flex-col gap-[28px] w-full">
        <div className="flex justify-between">
          <div className="flex gap-[32px]">
            <div className="flex items-center font-[600] text-[18px] leading-[26px] font-[pretendard] tracking-[-0.45px] text-[#111]">
              치료계획
            </div>
            <div className="inline-flex h-36px py-2 px-3 justify-center items-center gap-[21px] flex-shrink-0 rounded-[32px] bg-[#EAF6F3] font-[400] text-[13px] font-normal leading-[19px] font-[pretendard] tracking-[-0.325px] text-[#154D57]">
              2023년 10월 31일(화)
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z"
                    fill="#317580"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center gap-[8px] ">
            <button
              className=" flex inline-flex py-2 px-4 rounded-[8px] bg-[#EBF5F2] text-[#154D57]  text-[13px] font-[600] "
              onClick={handleOpenModal}
            >
              X-ray 업로드
            </button>

            <button className=" flex inline-flex py-2 px-4 rounded-[8px] bg-[#FFEBDE] text-[#DA5F1A] text-[13px] font-[600] ">
              전체확인
            </button>
          </div>
        </div>
        {openModal === true && (
          <AddXray
            setopenModal={setopenModal}
            step={step}
            setXrayView={setViewXray}
          />
        )}

        <div>
          <div classname="flex max-w-[874px] w-full h-[518px]">
            <div className="flex flex-col   shrink-[0] rounded-[8px] border border-solid border-gray-300 rounded-8 ] ">
              <div
                style={{
                  height: "578px",
                  overflowY: "auto",
                  borderRadius: "8px",
                  padding: "20px",
                }}
              >
                <div className="flex flex-col gap-[16px] ">
                  <div className="flex gap-[12px] justify-between">
                    <div>
                      <div className="flex gap-2">
                        <div>
                          <img
                            src={teeth1}
                            alt="Teeth2"
                            className="w-[130px] h-[70px]"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row gap-2 text-[#767676] text-[12px] leading-[18px] text-[-0.3px]  items-end pr-[40px]">
                      <div>메모</div>
                      <div>수정</div>
                      <div>삭제</div>
                    </div>
                  </div>
                  <div className="flex gap-[20px] "></div>
                  <div className="w-full h-[1px]gap-[12px]  bg-[#F1F1F5]"></div>

                  {/* Data */}
                  <div className="flex flex-col pt-[16px]">
                    <div className="flex justify-between ">
                      <div className="flex gap-[16px] ">
                        <div>
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                        <div>
                          <table className="h-[41px] w-[140px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                            <tr className="h-[20px] text-center">
                              <td className="border-r w-[63px] border-[#999999] w-max whitespace-nowrap leading-[18px]  p-[2px] tracking-[-0.89px]">
                                8 7 6 5 4 3 2 1
                              </td>
                              <td className="border-l w-[63px]  border-[#999999]  whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                1 2 3 4 5 6 7 8
                              </td>
                            </tr>
                            <tr className="h-[20px] text-center">
                              <td className="border-t border-r w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                {" "}
                                8 7 6 5 4 3 2 1
                              </td>
                              <td className="border-t border-l w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                1 2 3 4 5 6 7 8
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="flex gap-[20px]">
                          <div classname="flex ">
                            <div className="font-['pretendard'] w-[140px] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              치주소파술
                            </div>
                            <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]"></div>
                          </div>

                          <div className="text-[13px] text-[#DA5F1A] font-semibold leading-[19px] ">
                            {" "}
                            진행전
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-[20px]">
                        <div>
                          <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                            19,340원
                          </div>
                          <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            <div>메모</div>
                            <div>수정</div>
                            <div>삭제</div>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M14 11.998L10 7.99805L6 11.998"
                                stroke="#317580"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M6 8.00195L10 12.002L14 8.00195"
                                stroke="#317580"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col py-[16px]">
                    <div className="flex justify-between ">
                      <div className="flex gap-[16px] ">
                        <div>
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                        <div>
                          <table className="h-[41px]  w-[140px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                            <tr className="h-[20px] text-center">
                              <td className="border-r w-[63px] border-[#999999] w-max whitespace-nowrap leading-[18px]  p-[2px] tracking-[-0.89px]">
                                &#8194; &#8194; &#8194; &#8194; &#8194; &#8194;
                                &#8194; &#8194;
                              </td>
                              <td className="border-l w-[63px]  border-[#999999]  whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                &#8194; &#8194; &#8194; &#8194; &#8194; &#8194;
                                &#8194; &#8194; &#8194;
                              </td>
                            </tr>
                            <tr className="h-[20px] text-center">
                              <td className="border-t border-r w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                {" "}
                                &#8194; &#8194; &#8194; &#8194; &#8194; &#8194;
                                &#8194; &#8194; &#8194;
                              </td>
                              <td className="border-t border-l w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                &#8194; &#8194; &#8194; &#8194; &#8194; &#8194;
                                &#8194; &#8194; &#8194;8
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="flex gap-[20px]">
                          <div classname="flex ">
                            <div className="font-['pretendard'] text-[13px]  w-[140px] font-[400] leading-[19px] tracking-[-0.325px]">
                              발치
                            </div>
                            <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]"></div>
                          </div>
                        </div>

                        <div className="text-[13px] text-[#DA5F1A] font-semibold leading-[19px] ">
                          {" "}
                          진행전
                        </div>
                      </div>
                      <div className="flex gap-[20px]">
                        <div>
                          <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                            29,370원
                          </div>
                          <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            <div>메모</div>
                            <div>수정</div>
                            <div>삭제</div>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M14 11.998L10 7.99805L6 11.998"
                                stroke="#317580"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M6 8.00195L10 12.002L14 8.00195"
                                stroke="#317580"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between ">
                    <div className="flex gap-[16px] justify-between ">
                      <div>
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                      <div>
                        <table className="h-[41px]  w-[140px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                          <tr className="h-[20px] text-center">
                            <td className="border-r w-[63px] border-[#999999] w-max whitespace-nowrap leading-[18px]  p-[2px] tracking-[-0.89px]">
                              5
                            </td>
                            <td className="border-l w-[63px]  border-[#999999]  whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]"></td>
                          </tr>
                          <tr className="h-[20px] text-center">
                            <td className="border-t border-r w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                              {" "}
                            </td>
                            <td className="border-t border-l w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]"></td>
                          </tr>
                        </table>
                      </div>
                      <div className="flex gap-[20px]">
                        <div classname="flex ">
                          <div className="font-['pretendard'] w-[140px] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            근관치료
                          </div>
                          <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            {/* 잔류치근 */}
                          </div>
                        </div>
                      </div>
                      <div className="text-[13px] text-[#DA5F1A] font-semibold leading-[19px] ">
                        {" "}
                        진행전
                      </div>
                    </div>
                    <div className="flex gap-[20px]">
                      <div>
                        <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                          33,530원
                        </div>
                        <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                          <div>메모</div>
                          <div>수정</div>
                          <div>삭제</div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M14 11.998L10 7.99805L6 11.998"
                              stroke="#317580"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        </div>
                        <div className="flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M6 8.00195L10 12.002L14 8.00195"
                              stroke="#317580"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col py-[16px]">
                    {/* data 4 */}
                    <div className="flex justify-between ">
                      <div className="flex gap-[16px] justify-between ">
                        <div>
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                        <div>
                          <table className="h-[41px]  w-[140px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                            <tr className="h-[20px] text-center">
                              <td className="border-r w-[63px] border-[#999999] w-max whitespace-nowrap leading-[18px]  p-[2px] tracking-[-0.89px]">
                                5
                              </td>
                              <td className="border-l w-[63px]  border-[#999999]  whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]"></td>
                            </tr>
                            <tr className="h-[20px] text-center">
                              <td className="border-t border-r w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]">
                                {" "}
                              </td>
                              <td className="border-t border-l w-[63px]  border-[#999999] whitespace-nowrap leading-[18px] p-[2px] tracking-[-0.89px]"></td>
                            </tr>
                          </table>
                        </div>
                        <div className="flex gap-[20px]">
                          <div classname="flex ">
                            <div className="font-['pretendard'] w-[140px] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              크라운
                            </div>
                            <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              {/* 잔류치근 */}
                            </div>
                          </div>
                        </div>
                        <div className="text-[13px] text-[#DA5F1A] font-semibold leading-[19px] ">
                          {" "}
                          진행전
                        </div>
                      </div>
                      <div className="flex gap-[20px]">
                        <div>
                          <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                            500,000원
                          </div>
                          <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            <div>메모</div>
                            <div>수정</div>
                            <div>삭제</div>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M14 11.998L10 7.99805L6 11.998"
                                stroke="#317580"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M6 8.00195L10 12.002L14 8.00195"
                                stroke="#317580"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
