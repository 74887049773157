import React, { useEffect, useState } from "react";
import "./index.css";
import Calender from "../Calender";
import { format, startOfToday } from "date-fns";
function MakeReservation({ setModalOpen, onCancel, onConfirm }) {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-12-01"));
  const openModal = () => {
    setModalOpen(true);
  };

  const openCaleneder = () => {
    setOpenCal(true);
  };

  useEffect(() => {
    document.querySelectorAll("td").forEach((item) => {
      item.addEventListener("click", (e) => {
        document.querySelectorAll("td").forEach((item1) => {
          if (item1 === e.target) {
            console.log(e.target, item1);
            e.target.classList.add("bg-[#EBF5F2]");
          } else {
            item1.classList.remove("bg-[#EBF5F2]");
          }
        });
      });
    });
  }, [openModal]);
  const rowData = [];

  for (let hour = 9; hour <= 19; hour++) {
    rowData.push({
      col1: `${hour}시`,
      col2: "akash",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
    });

    if (hour < 19) {
      rowData.push({
        col1: `${hour}시 30분`,
        col2: "",
        col3: "",
        col4: "",
        col5: "",
        col6: "",
        col7: "",
      });
    }
  }

  // Now rowData contains data for hours and minutes from 9시 to 19시 30분

  return (
    <div>
      <div className="modal">
        <div className="modal-content p-[32px] h-[680px]">
          <div className="flex flex-row items-center justify-center   ">
            <div className="modal-title flex-1">예약하기</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" onClick={onCancel} />
            </div>
          </div>
          <div className="bg-[#FAFAFA] px-8 w-full">
            <div className="max-w-[836px] w-full mx-auto pt-4 flex flex-col">
              <div className="flex justify-center mb-3">
                <div
                  className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]"
                  style={{ width: "max-content" }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={openCaleneder}
                      className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.333 6L7.33301 10L11.333 14"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                        {`${format(currentDate, "yyyy")}년 ${format(
                          currentDate,
                          "MM"
                        )}월  ${format(currentDate, "dd")}주차`}
                      </span>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.66699 14L12.667 10L8.66699 6"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Add your SVG icon here */}
                  </div>
                  <div className="absolute z-[0] bg-white">
                    {openCal === true && (
                      <Calender
                        selectedDate={currentDate}
                        setCalendarState={setOpenCal}
                        onDateSelect={setCurrentDate}
                        buttonVisible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
                  <div className=" ">
                    <table className="w-full  ">
                      <thead className="mr-1">
                        <tr className="bg-[#F1F8FA] text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#767676] ">
                          <th className="w-[9.4%] text-left pl-3 border-r "></th>

                          <th className="w-[13.2%]  text-left pl-2 py-[9px] border-r">
                            12월 4일(월)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 5일(화)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 6일(수)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 7일(목)
                          </th>

                          <th className="w-[13.2%]  text-left pl-2 border-r">
                            12월 8일(금)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 9일(토)
                          </th>
                          <th className="w-[13%]  text-left pl-2 border-r">
                            12월 10일(일)
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    className="rounded-b-lg"
                    style={{ maxHeight: "424px", overflow: "scroll" }}
                  >
                    <table className="w-full">
                      <tbody>
                        <tr className="border-t py-[9px] ">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            9시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            9시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            10시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r  hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            10시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                            11시 박덴트
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            11시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            11시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            13시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            13시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            14시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                            14시 김미백 <br />
                            14시 이덴투
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            14시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 justify-center pt-[20px] items-end">
            <button
              onClick={onCancel}
              className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              취소
            </button>

            <button
              onClick={onConfirm}
              className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function MakeReservationScene9({ setModalOpen, onCancel, onConfirm }) {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-12-01"));
  const openModal = () => {
    setModalOpen(true);
  };

  const openCaleneder = () => {
    setOpenCal(true);
  };

  useEffect(() => {
    document.querySelectorAll("td").forEach((item) => {
      item.addEventListener("click", (e) => {
        document.querySelectorAll("td").forEach((item1) => {
          if (item1 === e.target) {
            console.log(e.target, item1);
            e.target.classList.add("bg-[#EBF5F2]");
          } else {
            item1.classList.remove("bg-[#EBF5F2]");
          }
        });
      });
    });
  }, [openModal]);
  const rowData = [];

  for (let hour = 9; hour <= 19; hour++) {
    rowData.push({
      col1: `${hour}시`,
      col2: "akash",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
    });

    if (hour < 19) {
      rowData.push({
        col1: `${hour}시 30분`,
        col2: "",
        col3: "",
        col4: "",
        col5: "",
        col6: "",
        col7: "",
      });
    }
  }

  // Now rowData contains data for hours and minutes from 9시 to 19시 30분

  return (
    <div>
      <div className="modal">
        <div className="modal-content p-[32px] h-[680px]">
          <div className="flex flex-row items-center justify-center   ">
            <div className="modal-title flex-1">예약하기</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" onClick={onCancel} />
            </div>
          </div>
          <div className="bg-[#FAFAFA] px-8 w-full">
            <div className="max-w-[836px] w-full mx-auto pt-4 flex flex-col">
              <div className="flex justify-center mb-3">
                <div
                  className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]"
                  style={{ width: "max-content" }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={openCaleneder}
                      className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.333 6L7.33301 10L11.333 14"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                        {`${format(currentDate, "yyyy")}년 ${format(
                          currentDate,
                          "MM"
                        )}월  ${format(currentDate, "dd")}주차`}
                      </span>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.66699 14L12.667 10L8.66699 6"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Add your SVG icon here */}
                  </div>
                  <div className="absolute z-[0] bg-white">
                    {openCal === true && (
                      <Calender
                        selectedDate={currentDate}
                        setCalendarState={setOpenCal}
                        onDateSelect={setCurrentDate}
                        buttonVisible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
                  <div className=" ">
                    <table className="w-full  ">
                      <thead className="mr-1">
                        <tr className="bg-[#F1F8FA] text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#767676] ">
                          <th className="w-[9.4%] text-left pl-3 border-r "></th>

                          <th className="w-[13.2%]  text-left pl-2 py-[9px] border-r">
                            12월 4일(월)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 5일(화)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 6일(수)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 7일(목)
                          </th>

                          <th className="w-[13.2%]  text-left pl-2 border-r">
                            12월 8일(금)
                          </th>
                          <th className="w-[13.1%]  text-left pl-2 border-r">
                            12월 9일(토)
                          </th>
                          <th className="w-[13%]  text-left pl-2 border-r">
                            12월 10일(일)
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    className="rounded-b-lg"
                    style={{ maxHeight: "424px", overflow: "scroll" }}
                  >
                    <table className="w-full">
                      <tbody>
                        <tr className="border-t py-[9px] ">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            9시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            9시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3  hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            10시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r  hover:bg-[#EBF5F2]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            10시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                            {/* 11시 박덴트 */}
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            11시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            11시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            13시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            13시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            14시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                            14시 김미백 <br />
                            14시 이덴투
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            14시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            15시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            15시 30분
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                        <tr className="border-t py-[9px]">
                          <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                            {" "}
                            16시
                          </td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          <td className="w-[13.2%] py-[9px] px-3"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 justify-center pt-[20px] items-end">
            <button
              onClick={onCancel}
              className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              취소
            </button>

            <button
              onClick={onConfirm}
              className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              다음
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function MakeReservationS12({ setModalOpen, onCancel, onConfirm }) {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-10-05"));

  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const openCaleneder = () => {
    setOpenCal(true);
  };

  useEffect(() => {
    if (currentDate.toDateString() === new Date("2023-12-01").toDateString()) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    console.log(currentDate.toDateString());
    console.log(visible);
    console.log(new Date("2023-12-01").toDateString());
  }, [currentDate]);

  useEffect(() => {
    document.querySelectorAll("td").forEach((item) => {
      item.addEventListener("click", (e) => {
        document.querySelectorAll("td").forEach((item1) => {
          if (item1 === e.target) {
            console.log(e.target, item1);
            e.target.classList.add("bg-[#EBF5F2]");
          } else {
            item1.classList.remove("bg-[#EBF5F2]");
          }
        });
      });
    });
  }, [openModal]);
  const rowData = [];

  for (let hour = 9; hour <= 19; hour++) {
    rowData.push({
      col1: `${hour}시`,
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
    });

    if (hour < 19) {
      rowData.push({
        col1: `${hour}시 30분`,
        col2: "",
        col3: "",
        col4: "",
        col5: "",
        col6: "",
      });
    }
  }

  // Now rowData contains data for hours and minutes from 9시 to 19시 30분

  return (
    <div>
      <div className="modal">
        <div className="modal-content p-[32px] h-[680px]">
          <div className="flex flex-row items-center justify-center   ">
            <div className="modal-title flex-1">예약하기</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" onClick={onCancel} />
            </div>
          </div>
          <div className="bg-[#FAFAFA] px-8 w-full">
            <div className="max-w-[836px] w-full mx-auto pt-4 flex flex-col">
              <div className="flex justify-center mb-3">
                <div
                  className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]"
                  style={{ width: "max-content" }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={openCaleneder}
                      className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.333 6L7.33301 10L11.333 14"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                        {`${format(currentDate, "yyyy")}년 ${format(
                          currentDate,
                          "MM"
                        )}월  ${format(currentDate, "dd")}주차`}
                      </span>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.66699 14L12.667 10L8.66699 6"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Add your SVG icon here */}
                  </div>
                  <div className="absolute z-[0] bg-white">
                    {openCal === true && (
                      <Calender
                        selectedDate={currentDate}
                        setCalendarState={setOpenCal}
                        onDateSelect={setCurrentDate}
                        buttonVisible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-scroll">
                  <div className=" ">
                    <table className="w-full  ">
                      <thead className="mr-1">
                        <tr className="bg-[#F1F8FA] text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#767676] ">
                          <th className="min-w-[70px] text-left pl-3 border-r "></th>

                          <th className="min-w-[126px]  text-left pl-2 py-[9px] border-r">
                            10월 30일(월)
                          </th>
                          <th className="min-w-[126px]  text-left pl-2 border-r">
                            10월 31일(화)
                          </th>
                          <th className="min-w-[126px]  text-left pl-2 border-r">
                            11월 1일(수)
                          </th>
                          <th className="min-w-[126px]  text-left pl-2 border-r">
                            11월 2일(목)
                          </th>

                          <th className="min-w-[126px]  text-left pl-2 border-r">
                            11월 3일(금)
                          </th>
                          <th className="min-w-[126px]  text-left pl-2 border-r">
                            11월 4일(토)
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    className="rounded-b-lg"
                    style={{ maxHeight: "424px", width: "100%" }}
                  >
                    {!visible && (
                      <table className="w-full">
                        <tbody className="text-[14px]">
                          <tr className="border-t py-[9px] ">
                            <td className="min-w-[70px] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              10시
                            </td>
                            <td className="w-[15%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-w-[126px] ">
                              심우민(110023)
                            </td>
                            <td className="w-[15%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-w-[126px]"></td>
                            <td className="w-[15%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-w-[126px]"></td>
                            <td className="w-[15%] py-[9px] px-3  border-r hover:bg-[#EBF5F2] min-w-[126px]">
                              박세은(112311)
                            </td>
                            <td className="w-[15%] py-[9px] px-3  border-r hover:bg-[#EBF5F2] min-w-[126px]"></td>

                            <td className="w-[15%] py-[9px] px-3 min-w-[126px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              10시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              한유리(131533)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              한주희(134515)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              11시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r  hover:bg-[#EBF5F2]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>

                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              11시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[17.2%] py-[9px] px-3 border-r text-[13px]">
                              김세희(118249)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>

                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              김연경(118362)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              정호걸(005258)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              홍아름(126622)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>

                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              정현주(110030)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>

                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              김연경(107010)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              김지영(118425)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              김택(112020)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              16시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              최용민(330250)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              {" "}
                              최용민(330250)
                            </td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              16시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              이창선(113010)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              17시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              박서윤(125410)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                              황은서(123323)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              이수림(156632)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              17시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              최우진(121244)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              18시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              김주원(134463)
                            </td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              18시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              신제희(146379)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              19시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {visible && (
                      <table className="w-full">
                        <tbody>
                          <tr className="border-t py-[9px] ">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                            <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                            <td className="w-[13.2%] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3  hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r  hover:bg-[#EBF5F2]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              14시 김미백 <br />
                              14시 이덴투
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 justify-center pt-[20px] items-end">
            <button
              onClick={onCancel}
              className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              취소
            </button>

            <button
              onClick={onConfirm}
              className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              추가
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function MakeReservationS1235({ setModalOpen, onCancel, onConfirm }) {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-12-01"));

  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const openCaleneder = () => {
    setOpenCal(true);
  };

  useEffect(() => {
    if (currentDate.toDateString() === new Date("2023-12-01").toDateString()) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    console.log(currentDate.toDateString());
    console.log(visible);
    console.log(new Date("2023-12-01").toDateString());
  }, [currentDate]);

  useEffect(() => {
    document.querySelectorAll("td").forEach((item) => {
      item.addEventListener("click", (e) => {
        document.querySelectorAll("td").forEach((item1) => {
          if (item1 === e.target) {
            console.log(e.target, item1);
            e.target.classList.add("bg-[#EBF5F2]");
          } else {
            item1.classList.remove("bg-[#EBF5F2]");
          }
        });
      });
    });
  }, [openModal]);
  const rowData = [];

  for (let hour = 9; hour <= 19; hour++) {
    rowData.push({
      col1: `${hour}시`,
      col2: "",
      col3: "",
      col4: "",
      col5: "",
      col6: "",
      col7: "",
    });

    if (hour < 19) {
      rowData.push({
        col1: `${hour}시 30분`,
        col2: "",
        col3: "",
        col4: "",
        col5: "",
        col6: "",
        col7: "",
      });
    }
  }

  // Now rowData contains data for hours and minutes from 9시 to 19시 30분

  return (
    <div>
      <div className="modal">
        <div className="modal-content p-[32px] h-[680px]">
          <div className="flex flex-row items-center justify-center   ">
            <div className="modal-title flex-1">예약하기</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" onClick={onCancel} />
            </div>
          </div>
          <div className="bg-[#FAFAFA] px-8 w-full">
            <div className="max-w-[836px] w-full mx-auto pt-4 flex flex-col">
              <div className="flex justify-center mb-3">
                <div
                  className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]"
                  style={{ width: "max-content" }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={openCaleneder}
                      className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.333 6L7.33301 10L11.333 14"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                        {`${format(currentDate, "yyyy")}년 ${format(
                          currentDate,
                          "MM"
                        )}월  ${format(currentDate, "dd")}주차`}
                      </span>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.66699 14L12.667 10L8.66699 6"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Add your SVG icon here */}
                  </div>
                  <div className="absolute z-[0] bg-white">
                    {openCal === true && (
                      <Calender
                        selectedDate={currentDate}
                        setCalendarState={setOpenCal}
                        onDateSelect={setCurrentDate}
                        buttonVisible={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-scroll">
                  <div className=" ">
                    <table className="w-full  ">
                      <thead className="mr-1">
                        <tr className="bg-[#F1F8FA] text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#767676] ">
                          <th className="min-w-[70px] text-left pl-3 border-r "></th>

                          <th className="min-w-[140px]  text-left pl-2 py-[9px] border-r">
                            12월 4일(월)
                          </th>
                          <th className="min-w-[140px]  text-left pl-2 border-r">
                            12월 5일(화)
                          </th>
                          <th className="min-w-[140px]  text-left pl-2 border-r">
                            12월 6일(화)
                          </th>
                          <th className="min-w-[140px]  text-left pl-2 border-r">
                            12월 7일(화)
                          </th>

                          <th className="min-w-[140px]  text-left pl-2 border-r">
                            12월 8일(화)
                          </th>
                          <th className="min-w-[140px]  text-left pl-2 border-r">
                            12월 9일(화)
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div
                    className="rounded-b-lg"
                    style={{ maxHeight: "424px", width: "100%" }}
                  >
                    {/* {!visible && (
                      <table className="w-full">
                        <tbody>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              13시 30분 심우민
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3  hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r  hover:bg-[#EBF5F2]">
                              14시 최이현
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              14시 김세희
                              <br /> 14시 박은영
                              <br />
                              14시 이수만
                              <br />
                              14시 송은지
                              <br />
                              14시 전상구 <br />
                              14시 이윤혁
                              <br />
                              <br />
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              14시 이혜지 <br />
                              14시 한유리
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                              14시 30분 김연경 <br />
                              14시 30분 김택
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              14시 30분 김은정
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              15시 최용민
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              15시 30분 박세은
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              13시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]">
                              14시 김미백 <br />
                              14시 이덴투
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3"></td>
                          </tr>
                        </tbody>
                      </table>
                    )} */}

                    {visible && (
                      <table className="w-full text-[14px]">
                        <tbody>
                          <tr className="border-t py-[9px] ">
                            <td className="min-w-[70px] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              10시
                            </td>
                            <td className="min-w-[140px] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="min-w-[140px] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="min-w-[140px] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="min-w-[140px] py-[9px] px-3  border-r hover:bg-[#EBF5F2]">
                              박한서(124745)
                            </td>
                            <td className="min-w-[140px] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                            <td className="min-w-[140px] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              10시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px] ">
                            <td className="min-w-[70px] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              11시
                            </td>
                            <td className="min-w-[126px] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="min-w-[126px] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="min-w-[126px] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="min-w-[126px] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                            <td className="min-w-[126px] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                            <td className="min-w-[126px] py-[9px] px-3  border-r hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              11시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              이제희(118249)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r  hover:bg-[#EBF5F2]">
                              김현경(173352)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] text-[14px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 hover:bg-[#EBF5F2]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              14시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              이희주(169437)
                            </td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]">
                              이은영(134485)
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              15시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              16시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              16시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              17시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              17시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              18시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              18시 30분
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                          <tr className="border-t py-[9px]">
                            <td className="w-[9.4%] py-2 px-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] bg-[#F1F8FA]  border-r bg-[#F1F8FA]">
                              {" "}
                              19시
                            </td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r text-[13px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                            <td className="w-[13.2%] py-[9px] px-3 border-r hover:bg-[#EBF5F2] min-h-[36px]"></td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 justify-center pt-[20px] items-end">
            <button
              onClick={onCancel}
              className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              취소
            </button>

            <button
              onClick={onConfirm}
              className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
            >
              추가
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  MakeReservation,
  MakeReservationScene9,
  MakeReservationS12,
  MakeReservationS1235,
};
