import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/DataProvider";

function Storage({ setData }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const { tableData, setTableData } = useContext(DataContext);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  const tableDataS12 = [
    {
      id: 1,
      reservation: "13:30",
      chartNumber: "102010",
      nameAgeGender: "심우민(30/남)",
      dobAgeGender: "111011/남(20)",
      doctor: "홍원장",
      status: "진료완료",
      action: "예약하기",
      nextReservation: "예약완료",
      totalMedicalExpense: "9,900,000원",
      patientContribution: "9,000,000원",
      paymentAmount: "-",
      amountReceivable: "8,000,000원",
    },
  ];

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          수납완료
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약하기
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataS12.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="my-[6px] text-[#23606B] text-[13px]   text-[#23606B] font-normal border-[#23606B] border-b">
                              예약하기
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Storage;
