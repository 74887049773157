import React, { useEffect, useState } from "react";
import PatientInfo from "../../components/PatientInfo";
import {
  StorageInfoScenario6,
  StorageInfo,
  StorageInfoScene6,
  StorageInfoScene9,
  StorageInfoScene8,
  StorageInfoScene7,
} from "../../components/StorageInfo";
import {
  PatientList,
  PatientListScenario5,
  PatientListScenario7,
  PatientListScene6,
  PatientListScene8,
  PatientListScene9,
} from "./components/PatinetList";
import PatientsHeader from "../../components/PatientsHeader";
import {
  TreatmentPlan,
  TreatmentPlanScenario5,
  TreatmentPlanScene6,
  TreatmentPlanScene7,
  TreatmentPlanScene8,
  TreatmentPlanScene9,
} from "../../components/TreatmentPlan";
import {
  OnDayTreatment,
  OnDayTreatmentScenario5,
  OnDayTreatmentScene6,
  OnDayTreatmentScene7,
  OnDayTreatmentScene8,
  OnDayTreatmentScene9,
} from "../../components/OnDayTreatment";
import {
  PreparingListScenario5,
  PreparingList,
  PreparingListScenario7,
  PreparingListScene6,
  PreparingListScene9,
  PreparingListScene8,
} from "./components/PreparingList";
import {
  DuringTreatment,
  DuringTreatmentScenario7,
  DuringTreatmentScene6,
} from "./components/DuringTreatment";
import CompletionTreatment from "./components/CompletionTreatment";
import StorageComplete from "./components/StorageComplete";

import AddoldMoney from "./Popups/AddOldMoney";
import { DataProvider } from "../../context/DataProvider";
import {
  EntirePatientListScenario6,
  EntirePatientList,
  EntirePatientListScenario7,
  EntirePatientListScene6,
  EntirePatientListScene9,
  EntirePatientListScene8,
} from "./components/EntirePatientList";
import { useLocation } from "react-router-dom";

function ReceptionWaiting({ scenario }) {
  const [activeButton, setActiveButton] = useState("접수대기");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // if(scenario === 10){
    //   localStorage.setItem("scenario",10)
    // }
  }, [scenario]);
  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientList setData={setData} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientList
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingList
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <DataProvider>
      <div className=" w-full">
        <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
          <div className="2xl:w-[34%] w-full overflow-x-auto ">
            <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
              <div className="flex-shrink-0 flex-[1]  ">
                <PatientInfo data={data} />
              </div>
              <div className="flex-shrink-0 flex-[1] ">
                <StorageInfo data={data} />
              </div>
              <div className="flex-shrink-0 flex-[1] ">
                <TreatmentPlan scenario={scenario} />
              </div>
              <div className="flex-shrink-0 flex-[1] ">
                <OnDayTreatment />
              </div>
            </div>
          </div>

          <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
            <PatientsHeader
              activeButton={activeButton}
              onButtonClick={handleButtonClick}
              setModalOpen={openModal}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
            />
            {activeButton === "전체" && <Component1 />}
            {activeButton === "접수대기" && <Component2 />}
            {activeButton === "진료준비" && <Component3 />}
            {activeButton === "진료중" && <Component4 />}
            {activeButton === "진료완료" && <Component5 />}
            {activeButton === "수납완료" && <Component6 />}

            {addmoneyModal === true && (
              <AddoldMoney
                setAddmoneyModal={closeModal}
                setActiveButton={setActiveButton}
                scenario={scenario}
              />
            )}
          </div>
        </div>
      </div>
    </DataProvider>
  );
}

function ReceptionWaitingScenario6({ scenario }) {
  const state = useLocation().state;
  const reload = useLocation().reload;
  const [activeButton, setActiveButton] = useState("전체");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (state) {
      setActiveButton("접수대기");
      // window.location.reload();
    }
  }, [state]);

  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientListScenario6 setData={setData} data={data} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientListScenario5
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingListScenario5
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <div className=" w-full">
      <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
        <div className="2xl:w-[34%] w-full overflow-x-auto ">
          <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
            <div className="flex-shrink-0 flex-[1]  ">
              <PatientInfo data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <StorageInfoScenario6 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <TreatmentPlanScenario5 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <OnDayTreatmentScenario5 data={data} />
            </div>
          </div>
        </div>

        <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
          <PatientsHeader
            activeButton={activeButton}
            onButtonClick={handleButtonClick}
            setModalOpen={openModal}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          {activeButton === "전체" && <Component1 />}
          {activeButton === "접수대기" && <Component2 />}
          {activeButton === "진료준비" && <Component3 />}
          {activeButton === "진료중" && <Component4 />}
          {activeButton === "진료완료" && <Component5 />}
          {activeButton === "수납완료" && <Component6 />}

          {addmoneyModal === true && (
            <AddoldMoney
              setAddmoneyModal={closeModal}
              setActiveButton={setActiveButton}
              scenario={scenario}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ReceptionWaitingScene6({ scenario }) {
  const state = useLocation().state;
  const [activeButton, setActiveButton] = useState("전체");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (state) {
      setActiveButton("접수대기");
    }
  }, [state]);
  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientListScene6 setData={setData} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientListScene6
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingListScene6
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatmentScene6 searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <div className=" w-full">
      <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
        <div className="2xl:w-[34%] w-full overflow-x-auto ">
          <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
            <div className="flex-shrink-0 flex-[1]  ">
              <PatientInfo data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <StorageInfoScene6 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <TreatmentPlanScene6 />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <OnDayTreatmentScene6 data={data} />
            </div>
          </div>
        </div>

        <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
          <PatientsHeader
            activeButton={activeButton}
            onButtonClick={handleButtonClick}
            setModalOpen={openModal}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          {activeButton === "전체" && <Component1 />}
          {activeButton === "접수대기" && <Component2 />}
          {activeButton === "진료준비" && <Component3 />}
          {activeButton === "진료중" && <Component4 />}
          {activeButton === "진료완료" && <Component5 />}
          {activeButton === "수납완료" && <Component6 />}

          {addmoneyModal === true && (
            <AddoldMoney
              setAddmoneyModal={closeModal}
              setActiveButton={setActiveButton}
              scenario={scenario}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ReceptionWaitingScenario7({ scenario }) {
  const state = useLocation().state;
  const [activeButton, setActiveButton] = useState("전체");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (state) {
      setActiveButton("접수대기");
    }
  }, [state]);
  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientListScenario7 setData={setData} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientListScenario7
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingListScenario7
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatmentScenario7 searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <div className=" w-full">
      <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
        <div className="2xl:w-[34%] w-full overflow-x-auto ">
          <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
            <div className="flex-shrink-0 flex-[1]  ">
              <PatientInfo data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <StorageInfoScene7 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <TreatmentPlanScene7 />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <OnDayTreatmentScene7 data={data} />
            </div>
          </div>
        </div>

        <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
          <PatientsHeader
            activeButton={activeButton}
            onButtonClick={handleButtonClick}
            setModalOpen={openModal}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          {activeButton === "전체" && <Component1 />}
          {activeButton === "접수대기" && <Component2 />}
          {activeButton === "진료준비" && <Component3 />}
          {activeButton === "진료중" && <Component4 />}
          {activeButton === "진료완료" && <Component5 />}
          {activeButton === "수납완료" && <Component6 />}

          {addmoneyModal === true && (
            <AddoldMoney
              setAddmoneyModal={closeModal}
              setActiveButton={setActiveButton}
              scenario={scenario}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ReceptionWaitingScenario9({ scenario }) {
  const state = useLocation().state;
  const [activeButton, setActiveButton] = useState("전체");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (state) {
      setActiveButton("접수대기");
    }
  }, [state]);
  useEffect(() => {
    localStorage.setItem("scenario", 9);
  }, []);
  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientListScene9 setData={setData} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientListScene9
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingListScene9
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatmentScenario7 searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <div className=" w-full">
      <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
        <div className="2xl:w-[34%] w-full overflow-x-auto ">
          <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
            <div className="flex-shrink-0 flex-[1]  ">
              <PatientInfo data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <StorageInfoScene9 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <TreatmentPlanScene9 />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <OnDayTreatmentScene9 data={data} />
            </div>
          </div>
        </div>

        <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
          <PatientsHeader
            activeButton={activeButton}
            onButtonClick={handleButtonClick}
            setModalOpen={openModal}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          {activeButton === "전체" && <Component1 />}
          {activeButton === "접수대기" && <Component2 />}
          {activeButton === "진료준비" && <Component3 />}
          {activeButton === "진료중" && <Component4 />}
          {activeButton === "진료완료" && <Component5 />}
          {activeButton === "수납완료" && <Component6 />}

          {addmoneyModal === true && (
            <AddoldMoney
              setAddmoneyModal={closeModal}
              setActiveButton={setActiveButton}
              scenario={scenario}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ReceptionWaitingScenario8({ scenario }) {
  const state = useLocation().state;
  const [activeButton, setActiveButton] = useState("전체");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (state) {
      setActiveButton("접수대기");
    }
  }, [state]);
  useEffect(() => {
    localStorage.setItem("scenario", 8);
  }, []);
  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientListScene8 setData={setData} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientListScene8
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingListScene8
        searchTerm={searchTerm}
        setData={setData}
        scenario={scenario}
      />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatmentScenario7 searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <div className=" w-full">
      <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
        <div className="2xl:w-[34%] w-full overflow-x-auto ">
          <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
            <div className="flex-shrink-0 flex-[1]  ">
              <PatientInfo data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <StorageInfoScene8 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <TreatmentPlanScene8 data={data} />
            </div>
            <div className="flex-shrink-0 flex-[1] ">
              <OnDayTreatmentScene8 data={data} />
            </div>
          </div>
        </div>

        <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
          <PatientsHeader
            activeButton={activeButton}
            onButtonClick={handleButtonClick}
            setModalOpen={openModal}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          {activeButton === "전체" && <Component1 />}
          {activeButton === "접수대기" && <Component2 />}
          {activeButton === "진료준비" && <Component3 />}
          {activeButton === "진료중" && <Component4 />}
          {activeButton === "진료완료" && <Component5 />}
          {activeButton === "수납완료" && <Component6 />}

          {addmoneyModal === true && (
            <AddoldMoney
              setAddmoneyModal={closeModal}
              setActiveButton={setActiveButton}
              scenario={scenario}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export {
  ReceptionWaiting,
  ReceptionWaitingScenario6,
  ReceptionWaitingScenario7,
  ReceptionWaitingScene6,
  ReceptionWaitingScenario9,
  ReceptionWaitingScenario8,
};
