import React, { useState } from "react";

function RadioQuestion({ question, answers, name, id, handleChange }) {
  const [selectedOption, setSelectedOption] = useState(""); // Initialize selectedOption as an empty string

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    handleChange(e); // Call the parent component's handleChange function
  };

  return (
    <div className="max-w-[640px] w-full">
      <div className="flex flex-col gap-[16px] items-start">
        <div className="flex ">{question}</div>
        <div className="flex flex-col gap-[12px]">
          {answers.map((answer, index) => (
            <div className="" key={answer + index}>
              <input
                id={id + index}
                type="radio"
                value={answer}
                name={name}
                checked={selectedOption === answer}
                onChange={(e) => handleOptionChange(e)}
                className="hidden"
              />
              <label
                htmlFor={id + index}
                className="flex radio-label text-sm md:text-lg gap-[6px]"
              >
                {selectedOption === answer ? (
                  // Checked SVG for the selected option
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle cx="9" cy="9" r="8.5" stroke="#317580" />
                    <circle cx="9" cy="9" r="4" fill="#317580" />
                  </svg>
                ) : (
                  // Non-checked SVG for other options
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle cx="9" cy="9" r="8.5" stroke="#D4D4D8" />
                  </svg>
                )}
                {answer}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RadioQuestion;
