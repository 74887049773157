import React, { useState } from "react";
import SuccessAlert from "../../components/SuccessAlert";
import { Link } from "react-router-dom";
import StoreAlertBoxSignUp from "../../components/SuccessAlertSignup";

function ChangePassword() {
  // Initialize state for username and password
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Function to check if the password is valid
  function isPasswordValid() {
    // Check if the password has a length between 8 and 16 characters
    if (password.length < 8 || password.length > 16) {
      return false;
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }

    // Check if the password contains at least one digit (number)
    if (!/\d/.test(password)) {
      return false;
    }

    // Check if the password contains at least one special character (e.g., !@#$%^&*)
    if (!/[!@#\$%^&*]/.test(password)) {
      return false;
    }

    // If all checks pass, the password is valid
    return true;
  }

  // Function to handle form submission
  function handleSubmit(e) {
    e.preventDefault();

    // Check if the password is valid
    if (!isPasswordValid()) {
      setErrorMessage(
        "8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요."
      );
      return;
    }

    // Check if password and password confirmation match
    if (password !== passwordConfirm) {
      setErrorMessage(true);

      return;
    }

    setPasswordSuccess(true);
    setErrorMessage("");
    // Password is valid, perform further actions (e.g., send data to the server)
    // You can display a success message or navigate to another page here.
  }

  return (
    <div className="">
      <h1 className="flex justify-center text-[36px] leading-[48px] tracking-[-0.9px] text-[36px] font-semibold text-[#154D57] pb-[32px] pt-[120px]">
        비밀번호 변경
      </h1>
      <div className="max-w-[556px] w-full mx-auto bg-[#FFFFFF] rounded-[8px] p-[48px]">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="text-[18px] font-normal leading-[26px] tracking-[-0.45px] text-center pb-[32px]">
              변경할 비밀번호를 입력해주세요.
            </div>
            <div className="flex justify-center flex-col ">
              <input
                placeholder="새 비밀번호"
                className="max-w-[460px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]  focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4 mb-4"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                placeholder="새 비밀번호 확인"
                className="max-w-[460px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676] focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4"
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
              {errorMessage && (
                <div className="text-red-500 text-left pt-3">
                  {errorMessage}
                </div>
              )}
            </div>{" "}
            <div className="flex   flex-col">
              <div className="flex justify-center pt-[40px] ">
                <button className="bg-[#317580] rounded-[8px] max-w-[460px] w-full py-4 flex justify-center text-[#FFFFFF]">
                  비밀번호 변경
                </button>
              </div>
              {passwordSuccess === true && (
                <StoreAlertBoxSignUp
                  title="비밀번호 변경"
                  subtitle="비밀번호가 변경되었습니다."
                />
              )}

              {errorMessage === true && (
                <StoreAlertBoxSignUp
                  title="비밀번호 변경"
                  subtitle="새 비밀번호와 비밀번호 확인이 일치하지 않습니다.."
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
