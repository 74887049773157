import React from "react";
import { Link, useNavigate } from "react-router-dom";

function StorageInfo({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/1");
  };

  return (
    <div className="flex justify-center h-full">
      <div className="bg-white max-w-[462px] w-full  rounded-lg shadow-xl  p-[20px] ">
        <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
          당일 수납 정보
        </h2>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg">
          <table className="w-full">
            <tbody>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                  급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {data.totalMedicalExpense}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  비급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {data.PatientContribution}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  총진료비
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {/* {data.PaymentAmount} */}
                </td>
              </tr>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                  <td className="">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td>미수금</td>
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {data.AmountReceivable}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  환자부담금
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {data.AmountReceivable}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  할인금액
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {data.AmountReceivable}
                </td>
              </tr>
              <tr>
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                  수납할 금액
                </td>
                <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                  {data.AmountReceivable}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-[13px] gap-2 radius-[8px]">
          <button className="bg-[#DBEEF9] py-[9px] px-[10px] text-[#1778B0] flex-[1] rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            카드수납
          </button>
          <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            현금수납
          </button>
          <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            미수처리
          </button>
          <button className="bg-[#317580] text-[#FFFFFF]  py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            예약하기
          </button>
        </div>
      </div>
    </div>
  );
}

function StorageInfoScenario6({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/1");
  };

  return (
    <div className="flex justify-center h-full">
      <div className="bg-white max-w-[462px] w-full  rounded-lg shadow-xl  p-[20px] ">
        <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
          당일 수납 정보
        </h2>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg">
          <table className="w-full">
            <tbody>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                  급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  비급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  400,000
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  총진료비
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  400,000
                </td>
              </tr>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                  <td className="">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td>미수금</td>
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  환자부담금
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  400000
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  할인금액
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  80,000
                </td>
              </tr>
              <tr>
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                  수납할 금액
                </td>
                <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                  320,000
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-[13px] gap-2 radius-[8px]">
          <button className="bg-[#DBEEF9] py-[9px] px-[10px] text-[#1778B0] flex-[1] rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            카드수납
          </button>
          <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            현금수납
          </button>
          <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            미수처리
          </button>
          <button className="bg-[#317580] text-[#FFFFFF]  py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            예약하기
          </button>
        </div>
      </div>
    </div>
  );
}

function StorageInfoScene6({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/1");
  };

  function show() {
    if (data.chartNumber === "103000") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="flex justify-center h-full">
      <div className="bg-white max-w-[462px] w-full  rounded-lg shadow-xl  p-[20px] ">
        <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
          당일 수납 정보
        </h2>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg">
          <table className="w-full">
            <tbody>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                  급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() && "19,340원"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  비급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  총진료비
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() && "19,340원"}
                </td>
              </tr>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                  <td className="">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td>미수금</td>
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  환자부담금
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() && "1,930"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  할인금액
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr>
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                  수납할 금액
                </td>
                <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                  {show() && "1,930"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-[13px] gap-2 radius-[8px]">
          <button className="bg-[#DBEEF9] py-[9px] px-[10px] text-[#1778B0] flex-[1] rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            카드수납
          </button>
          <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            현금수납
          </button>
          <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            미수처리
          </button>
          <button className="bg-[#317580] text-[#FFFFFF]  py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            예약하기
          </button>
        </div>
      </div>
    </div>
  );
}

function StorageInfoScene7({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/1");
  };

  function show() {
    if (data.chartNumber === "320010") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="flex justify-center h-full">
      <div className="bg-white max-w-[462px] w-full  rounded-lg shadow-xl  p-[20px] ">
        <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
          당일 수납 정보
        </h2>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg">
          <table className="w-full">
            <tbody>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                  급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {/* {show() && "19,340원"} */}0
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  비급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() && "500,000"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  총진료비
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() && "500,000"}
                </td>
              </tr>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                  <td className="">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td>미수금</td>
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  환자부담금
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() && "500,000"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  할인금액
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  0
                </td>
              </tr>
              <tr>
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                  수납할 금액
                </td>
                <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                  {show() && "500,000"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-[13px] gap-2 radius-[8px]">
          <button className="bg-[#DBEEF9] py-[9px] px-[10px] text-[#1778B0] flex-[1] rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            카드수납
          </button>
          <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            현금수납
          </button>
          <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            미수처리
          </button>
          <button className="bg-[#317580] text-[#FFFFFF]  py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            예약하기
          </button>
        </div>
      </div>
    </div>
  );
}
function StorageInfoScene9({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/1");
  };

  function show() {
    if (data.chartNumber === "113010") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="flex justify-center h-full">
      <div className="bg-white max-w-[462px] w-full  rounded-lg shadow-xl  p-[20px] ">
        <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
          당일 수납 정보
        </h2>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg">
          <table className="w-full">
            <tbody>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                  급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "29,370" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  비급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "0" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  총진료비
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "29,370" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                  <td className="">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td>미수금</td>
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "0" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  환자부담금
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "8,000" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  할인금액
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "0" : "-"}
                </td>
              </tr>
              <tr>
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                  수납할 금액
                </td>
                <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                  {show() ? "8,000" : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-[13px] gap-2 radius-[8px]">
          <button className="bg-[#DBEEF9] py-[9px] px-[10px] text-[#1778B0] flex-[1] rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            카드수납
          </button>
          <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            현금수납
          </button>
          <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            미수처리
          </button>
          <button className="bg-[#317580] text-[#FFFFFF]  py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            예약하기
          </button>
        </div>
      </div>
    </div>
  );
}

function StorageInfoScene8({ data }) {
  const navigate = useNavigate();

  const handleClick = () => {
    // navigate("/doctor/receptionwaiting/1");
  };

  function show() {
    if (data.chartNumber === "110030") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="flex justify-center h-full">
      <div className="bg-white max-w-[462px] w-full  rounded-lg shadow-xl  p-[20px] ">
        <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
          당일 수납 정보
        </h2>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg">
          <table className="w-full">
            <tbody>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                  급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "0" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  비급여
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "300,000" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  총진료비
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "300,000" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300 ">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                  <td className="">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td>미수금</td>
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "15,600" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  환자부담금
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "300,000" : "-"}
                </td>
              </tr>
              <tr className="border-b border-gray-300">
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                  할인금액
                </td>
                <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                  {show() ? "0" : "-"}
                </td>
              </tr>
              <tr>
                <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                  수납할 금액
                </td>
                <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                  {show() ? "315,600" : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex mt-[13px] gap-2 radius-[8px]">
          <button className="bg-[#DBEEF9] py-[9px] px-[10px] text-[#1778B0] flex-[1] rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            카드수납
          </button>
          <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            현금수납
          </button>
          <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            미수처리
          </button>
          <button className="bg-[#317580] text-[#FFFFFF]  py-[9px] px-[10px] flex-[1] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
            예약하기
          </button>
        </div>
      </div>
    </div>
  );
}
export {
  StorageInfo,
  StorageInfoScenario6,
  StorageInfoScene6,
  StorageInfoScene9,
  StorageInfoScene7,
  StorageInfoScene8,
};
