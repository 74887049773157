import React, { useState } from "react";
import "./index.scss";

import { format, startOfToday } from "date-fns";
import Questionnaire from "./components/Questionnaire";
import QuestionnairePerson2 from "./components/QuestionnairePerson2";
import Calender from "../../../components/Calender";
import { Link } from "react-router-dom";

const PatientInformationS12 = () => {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-10-31"));
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [isQuestionaireOpen, setIsQuestionaireOpen] = useState(0);
  const [tableData, setTableData] = useState({
    tableDataS12: [
      {
        id: 1,
        reservation: "14:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(52/남)",
        dobAgeGender: "005258/남(52)",
        number: "010-5351-0064",
        doctor: "심원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "14:00",
        chartNumber: "111011",
        nameAgeGender: "박세로이(25/남)",
        dobAgeGender: "111011/남(25)",
        doctor: "최원장",
        number: "010-8888-0000",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 3,
        reservation: "14:00",
        chartNumber: "120020",
        nameAgeGender: "이혜빈(40/여)",
        dobAgeGender: "120020/남(40)",
        doctor: "홍원장",
        status: "접수대기",
        action: "접수하기",
        number: "010-3232-2222",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "14:30",
        chartNumber: "207010",
        nameAgeGender: "오찬욱(61/남)",
        dobAgeGender: "207010/남(61)",
        doctor: "김원장",
        status: "접수대기",
        number: "010-8888-0000",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "14:30",
        chartNumber: "103000",
        nameAgeGender: "김선희(62/여)",
        dobAgeGender: "103000/남(62)",
        number: "010-3232-2222",
        doctor: "최원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "14:30",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "320010/남(48)",
        number: "010-3232-2222",
        doctor: "심원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "15:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "314010/남(19)",
        doctor: "김원장",
        number: "010-8888-0000",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "15:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "110030/남(42)",
        number: "010-3232-2222",
        doctor: "심원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 9,
        reservation: "15:30",
        chartNumber: "107010",
        nameAgeGender: " 김연경(50/여)",
        dobAgeGender: "107010/남(50)",
        number: "010-8888-0000",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "-",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 10,
        reservation: "16:00",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "112020/남(44)",
        number: "010-8888-0000",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "-",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "330250/남(37)",
        number: "010-8888-0000",
        doctor: "심원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "-",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 12,
        reservation: "16:00",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "113010/남(30)",
        doctor: "최원장",
        number: "010-3232-2222",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "다음예약",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 13,
        reservation: "16:30",
        chartNumber: "111021",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "111021/남(13)",
        doctor: "심원장",
        number: "010-8888-0000",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "-",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 14,
        reservation: "16:30",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "김서준(7/남)/남(7)",
        number: "010-8888-0000",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "-",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 15,
        reservation: "16:30",
        chartNumber: "111023",
        nameAgeGender: "김민서(13/여)",
        dobAgeGender: "111028/남(13)",
        doctor: "최원장",
        status: "접수대기",
        action: "접수하기",
        number: "010-8888-0000",
        nextReservation: "-",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
    ],
  });

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    // setData(rowData);
  };
  const openCaleneder = () => {
    setOpenCal(true);
  };

  return (
    <div className="patient-information">
      {isQuestionaireOpen === 1 ? (
        <Questionnaire />
      ) : isQuestionaireOpen === 2 ? (
        <QuestionnairePerson2 />
      ) : (
        <div className="patient-container">
          <div className="header">
            <div className="left">
              <h1>환자정보 리스트</h1>

              <div className="misc">
                {/* calendar */}
                <div className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={openCaleneder}
                      className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                    >
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.333 6L7.33301 10L11.333 14"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                        {`${format(currentDate, "yyyy")}년 ${format(
                          currentDate,
                          "MM"
                        )}월  ${format(currentDate, "dd")}일(월)`}
                      </span>
                      <div>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.66699 14L12.667 10L8.66699 6"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Add your SVG icon here */}
                  </div>
                  <div className="absolute z-[9999] bg-white">
                    {openCal === true && (
                      <Calender
                        selectedDate={currentDate}
                        setCalendarState={setOpenCal}
                        onDateSelect={setCurrentDate}
                      />
                    )}
                  </div>
                </div>
                {/* search */}
                <div class="flex justify-center items-center px-2">
                  <form action="/search" class="max-w-[200px] w-full ">
                    <div class="relative flex  border rounded-full">
                      <input
                        type=""
                        name=""
                        value={searchTerm}
                        class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                        placeholder="이름을 입력해주세요."
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <button type="submit" class="ml-2 px-3">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M13.2148 13.2148L17.5004 17.5004"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="right">
              <button className="blue">문진표작성</button>
              <Link to="/scenario122">
                <button
                  className="green"
                  onClick={() => {
                    localStorage.setItem("completed", "true");
                    sessionStorage.clear();
                    localStorage.clear();
                  }}
                >
                  데스크로 가기
                </button>
              </Link>
            </div>
          </div>
          <div className="content">
            <div className="table w-full text-[13px]">
              <div className="border-[1px] rounded-lg w-full">
                <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
                  <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
                    <tr>
                      <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </th>
                      <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left pl-4">
                        예약시간
                      </th>
                      <th className="py-[14px] border-b border-gray-300 w-[9.09%] text-left">
                        차트번호
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        이름(나이/성별)
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        담당의사
                      </th>

                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        전화번호
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        부작용
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        다음예약
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        문진표보기
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        차팅이력보기
                      </th>
                      <th className="py-[14px] border-b border-gray-300  w-[9.09%] text-left">
                        수정
                      </th>
                    </tr>
                  </thead>
                </table>

                <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
                  <table className="w-full">
                    <tbody>
                      {tableData.tableDataS12
                        .filter((item) => item.status === "접수대기")
                        .map((data, index) => (
                          <React.Fragment key={index}>
                            <tr
                              key={index}
                              className={`text-left cursor-pointer ${
                                selectedRow === data
                                  ? "bg-[#EBF5F2]"
                                  : "hover:bg-[#EBF5F2]"
                              }`}
                              onClick={() => handleRowClick(data)}
                            >
                              <td className="  w-[9.09%] text-center">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  value=""
                                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                              </td>
                              <td className="w-[9.09%]">{data.reservation}</td>
                              <td className="w-[9.09%]">{data.chartNumber}</td>
                              <td className="w-[9.09%]">
                                {data.nameAgeGender}
                              </td>
                              <td className="w-[9.09%]">{data.doctor}</td>
                              <td className="w-[9.09%]">{data.number}</td>
                              <td className="w-[9.09%]">-</td>
                              <td className="w-[9.09%] text-[#767676] font-semibold">
                                {data.nextReservation}
                              </td>
                              <td className="w-[9.09%]">
                                <div className="items-center py-[6px] flex justify-접수하기 ">
                                  <button
                                    onClick={() => {
                                      // setPatientIsProcessing(data.id);
                                      console.log("clicked");
                                      if (data.chartNumber === "005258") {
                                        setIsQuestionaireOpen(1);
                                        console.log("clicked");
                                      } else if (
                                        data.chartNumber === "005258"
                                      ) {
                                        setIsQuestionaireOpen(2);
                                      }
                                    }}
                                    className="px-[12px] py-[6px] border bg-[#DBEEF9] text-[#1778B0] font-semibold rounded-[32px] border-none"
                                  >
                                    문진표보기
                                  </button>
                                </div>
                              </td>
                              <td className="w-[9.09%]">
                                <div className="items-center py-[6px] flex justify-접수하기 ">
                                  <button
                                    onClick={() => {
                                      // setPatientIsProcessing(data.id);
                                    }}
                                    className="px-[12px] py-[6px] border bg-[#D0E7DC] text-[#154D57] font-semibold rounded-[32px] border-none"
                                  >
                                    차팅이력보기
                                  </button>
                                </div>
                              </td>

                              <td className="w-[9.09%]">수정</td>
                            </tr>
                            {index !== tableData.length - 1 && (
                              <tr className="border-t "></tr>
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientInformationS12;
