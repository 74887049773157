import { createContext, useState } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [tableData, setTableData] = useState({
    patientList: [
      {
        id: 1,
        reservation: "9:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(51/남)",
        dobAgeGender: "005258/남(51)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "9:30",
        chartNumber: "111011",
        nameAgeGender: "박세로이(25/남)",
        dobAgeGender: "111011/남(25)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 3,
        reservation: "10:00",
        chartNumber: "120020",
        nameAgeGender: "이혜빈(40/여)",
        dobAgeGender: "120020/여(40)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "10:30",
        chartNumber: "207010",
        nameAgeGender: "오찬욱(61/남)",
        dobAgeGender: "207010/남(61)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "11:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(51/남)",
        dobAgeGender: "005258/남(51)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "12:00",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "005258/남(48)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "14:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "314010/남(19)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "110030/여(42)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 9,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "107010/여(50)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 10,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "112020/남(44)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "330250/남(37)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 12,
        reservation: "17:00",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "113010/남(30)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 13,
        reservation: "17:00",
        chartNumber: "111021",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "111021/여(13)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "100000",
        patientContribution: "100000",
        paymentAmount: "100000",
        amountReceivable: "100000",
      },
      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "111022/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },

      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "111022/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 15,
        reservation: "17:15",
        chartNumber: "111023",
        nameAgeGender: "이지영(32/여)",
        dobAgeGender: "111023/여(32)",
        doctor: "박승우",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 16,
        reservation: "17:30",
        chartNumber: "111024",
        nameAgeGender: "손민수(45/남)",
        dobAgeGender: "111024/남(45)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 17,
        reservation: "17:45",
        chartNumber: "111025",
        nameAgeGender: "박미라(27/여)",
        dobAgeGender: "111025/여(27)",
        doctor: "김태호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 18,
        reservation: "18:00",
        chartNumber: "111026",
        nameAgeGender: "이동욱(38/남)",
        dobAgeGender: "111026/남(38)",
        doctor: "유진영",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 19,
        reservation: "18:15",
        chartNumber: "111027",
        nameAgeGender: "김수진(29/여)",
        dobAgeGender: "111027/여(29)",
        doctor: "백승민",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 20,
        reservation: "18:30",
        chartNumber: "111028",
        nameAgeGender: "오재원(55/남)",
        dobAgeGender: "111028/남(55)",
        doctor: "이지영",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 21,
        reservation: "18:45",
        chartNumber: "111029",
        nameAgeGender: "이수현(22/여)",
        dobAgeGender: "111029/여(22)",
        doctor: "김용호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 22,
        reservation: "19:00",
        chartNumber: "111030",
        nameAgeGender: "박영진(42/남)",
        dobAgeGender: "111030/남(42)",
        doctor: "송승민",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 23,
        reservation: "19:15",
        chartNumber: "111031",
        nameAgeGender: "김지영(34/여)",
        dobAgeGender: "111031/여(34)",
        doctor: "전지원",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 24,
        reservation: "19:30",
        chartNumber: "111032",
        nameAgeGender: "정현우(50/남)",
        dobAgeGender: "111032/남(50)",
        doctor: "김현아",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 25,
        reservation: "19:45",
        chartNumber: "111033",
        nameAgeGender: "이영미(60/여)",
        dobAgeGender: "111033/여(60s)",
        doctor: "최성준",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
    ],
  });
  const [tableDataScenario10, setTableDataScenario10] = useState({
    patientList: [
      {
        id: 1,
        reservation: "14:00",
        chartNumber: "200336",
        nameAgeGender: "송은지(30/여)",
        dobAgeGender: "200336/남(30)",
        doctor: "홍원장",
        status: "진료준비",
        insurance: "건강보험",
        action: "진료준비",
        nextReservation: "",
        totalMedicalExpense: "",

        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "14:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(52/남)",
        dobAgeGender: "720408/남(52)",
        doctor: "심우현",
        status: "접수대기",
        insurance: "건강보험",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 3,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "110030/남(42)",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "107010/남(50)",
        doctor: "심원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "112020/남(44)",
        doctor: "최원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "330250/남(37)",
        doctor: "홍원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "16:30",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "113010/남(30)",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "17:30",
        chartNumber: "125410",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "113010/남(30)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
    ],
  });
  const [tableDataScenario5, setTableDataScenario5] = useState({
    patientList: [
      {
        id: 1,
        reservation: "9:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(51/남)",
        dobAgeGender: "830820/남(51)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "9:30",
        chartNumber: "111011",
        nameAgeGender: "박세로이(25/남)",
        dobAgeGender: "830820/남(25)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 20,
        reservation: "10:00",
        chartNumber: "120020",
        nameAgeGender: "이혜빈(40/여)",
        dobAgeGender: "830820/여(40)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "10:30",
        chartNumber: "207010",
        nameAgeGender: "오찬욱(61/남)",
        dobAgeGender: "830820/남(61)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "11:00",
        chartNumber: "103000",
        nameAgeGender: "김선희(62/여)",
        dobAgeGender: "830820/여(62)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "12:00",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "830820/남(48)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "14:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "830820/남(19)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "830820/여(42)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 9,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "830820/여(50)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 10,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "830820/남(44)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "830820/남(37)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 12,
        reservation: "17:00",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "830820/남(30)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 13,
        reservation: "17:00",
        chartNumber: "111021",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "100000",
        patientContribution: "100000",
        paymentAmount: "100000",
        amountReceivable: "100000",
      },
      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "830820/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },

      {
        id: 15,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "830820/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 16,
        reservation: "17:00",
        chartNumber: "111023",
        nameAgeGender: "김민서(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "박승우",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 17,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 18,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 19,
        reservation: "17:00",
        chartNumber: "111025",
        nameAgeGender: "이유나(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 209,
        reservation: "17:00",
        chartNumber: "111026",
        nameAgeGender: "박지원(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 200,
        reservation: "17:30",
        chartNumber: "111027",
        nameAgeGender: "김지후(7/남)",
        dobAgeGender: "830820/남(7)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 21,
        reservation: "17:30",
        chartNumber: "111028",
        nameAgeGender: "김윤아(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 22,
        reservation: "17:30",
        chartNumber: "111029",
        nameAgeGender: "이민지(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 23,
        reservation: "17:30",
        chartNumber: "111030",
        nameAgeGender: "박나은(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 24,
        reservation: "17:30",
        chartNumber: "111031",
        nameAgeGender: "이채은(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 25,
        reservation: "17:30",
        chartNumber: "111032",
        nameAgeGender: "김덴투(13/여)",
        dobAgeGender: "830820/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 26,
        reservation: "17:30",
        chartNumber: "111033",
        nameAgeGender: "박우진(7/남)",
        dobAgeGender: "830820/남(7)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
    ],
  });
  const [tableDataScenario7, setTableDataScenario7] = useState({
    patientList: [
      {
        id: 1,
        reservation: "9:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(51/남)",
        dobAgeGender: "750408/남(51)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "9:30",
        chartNumber: "111011",
        nameAgeGender: "박세로이(25/남)",
        dobAgeGender: "750408/남(25)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 20,
        reservation: "10:00",
        chartNumber: "120020",
        nameAgeGender: "이혜빈(40/여)",
        dobAgeGender: "750408/여(40)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "11:00",
        chartNumber: "103000",
        nameAgeGender: "김선희(62/여)",
        dobAgeGender: "750408/여(62)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "12:00",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "750408/남(48)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "14:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "750408/남(19)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "750408/여(42)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "750408/여(42)",
        doctor: "최윤호",
        status: "진료중",
        action: "진료완료",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 9,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "750408/여(50)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 10,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "750408/남(44)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "750408/남(37)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 12,
        reservation: "17:00",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "750408/여(30)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 13,
        reservation: "17:00",
        chartNumber: "111021",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "100000",
        patientContribution: "100000",
        paymentAmount: "100000",
        amountReceivable: "100000",
      },
      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "750408/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },

      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "750408/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 15,
        reservation: "17:00",
        chartNumber: "111023",
        nameAgeGender: "김민서(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "박승우",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 16,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 17,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 18,
        reservation: "17:00",
        chartNumber: "111025",
        nameAgeGender: "이유나(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 19,
        reservation: "17:00",
        chartNumber: "111026",
        nameAgeGender: "박지원(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 200,
        reservation: "17:30",
        chartNumber: "111027",
        nameAgeGender: "김지후(7/남)",
        dobAgeGender: "750408/남(7)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 21,
        reservation: "17:30",
        chartNumber: "111028",
        nameAgeGender: "김윤아(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 22,
        reservation: "17:30",
        chartNumber: "111029",
        nameAgeGender: "이민지(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 23,
        reservation: "17:30",
        chartNumber: "111030",
        nameAgeGender: "박나은(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 24,
        reservation: "17:30",
        chartNumber: "111031",
        nameAgeGender: "이채은(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 25,
        reservation: "17:30",
        chartNumber: "111032",
        nameAgeGender: "김덴투(13/여)",
        dobAgeGender: "750408/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 26,
        reservation: "17:30",
        chartNumber: "111033",
        nameAgeGender: "박우진(7/남)",
        dobAgeGender: "750408/남(7)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
    ],
  });

  const [tableDataScenario6, setTableDataScenario6] = useState({
    patientList: [
      {
        id: 1,
        reservation: "9:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(51/남)",
        dobAgeGender: "611013/남(51)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "9:30",
        chartNumber: "111011",
        nameAgeGender: "박세로이(25/남)",
        dobAgeGender: "611013/남(25)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 20,
        reservation: "10:00",
        chartNumber: "120020",
        nameAgeGender: "이혜빈(40/여)",
        dobAgeGender: "611013/여(40)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "10:30",
        chartNumber: "207010",
        nameAgeGender: "오찬욱(61/남)",
        dobAgeGender: "611013/남(61)",
        doctor: "최윤호",
        status: "진료완료",
        action: "수납하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "11:00",
        chartNumber: "103000",
        nameAgeGender: "김선희(62/여)",
        dobAgeGender: "611013/여(62)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "12:00",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "611013/남(48)",
        doctor: "심우현",
        status: "진료중",
        action: "진료완료",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "14:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "611013/남(19)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "611013/여(42)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      // {
      //   id: 8,
      //   reservation: "14:30",
      //   chartNumber: "110030",
      //   nameAgeGender: "정현주(42/여)",
      //   doctor: "최윤호",
      //   status: "진료중",
      //   action: "진료완료",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      {
        id: 9,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "611013/여(50)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 10,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "611013/남(44)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "611013/남(37)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 12,
        reservation: "17:00",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "611013/남(30)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 13,
        reservation: "17:00",
        chartNumber: "111021",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "100000",
        patientContribution: "100000",
        paymentAmount: "100000",
        amountReceivable: "100000",
      },
      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "611013/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },

      {
        id: 148,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "611013/남(7)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 15,
        reservation: "17:00",
        chartNumber: "111023",
        nameAgeGender: "김민서(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "박승우",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 16,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 17,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 18,
        reservation: "17:00",
        chartNumber: "111025",
        nameAgeGender: "이유나(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 19,
        reservation: "17:00",
        chartNumber: "111026",
        nameAgeGender: "박지원(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 200,
        reservation: "17:30",
        chartNumber: "111027",
        nameAgeGender: "김지후(7/남)",
        dobAgeGender: "611013/남(7)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 21,
        reservation: "17:30",
        chartNumber: "111028",
        nameAgeGender: "김윤아(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 22,
        reservation: "17:30",
        chartNumber: "111029",
        nameAgeGender: "이민지(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 23,
        reservation: "17:30",
        chartNumber: "111030",
        nameAgeGender: "박나은(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 24,
        reservation: "17:30",
        chartNumber: "111031",
        nameAgeGender: "이채은(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 25,
        reservation: "17:30",
        chartNumber: "111032",
        nameAgeGender: "김덴투(13/여)",
        dobAgeGender: "611013/여(13)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 26,
        reservation: "17:30",
        chartNumber: "111033",
        nameAgeGender: "박우진(7/남)",
        dobAgeGender: "611013/남(7)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
    ],
  });
  const [tableDataScenario8, setTableDataScenario8] = useState({
    patientList: [
      {
        id: 1,
        reservation: "9:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(51/남)",
        dobAgeGender: "810502/51(남)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "9:30",
        chartNumber: "111011",
        nameAgeGender: "박세로이(25/남)",
        dobAgeGender: "810502/25(남)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 20,
        reservation: "10:00",
        chartNumber: "120020",
        nameAgeGender: "이혜빈(40/여)",
        dobAgeGender: "810502/40(여)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "11:00",
        chartNumber: "103000",
        nameAgeGender: "김선희(62/여)",
        dobAgeGender: "810502/62(여)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "12:00",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "810502/48(남)",
        doctor: "심우현",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "14:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "810502/19(남)",
        doctor: "최윤호",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "810502/42(여)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 80,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "810502/42(여)",
        doctor: "최윤호",
        status: "진료중",
        action: "진료완료",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 9,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "810502/50(여)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 10,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "810502/44(남)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "810502/37(남)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 12,
        reservation: "17:00",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "810502/30(남)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 13,
        reservation: "17:00",
        chartNumber: "111021",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "100000",
        patientContribution: "100000",
        paymentAmount: "100000",
        amountReceivable: "100000",
      },
      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "810502/7(남)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },

      {
        id: 14,
        reservation: "17:00",
        chartNumber: "111022",
        nameAgeGender: "김서준(7/남)",
        dobAgeGender: "810502/7(남)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 15,
        reservation: "17:00",
        chartNumber: "111023",
        nameAgeGender: "김민서(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "박승우",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 16,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 17,
        reservation: "17:00",
        chartNumber: "111024",
        nameAgeGender: "박시은(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 18,
        reservation: "17:00",
        chartNumber: "111025",
        nameAgeGender: "이유나(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 19,
        reservation: "17:00",
        chartNumber: "111026",
        nameAgeGender: "박지원(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 200,
        reservation: "17:30",
        chartNumber: "111027",
        nameAgeGender: "김지후(7/남)",
        dobAgeGender: "810502/7(남)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 21,
        reservation: "17:30",
        chartNumber: "111028",
        nameAgeGender: "김윤아(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 22,
        reservation: "17:30",
        chartNumber: "111029",
        nameAgeGender: "이민지(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 23,
        reservation: "17:30",
        chartNumber: "111030",
        nameAgeGender: "박나은(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 24,
        reservation: "17:30",
        chartNumber: "111031",
        nameAgeGender: "이채은(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 25,
        reservation: "17:30",
        chartNumber: "111032",
        nameAgeGender: "김덴투(13/여)",
        dobAgeGender: "810502/13(여)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 26,
        reservation: "17:30",
        chartNumber: "111033",
        nameAgeGender: "박우진(7/남)",
        dobAgeGender: "810502/7(남)",
        doctor: "한영희",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
      },
    ],
  });
  const [tableDataScenario9, setTableDataScenario9] = useState({
    patientList: [
      // {
      //   id: 1,
      //   reservation: "9:00",
      //   chartNumber: "005258",
      //   nameAgeGender: "정호걸(51/남)",
      //   dobAgeGender: "931225/51(남)",
      //   doctor: "최윤호",
      //   status: "진료준비",
      //   action: "진료시작",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 2,
      //   reservation: "9:30",
      //   chartNumber: "111011",
      //   nameAgeGender: "박세로이(25/남)",
      //   dobAgeGender: "931225/25(남)",
      //   doctor: "최윤호",
      //   status: "진료준비",
      //   action: "진료시작",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 20,
      //   reservation: "10:00",
      //   chartNumber: "120020",
      //   nameAgeGender: "이혜빈(40/여)",
      //   dobAgeGender: "720408/40(여)",
      //   doctor: "최윤호",
      //   status: "진료준비",
      //   action: "진료시작",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 5,
      //   reservation: "11:00",
      //   chartNumber: "103000",
      //   nameAgeGender: "김선희(62/여)",
      //   dobAgeGender: "720408/62(여)",
      //   doctor: "최윤호",
      //   status: "진료준비",
      //   action: "진료시작",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 6,
      //   reservation: "12:00",
      //   chartNumber: "320010",
      //   nameAgeGender: "전상구(48/남)",
      //   dobAgeGender: "720408/48(남)",
      //   doctor: "심우현",
      //   status: "진료준비",
      //   action: "진료시작",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      {
        id: 122,
        reservation: "13:00",
        chartNumber: "120360",
        nameAgeGender: "김세희(21/여)",
        dobAgeGender: "720408/19(남)",
        doctor: "홍원장",
        status: "진료완료",
        action: "수납하기",
        nextReservation: "예약완료",
        totalMedicalExpense: "9,900,000원",
        patientContribution: "9,000,000원",
        paymentAmount: "-",
        amountReceivable: "8,000,000원",
      },
      {
        id: 7,
        reservation: "14:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(52/남)",
        dobAgeGender: "720408/19(남)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      {
        id: 8,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "720408/42(여)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      // {
      //   id: 8,
      //   reservation: "14:30",
      //   chartNumber: "110030",
      //   nameAgeGender: "정현주(42/여)",
      //   doctor: "최윤호",
      //   status: "진료중",
      //   action: "진료완료",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      {
        id: 9,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "720408/50(여)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      {
        id: 10,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "720408/44(남)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      {
        id: 119,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "720408/37(남)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      {
        id: 14123,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "720408/30(남)",
        doctor: "심우현",
        status: "진료준비",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      {
        id: 12,
        reservation: "16:30",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "720408/30(남)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "-",
        totalMedicalExpense: "-",
        patientContribution: "8,810원",
        paymentAmount: "8,810원",
        amountReceivable: "-",
        insurance: "건강보험",
      },
      // {
      //   id: 12,
      //   reservation: "17:00",
      //   chartNumber: "113010",
      //   nameAgeGender: "이창선(30/남)",
      //   dobAgeGender: "720408/30(남)",
      //   doctor: "최윤호",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 13,
      //   reservation: "17:00",
      //   chartNumber: "111021",
      //   nameAgeGender: "박서윤(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "최윤호",
      //   status: "진료중",
      //   action: "진료완료",
      //   nextReservation: "",
      //   totalMedicalExpense: "100000",
      //   patientContribution: "100000",
      //   paymentAmount: "100000",
      //   amountReceivable: "100000",
      // },
      // {
      //   id: 14,
      //   reservation: "17:00",
      //   chartNumber: "111022",
      //   nameAgeGender: "김서준(7/남)",
      //   dobAgeGender: "720408/7(남)",
      //   doctor: "최윤호",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },

      // {
      //   id: 19,
      //   reservation: "17:00",
      //   chartNumber: "111022",
      //   nameAgeGender: "김서준(7/남)",
      //   dobAgeGender: "720408/7(남)",
      //   doctor: "최윤호",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 15,
      //   reservation: "17:00",
      //   chartNumber: "111023",
      //   nameAgeGender: "김민서(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "박승우",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 16,
      //   reservation: "17:00",
      //   chartNumber: "111024",
      //   nameAgeGender: "박시은(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 17,
      //   reservation: "17:00",
      //   chartNumber: "111024",
      //   nameAgeGender: "박시은(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 18,
      //   reservation: "17:00",
      //   chartNumber: "111025",
      //   nameAgeGender: "이유나(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 19,
      //   reservation: "17:00",
      //   chartNumber: "111026",
      //   nameAgeGender: "박지원(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      {
        id: 200,
        reservation: "17:30",
        chartNumber: "125410",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "720408/13(여)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "",
        amountReceivable: "",
        insurance: "건강보험",
      },
      // {
      //   id: 21,
      //   reservation: "17:30",
      //   chartNumber: "111028",
      //   nameAgeGender: "김윤아(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 22,
      //   reservation: "17:30",
      //   chartNumber: "111029",
      //   nameAgeGender: "이민지(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 23,
      //   reservation: "17:30",
      //   chartNumber: "111030",
      //   nameAgeGender: "박나은(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 24,
      //   reservation: "17:30",
      //   chartNumber: "111031",
      //   nameAgeGender: "이채은(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 25,
      //   reservation: "17:30",
      //   chartNumber: "111032",
      //   nameAgeGender: "김덴투(13/여)",
      //   dobAgeGender: "720408/13(여)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
      // {
      //   id: 26,
      //   reservation: "17:30",
      //   chartNumber: "111033",
      //   nameAgeGender: "박우진(7/남)",
      //   dobAgeGender: "720408/7(남)",
      //   doctor: "한영희",
      //   status: "접수대기",
      //   action: "접수하기",
      //   nextReservation: "",
      //   totalMedicalExpense: "",
      //   patientContribution: "",
      //   paymentAmount: "",
      //   amountReceivable: "",
      // },
    ],
  });

  const [tableDataScenario12, setTableDataScenario12] = useState({
    patientList: [
      {
        id: 1,
        reservation: "14:00",
        chartNumber: "200336",
        nameAgeGender: "송은지(30/여)",
        dobAgeGender: "200336/남(30)",
        doctor: "홍원장",
        status: "진료준비",
        insurance: "건강보험",
        action: "진료준비",
        nextReservation: "",
        totalMedicalExpense: "",

        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "14:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(52/남)",
        dobAgeGender: "720408/남(52)",
        doctor: "심우현",
        status: "접수대기",
        insurance: "건강보험",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 3,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "110030/남(42)",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여)",
        dobAgeGender: "107010/남(50)",
        doctor: "심원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 5,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "112020/남(44)",
        doctor: "최원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 6,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "330250/남(37)",
        doctor: "홍원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "16:30",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "113010/남(30)",
        doctor: "김원장",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 7,
        reservation: "17:30",
        chartNumber: "125410",
        nameAgeGender: "박서윤(13/여)",
        dobAgeGender: "113010/남(30)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
    ],
  });
  const [
    tableDataScenarioPreparingList12,
    setTableDataScenarioPreparingList12,
  ] = useState({
    patientList: [
      {
        id: 1,
        reservation: "14:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(52/남)",
        dobAgeGender: "005258/남(52)",
        doctor: "심원장",
        status: "접수대기",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 2,
        reservation: "14:00",
        chartNumber: "320010",
        nameAgeGender: "전상구(48/남)",
        dobAgeGender: "320010/남(48)",
        doctor: "홍원장",
        status: "접수대기",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 33,
        reservation: "14:00",
        chartNumber: "314010",
        nameAgeGender: "이윤혁(19/남)",
        dobAgeGender: "314010/남(19)",
        doctor: "최원장",
        status: "접수대기",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 4,
        reservation: "14:00",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "110030/남(42)",
        doctor: "김원장",
        status: "접수대기",
        action: "진료시작",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
    ],
  });

  const [tableDataScenario122, setTableDataScenario122] = useState({
    patientList: [
      {
        id: 99,
        reservation: "13:00",
        chartNumber: "120360",
        nameAgeGender: "김세희(21/여)",
        dobAgeGender: "120360/남(21)",
        doctor: "홍원장",
        status: "수납하기",
        action: "진료완료",
        nextReservation: "예약완료",
        totalMedicalExpense: "9,900,000원",
        patientContribution: "9,000,000원",
        paymentAmount: "-",
        amountReceivable: "8,000,000원",
      },
      {
        id: 98,
        reservation: "14:00",
        chartNumber: "103440",
        nameAgeGender: "박은영(23/여)",
        dobAgeGender: "111011/남(25)",
        doctor: "김원장",
        status: "수납하기",
        action: "진료완료",
        nextReservation: "-",
        totalMedicalExpense: "2,000,000원",
        patientContribution: "2,000,000원",
        paymentAmount: "",
        amountReceivable: "",
      },
      {
        id: 1,
        reservation: "13:30",
        chartNumber: "200336",
        nameAgeGender: "송은지(30/여)",
        dobAgeGender: "200336/남(30)",
        doctor: "최원장",
        status: "진료준비",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },

      {
        id: 3,
        reservation: "13:00",
        chartNumber: "100060",
        nameAgeGender: "이수만(56/남)",
        dobAgeGender: "100060/남(56)",
        doctor: "최원장",
        status: "진료완료",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
      {
        id: 31,
        reservation: "14:00",
        chartNumber: "005258",
        nameAgeGender: "정호걸(52/남)",
        dobAgeGender: "005258/남(52)",
        doctor: "심원장",
        status: "진료완료",
        action: "접수하기",
        insurance: "건강보험",
        nextReservation: "-",
        totalMedicalExpense: "25,300원",
        patientContribution: "7,590원",
        paymentAmount: "-",
        amountReceivable: "",
        data1: "25,300",
        data2: "-",
        data3: "-",
        data4: "-",
        data5: "7,590",
        data6: "-",
        data7: "7,590",
      },
      {
        id: 4,
        reservation: "14:30",
        chartNumber: "110030",
        nameAgeGender: "정현주(42/여)",
        dobAgeGender: "110030/남(42)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },

      {
        id: 6,
        reservation: "15:00",
        chartNumber: "107010",
        nameAgeGender: "김연경(50/여))",
        dobAgeGender: "107010/남(50)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },

      {
        id: 8,
        reservation: "15:30",
        chartNumber: "112020",
        nameAgeGender: "김택(44/남)",
        dobAgeGender: "112020/남(44)",
        doctor: "최윤호",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },

      {
        id: 11,
        reservation: "16:00",
        chartNumber: "330250",
        nameAgeGender: "최용민(37/남)",
        dobAgeGender: "330250/남(37)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },

      {
        id: 13,
        reservation: "16:30",
        chartNumber: "113010",
        nameAgeGender: "이창선(30/남)",
        dobAgeGender: "113010/남(30)",
        doctor: "심우현",
        status: "접수대기",
        action: "접수하기",
        nextReservation: "",
        totalMedicalExpense: "",
        patientContribution: "",
        paymentAmount: "-",
        amountReceivable: "",
      },
    ],
  });

  const [isHome, setIsHome] = useState(false);
  function setPatientIsProcessing(id) {
    setTableData((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsProcessingScenario10(id) {
    setTableDataScenario10((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsProcessingScenario5(id) {
    setTableDataScenario5((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsProcessingScenario7(id) {
    setTableDataScenario7((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsProcessingScenario6(id) {
    setTableDataScenario6((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsProcessingScenario9(id) {
    setTableDataScenario9((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsProcessingScenario8(id) {
    setTableDataScenario8((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsCompleted(id) {
    setTableData((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsCompletedScenario10(id) {
    setTableDataScenario10((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsCompletedScenario5(id) {
    setTableDataScenario5((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsCompletedScenario6(id) {
    setTableDataScenario6((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsCompletedScenario9(id) {
    setTableDataScenario9((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }

  //table data scenario
  // function setPatientIsCompletedScenario6(id) {
  //   setTableDataScenario9((prev) => ({
  //     patientList: prev.patientList.map((item) => {
  //       if (item.id === id) {
  //         return { ...item, status: "진료중", action: "진료완료" };
  //       } else {
  //         return item;
  //       }
  //     }),
  //   }));
  // }
  function setPatientIsCompletedScenario8(id) {
    setTableDataScenario8((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsCompletedScenario7(id) {
    setTableDataScenario7((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료중", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsPaymentScenario7(id) {
    setTableDataScenario7((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료완료", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsPaymentScenario9(id) {
    setTableDataScenario9((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료완료", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsPaymentScenario6(id) {
    setTableDataScenario6((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료완료", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientIsPaymentScenario8(id) {
    setTableDataScenario8((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료완료", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsPaymentComplete(id) {
    setTableData((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료완료", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientDisplayNoneScenario7(id) {
    setTableDataScenario7((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "none", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientDisplayNoneScenario6(id) {
    setTableDataScenario6((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "none", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientDisplayNoneScenario9(id) {
    setTableDataScenario9((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "none", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }
  function setPatientDisplayNoneScenario8(id) {
    setTableDataScenario8((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "none", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsProcessing12(id) {
    setTableDataScenario12((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientDisplayNoneScenario12(id) {
    setTableDataScenario12((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "abc", action: "abc" };
        } else {
          return item;
        }
      }),
    }));
  }

  // add the data
  function setPatientDisplayScenario12AddedData(id) {
    setTableDataScenario12((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "접수대기", action: "접수대기" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientDisplayNoneScenario122(id) {
    setTableDataScenario122((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료준비", action: "진료준비" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsCompleted122(id) {
    setTableDataScenario122((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "진료완료", action: "진료완료" };
        } else {
          return item;
        }
      }),
    }));
  }

  function setPatientIsPaymentComplete122(id) {
    setTableDataScenario122((prev) => ({
      patientList: prev.patientList.map((item) => {
        if (item.id === id) {
          return { ...item, status: "수납하기", action: "수납하기" };
        } else {
          return item;
        }
      }),
    }));
  }

  return (
    <DataContext.Provider
      value={{
        isHome,
        setIsHome,

        tableData,
        setTableData,
        setPatientIsProcessing,
        setPatientIsCompleted,
        setPatientIsPaymentComplete,

        tableDataScenario10,
        setTableDataScenario10,
        setPatientIsCompletedScenario10,
        setPatientIsProcessingScenario10,

        tableDataScenario5,
        setTableDataScenario5,
        setPatientIsCompletedScenario5,
        setPatientIsProcessingScenario5,

        tableDataScenario7,
        setTableDataScenario7,
        setPatientIsCompletedScenario7,
        setPatientIsProcessingScenario7,
        setPatientIsPaymentScenario7,
        setPatientDisplayNoneScenario7,

        tableDataScenario6,
        setTableDataScenario6,
        setPatientDisplayNoneScenario6,
        setPatientIsCompletedScenario6,
        setPatientIsProcessingScenario6,
        setPatientIsPaymentScenario6,

        tableDataScenario9,
        setTableDataScenario9,
        setPatientDisplayNoneScenario9,
        setPatientIsCompletedScenario9,
        setPatientIsProcessingScenario9,
        setPatientIsPaymentScenario9,
        setPatientIsProcessing12,

        tableDataScenario8,
        setTableDataScenario8,
        setPatientDisplayNoneScenario8,
        setPatientIsCompletedScenario8,
        setPatientIsProcessingScenario8,
        setPatientIsPaymentScenario8,
        tableDataScenario12,
        setPatientDisplayNoneScenario12,
        setTableDataScenarioPreparingList12,
        tableDataScenarioPreparingList12,
        setTableDataScenario122,
        tableDataScenario122,
        setPatientDisplayNoneScenario122,
        setPatientIsCompleted122,
        setPatientIsCompleted122,
        setPatientIsPaymentComplete122,
        setPatientDisplayScenario12AddedData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
