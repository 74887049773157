import React, { useRef, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

function AddXray({ setopenModal, step, setXrayView }) {
  const input = useRef(null);
  const imagePlaceholder = useRef(null);

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    imagePlaceholder.current.innerText = "";
    imagePlaceholder.current.style.backgroundImage = `url('${URL.createObjectURL(
      file
    )}')`;
  };

  const closeModal = () => {
    setopenModal(false);
  };

  const XrayClose = () => {
    setopenModal(false);
    setXrayView(true);
  };

  const navigate = useNavigate();
  return (
    <div>
      <div className="modal">
        <div className="modal-content p-[32px]">
          <div className="flex flex-row items-center justify-center   ">
            <div className="modal-title flex-1">X-ray 업로드</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" onClick={() => closeModal()} />
            </div>
          </div>
          <div className="flex flex-col justify-between h-[90%]">
            <div>
              <div className="text-[#111111] text-[14px] leading-[20px] font-semibold tracking-[-0.35px] ">
                X-ray 이미지나 영상을 업로드해주세요
              </div>
              <div>
                <div
                  ref={imagePlaceholder}
                  onClick={() => input.current.click()}
                  className="bg-center bg-no-repeat bg-cover cursor-pointer mt-4 border-[1px] w-[178px] h-[148px]  flex text-center justify-center items-center border-[#D4D4D8] text-[#111] text-[18px]  leading-[26px]"
                >
                  image <br /> or <br /> video
                </div>

                <input
                  type="file"
                  hidden
                  ref={input}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="flex gap-2 justify-center pt-[20px]">
              <button className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
                취소
              </button>

              <button
                onClick={XrayClose}
                className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddXray;
