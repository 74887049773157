import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataContext from "../../../context/DataProvider";

function PatientList({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    tableData,
    setTableData,
    setPatientIsProcessing,
    setPatientIsProcessingScenario10,
    tableDataScenario10,
    setTableDataScenario10,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    localStorage.getItem("scenario") * 1 === 10
      ? tableDataScenario10.patientList
      : tableData.patientList
  );

  useEffect(() => {
    setTableDataFiltered(
      localStorage.getItem("scenario") * 1 === 10
        ? tableDataScenario10.patientList.filter((item) => {
            let dataString =
              `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
            if (dataString.includes(searchTerm.toLowerCase())) {
              return true;
            } else {
              return false;
            }
          })
        : tableData.patientList.filter((item) => {
            let dataString =
              `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
            if (dataString.includes(searchTerm.toLowerCase())) {
              return true;
            } else {
              return false;
            }
          })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#767676] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                localStorage.getItem("scenario") * 1 === 10
                                  ? setPatientIsProcessingScenario10(data.id)
                                  : setPatientIsProcessing(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function PatientListScenario5({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    tableDataScenario5,
    setTableData,
    setPatientIsProcessingScenario5,
    setTableDataScenario10,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario5.patientList
  );

  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario5.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#767676] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessingScenario5(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario5.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function PatientListScenario7({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    setPatientIsProcessingScenario7,
    tableDataScenario7,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario7.patientList
  );

  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario7.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#767676] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessingScenario7(data.id);
                                window.localStorage.setItem("scenario", 7);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario7.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
function PatientListScene6({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    tableDataScenario6,
    setTableData,
    setPatientIsProcessingScenario6,
    setTableDataScenario10,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario6.patientList
  );

  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario6.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#767676] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessingScenario6(data.id);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario6.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function PatientListScene9({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    setPatientIsProcessingScenario9,
    tableDataScenario9,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario9.patientList
  );

  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario9.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
    localStorage.setItem("scenario9Visible", "true");
  };
  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "hover:bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#767676] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessingScenario9(data.id);
                                window.localStorage.setItem("scenario", 9);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        {/* <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td> */}
                        <td className="w-[8.33%]"></td>
                        <td className="w-[8.33%]"></td>
                        <td className="w-[8.33%]"></td>
                        <td className="w-[8.33%]"></td>
                        <td className="w-[8.33%]"></td>
                      </tr>
                      {index !== tableDataScenario9.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function PatientListScene8({ searchTerm, setData, scenario }) {
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    setPatientIsProcessingScenario8,
    tableDataScenario8,
    setTableDataScenario7,
  } = useContext(DataContext);

  const [tableDataFiltered, setTableDataFiltered] = useState(
    tableDataScenario8.patientList
  );

  useEffect(() => {
    setTableDataFiltered(
      tableDataScenario8.patientList.filter((item) => {
        let dataString =
          `${item.reservation} ${item.chartNumber} ${item.nameAgeGender} ${item.doctor} ${item.status} ${item.action} ${item.nextReservation} ${item.totalMedicalExpense} ${item.patientContribution} ${item.paymentAmount}   ${item.amountReceivable} `.toLowerCase();
        if (dataString.includes(searchTerm.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchTerm]);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };
  return (
    <div>
      <div className="table w-full text-[13px]">
        <div className="border-[1px] rounded-lg w-full">
          <table className="rounded-lg text-sm bg-white w-full overflow-hidden">
            <thead className="gap-[28px] bg-[#F1F8FA] text-[#154D57]">
              <tr>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left text-center ">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left pl-4">
                  예약시간
                </th>
                <th className="py-[14px] border-b border-gray-300 w-[8.33%] text-left">
                  차트번호
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  이름(나이/성별)
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  담당의사
                </th>

                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  진료상태변경
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  다음예약
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  총진료비
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  환자부담금
                </th>
                <th className="py-[14px] border-b border-gray-300  w-[8.33%] text-left">
                  수납금액
                </th>
                <th className="py-[14px] pl-[10px] border-b border-gray-300 text-left  w-[8.33%] text-left">
                  미수금액
                </th>
              </tr>
            </thead>
          </table>

          <div style={{ maxHeight: "1118px", overflow: "scroll" }}>
            <table className="w-full">
              <tbody>
                {tableDataFiltered
                  .filter((item) => item.status === "접수대기")
                  .map((data, index, array) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className={`text-left cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : index === array.length - 1
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td className="  w-[8.33%] text-center">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </td>
                        <td className=" pl-4  w-[8.33%]">{data.reservation}</td>
                        <td className="w-[8.33%]">{data.chartNumber}</td>
                        <td className="w-[8.33%]">{data.nameAgeGender}</td>
                        <td className="w-[8.33%]">{data.doctor}</td>
                        <td className="w-[8.33%] text-[#767676] font-semibold">
                          {data.status}
                        </td>
                        <td className="w-[8.33%]">
                          <div className="items-center py-[6px] flex justify-접수하기 ">
                            <button
                              onClick={() => {
                                setPatientIsProcessingScenario8(data.id);
                                window.localStorage.setItem("scenario", 8);
                              }}
                              className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                            >
                              {data.action}
                            </button>
                          </div>
                        </td>

                        <td className="w-[8.33%]">{data.nextReservation}</td>
                        <td className="w-[8.33%]">
                          {data.totalMedicalExpense}
                        </td>
                        <td className="w-[8.33%]">
                          {data.patientContribution}
                        </td>
                        <td className="w-[8.33%]">{data.paymentAmount}</td>
                        <td className="w-[8.33%]">{data.amountReceivable}</td>
                      </tr>
                      {index !== tableDataScenario8.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  PatientList,
  PatientListScenario5,
  PatientListScenario7,
  PatientListScene6,
  PatientListScene9,
  PatientListScene8,
};
