//

import React from "react";
import "./index.css";
export default function StoreAlertBoxS121({ setOpenAlert, onConfirm }) {
  const closeAlert = () => {
    setOpenAlert(false);
  };

  return (
    <div>
      <div className="modal-alert z-[999]">
        <div className="modal-content-alert p-[32px] max-w-[420px] w-full mx-auto ">
          <div className="text-center">
            <div className="mb-[20px] text-[#154D57] font-semibold leading-[30px] tracking-[-0.5px]">
              문진표보기
            </div>
            <div className="mb-[32px] text-[#767676] text-[14px] font-normal leading-[20px]  tracking-[-0.35px] ">
              정보를 저장하시겠습니까?
            </div>
            <div className="gap-2 flex justify-center ">
              <button
                onClick={() => closeAlert(true)}
                className="rounded-[8px] bg-[#EBF5F2] text-[#154D57] px-[37px]  py-2  text-[13px] font-semibold leading-[19px] tracking-[0.325px]"
              >
                취소
              </button>
              <button
                onClick={onConfirm}
                className="rounded-[8px] bg-[#317580] text-[#FFFFFF] px-[37px] py-2 text-[13px] font-semibold leading-[19px] tracking-[0.325px]"
              >
                확인
              </button>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}
