import React, { useEffect, useState } from "react";

import EntirePatientList from "./components/EntirePatientList";
import PatientList from "./components/PatinetList";
import PreparingList from "./components/PreparingList";
import DuringTreatment from "./components/DuringTreatment";
import CompletionTreatment from "./components/CompletionTreatment";
import StorageComplete from "./components/StorageComplete";
import { DataProvider } from "../../context/DataProvider";
import PatientInfo from "./components/PatientInfo";
import { StorageInfo } from "./components/StorageInfo";
import { TreatmentPlan } from "./components/TreatmentPlan";
import { OnDayTreatment } from "./components/OnDayTreatment";

import PatientsHeader from "./components/PatientsHeader";

function ReceptionWaitingS12() {
  const [activeButton, setActiveButton] = useState("접수대기");

  const [data, setData] = useState("");
  const [addmoneyModal, setAddmoneyModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const openModal = () => {
    setAddmoneyModal(true);
  };

  const closeModal = () => {
    setAddmoneyModal(false);
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  const Component1 = () => (
    <div>
      <EntirePatientList setData={setData} />
    </div>
  );
  const Component2 = () => (
    <div>
      <PatientList searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component3 = () => (
    <div>
      <PreparingList searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component4 = () => (
    <div>
      <DuringTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component5 = () => (
    <div>
      <CompletionTreatment searchTerm={searchTerm} setData={setData} />
    </div>
  );
  const Component6 = () => (
    <div>
      <StorageComplete searchTerm={searchTerm} setData={setData} />
    </div>
  );
  return (
    <DataProvider>
      <div className=" w-full">
        <div className="px-[32px] py-[48px] flex 2xl:flex-row flex-col gap-[28px]">
          <div className="2xl:w-[34%] w-full overflow-x-auto ">
            <div className="w-full gap-[28px] flex 2xl:flex-col flex-row ">
              <div className="flex-shrink-0 flex-[1]  ">
                <PatientInfo data={data} />
              </div>
              <div className="flex-shrink-0 flex-[1] ">
                <StorageInfo data={data} />
              </div>
              <div className="flex-shrink-0 flex-[1] ">
                <TreatmentPlan />
              </div>
              <div className="flex-shrink-0 flex-[1] ">
                <OnDayTreatment />
              </div>
            </div>
          </div>

          <div className=" w-full bg-[#FFF] p-[20px] rounded-lg">
            <PatientsHeader
              activeButton={activeButton}
              onButtonClick={handleButtonClick}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              setModalOpenS12={openModal}
            />
            {activeButton === "전체" && <Component1 />}
            {activeButton === "접수대기" && <Component2 />}
            {activeButton === "진료준비" && <Component3 />}
            {activeButton === "진료중" && <Component4 />}
            {activeButton === "진료완료" && <Component5 />}
            {activeButton === "수납완료" && <Component6 />}

            {/* {addmoneyModal === true && (
              <AddOldMoneyS12
                setAddmoneyModal={closeModal}
                setActiveButton={setActiveButton}
              />
            )} */}
          </div>
        </div>
      </div>
    </DataProvider>
  );
}

export default ReceptionWaitingS12;
