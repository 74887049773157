import React, { useState } from "react";
import SuccessAlert from "../../components/SuccessAlert";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import StoreAlertBoxSignUp from "../../components/SuccessAlertSignup";

function SignIn() {
  // Initialize state for username and password
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(null);
  const [doctorLoginSuccess, setDoctorLoginSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleConfirm = () => {
    // Perform the navigation action here
    navigate("/signup");

    // Reload the page after navigation
    window.location.reload();
  };

  const handleConfirmDoctor = () => {
    // Perform the navigation action here
    navigate("/doctor/receptionwaiting");

    // Reload the page after navigation
    window.location.reload();
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if both fields are empty
    if (username.trim() === "" && password.trim() === "") {
      setErrorMessage("아이디를 입력해주세요.");
      return;
    }

    // Check if only username is entered without a password
    if (username.trim() !== "" && password.trim() === "") {
      setErrorMessage("비밀번호를 입력해주세요.");
      return;
    }

    // Replace these with your actual default credentials
    const defaultCredentials1 = {
      username: "user",
      password: "user",
    };

    const defaultCredentials2 = {
      username: "Den2bio",
      password: "d2b1008",
    };

    // Check if the entered credentials match the default credentials
    if (
      username === defaultCredentials1.username &&
      password === defaultCredentials1.password
    ) {
      setLoginSuccess(true);
      setDoctorLoginSuccess(false);
      setErrorMessage("");
    }
    // Check if the entered credentials match the default credentials 2
    else if (
      username === defaultCredentials2.username &&
      password === defaultCredentials2.password
    ) {
      setDoctorLoginSuccess(true);
      setLoginSuccess(false);
    } else if (username === "") {
      setErrorMessage("아이디를 입력해주세요.");
      return;
    } else if (password === "") {
      setErrorMessage("비밀번호를 입력해주세요.");
    } else {
      setLoginSuccess(false);
      setErrorMessage("아이디 또는 비밀번호를 잘못 입력했습니다.");
    }
  };

  return (
    <div className="">
      <h1 className="flex justify-center text-[36px] leading-[48px] tracking-[-0.9px] text-[36px] font-semibold text-[#154D57] pb-[32px] pt-[120px]">
        로그인
      </h1>
      <div className="max-w-[556px] w-full mx-auto bg-[#FFFFFF] rounded-[8px] p-[48px]">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center flex-col ">
              <input
                placeholder="아이디를 입력해주세요."
                className="max-w-[460px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]  focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4 mb-4"
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                placeholder="비밀번호를 입력해주세요."
                className="max-w-[460px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]   focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errorMessage && (
                <div className="text-red-500 text-left pt-3">
                  {errorMessage}
                </div>
              )}
            </div>{" "}
            <div className="flex   flex-col">
              <div className="flex justify-center pt-[40px] pb-[32px]">
                <button className="bg-[#317580] rounded-[8px] max-w-[460px] w-full py-4 flex justify-center text-[#FFFFFF]">
                  로그인
                </button>
              </div>
              <div className="gap-3 flex flex-row justify-center items-center">
                <Link to="/findid">
                  <div className="text-[#767676] font-normal text-[14px] leading-[20px] tracking-[-0.35px] font-['Pretendard']">
                    아이디/비밀번호 찾기{" "}
                  </div>
                </Link>
                <span>
                  <svg
                    width="1"
                    height="10"
                    viewBox="0 0 1 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect y="0.5" width="1" height="10" fill="#D4D4D8" />
                  </svg>
                </span>
                <Link to="/signup">
                  <div className="text-[#154D57]">회원가입</div>
                </Link>
              </div>

              {loginSuccess === true && (
                <StoreAlertBoxSignUp
                  title="로그인"
                  subtitle="로그인이 완료되었습니다."
                  onConfirm={handleConfirm}
                />
              )}

              {doctorLoginSuccess === true && (
                <StoreAlertBoxSignUp
                  title="로그인"
                  subtitle="로그인이 완료되었습니다."
                  onConfirm={handleConfirmDoctor}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
