import React, { useState } from "react";

function DropdownS12({ onConfirm, onCancel }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState("예약할 항목을 선택해 주세요."); // Initialize with an empty string or your default option
  const [selectedTable, setSelectedTable] = useState(0);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false); // Close the dropdown when an option is selected
  };

  const toggleSelect = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const options = {
    0: <div></div>,

    1: (
      <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
        <tr className="h-[20px] text-center">
          <td className="border-r w-[63px] border-[#999]">87654321</td>
          <td className="border-l w-[63px] border-[#999]">12345678</td>
        </tr>
        <tr className="h-[20px]">
          <td className="border-t border-r w-[63px] border-[#999]">87654321</td>
          <td className="border-t border-l w-[63px] border-[#999] text-right pr-2">
            12345678
          </td>
        </tr>
      </table>
    ),
    2: (
      <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
        <tr className="h-[20px] text-center">
          <td className="border-r w-[63px] border-[#999]"></td>
          <td className="border-l w-[63px] border-[#999]"></td>
        </tr>
        <tr className="h-[20px]">
          <td className="border-t border-r w-[63px] border-[#999]"></td>
          <td className="border-t border-l w-[63px] border-[#999] text-right">
            8
          </td>
        </tr>
      </table>
    ),
    3: (
      <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
        <tr className="h-[20px] text-center">
          <td className="border-r w-[63px] border-[#999]">5</td>
          <td className="border-l w-[63px] border-[#999]"></td>
        </tr>
        <tr className="h-[20px]">
          <td className="border-t border-r w-[63px] border-[#999]"></td>
          <td className="border-t border-l w-[63px] border-[#999] pl-4"></td>
        </tr>
      </table>
    ),
    4: (
      <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
        <tr className="h-[20px] text-center">
          <td className="border-r w-[63px] border-[#999]">5</td>
          <td className="border-l w-[63px] border-[#999]"></td>
        </tr>
        <tr className="h-[20px]">
          <td className="border-t border-r w-[63px] border-[#999]"></td>
          <td className="border-t border-l w-[63px] border-[#999] text-right pr-2"></td>
        </tr>
      </table>
    ),
    // 5: (
    //   <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //     <tr className="h-[20px] text-center">
    //       <td className="border-r w-[63px] border-[#999]"></td>
    //       <td className="border-l w-[63px] border-[#999]">6</td>
    //     </tr>
    //     <tr className="h-[20px]">
    //       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //       <td className="border-t border-l w-[63px] border-[#999] text-right pr-2"></td>
    //     </tr>
    //   </table>
    // ),
  };

  return (
    <div className="modal">
      <div className="modal-content p-[32px] h-[680px]">
        <div>
          <div className="flex flex-row items-center justify-center mb-[20px]  ">
            <div className="modal-title flex-1">예약하기</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" />
            </div>
          </div>
          <div className="w-full max-w-[596px] mx-auto h-[488px]">
            <div className="text-[#111111] font-semibold text-[14px] leading-[20px] tracking-[-0.35px] mb-3">
              아래 항목으로 수정하시겠습니까?
            </div>
            <div className="relative">
              <div
                className={`bg-[#FFF] border-[1px] border-[#D4D4D8] py-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] cursor-pointer ${
                  isDropdownOpen ? "rounded-tl-lg rounded-tr-lg" : "rounded-lg"
                }`}
                onClick={toggleSelect}
              >
                <div className="flex flex-row items-center justify-between ">
                  <div className="flex flex-row items-center">
                    <div>{options[selectedTable]}</div>
                    <div className="pl-[20px] text-[14px]">
                      {selectedOption}
                    </div>
                  </div>
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.6001 13.8667L16.0001 20.2667L22.4001 13.8667"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {isDropdownOpen && (
                <div className="dropdown-content border-[1px] text-[14px] px-[10px] border-t-0 flex flex-col gap-2">
                  <div
                    className="py-2 flex gap-2"
                    onClick={() => {
                      handleOptionClick("치주소파술 ");
                      setSelectedTable(1);
                    }}
                  >
                    {options[1]}
                    {" 치주소파술"}
                  </div>
                  <div
                    className="py-2 flex gap-2"
                    onClick={() => {
                      handleOptionClick("발치");
                      setSelectedTable(2);
                    }}
                  >
                    {options[2]}
                    {"발치 "}
                  </div>
                  <div
                    className="py-2 flex gap-2"
                    onClick={() => {
                      handleOptionClick("근관치료");
                      setSelectedTable(3);
                    }}
                  >
                    {options[3]}
                    {" 근관치료"}
                  </div>
                  <div
                    className="py-2 flex gap-2"
                    onClick={() => {
                      handleOptionClick("크라운");
                      setSelectedTable(4);
                    }}
                  >
                    {" "}
                    {options[4]}
                    {" 크라운"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-center pt-[20px] items-end">
          <button
            onClick={onCancel}
            className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
          >
            취소
          </button>

          <button
            onClick={onConfirm}
            className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
          >
            추가
          </button>
        </div>
      </div>
    </div>
  );
}
export function DropdownS9({ onConfirm, onCancel }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState("예약할 항목을 선택해 주세요."); // Initialize with an empty string or your default option
  const [selectedTable, setSelectedTable] = useState(0);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false); // Close the dropdown when an option is selected
  };

  const toggleSelect = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const options = {
    0: <div></div>,

    1: (
      <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
        <tr className="h-[20px] text-center">
          <td className="border-r w-[63px] border-[#999]">4</td>
          <td className="border-l w-[63px] border-[#999]"></td>
        </tr>
        <tr className="h-[20px]">
          <td className="border-t border-r w-[63px] border-[#999]"></td>
          <td className="border-t border-l w-[63px] border-[#999] text-right pr-2">
            6
          </td>
        </tr>
      </table>
    ),
    // 2: (
    //   <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //     <tr className="h-[20px] text-center">
    //       <td className="border-r w-[63px] border-[#999]"></td>
    //       <td className="border-l w-[63px] border-[#999]"></td>
    //     </tr>
    //     <tr className="h-[20px]">
    //       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //       <td className="border-t border-l w-[63px] border-[#999] text-right">
    //         8
    //       </td>
    //     </tr>
    //   </table>
    // ),
    // 3: (
    //   <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //     <tr className="h-[20px] text-center">
    //       <td className="border-r w-[63px] border-[#999]">5</td>
    //       <td className="border-l w-[63px] border-[#999]"></td>
    //     </tr>
    //     <tr className="h-[20px]">
    //       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //       <td className="border-t border-l w-[63px] border-[#999] pl-4"></td>
    //     </tr>
    //   </table>
    // ),
    // 4: (
    //   <table className="ml-[20px] w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //     <tr className="h-[20px] text-center">
    //       <td className="border-r w-[63px] border-[#999]">5</td>
    //       <td className="border-l w-[63px] border-[#999]"></td>
    //     </tr>
    //     <tr className="h-[20px]">
    //       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //       <td className="border-t border-l w-[63px] border-[#999] text-right pr-2"></td>
    //     </tr>
    //   </table>
    // ),
    // 5: (
    //   <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
    //     <tr className="h-[20px] text-center">
    //       <td className="border-r w-[63px] border-[#999]"></td>
    //       <td className="border-l w-[63px] border-[#999]">6</td>
    //     </tr>
    //     <tr className="h-[20px]">
    //       <td className="border-t border-r w-[63px] border-[#999]"></td>
    //       <td className="border-t border-l w-[63px] border-[#999] text-right pr-2"></td>
    //     </tr>
    //   </table>
    // ),
  };

  return (
    <div className="modal">
      <div className="modal-content p-[32px] h-[680px]">
        <div>
          <div className="flex flex-row items-center justify-center mb-[20px]  ">
            <div className="modal-title flex-1">예약하기</div>
            <div className="cursor-pointer">
              <img src="/close.png" alt="" />
            </div>
          </div>
          <div className="w-full max-w-[596px] mx-auto h-[488px]">
            <div className="text-[#111111] font-semibold text-[14px] leading-[20px] tracking-[-0.35px] mb-3">
              아래 항목으로 수정하시겠습니까?
            </div>
            <div className="relative">
              <div
                className={`bg-[#FFF] border-[1px] border-[#D4D4D8] py-2 text-[13px] font-normal leading-[19px] tracking-[-0.325px] cursor-pointer ${
                  isDropdownOpen ? "rounded-tl-lg rounded-tr-lg" : "rounded-lg"
                }`}
                onClick={toggleSelect}
              >
                <div className="flex flex-row items-center justify-between ">
                  <div className="flex flex-row items-center">
                    <div>{options[selectedTable]}</div>
                    <div className="pl-[20px]">{selectedOption}</div>
                  </div>
                  <div>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.6001 13.8667L16.0001 20.2667L22.4001 13.8667"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {isDropdownOpen && (
                <div className="dropdown-content text-[14px] border-[1px] px-[10px] border-t-0 flex flex-col gap-2">
                  <div
                    className="py-2 flex gap-2"
                    onClick={() => {
                      handleOptionClick("레진인레이 ");
                      setSelectedTable(1);
                    }}
                  >
                    {options[1]}
                    {" 레진인레이"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-center pt-[20px] items-end">
          <button
            onClick={onCancel}
            className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
          >
            취소
          </button>

          <button
            onClick={onConfirm}
            className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
          >
            추가
          </button>
        </div>
      </div>
    </div>
  );
}

export default DropdownS12;
