import React, { useState } from "react";
import "./index.scss";
import styles from "./index.scss";
import { Input } from "antd";
import { Button } from "antd";
import Upload12 from "../../../../../Uploads/uploads";
import { Radio } from "antd";
import SuccessAlert from "../../../../components/SuccessAlert";
import { Select, Space } from "antd";
import StoreAlertBoxSignUp from "../../../../components/SuccessAlertSignup";
const options = [
  { label: "직종을 선택해주세요.", value: "0" },
  { label: "의사", value: "1" },
  { label: "치위생사", value: "2" },
  { label: "치기공사", value: "3" },
  { label: "사무장", value: "4" },
  { label: "기타", value: "5" },
];

const handleChange = (value) => {
  console.log(`Selected: ${value}`);
};

function MembershipUserInput() {
  const [success, setSuccess] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleSuccessclose = () => {
    setSuccess(false);
  };
  const [size, setSize] = useState("middle");
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  return (
    <div className=" flex justify-center flex-col max-w-[1040px] w-full">
      <div className="formContent">
        <div className="input1">
          <div className="Q1">
            <div className="text">사용자 이름</div>
            <div className="star">*</div>
          </div>

          <div className="A1">
            <Input
              className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
              placeholder="이름을 입력해주세요."
            />
          </div>
        </div>

        <div className="input1">
          <div className="Q1">
            <div className="text">생년월일</div>
            <div className="star">*</div>
          </div>
          <div className="A1">
            <Input
              className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
              placeholder="생년월일을 입력해주세요."
            />
          </div>
        </div>
        <div className="input1">
          <div className="Q1">
            <div className="text"> 아이디</div>
            <div className="star">*</div>
          </div>
          <div className="A1">
            <Input
              className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
              placeholder="아이디를 입력해주세요."
            />
          </div>
        </div>

        <div className="input1">
          <div className="Q1">
            <div className="text">전화번호</div>
            <div className="star">*</div>
          </div>
          <div className="input-field placeholder-[#767676]">
            <div className="input-field-number">
              <div className="div1">
                <div className="A1 max-w-[922px] w-full">
                  <Input
                    className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                    placeholder="휴대폰 번호를 입력해주세요.(-제외)"
                  />
                </div>
                <div>
                  <button className="text-[14px] bg-[#EBF5F2] text-[#154D57] leading-[20px] font-normal tracking-[-0.35px] py-[13px] px-[17px] rounded-lg">
                    인증번호 받기
                  </button>
                </div>
              </div>

              <div className="A1">
                <Input
                  className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
                  placeholder="인증번호 6자리 숫자 입력"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input1">
          <div className="Q1">
            <div className="text">직종선택</div>
            <div className="star">*</div>
          </div>

          <div className="A1">
            <Select
              defaultValue={"직종을 선택해주세요."}
              size={size}
              onChange={handleChange}
              options={options}
            />
          </div>
        </div>

        <div className="input1">
          <div className="Q1">
            <div className="text">접근권한</div>
            <div className="star">*</div>
          </div>

          <div className="radio">
            <div className="flex flex-row gap-[6px]">
              {" "}
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="HTML"
                color="#317580"
              />
              <div>관리자(의사)</div>
            </div>
            <div className="input2">
              <input
                type="radio"
                id="html"
                name="fav_language"
                value="HTML"
                color="#317580"
              />
              <div>일반(의사 외)</div>
            </div>

            {/* <Radio>관리자(의사)</Radio>
              <Radio>일반(의사 외)</Radio> */}
          </div>
        </div>

        <div className="input1">
          <div className="Q1">
            <div className="text">치과의사명(최종관리자)</div>
            <div className="star">*</div>
          </div>
          <div className="A1">
            <Input
              className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
              placeholder="김덴탈"
            />
          </div>
        </div>

        <div className="input1">
          <div className="Q1">
            <div className="text"> 치과이름</div>
            <div className="star">*</div>
          </div>
          <div className="A1">
            <Input
              className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
              placeholder="밝은미소치과"
            />
          </div>
        </div>

        <div className="input1">
          <div className="Q1">
            <div className="text"> 비밀번호(생년월일 8자리 자동생성)</div>
            <div className="star">*</div>
          </div>
          <div className="A1">
            <Input
              className="input-field placeholder-[#767676]  border border-gray-300 p-1 rounded-[8px] w-full  leading-[24px] tracking-[-0.4px]"
              placeholder="00000000"
            />
          </div>
        </div>
      </div>
      <div className="l">
        <div className="star">*</div>
        <div className="w">필수입력사항</div>
      </div>
      <div className="buttoncont">
        <button className="lbutton">확인</button>
      </div>

      {success === true && (
        <StoreAlertBoxSignUp
          title="회원가입"
          subtitle="가입이 완료되었습니다."
          onConfirm={handleSuccessclose}
        />
      )}
    </div>
  );
}

export default MembershipUserInput;
