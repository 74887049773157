import { format } from "date-fns";
import React from "react";
import { useEffect } from "react";

const Table = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <table className="w-full">
          <tbody>
            {/* Create 10 rows here */}
            {Array(10)
              .fill(null)
              .map((_, index) => (
                <tr key={index} className="border-t">
                  <td className="w-[5.5%] pl-3 border-r bg-[#F1F8FA]">1</td>
                  <td className="w-[15.8%] h-[223px] px-3 border-r"> Col 2</td>
                  <td className="w-[15.8%] h-[223px] px-3 border-r"> Col 3</td>
                  <td className="w-[15.8%] h-[223px] px-3 border-r"> Col 4</td>
                  <td className="w-[15.84%] h-[223px] px-3 border-r"> Col 5</td>
                  <td className="w-[15.8%] h-[223px] px-3 border-r"> Col 6</td>
                  <td className="w-[15.8%] h-[223px] px-3 "> Col 7</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableScenario6 = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-scroll">
      <div className=" ">
        <table className="  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="min-w-[77px] text-left  border-r "></th>
              <th className="min-w-[220px]  text-left pl-1 py-[9px] border-r">
                최원장
              </th>
              <th className="min-w-[220px]  text-left px-3 border-r">심원장</th>
              <th className="min-w-[220px]  text-left px-3 border-r">홍원장</th>
              <th className="min-w-[220px]  text-left px-3 border-r">이원장</th>

              <th className="min-w-[220px]  text-left px-3 border-r">박원장</th>
              <th className="min-w-[220px]  text-left px-3 border-r">심우현</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="rounded-b-lg"
        style={{ maxHeight: "834px", width: "100%" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      김미백 - 미백
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>

                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>

                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 ">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableScenario6CalenderView = ({ selectedDate }) => {
  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 "> </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] ">
                    {" "}
                    {selectedDate && format(selectedDate, "dd") === "06" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]"></td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]"></td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  8
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            이혜빈 - 발치
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableExactScenario6 = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      김미백 - 미백
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            5
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      박덴트 - 치석제거
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableScenario6ExactCalenderView = ({ selectedDate }) => {
  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 "> </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {" "}
                    {selectedDate && format(selectedDate, "dd") === "08" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]"></td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]"></td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  8
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            이혜빈 - 발치
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] "></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableExactScenario7 = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-scroll">
      <div className=" ">
        <table className="  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="min-w-[77px] text-left  border-r "></th>
              <th className="min-w-[220px]  text-left pl-1 py-[9px] border-r">
                최원장
              </th>
              <th className="min-w-[220px]  text-left px-3 border-r">심원장</th>
              <th className="min-w-[220px]  text-left px-3 border-r">홍원장</th>
              <th className="min-w-[220px]  text-left px-3 border-r">이원장</th>

              <th className="min-w-[220px]  text-left px-3 border-r">박원장</th>
              <th className="min-w-[220px]  text-left px-3 border-r">심우현</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className=" rounded-b-lg"
        style={{ maxHeight: "834px", width: "100%" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      김미백 - 미백
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>

                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>

                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 ">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableScenario7ExactCalenderView = ({ selectedDate }) => {
  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 "> </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] "></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {" "}
                    {selectedDate && format(selectedDate, "dd") === "05" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]"></td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]"></td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  8
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            전상구 - 발치
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};
const TableExactScenario8 = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      김미백 - 미백
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            5
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      박덴트 - 치석제거
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableScenario8ExactCalenderView = ({ selectedDate }) => {
  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 "> </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] "></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  15시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {selectedDate && format(selectedDate, "dd") === "07" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]"></td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]"></td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  8
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            이혜빈 - 발치
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  15시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

const TableExactScenario9 = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      김미백 - 미백
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            5
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      박덴트 - 치석제거
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TableScenario9ExactCalenderView = ({ selectedDate }) => {
  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 "> </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] "></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {" "}
                    {selectedDate && format(selectedDate, "dd") === "04" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]">
                                  4
                                </td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]"></td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  6
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            이창선 - 레진인레이
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {" "}
                    {/* {selectedDate && format(selectedDate, "dd") === "04" && (
                      <>
                        <div className="p-3  h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]">
                                  87654321
                                </td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2">
                                  12345678
                                </td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]">
                                  87654321
                                </td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  12345678
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            정호걸 - 치주소파술
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

const TableScenario12ExactCalenderView = ({ selectedDate }) => {
  useEffect(() => {
    console.log(selectedDate);
  }, [selectedDate]);
  return (
    <div className="border-[1px]  border-[#D4D4D8] rounded-lg overflow-scroll">
      <div className=" ">
        <table className=" ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="min-w-[77px]  text-left pl-3 border-r "></th>
              <th className="min-w-[220px]  text-left px-3 py-[9px] border-r">
                최원장
              </th>
              <th className="min-w-[220px]   text-left px-3 border-r">
                심원장
              </th>
              <th className="min-w-[220px]   text-left px-3 border-r">
                홍원장
              </th>
              <th className="min-w-[220px]   text-left px-3 border-r">
                이원장
              </th>

              <th className="min-w-[220px]   text-left px-3 border-r">
                박원장
              </th>
              <th className="min-w-[220px]   text-left px-3 border-r">
                심우현
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="rounded-b-lg"
        style={{ maxHeight: "834px", width: "100%" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r  h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r  h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] border-r h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] border-r  h-[111px]"></div>
                </div>
                {/* <div>
                  <div className="w-[220px] h-[111px]"></div>

                  <hr />
                  <div className="w-[240px] h-[111px]"></div>
                </div> */}
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3"> </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3 "> </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-b border-r border-t"></div>

                  <hr />
                  <div className="w-[215px] border-r h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"> </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-r border-t"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-r border-t"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] "></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-r  border-t"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {/* {" "}
                    {selectedDate && format(selectedDate, "dd") === "05" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]"></td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]"></td>
                                <td className="border-t border-l w-[63px] border-[#999] text-right">
                                  8
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            전상구 - 발치
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-r border-t"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  13시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-r border-t"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] border-r border-t h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] border-r h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  14시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  15시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  15시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  16시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {" "}
                    {selectedDate && format(selectedDate, "dd") === "05" && (
                      // <>
                      //   <div className="p-3  h-[111px]">
                      //     <div>
                      //       <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                      //         <tr className="h-[20px] text-center">
                      //           <td className="border-r w-[63px] border-[#999]"></td>
                      //           <td className="border-l w-[63px] border-[#999] text-right pr-2"></td>
                      //         </tr>
                      //         <tr className="h-[20px]">
                      //           <td className="border-t border-r w-[63px] border-[#999]"></td>
                      //           <td className="border-t border-l w-[63px] border-[#999] text-right">
                      //             8
                      //           </td>
                      //         </tr>
                      //       </table>
                      //     </div>
                      //     <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      //       이창선 - 구치부 레진
                      //     </div>
                      //   </div>
                      // </>
                      <></>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]">
                    {" "}
                    {selectedDate && format(selectedDate, "dd") === "05" && (
                      <>
                        <div className="p-3 bg-[#EBF5F2] h-[111px]">
                          <div>
                            <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                              <tr className="h-[20px] text-center">
                                <td className="border-r w-[63px] border-[#999]">
                                  87654321
                                </td>
                                <td className="border-l w-[63px] border-[#999] text-right pr-2">
                                  12345678
                                </td>
                              </tr>
                              <tr className="h-[20px]">
                                <td className="border-t border-r w-[63px] border-[#999]">
                                  87654321
                                </td>
                                <td className="border-t border-l w-[63px] border-[#999]">
                                  12345678
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                            정호걸 - 치주소파술
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  16시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  17시 00분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px] border-t border-r"></div>

                  <hr />
                  <div className="w-[215px] h-[111px] border-r"></div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

const TableExactScenarioS12 = () => {
  return (
    <div className=" border-[1px]  border-[#D4D4D8] rounded-lg overflow-hidden">
      <div className=" ">
        <table className="w-full  ">
          <thead className="">
            <tr className="bg-[#F1F8FA] ">
              <th className="w-[5.52%] text-left pl-3 border-r ">-</th>
              <th className="w-[15.74%]  text-left px-3 py-[9px] border-r">
                박원장
              </th>
              <th className="w-[15.74%]  text-left px-3 border-r">김원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">홍원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">이원장</th>

              <th className="w-[15.74%]  text-left px-3 border-r">최원장</th>
              <th className="w-[15.74%]  text-left px-3 border-r">-</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className="border-[1px] rounded-b-lg"
        style={{ maxHeight: "834px", overflow: "scroll" }}
      >
        <div className="w-full">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="">
                <div className="border-r w-[77px] flex items-center justify-center h-[223px] ">
                  9시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      김미백 - 미백
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  9시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            5
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      박덴트 - 치석제거
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px] p-3">
                    {" "}
                    <div>
                      <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal inline-block mr-2">
                        <tr className="h-[20px] text-center">
                          <td className="border-r w-[63px] border-[#999]"></td>
                          <td className="border-l w-[63px] border-[#999] text-right pr-2">
                            7
                          </td>
                        </tr>
                        <tr className="h-[20px]">
                          <td className="border-t border-r w-[63px] border-[#999]"></td>
                          <td className="border-t border-l w-[63px] border-[#999] text-right"></td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-[13px] leading-[19px] gap-[-0.325px] text-[#111]">
                      홍덴투 - 검진
                      <br />
                      (파노라마 추가)
                    </div>
                  </div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px] justify-center">
                  10시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  10시 30분
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row">
              <div>
                <div className="border-r w-[77px] flex items-center h-[223px]">
                  11시
                </div>
              </div>
              <div className="flex">
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[221px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[221px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[220px] border-r h-[111px]"></div>
                  <hr />
                  <div className="w-[220px] border-r h-[111px]"></div>
                </div>
                <div>
                  <div className="w-[215px] h-[111px]"></div>

                  <hr />
                  <div className="w-[215px] h-[111px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  Table,
  TableScenario6,
  TableScenario6CalenderView,
  TableExactScenario6,
  TableScenario6ExactCalenderView,
  TableExactScenario7,
  TableScenario7ExactCalenderView,
  TableExactScenario8,
  TableScenario8ExactCalenderView,
  TableExactScenario9,
  TableScenario9ExactCalenderView,
  TableExactScenarioS12,
  TableScenario12ExactCalenderView,
};
