import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/DataProvider";

function PreparingPatient({ setData }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const { tableData, setTableData, setPatientIsCompleted } =
    useContext(DataContext);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료준비
        </h3>
        <div className="border-[1px] h-full border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableData.patientList
                    .filter((item) => item.status === "진료준비")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() => {
                                  setPatientIsCompleted(data.id);
                                }}
                                className="px-[12px] py-[6px] border bg-[#FCF6D1] text-[#C29800] font-semibold rounded-[32px] border-none"
                              >
                                {data.status}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !== tableData.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PreparingPatientScenario6({ setData }) {
  const [tableData, setTableData] = useState({ patientList: [] });

  const dataToMap = [
    {
      id: 5,
      reservation: "11:00",
      chartNumber: "103000",
      nameAgeGender: "김선희(62/여)",
      doctor: "최윤호",
      status: "진료시작",
      action: "진료시작",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료준비
        </h3>
        <div className="border-[1px] h-full border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {dataToMap.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`text-center cursor-pointer ${
                          selectedRow === data
                            ? "bg-[#EBF5F2]"
                            : "hover:bg-[#EBF5F2]"
                        }`}
                        onClick={() => handleRowClick(data)}
                      >
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 예약시간 column in the table head */
                        >
                          {data.reservation}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "15%",
                          }} /* Match the width to the 차트번호 column in the table head */
                        >
                          {data.chartNumber}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 이름(나이/성별) column in the table head */
                        >
                          {data.nameAgeGender}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "20%",
                          }} /* Match the width to the 담당의사 column in the table head */
                        >
                          {data.doctor}
                        </td>
                        <td
                          className=" text-[13px] font-normal"
                          style={{
                            width: "25%",
                          }} /* Match the width to the 진료상태변경 column in the table head */
                        >
                          <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                            <button className="px-[12px] py-[6px] border bg-[#FCF6D1] text-[#C29800] font-semibold rounded-[32px] border-none">
                              {data.status}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {index !== tableData.length - 1 && (
                        <tr className="border-t "></tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function PreparingPatientScenario7({ setData }) {
  const {
    setPatientIsCompletedScenario7,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario7,
    setTableDataScenario7,
  } = useContext(DataContext);

  const dataToMap = [
    {
      id: 5,
      reservation: "11:00",
      chartNumber: "103000",
      nameAgeGender: "김선희(62/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료준비
        </h3>
        <div className="border-[1px] h-full border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario7.patientList
                    .filter((item) => item.status === "진료준비")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsCompletedScenario7(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FCF6D1] text-[#C29800] font-semibold rounded-[32px] border-none"
                              >
                                {data.status}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario7.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PreparingPatientScene6({ setData }) {
  const {
    setPatientIsCompletedScenario6,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario6,
    setTableDataScenario7,
  } = useContext(DataContext);

  const dataToMap = [
    {
      id: 5,
      reservation: "11:00",
      chartNumber: "103000",
      nameAgeGender: "김선희(62/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료준비
        </h3>
        <div className="border-[1px] h-full border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario6.patientList
                    .filter((item) => item.status === "진료준비")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsCompletedScenario6(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FCF6D1] text-[#C29800] font-semibold rounded-[32px] border-none"
                              >
                                {data.status}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario6.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function PreparingPatientScene9({ setData }) {
  const {
    setPatientIsCompletedScenario9,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario9,
    setTableDataScenario7,
  } = useContext(DataContext);

  const dataToMap = [
    {
      id: 5,
      reservation: "11:00",
      chartNumber: "103000",
      nameAgeGender: "김선희(62/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료준비
        </h3>
        <div className="border-[1px] h-full border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario9.patientList
                    .filter((item) => item.status === "진료준비")
                    .map(
                      (data, index) =>
                        ""
                        // <React.Fragment key={index}>
                        //   <tr
                        //     className={`text-center cursor-pointer ${
                        //       selectedRow === data
                        //         ? "hover:bg-[#EBF5F2]"
                        //         : "hover:bg-[#EBF5F2]"
                        //     }`}
                        //     onClick={() => handleRowClick(data)}
                        //   >
                        //     <td
                        //       className=" text-[13px] font-normal"
                        //       style={{
                        //         width: "15%",
                        //       }} /* Match the width to the 예약시간 column in the table head */
                        //     >
                        //       {data.reservation}
                        //     </td>
                        //     <td
                        //       className=" text-[13px] font-normal"
                        //       style={{
                        //         width: "15%",
                        //       }} /* Match the width to the 차트번호 column in the table head */
                        //     >
                        //       {data.chartNumber}
                        //     </td>
                        //     <td
                        //       className=" text-[13px] font-normal"
                        //       style={{
                        //         width: "25%",
                        //       }} /* Match the width to the 이름(나이/성별) column in the table head */
                        //     >
                        //       {data.nameAgeGender}
                        //     </td>
                        //     <td
                        //       className=" text-[13px] font-normal"
                        //       style={{
                        //         width: "20%",
                        //       }} /* Match the width to the 담당의사 column in the table head */
                        //     >
                        //       {data.doctor}
                        //     </td>
                        //     <td
                        //       className=" text-[13px] font-normal"
                        //       style={{
                        //         width: "25%",
                        //       }} /* Match the width to the 진료상태변경 column in the table head */
                        //     >
                        //       <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                        //         <button
                        //           onClick={() =>
                        //             setPatientIsCompletedScenario9(data.id)
                        //           }
                        //           className="px-[12px] py-[6px] border bg-[#FCF6D1] text-[#C29800] font-semibold rounded-[32px] border-none"
                        //         >
                        //           {data.status}
                        //         </button>
                        //       </div>
                        //     </td>
                        //   </tr>
                        //   {index !==
                        //     tableDataScenario9.patientList.length - 1 && (
                        //     <tr className="border-t "></tr>
                        //   )}
                        // </React.Fragment>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PreparingPatientScene8({ setData }) {
  const {
    setPatientIsCompletedScenario8,
    setPatientIsCompleted,
    setPatientIsProcessingScenario7,
    tableDataScenario8,
    setTableDataScenario7,
  } = useContext(DataContext);

  const dataToMap = [
    {
      id: 5,
      reservation: "11:00",
      chartNumber: "103000",
      nameAgeGender: "김선희(62/여)",
      doctor: "최윤호",
      status: "접수대기",
      action: "접수하기",
      nextReservation: "",
      totalMedicalExpense: "",
      patientContribution: "",
      paymentAmount: "",
      amountReceivable: "",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          진료준비
        </h3>
        <div className="border-[1px] h-full border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full  overflow-hidden ">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                minHeight: "260px",
                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full h-full">
                <tbody>
                  {tableDataScenario8.patientList
                    .filter((item) => item.status === "진료준비")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                onClick={() =>
                                  setPatientIsCompletedScenario8(data.id)
                                }
                                className="px-[12px] py-[6px] border bg-[#FCF6D1] text-[#C29800] font-semibold rounded-[32px] border-none"
                              >
                                {data.status}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !==
                          tableDataScenario8.patientList.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export {
  PreparingPatient,
  PreparingPatientScenario6,
  PreparingPatientScenario7,
  PreparingPatientScene6,
  PreparingPatientScene9,
  PreparingPatientScene8,
};
