import React, { useContext, useState } from "react";
import DataContext from "../../../context/DataProvider";

function WaitingList({ setData }) {
  const {
    tableData,
    setTableData,
    setPatientIsProcessing,
    tableDataScenario12,
  } = useContext(DataContext);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setData(rowData);
  };

  return (
    <div>
      <div className="table w-full">
        <h3 className="text-[16px] font-semibold mb-[16px] text-[#154D57]">
          접수대기
        </h3>
        <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
          <div>
            {/* Separate div for table head */}
            <div className="rounded-[8px]">
              <table className="rounded-lg text-sm bg-white w-full   overflow-hidden">
                <thead className="text-[#154D57]">
                  <tr className="">
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA] "
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      예약시간
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "15%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      차트번호
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "25%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      이름(나이/성별)
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                      style={{
                        width: "20%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      담당의사
                    </th>
                    <th
                      className="py-[14px] border-b border-gray-300 border-b-0 bg-[#F1F8FA] "
                      style={{
                        width: "24%",
                      }} /* Match the width to the 예약시간 column in the table head */
                    >
                      진료상태변경
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {/* Separate div for table body with max height and overflow */}
            <div
              style={{
                height: "260px",

                overflowY: "auto",
                borderRadius: "8px",
              }}
            >
              <table className="rounded-lg text-sm bg-white w-full">
                <tbody>
                  {tableDataScenario12.patientList
                    .filter((item) => item.status === "접수대기")
                    .map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`text-center cursor-pointer ${
                            selectedRow === data
                              ? "bg-[#EBF5F2]"
                              : "hover:bg-[#EBF5F2]"
                          }`}
                          onClick={() => handleRowClick(data)}
                        >
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            {data.reservation}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            {data.chartNumber}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 이름(나이/성별) column in the table head */
                          >
                            {data.nameAgeGender}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "20%",
                            }} /* Match the width to the 담당의사 column in the table head */
                          >
                            {data.doctor}
                          </td>
                          <td
                            className=" text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 진료상태변경 column in the table head */
                          >
                            <div className="items-center py-[6px] flex justify-start pl-[11px] flex justify-center">
                              <button
                                className="px-[12px] py-[6px] border bg-[#FFEBDE] text-[#DA5F1A] font-semibold rounded-[32px] border-none"
                                onClick={() => {
                                  setPatientIsProcessing(data.id);
                                }}
                              >
                                {data.action}
                              </button>
                            </div>
                          </td>
                        </tr>
                        {index !== tableData.length - 1 && (
                          <tr className="border-t "></tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaitingList;
