import React, { useState } from "react";
import FindIDAlert from "../../../../components/FIndIdAlertbox";

function StoreS12({ setOpenAlert, setModal }) {
  const data = [
    {
      id: 1,
      name: "세라믹이레",
      status: "완료",
      price: "200,000원",
      table: (
        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
          <tr className="h-[20px] text-center">
            <td className="border-r w-[63px]">4</td>
            <td className="border-l w-[63px]"></td>
          </tr>
          <tr className="h-[20px]">
            <td className="border-t border-r w-[63px]"></td>
            <td className="border-t border-l w-[63px]"></td>
          </tr>
        </table>
      ),
    },
    {
      id: 2,
      status: "완료",
      name: "세라믹이레",
      price: "200,000원",
      table: (
        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
          <tr className="h-[20px] text-center">
            <td className="border-r w-[63px]">3</td>
            <td className="border-l w-[63px]"></td>
          </tr>
          <tr className="h-[20px]">
            <td className="border-t border-r w-[63px]"></td>
            <td className="border-t border-l w-[63px]"></td>
          </tr>
        </table>
      ),
    },
    {
      id: 3,
      name: "치주소피술",
      status: "완료",
      price: "19,340원",
      table: (
        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
          <tr className="h-[20px] text-center">
            <td className="border-r w-[63px]"></td>
            <td className="border-l w-[63px]">4567</td>
          </tr>
          <tr className="h-[20px] text-center">
            <td className="border-t border-r w-[63px]"></td>
            <td className="border-t border-l w-[63px]">4567</td>
          </tr>
        </table>
      ),
    },
    {
      id: 4,
      status: "완료",
      name: "치석제거술",
      price: "60,000원",
      table: (
        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
          <tr className="h-[20px] text-center">
            <td className="border-r w-[63px]">1234567</td>
            <td className="border-l w-[63px]">7654321</td>
          </tr>
          <tr className="h-[20px] text-center">
            <td className="border-t border-r w-[63px]"></td>
            <td className="border-t border-l w-[63px]"></td>
          </tr>
        </table>
      ),
    },

    // Add more data as needed
  ];

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="modal z-[1]">
      <div className="modal-content p-[32px]">
        <div className="flex flex-row items-center justify-center   ">
          <div className="modal-title flex-1">다음 진료 입력</div>
          <div className="cursor-pointer" onClick={closeModal}>
            <img src="/close.png" alt="" />
          </div>
        </div>
        <div className="bg-[#FAFAFA]  w-full mt-[20px]  ">
          <div
            className="max-w-[836px] w-full mx-auto pt-4"
            style={{ maxHeight: "538px", overflow: "scroll" }}
          >
            <div className="max-w-[420px] w-full mx-auto">
              <div>
                <h2 className="text-[18px] font-semibold leading-[26px] tracking-[-0.45px] mb-4">
                  당일 수납 정보
                </h2>
                <div className="border-[1px] border-[#D4D4D8] rounded-lg ">
                  <table className="w-full">
                    <tbody>
                      <tr className="border-b border-gray-300 ">
                        <td className="px-3 py-2 bg-[#F1F8FA] w-[120px] font-semibold text-[13px] rounded-tl-[8px] leading-[19px] text-[#505050]">
                          급여
                        </td>
                        <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                          25,300
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                          비급여
                        </td>
                        <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                          -
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                          총진료비
                        </td>
                        <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                          -
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300 ">
                        <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050] ">
                          <td className="">
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              value=""
                              className="w-4 h-4 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </td>
                          <td>미수금</td>
                        </td>
                        <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                          -
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                          환자부담금
                        </td>
                        <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                          7,590
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] font-semibold text-[#505050]">
                          할인금액
                        </td>
                        <td className="text-[#111111] text-[13px] font-normal leading-[19px] flex justify-end py-[9px] px-3">
                          -
                        </td>
                      </tr>
                      <tr>
                        <td className="px-3 py-2 bg-[#F1F8FA] text-[13px] text-[#154D57] leading-[19px] tracking-[-0.325px] font-semibold rounded-bl-[8px] ">
                          수납할 금액
                        </td>
                        <td className="text-[#154D57] text-[15px] font-normal leading-[22px] flex justify-end font-semibold py-[9px] px-3">
                          7,590
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex mt-[13px] gap-2 radius-[8px]">
                  <button className="bg-[#DBEEF9] py-[9px] px-[28px] text-[#1778B0]  rounded-lg text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
                    카드수납
                  </button>
                  <button className="bg-[#D0E7DC] text-[#154D57] py-[9px] px-[27px] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
                    현금수납
                  </button>
                  <button className="bg-[#FFEBDE] text-[#DA5F1A] py-[9px] px-[27px] rounded-lg  text-[13px] font-semibold tracking-[-0.325px] leading-[19px]">
                    미수처리
                  </button>
                  <button className="bg-[#317580] text-[#FFFFFF] font-[600] py-[9px] px-[28px] rounded-lg  text-[13px]  tracking-[-0.325px] leading-[19px]">
                    예약하기
                  </button>
                </div>
              </div>

              <div className="mt-10">
                <div className="text-[18px] font-bold leading-[26px] tracking-[-0.45px] text-[#111] pb-4">
                  당일 치료 내용
                </div>
                <div
                  style={{ maxHeight: "206px", overflowY: "auto" }}
                  className="pr-[15px] py-[20px] pl-[20px] border-[1px] rounded-lg"
                >
                  <div className="pb-6 w-max ">
                    <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                      <div>
                        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                          <tr className="h-[20px] text-center">
                            <td className="border-r border-[#999]  w-[63px]"></td>
                            <td className="border-l border-[#999]  w-[63px]">
                              5
                            </td>
                          </tr>
                          <tr className="h-[20px]">
                            <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                            <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                          </tr>
                        </table>
                      </div>
                      <div className="flex flex-row w-full justify-between">
                        <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                          파노라마
                        </div>
                        <div className="text-[#1778B0] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                          완료
                        </div>
                        <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                          25,300원
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                      <div>
                        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                          <tr className="h-[20px] text-center">
                            <td className="border-r border-[#999]  w-[63px]">
                              7
                            </td>
                            <td className="border-l border-[#999]  w-[63px] text-right"></td>
                          </tr>
                          <tr className="h-[20px]">
                            <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                            <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                          </tr>
                        </table>
                      </div>
                      <div className="flex flex-row w-full justify-between">
                        <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                          치근단
                        </div>
                        <div className="text-[#C29800] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                          진행중
                        </div>
                        <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                          30,000원
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="flex flex-row gap-4 items-center  pb-[12px] pt-[12px]  border-b">
                      <div>
                        <table className="w-[127px] h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                          <tr className="h-[20px] text-center">
                            <td className="border-r border-[#999]  w-[63px]">
                              7
                            </td>
                            <td className="border-l border-[#999]  w-[63px] text-right"></td>
                          </tr>
                          <tr className="h-[20px]">
                            <td className="border-t border-[#999]  border-r border-[#999]  w-[63px]"></td>
                            <td className="border-t border-[#999]  border-l border-[#999]  w-[63px]"></td>
                          </tr>
                        </table>
                      </div>
                      <div className="flex flex-row w-full justify-between">
                        <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                          파노라마
                        </div>
                        <div className="text-[#C29800] text-[13px] font-bold leading-[19px] tracking-[-0.325px]">
                          진행중
                        </div>
                        <div className="text-[#111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                          50,000원
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-center ">
          <button className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
            취소
          </button>

          <button
            onClick={() => setOpenAlert(true)}
            className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default StoreS12;
