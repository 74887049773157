import React,{useState} from "react";
import RadioQuestion from "./radioComponent";
import SelectQuestion from "./selectComponent";
import Checkbox from "./checkboxComponent";

const Step4 = ({ data, setData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData((prev) => ({
      ...prev,
      step2: {
        ...prev.step2,
        [name]: value,
      },
    }));
  };

  const [checked, setChecked] = useState([]);

  // Define a function to handle checkbox changes
  const toggleCheckbox = (index) => {
    // Add or remove the index from the checked array based on your logic
    if (checked.includes(index)) {
      setChecked(checked.filter((item) => item !== index));
    } else {
      setChecked([...checked, index]);
    }
  };
  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex gap-[9px]">
        <div className="text-[#317580] font-['Pretendard'] text-[18px] font-[600] leading-[26px]">
          60%
        </div>
        <div className="flex items-center">
          <div className="relative w-[575px]  h-[8px] rounded-[8px] bg-[#D4D4D8]">
            <div className="absolute max-w-[345px] w-full h-[8px] mx-auto rounded-[8px] bg-[#317580]"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[48px]">
        <div className="flex flex-col gap-[40px]">
          <div className="gap-y-10 flex flex-col">
            <RadioQuestion
              handleChange={handleChange}
              name="question"
              id="question"
              question="전신질환이 있으신가요?  "
              answers={[
                "예",
                "아니요",
                
              ]}
            />
          </div>
          <div className="flex flex-col gap-[16px]">
          <div className="text-[111] font-[Pretendard] text-[16px] font-[600] leading-[-0.4px]">
          어떤 전신질환이 있으신가요?(복수선택가능)
          </div>

          <div className="flex flex-col font-['Pretendard'] gap-[12px]">
            <Checkbox
              answers={["고혈압"]}
              name="reason1"
              id="reason1"
              handleChange={() => toggleCheckbox(0)}
            />
            <Checkbox
              answers={["당뇨"]}
              name="reason1"
              id="reason1"
              handleChange={() => toggleCheckbox(0)}
            />
            <Checkbox
              answers={["고지혈증"]}
              name="reason1"
              id="reason1"
              handleChange={() => toggleCheckbox(0)}
            />
            <Checkbox
              answers={["심장질환"]}
              name="reason1"
              id="reason1"
              handleChange={() => toggleCheckbox(0)}
            />
            <Checkbox
              answers={["간질환(간염 등)"]}
              name="reason1"
              id="reason1"
              handleChange={() => toggleCheckbox(0)}
            />
           
            <Checkbox
              answers={["호흡기 질환(천식, 축농증, 결핵 등)"]}
              name="reason1"
              id="reason1"
              handleChange={() => toggleCheckbox(0)}
            />
            

           
            </div>
          </div>
          <div className="gap-y-10 flex flex-col">
            <RadioQuestion
              handleChange={handleChange}
              name="question2"
              id="question2"
              question="전신질환이 있으신가요?  "
              answers={[
                "예",
                "아니요",
                
              ]}
            />
          </div>
          </div>
          
        </div>
      </div>

  );
};

export default Step4;
