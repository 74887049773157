import { PreparingPatient } from "./PreparingPatient";
import Storage from "./Storage";
import TreatmentComplete from "./TreatmentComplete";
import { TreatmentList } from "./TreatmentList";
import WaitingList from "./WaitingList";

function EntirePatientList({ setData }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingList setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatient setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentList setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <Storage setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentComplete setData={setData} />
      </div>
    </div>
  );
}

export default EntirePatientList;
