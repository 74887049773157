import React, { useState } from "react";
import teeth from "../../../../app/Assets/images/s7teeth1.png";
import teeth1 from "../../../../app/Assets/images/s7teeth2.png.png";
import NextTreatmentPopup from "../../../components/NextTreatment";
import TreatmentModal from "../../DeskReservation/components/TreatmentModal";
import { Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import SuccessAlert from "../../../components/SuccessAlert";
import StoreAlertBoxSignUp from "../../../components/SuccessAlertSignup";
function AppMod1() {
  const [showModal, setshowModal] = useState(false);
  const navigator = useNavigate();
  const handleButtonClick = () => {
    setshowModal(true);
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <button
          className=" flex inline-flex w-max rounded-[8px] bg-[#FFEBDE] text-[#DA5F1A] text-[13px] font-[600] "
          onClick={handleButtonClick}
        >
          <div className="font-['pretendard'] text-[#DA5F1A] text-[13px] font-[600] leading-[19px] tracking-[-0.325px]">
            차팅
          </div>
        </button>
      </div>

      {showModal && (
        <StoreAlertBoxSignUp
          title="당일 진료차팅"
          subtitle="차팅이 완료되었습니다. 데스크 화면에서 확인하실 수 있습니다."
          onConfirm={() => {
            setshowModal(false);
            navigator("/doctor/scene7/receptionwaiting/7");
          }}
        />
      )}
    </div>
  );
}
function AppMod() {
  const [showModal, setshowModal] = useState(false);
  const [openModel, setOpenmodal] = useState(false);
  const [treatmentModel, setTreatmentmodal] = useState(false);

  const handleButtonClick = () => {
    setOpenmodal(true);
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <button
          className=" flex inline-flex py-2 px-4 rounded-[8px] bg-[#317580] text-[#FFF] text-[13px] font-[600] "
          onClick={handleButtonClick}
        >
          추가
        </button>
      </div>

      {openModel && (
        <TreatmentModal
          setIsModalOpen={setOpenmodal}
          treatmentModelOpen={openModel}
        />
      )}
    </div>
  );
}

function MyComponent() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </label>
      </div>
    </div>
  );
}

export default function ClinicTab7_6() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/doctor/receptionwaiting/6");
  };
  return (
    <>
      {/* <NavigationBar /> */}
      <div className="flex flex-col gap-[28px] ">
        <div className="flex  pt-[48px] px-[32px] gap-[28px] md:items-center justify-center">
          <div className="bg-white max-w-[326px] w-[100%] rounded-lg shadow-md  p-[20px]  h-full">
            <h2 className="text-[18px] leading-[26px] tracking-[-0.45px] text-[#111111] font-semibold mb-[17px]">
              환자정보
            </h2>
            <div class="w-full border-[1px] border-[#D4D4D8] rounded-lg">
              <table className="w-full">
                <tbody className="rounded-lg">
                  <tr className="border-b border-gray-300 ">
                    <td className="px-3 py-3 rounded-tl-[8px] bg-[#F1F8FA] w-[99px] font-semibold text-[13px] leading-[19px] tracking-[-0.325px] text-[#505050]">
                      이름(차트번호)
                    </td>
                    <td className="px-3 py-2">
                      <div className="text-[13px] font-normal leading-[19px] font-[pretendard] tracking-[-0.325px] text-[#111111]">
                        전상구(320010)
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <td className="px-3 py-3 bg-[#F1F8FA] font-semibold leading-[19px]  text-[13px] text-[#505050]">
                      생년월일/성별{" "}
                    </td>
                    <td className="px-3 py-2">
                      <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                        750408/남(48)
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <td className="px-3 py-3 rounded-bl-[8px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                      구분
                    </td>
                    <td className="px-3 py-[9px]">
                      <div className="text-[13px] font-normal leading-[19px] tracking-[-0.325px] text-[#111111]">
                        건강보험
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-3 py-3 rounded-bl-[8px] bg-[#F1F8FA] leading-[19px] font-semibold text-[13px] text-[#505050]">
                      특이사항
                    </td>
                    <td className="px-3 py-[9px]">
                      <div className="text-[13px] font-[pretendard] font-normal leading-[19px] font-[400] tracking-[-0.325px] text-[#111111]">
                        골다공증 약 복용중
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col p-[20px] bg-[#FFF] max-w-[326px] w-full h-auto gap-[16px] bg-white max-w-[326px] w-[100%] rounded-lg shadow-md  p-[20px]  ">
            <div className="font-['pretendard'] text-[18px] font-[600] leading-[26px] tracking-[-0.45px]">
              환자정보
            </div>
            <div className="table w-full">
              <div className="border-[1px] border-[#D4D4D8] rounded-lg  w-full">
                <div>
                  {/* Separate div for table head */}
                  <div className="">
                    <table className=" text-sm bg-white w-full">
                      <thead className="font-['pretendard'] text-[13px] font-[600] leading-[19px] tracking-[-0.325px]">
                        <tr className="text-[#505050]">
                          <th
                            className="py-[14px] border-b border-gray-300 border-b-0 rounded-l-[8px] bg-[#F1F8FA]"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            이름/성별/나이
                          </th>
                          <th
                            className="py-[14px]  border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            진료상태
                          </th>
                          <th
                            className="py-[14px]   border-b border-gray-300 border-b-0 bg-[#F1F8FA]"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            진료상태변경
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {/* Separate div for table body with max height and overflow */}
                  <div
                    style={{
                      maxHeight: "240px",
                      overflowY: "auto",
                      borderRadius: "8px",
                    }}
                  >
                    <table className=" text-sm bg-white w-full">
                      <tbody className=" font-['pretendard'] text-[12px] font-[400] leading-[19px] tracking-[-0.325px]">
                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            이혜빈/여/40
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#1778B0] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료완료
                          </td>
                          <td
                            className=" border justify-content items-center text-[#DA5F1A] font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            {/* <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              onClick={handleClick}
                            >
                              진료완료
                            </button> */}
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            오찬욱/남/61
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#1778B0] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료완료
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold border-none">
                            {/* <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              onClick={handleClick}
                            >
                              진료완료
                            </button> */}
                          </td>
                        </tr>
                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            김선희/여/62
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#1778B0] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료완료
                          </td>
                          <td className=" border justify-content items-center text-[#DA5F1A] font-semibold border-none">
                            {/* <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              onClick={handleClick}
                            >
                              진료완료
                            </button> */}
                          </td>
                        </tr>
                        <tr className="text-center cursor-pointer items-center bg-[#EBF5F2]">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            전상구/남/48
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#C29800] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료중
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#1778B0] font-semibold rounded-[32px] bg-[#DBEEF9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}
                            >
                              진료완료
                            </button>
                          </td>
                        </tr>
                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            이윤혁/남/19
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}
                            >
                              진료시작
                            </button>
                          </td>
                        </tr>
                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            정현주/여/42
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}
                            >
                              진료시작
                            </button>
                          </td>
                        </tr>
                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            정현주/여/42
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}/
                            >
                              진료시작
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            정현주/여/42
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}
                            >
                              진료시작
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            정현주/여/42
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}
                            >
                              진료시작
                            </button>
                          </td>
                        </tr>

                        <tr className="text-center cursor-pointer items-center">
                          <td
                            className="py-[9px] text-[13px] font-normal"
                            style={{
                              width: "25%",
                            }} /* Match the width to the 예약시간 column in the table head */
                          >
                            정현주/여/42
                          </td>
                          <td
                            className="py-[9px] text-[13px] text-[#DA5F1A] font-semibold font-normal"
                            style={{
                              width: "15%",
                            }} /* Match the width to the 차트번호 column in the table head */
                          >
                            진료준비
                          </td>
                          <td
                            className=" border justify-content items-center font-semibold border-none"
                            style={{ width: "25%" }}
                          >
                            <button
                              className="text-[13px] font-normal text-[#C29800] font-semibold rounded-[32px] bg-[#FAF6D9] px-[10px] items-center py-[6px] justify-center "
                              // onClick={handleClick}
                            >
                              진료시작
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[32px] md:items-center justify-center pb-[48px] gap-[28px]">
          <div className="flex flex-col relative pb-[48px] bg-white max-w-[680px] w-[100%] h-auto rounded-lg shadow-md  pt-[20px] px-[20px] gap-[16px] ">
            <div className="flex gap-[318px]">
              <div className="flex gap-[32px]">
                <div className="flex items-center ">
                  <div className="font-[600] font-semibold text-[18px] font-normal leading-[26px] font-[pretendard] tracking-[-0.45px] text-[#111]">
                    치료계획
                  </div>
                </div>
                <div className="inline-flex h-36px py-2 px-3 justify-center items-center gap-[21px] flex-shrink-0 rounded-[32px] bg-[#EAF6F3] font-[400] text-[13px] font-normal leading-[19px] font-[pretendard] tracking-[-0.325px] text-[#154D57]">
                  2023년 10월 31일(화)
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z"
                        fill="#317580"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="">
                <button className="absolute flex inline-flex py-2 px-4 justify-center items-center rounded-[8px] bg-[#317580] text-[#FFF] ">
                  추가
                </button>
              </div>
            </div>

            <div>
              <div classname="flex absolute ">
                <div className="flex flex-col gap-[16px]  max-w-[640px] w-full h-auto shrink-[0] rounded-[8px] border border-solid border-gray-300 rounded-8 p-[20px] pb-[210px]">
                  <div className="flex flex-col gap-[6px] ">
                    <div className="flex gap-[12px] pb-[16px] mb-[20px] border-b-[1px] border-[#F1F1F5]">
                      <div classname="">
                        <img
                          src={teeth}
                          alt="Teeth1"
                          className="w-[84px] h-[70px]"
                        />
                      </div>
                      <div>
                        <img
                          src={teeth1}
                          alt="Teeth2"
                          className="w-[84px] h-[70px]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full p-[20px] border-b-[1px] border-[#F1F1F5]">
                      <div className="flex justify-between w-full">
                        <div className="flex gap-[16px] items-center">
                          <div>
                            <table className=" h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                              <tr className="h-[20px] text-center">
                                <td className="border-r">
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp;
                                </td>
                                <td className="border-l">
                                  &nbsp; &nbsp; &nbsp; &nbsp; 7 &nbsp; &nbsp;
                                  &nbsp;
                                </td>
                              </tr>
                              <tr className="h-[20px] text-center">
                                <td className="border-t border-r">
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp;
                                </td>
                                <td className="border-t border-l">
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp;
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div classname="flex gap-[296px]">
                            <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              임플란트
                            </div>
                            {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              치근단
                            </div> */}
                          </div>
                        </div>
                        <div classname="flex gap-[296px]">
                          <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                            850,000원
                          </div>
                          <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            <div>메모</div>
                            <div>수정</div>
                            <div>삭제</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full p-[20px]  border-[#F1F1F5]">
                      <div className="flex justify-between w-full">
                        <div className="flex gap-[16px] items-center">
                          <div>
                            <table className="h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                              <tr className="h-[20px] text-center">
                                <td className="border-r ">
                                  8 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp;
                                </td>
                                <td className="border-l "></td>
                              </tr>
                              <tr className="h-[20px] text-center">
                                <td className="border-t border-r">
                                  8 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp;
                                </td>
                                <td className="border-t border-l">
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; 8
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div classname="flex gap-[296px]">
                            <div className="font-['pretendard'] w-max text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              발치
                            </div>
                            {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                              치근단
                            </div> */}
                          </div>
                        </div>
                        <div classname="flex gap-[296px]">
                          <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                            29,370원
                          </div>
                          <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                            <div>메모</div>
                            <div>수정</div>
                            <div>삭제</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <button className="inline-flex px-4 py-2 justify-center items-center rounded-[8px] bg-orange-50 max-w-[110px] w-full h-[36px]">
                <div className="font-['pretendard'] text-[#DA5F1A] text-[13px] font-[600] leading-[19px] tracking-[-0.325px]">
                  전체확인
                </div>
              </button>
            </div>
          </div>

          <div className="flex flex-col relative pb-[48px] bg-white max-w-[680px] w-[100%] h-auto rounded-lg shadow-md  pt-[20px] px-[20px] gap-[16px] ">
            <div className="flex gap-[448px]">
              <div className="flex items-center font-semibold font-[600] text-[18px] font-normal leading-[26px] font-[pretendard] tracking-[-0.45px] text-[#111]">
                치료 내용
              </div>

              <div className="flex justify-center items-center gap-[8px] ">
                <button className=" flex inline-flex py-2 px-4 rounded-[8px] bg-[#FFEBDE] text-[#DA5F1A] text-[13px] font-[600] ">
                  <AppMod1 />
                </button>
                <div className="flex justify-center items-center">
                  <AppMod />
                </div>
              </div>
            </div>

            <div>
              <div classname="flex absolute ">
                <div
                  style={{
                    maxHeight: "375px",
                    overflowY: "auto",
                    borderRadius: "8px",
                  }}
                >
                  <div className="flex flex-col gap-[16px] pb-[200px] max-w-[640px] w-full h-auto shrink-[0] rounded-[8px] border border-solid border-gray-300 rounded-8 p-[20px] ">
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px] items-center">
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676] pl-[30px]">
                            23.10.10
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px] items-center w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-[16px] items-center">
                              <div>
                                <table className=" h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r ">87654321</td>
                                    <td className="border-l">12345678</td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r">
                                      87654321
                                    </td>
                                    <td className="border-t border-l">
                                      12345678
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="font-['pretendard'] w-max text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치주소파술
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[12px]">
                                  <div className="font-['pretendard'] w-max text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    완료
                                  </div>
                                  <div>
                                    {/* <button className="inline-flex px-2 py-2 justify-center items-center rounded-[8px] bg-[#DBEEF9] max-w-[37px] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                33,150원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px] items-center">
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676] pl-[30px]">
                            23.10.17
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px] items-center w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-[16px] items-center">
                              <div>
                                <table className=" h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r ">87654321</td>
                                    <td className="border-l">12345678</td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r">
                                      87654321
                                    </td>
                                    <td className="border-t border-l">
                                      12345678
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="font-['pretendard'] w-max text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치주소파술
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[12px]">
                                  <div className="font-['pretendard'] w-max text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    완료
                                  </div>
                                  <div>
                                    {/* <button className="inline-flex px-2 py-2 justify-center items-center rounded-[8px] bg-[#DBEEF9] max-w-[37px] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                19,340원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px] items-center pl-[30px]">
                          {/* <input type="checkbox" name="" id="" /> */}
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676]">
                            23.10.24
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px] items-center w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-[16px] items-center">
                              <div>
                                <table className=" h-[41px] w-[110px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                    <td className="border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r ">
                                      &nbsp; &nbsp; 6 &nbsp; &nbsp; &nbsp;
                                      &nbsp;&nbsp;
                                    </td>
                                    <td className="border-t border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="w-[70px] font-['pretendard'] w-max text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    임플란트
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[12px]">
                                  <div className="font-['pretendard'] w-max text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    완료
                                  </div>
                                  <div>
                                    {/* <button className="inline-flex px-2 py-2 justify-center items-center rounded-[8px] bg-[#DBEEF9] max-w-[37px] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                850,000원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px] items-center">
                          <input type="checkbox" name="" id="" />
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676]">
                            23.10.31
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px] items-center w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-[16px] items-center">
                              <div>
                                <table className=" h-[41px] w-[110px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                    <td className="border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      7 &nbsp;
                                    </td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp;&nbsp;
                                    </td>
                                    <td className="border-t border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="w-[70px] font-['pretendard'] w-max text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    임플란트
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[16px] items-center">
                                  <div className="font-['pretendard'] w-max text-[#C29800] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    진행중
                                  </div>
                                  <div>
                                    <button className="inline-flex px-2 py-2 justify-center items-center rounded-[32px] bg-[#DBEEF9] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                850,000원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px] items-center">
                          <input type="checkbox" name="" id="" />
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676]">
                            23.10.31
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px] items-center w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-[16px] items-center">
                              <div>
                                <table className=" h-[41px] w-[110px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r ">
                                      8 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                    <td className="border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r ">
                                      8 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp;&nbsp;
                                    </td>
                                    <td className="border-t border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; 8
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="w-[70px] font-['pretendard'] w-max text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    발치발치
                                  </div>
                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[16px] items-center">
                                  <div className="font-['pretendard'] w-max text-[#DA5F1A] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                    진행전
                                  </div>
                                  <div>
                                    <button className="inline-flex px-2 py-2 justify-center items-center rounded-[32px] bg-[#FAF6D9] w-full h-[26px] font-['pretendard'] text-[#C29800] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      진행중
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                850,000원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                    <div className="flex flex-col gap-[16px] ">
                      <div className="flex gap-[20px] ">
                        <div className="flex gap-[16px] items-center">
                          <Input type="checkbox" name="" id="" />
                          <div className="font-['pretendard'] text-[12px] font-[400] leading-[18px] text-[#767676]">
                            23.10.31
                          </div>
                        </div>
                        <div className="flex flex-col gap-[16px] items-center w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex gap-[16px] items-center">
                              <div>
                                <table className=" h-[41px] w-[110px] text-[#154D57] text-[12px] leading-[18px] font-normal">
                                  <tr className="h-[20px] text-center">
                                    <td className="border-r ">
                                      &nbsp; &nbsp; &nbsp; 5 &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                    <td className="border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                  <tr className="h-[20px] text-center">
                                    <td className="border-t border-r ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp;&nbsp;
                                    </td>
                                    <td className="border-t border-l ">
                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                      &nbsp; &nbsp;
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="flex gap-[20px]">
                                <div classname="flex ">
                                  <div className="font-['pretendard'] w-max text-[12px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    완전도재크라운
                                  </div>

                                  {/* <div className="font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                    치근단
                                  </div> */}
                                </div>
                                <div className="flex gap-[12px]">
                                  <div className="flex gap-[20px] items-center">
                                    <div className="font-['pretendard'] w-max text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </div>
                                    <div>
                                      {/* <button onClick={handleClick} className="inline-flex px-2 py-2 justify-center items-center rounded-[32px] bg-[#DBEEF9] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button> */}
                                    </div>
                                  </div>
                                  <div>
                                    {/* <button className="inline-flex px-2 py-2 justify-center items-center rounded-[8px] bg-[#DBEEF9] max-w-[37px] w-full h-[26px] font-['pretendard'] text-[#1778B0] text-[12px] font-[600] leading-[18px] tracking-[-0.3px]">
                                      완료
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="font-['pretendard'] text-[13px] font-[400] leading-[19px] tracking-[-0.325px] pl-[30px]">
                                500,000원
                              </div>
                              <div className="flex gap-[8px] font-['pretendard'] text-[#767676] text-[13px] font-[400] leading-[19px] tracking-[-0.325px]">
                                <div>메모</div>
                                <div>수정</div>
                                <div>삭제</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-[#F1F1F5]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
