import React,{useState} from "react";
import Checkbox from "./checkboxComponent";


const Step2 = ({ data, setData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData((prev) => ({
      ...prev,
      step2: {
        ...prev.step2,
        [name]: value,
      },
    }));
  };

  const [checked, setChecked] = useState([]);

  // Define a function to handle checkbox changes
  const toggleCheckbox = (index) => {
    // Add or remove the index from the checked array based on your logic
    if (checked.includes(index)) {
      setChecked(checked.filter((item) => item !== index));
    } else {
      setChecked([...checked, index]);
    }
  };

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex gap-[9px]">
        <div className="text-[#317580] font-['Pretendard'] text-[18px] font-[600] leading-[26px]">
          20%
        </div>
        <div className="flex items-center">
        <div className="relative w-[575px]  h-[8px] rounded-[8px] bg-[#D4D4D8]">
          <div className="absolute max-w-[115px] w-full h-[8px] mx-auto rounded-[8px] bg-[#317580]">
            
          </div>
        </div>
        </div>
        
      </div>
      

      <div className="flex flex-col gap-[48px]">
          <div className="flex flex-col gap-[16px]">
            <div className="text-[111] font-[Pretendard] text-[16px] font-[600] leading-[-0.4px]">
              내원이유(복수선택가능)
            </div>

            <div className="flex flex-col font-['Pretendard'] gap-[12px]">
              <Checkbox
                answers={["충치가 있어요"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />  
              <Checkbox
                answers={["이가 아파요."]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["검진하고 싶어요"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["턱관절이 아파요"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["잇몸이 부었어요"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
                answers={["잇몸에서 피가 나요"]}
                name="reason1"
                id="reason1"
                handleChange={() => toggleCheckbox(0)}
              />
              <Checkbox
               answers={["입에서 냄새가 나요"]}
               name="reason1"
               id="reason1"
               handleChange={() => toggleCheckbox(0)}
              />
              <div className="flex gap-[8px] pt-[11px]">
                <div>
                  <Checkbox
                    answers={["기타"]}
                    name="reason1"
                    id="reason1"
                    handleChange={() => toggleCheckbox(0)}
                  />
                </div>

                <div className="font-[pretendard] text-[16px] p-[0px]">
                  <input
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: "1px solid #ccc",
                      outline: "none",
                      padding: "0px",
                      
                    }}
                    placeholder ="내용을 입력해주세요." 
                  />
                </div>
              </div>
            </div>
          </div>

          
          </div>
        </div>




    
  );
};

export default Step2;
