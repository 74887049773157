import React from "react";

const Step6 = () => {
  return (
    <div className="flex flex-col pt-[56px] pb-[589px]  w-full justify-center items-center">
      <div className="text-[#154D57] font-[Pretendard] text-[28px] font-[600] leading-[40px] tracking-[-0.7px]">
      작성이 완료되었습니다!
      </div>
      <div className="text-[#505050] font-[Pretendard] text-[16px] font-[400] leading-[24px] tracking-[-0.4px]">
      곧 접수가 완료될 예정입니다.
      </div>
    </div>
  );
};

export default Step6;
