import React, { useState } from "react";
import SuccessAlert from "../../components/SuccessAlert";
import FindIDAlert from "../../components/FIndIdAlertbox";
import { useNavigate } from "react-router-dom";
import StoreAlertBoxSignUp from "../../components/SuccessAlertSignup";

function FindID() {
  const [isActiveId, setIsActiveId] = useState(true);
  const [isActivePassword, setIsActivePassword] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobilePassword, setMobilePassword] = useState("");
  const [success, setSuccess] = useState(null);
  const [successPassword, setSuccessPassword] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertPassword, setShowAlertPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");

  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleConfirm = () => {
    // Perform the navigation action here
    navigate("/resetpassword");
    console.log("rendering any time"); // Replace "/targetPage" with your desired route
  };

  const handleButtonClick = () => {
    // Toggle the state to show/hide the SuccessAlert component
    setShowAlert(true);
  };

  const handleButtonClickPassword = () => {
    // Toggle the state to show/hide the SuccessAlert component
    setShowAlertPassword(true);
  };
  const handleItemClick = (item) => {
    if (item === "id") {
      setIsActiveId(true);
      setIsActivePassword(false);
    } else if (item === "password") {
      setIsActiveId(false);
      setIsActivePassword(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneNumberRegex = /^\d{11}$/;

    if (phoneNumberRegex.test(mobile)) {
      setSuccess(true);
      setErrorMessage("");
      setTimeout(() => {
        setSuccess(false);
      }, 10000);
    } else {
      setErrorMessage("휴대폰 번호를 정확하게 입력해주세요.");
      return;
    }
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();

    const phoneNumberRegex = /^\d{11}$/;

    if (phoneNumberRegex.test(mobilePassword)) {
      setSuccessPassword(true);
      setErrorMessagePassword("");
      setTimeout(() => {
        setSuccessPassword(false);
      }, 10000);
    } else {
      setErrorMessagePassword("휴대폰 번호를 정확하게 입력해주세요.");
      return;
    }
  };
  return (
    <div className="">
      <h1 className="flex justify-center text-[36px] leading-[48px] tracking-[-0.9px] text-[36px] font-semibold text-[#154D57] pb-[32px] pt-[120px]">
        아이디/비밀번호 찾기
      </h1>

      <div className="max-w-[556px] w-full mx-auto bg-[#FFFFFF] rounded-[8px] p-[48px]">
        <div className="flex flex-row justify-center pb-10">
          <div
            className={`text-[16px] font-normal leading-[24px] tracking-[-0.4px] text-center  cursor-pointer font-normal ${
              isActiveId
                ? " border-b-[3px] w-[230px] border-[#154D57] text-[#154D57] font-semibold "
                : "border-b-[1px] border-[#D4D4D8]  w-[230px]  text-[#767676]"
            }`}
            onClick={() => handleItemClick("id")}
          >
            <div className="pb-2">아이디 찾기</div>
          </div>

          <div
            className={`text-[16px] font-normal leading-[24px] tracking-[-0.4px] text-center  cursor-pointer font-normal ${
              isActivePassword
                ? " border-b-[3px] w-[230px] border-[#154D57] text-[#154D57] font-semibold "
                : "border-b-[1px] border-[#D4D4D8]  w-[230px]  text-[#767676]"
            }`}
            onClick={() => handleItemClick("password")}
          >
            <div className="pb-2">비밀번호 찾기</div>
          </div>
        </div>
        {isActiveId && (
          <div>
            <div className="flex justify-center flex-col ">
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  className="max-w-[460px] w-full flex flex-row "
                >
                  <input
                    placeholder="휴대폰 번호를 입력해주세요.(-제외)"
                    className=" mx-auto border-[#D4D4D8] max-w-[359px] w-full text-[#767676] mr-2 rounded-[8px] border-[1px] placeholder-[#767676]  focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4 mb-4"
                    type="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  <div>
                    <button
                      type="submit"
                      className=" text-[13px] leading-[19px] tracking-[-0.325px]  px-3 py-[20px] bg-[#EBF5F2] text-[#154D57] rounded-[8px]"
                    >
                      인증번호 받기
                    </button>
                  </div>
                  {success === true && (
                    <StoreAlertBoxSignUp
                      title="아이디/비밀번호 찾기"
                      subtitle="인증번호를 발송했습니다."
                    />
                  )}
                </form>
              </div>
              <input
                placeholder="인증번호 6자리 숫자 입력"
                className="max-w-[460px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]   focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4"
                type="password"
              />
              {errorMessage && (
                <div className="text-red-500 text-left pt-3">
                  {errorMessage}
                </div>
              )}
            </div>
            <div className="flex   flex-col">
              <div className="flex justify-center pt-[40px] ">
                <button
                  className="bg-[#317580] rounded-[8px] max-w-[460px] w-full py-4 flex justify-center text-[#FFFFFF]"
                  onClick={handleButtonClick}
                >
                  확인
                </button>
              </div>
              {showAlert && (
                <FindIDAlert
                  title="아이디/비밀번호 찾기"
                  subtitle="den2bio 입니다."
                  secondarybutton="로그인"
                  confirmbuttton="비밀번호 찾기"
                />
              )}
            </div>
          </div>
        )}

        {isActivePassword && (
          <div>
            <div className="flex justify-center flex-col ">
              <div className="">
                <form onSubmit={handleSubmitPassword}>
                  <input
                    placeholder="아이디를 입력해주세요."
                    className="max-w-[460px] mb-[24px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]   focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4"
                    type="text"
                  />
                  <input
                    placeholder="휴대폰 번호를 입력해주세요.(-제외)"
                    className="max-w-[359px] mr-2 w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]  focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4 mb-4"
                    type="text"
                    value={mobilePassword}
                    onChange={(e) => setMobilePassword(e.target.value)}
                  />
                  <button
                    type="submit"
                    className=" text-[13px] leading-[19px] tracking-[-0.325px] px-3 py-[20px] bg-[#EBF5F2] text-[#154D57] rounded-[8px]"
                  >
                    인증번호 받기
                  </button>
                  {successPassword === true && (
                    <StoreAlertBoxSignUp
                      title="계정찾기"
                      subtitle="계정이 존재하지 않습니다.."
                    />
                  )}
                </form>
              </div>
              <input
                placeholder="인증번호 6자리 숫자 입력"
                className="max-w-[460px] w-full mx-auto border-[#D4D4D8] text-[#767676] rounded-[8px] border-[1px] placeholder-[#767676]   focus:outline-none font-['Pretendard'] text-[16px] leading-[24px] tracking-[-0.4px] font-normal p-4"
                type="password"
              />
              {errorMessagePassword && (
                <div className="text-red-500 text-left pt-3">
                  {errorMessagePassword}
                </div>
              )}
            </div>
            <div className="flex   flex-col">
              <div className="flex justify-center pt-[40px] ">
                <button
                  className="bg-[#317580] rounded-[8px] max-w-[460px] w-full py-4 flex justify-center text-[#FFFFFF]"
                  onClick={handleButtonClickPassword}
                >
                  다음
                </button>
              </div>
              {showAlertPassword && (
                <StoreAlertBoxSignUp
                  title="계정찾기"
                  subtitle="인증번호를 발송했습니다."
                  onConfirm={handleConfirm}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FindID;
