import React, { useEffect, useState } from "react";
import CalenderDesk from "../../Pages/ReceptionWaiting/components/Calender";
import Calender from "../Calender";
import { format, startOfToday } from "date-fns";
import { Link } from "react-router-dom";

function PatientsHeader({
  activeButton,
  onButtonClick,
  setModalOpen,
  searchTerm,
  setSearchTerm,
}) {
  const [openCal, setOpenCal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-10-31"));
  const buttonData = [
    "전체",
    "접수대기",
    "진료준비",
    "진료중",
    "진료완료",
    "수납완료",
  ];
  useEffect(() => {
    if (currentDate.toDateString() !== new Date("2023-10-31").toDateString()) {
      document.body.setAttribute("data-visible", "true");
    } else {
      document.body.removeAttribute("data-visible");
    }
  }, [currentDate]);
  const openModal = () => {
    setModalOpen(true);
  };

  const openCaleneder = () => {
    setOpenCal(true);
  };

  return (
    <div>
      <div className=" pb-[20px] w-full">
        <div className="flex flex-row items-center pb-[37px]  w-full">
          <div className="flex flex-row items-center w-full">
            <div className="text-[18px] text-[#111] font-bold tracking-[-0.45px] leading-[26px] pr-[32px]">
              당일 환자 리스트
            </div>
            <div className=" text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
              <div style={{ position: "relative" }}>
                <div
                  onClick={openCaleneder}
                  className=" bg-[#EAF6F3] w-max-content max-h-[36px] flex flex-row px-3 py-2 rounded-[32px] gap-[11px] w-full items-center cursor-pointer"
                >
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.333 6L7.33301 10L11.333 14"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <span className="text-[#154D57] text-[13px] leading-[19px] font-normal tracking-[-0.325px]">
                    {`${format(currentDate, "yyyy")}년 ${format(
                      currentDate,
                      "MM"
                    )}월  ${format(currentDate, "dd")}일(월)`}
                  </span>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66699 14L12.667 10L8.66699 6"
                        stroke="#317580"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </div>

                {/* Add your SVG icon here */}
              </div>
              <div className="absolute z-[0] bg-white">
                {openCal === true && (
                  <Calender
                    setCalendarState={setOpenCal}
                    onDateSelect={setCurrentDate}
                    buttonVisible={true}
                  />
                )}
              </div>
            </div>

            <div>
              {activeButton !== "전체" && (
                <div class="flex justify-center items-center px-2">
                  <form class="max-w-[200px] w-full ">
                    <div class="relative flex  border rounded-full">
                      <input
                        type=""
                        name=""
                        value={searchTerm}
                        class="w-full  h-[36px] rounded-full p-4 border-0 outline-none"
                        placeholder="search"
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                      <button type="submit" class="ml-2 px-3">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M13.2148 13.2148L17.5004 17.5004"
                            stroke="#317580"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-2 w-full justify-end">
            {activeButton !== "전체" && activeButton !== "접수대기" && (
              <button className="px-[18px] py-[9px] text-[#DA5F1A] border-[#DA5F1A] border-[1px] rounded-[8px] font-semibold text-[13px] leading-[19px] tracking-[-0.325px]">
                진료중취소
              </button>
            )}
            <Link to="/Questionnaire">
              <button className="px-[18px] py-[9px] text-[#1778B0] bg-[#DBEEF9] rounded-[8px] font-semibold text-[13px] leading-[19px] tracking-[-0.325px]">
                문진표작성
              </button>
            </Link>
            <Link to={"/doctor/scenario10"}>
              <button
                onClick={() => openModal(true)}
                className="px-[23px] py-[9px] text-[#154D57] bg-[#D0E7DC] rounded-[8px] font-semibold text-[13px] leading-[19px] tracking-[-0.325px"
              >
                구환추가
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          {buttonData.map((buttonLabel, index) => (
            <button
              key={index}
              className={`${
                activeButton === buttonLabel
                  ? "bg-[#317580] text-[#FFFFFF] border-none "
                  : "bg-[#FFFFFF] text-[#505050] border-[#D4D4D8]"
              } px-4 py-[13px] rounded-[32px] text-[14px] border-[1px] font-semibold leading-[20px] tracking-[-0.35px]`}
              onClick={() => onButtonClick(buttonLabel)}
            >
              {buttonLabel}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PatientsHeader;
