import React, { useContext, useEffect, useRef, useState } from "react";
// Import the Datepicker and DateRangePicker modules
import Datepicker from "flowbite-datepicker/Datepicker";
import DateRangePicker from "flowbite-datepicker/DateRangePicker";
import { Input, Timepicker, initTE } from "tw-elements";
import CalenderDesk from "../../components/Calender";
import SuccessAlert from "../../../../components/SuccessAlert";
import Calender from "../../../../components/Calender";
import TimePicker from "../../../../components/TimePicker";
import DataContext from "../../../../context/DataProvider";
import { parse, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import StoreAlertBoxSignUp from "../../../../components/SuccessAlertSignup";

initTE({ Input, Timepicker });

function AddoldMoney({ setAddmoneyModal, setActiveButton, scenario }) {
  const { tableDataScenario10, setTableData, setTableDataScenario10 } =
    useContext(DataContext);
  const datePickerRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [calendarVisible, setCalendarVisible] = useState(false);

  function parseBackTimeString(inputString) {
    const date = new Date(inputString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString(); // Remove leading zero
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return [[formattedHours, formattedMinutes], ampm];
  }

  useEffect(() => {
    console.log(selectedTime);
  }, [selectedTime]);

  const OpenModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setActiveButton("접수대기");
    localStorage.setItem("completeScenario10", "true");
    setAddmoneyModal(false);
    if (scenario === 10) {
      setTableDataScenario10((prev) => ({
        patientList: [
          ...prev.patientList,
          {
            id: 27,
            reservation: "17:30",
            chartNumber: "089570",
            nameAgeGender: "홍지형(50/여)",
            dobAgeGender: "089570/여(50)",
            doctor: "한영희",
            status: "접수대기",
            action: "접수하기",
            nextReservation: "",
            totalMedicalExpense: "",
            patientContribution: "",
            paymentAmount: "",
            amountReceivable: "",
          },
        ],
      }));
    }
  };

  useEffect(() => {
    const datepickerEl = datePickerRef.current;
    if (datepickerEl) {
      new Datepicker(datepickerEl, {
        // options for Datepicker
      });
    }
    console.log(
      tableDataScenario10.patientList.flatMap((val, index) => ({
        id: index,
        name: val.nameAgeGender.split("(")[0] + "(" + val.chartNumber + ")",
      }))
    );
  }, []); // The empty dependency array ensures this effect runs only once.

  const handleDateSelect = (date) => {
    console.log("Selected date:", date); // Log the selected date
    setSelectedDate(date);
    setCalendarVisible(false); // Close the calendar after selecting a date
  };
  const [value, setValue] = useState("전상구(750408)");
  return (
    <div>
      {" "}
      <div className="modal">
        <div className="modal-content p-[32px]">
          <div className="">
            {" "}
            <div className="flex flex-row items-center justify-center   ">
              <div className="modal-title flex-1">구환추가</div>
              <div className="cursor-pointer">
                <img
                  src="/close.png"
                  alt=""
                  onClick={() => setAddmoneyModal(false)}
                />
              </div>
            </div>
            <div
              className="bg-[#FAFAFA] px-8 w-full mt-[20px]"
              style={{ maxHeight: "488px", overflow: "scroll" }}
            >
              <div className="max-w-[596px] w-full mx-auto pt-4 ">
                <div className="flex flex-col  gap-8">
                  <div className="gap-3 flex flex-col">
                    <div className="text-[#111111] text-[13px] font-semibold leading-[19px] tracking-[-0.325px]">
                      고객이름{" "}
                    </div>
                    <div className="text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                      <div class="relative">
                        <SearchableDropdown
                          options={[
                            ...tableDataScenario10.patientList.flatMap(
                              (val, index) => ({
                                id: index,
                                name:
                                  val.nameAgeGender.split("(")[0] +
                                  "(" +
                                  val.chartNumber +
                                  ")",
                              })
                            ),
                            { id: 100, name: "홍지형(731010)" },
                          ]}
                          label="name"
                          id="id"
                          className={
                            "border-[1px] border-[#D4D4D8] bg-[#FFF] focus:outline-none text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full   :outline-none placeholder-[#111111]"
                          }
                          selectedVal={value}
                          handleChange={(val) => setValue(val)}
                        />
                        {/* <input
                          id="helper-text"
                          aria-describedby="helper-text-explanation"
                          class="border-[1px] border-[#D4D4D8] bg-[#FFF] focus:outline-none text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full py-2 px-3 focus:outline-none placeholder-[#111111]"
                          placeholder="박덴탈(951010)"
                        /> */}
                        <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.63633 2.5C7.42268 2.5 6.23628 2.85989 5.22717 3.53416C4.21806 4.20843 3.43155 5.16679 2.9671 6.28806C2.50266 7.40932 2.38114 8.64314 2.61791 9.83347C2.85468 11.0238 3.43911 12.1172 4.29729 12.9754C5.15547 13.8335 6.24886 14.418 7.43919 14.6547C8.62952 14.8915 9.86334 14.77 10.9846 14.3056C12.1059 13.8411 13.0642 13.0546 13.7385 12.0455C14.4128 11.0364 14.7727 9.84998 14.7727 8.63633C14.7726 7.0089 14.126 5.44817 12.9753 4.2974C11.8245 3.14664 10.2638 2.5001 8.63633 2.5Z"
                              stroke="#317580"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M13.2148 13.2148L17.5004 17.5004"
                              stroke="#317580"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap-3 flex flex-col">
                    <div className="text-[#111111] text-[13px] font-semibold leading-[19px] tracking-[-0.325px]">
                      의사이름
                    </div>
                    <div className="text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px] ">
                      <select
                        id="doctor"
                        class="  border-[1px] border-[#D4D4D8]  focus:outline-none bg-[#FFF] text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full py-2 px-3 focus:outline-none placeholder-[#111111]"
                      >
                        <option selected> 김원장</option>
                        <option value="US">박원장</option>
                        <option value="CA">이원장</option>
                        <option value="FR">박원장</option>
                        <option value="DE">이원장</option>
                        <option value="DS">심우현</option>
                      </select>
                    </div>
                  </div>
                  <div className="gap-3 flex flex-col">
                    <div className="text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px] relative">
                      <div className="text-[#111111] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] mb-3">
                        접수날짜
                      </div>

                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          placeholder="Select a date"
                          className="border-[1px] border-[#D4D4D8] bg-[#FFF] text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full py-2 px-3 focus:outline-none placeholder-[#111111]"
                          value={
                            selectedDate
                              ? format(selectedDate, "yyyy.MM.dd")
                              : ""
                          }
                          onClick={() => setCalendarVisible(true)}
                        />

                        {/* Add your SVG icon here */}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <path
                            d="M16.25 2.5H14.375V1.875C14.375 1.70924 14.3092 1.55027 14.1919 1.43306C14.0747 1.31585 13.9158 1.25 13.75 1.25C13.5842 1.25 13.4253 1.31585 13.3081 1.43306C13.1908 1.55027 13.125 1.70924 13.125 1.875V2.5H6.875V1.875C6.875 1.70924 6.80915 1.55027 6.69194 1.43306C6.57473 1.31585 6.41576 1.25 6.25 1.25C6.08424 1.25 5.92527 1.31585 5.80806 1.43306C5.69085 1.55027 5.625 1.70924 5.625 1.875V2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM5.625 3.75V4.375C5.625 4.54076 5.69085 4.69973 5.80806 4.81694C5.92527 4.93415 6.08424 5 6.25 5C6.41576 5 6.57473 4.93415 6.69194 4.81694C6.80915 4.69973 6.875 4.54076 6.875 4.375V3.75H13.125V4.375C13.125 4.54076 13.1908 4.69973 13.3081 4.81694C13.4253 4.93415 13.5842 5 13.75 5C13.9158 5 14.0747 4.93415 14.1919 4.81694C14.3092 4.69973 14.375 4.54076 14.375 4.375V3.75H16.25V6.25H3.75V3.75H5.625ZM16.25 16.25H3.75V7.5H16.25V16.25Z"
                            fill="#317580"
                          />
                        </svg>
                      </div>
                      {calendarVisible && (
                        <div className="absolute z-[9999] bg-white top-[100%]">
                          <Calender
                            onDateSelect={setSelectedDate}
                            setCalendarState={setCalendarVisible}
                            selectedDate={selectedDate}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="gap-3 flex flex-col"
                    style={{ position: "relative" }}
                  >
                    <div className="text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                      <div className="text-[#111111] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] mb-3">
                        접수날짜
                      </div>

                      <div style={{ position: "relative" }}>
                        <input
                          onClick={() => setIsTimeModalOpen(true)}
                          type="text"
                          value={
                            selectedTime !== ""
                              ? format(selectedTime, "HH:mm")
                              : ""
                          }
                          placeholder="Select a time"
                          className="border-[1px] border-[#D4D4D8] bg-[#FFF] text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full py-2 px-3 focus:outline-none placeholder-[#111111]"
                        />

                        {/* Add your SVG icon here */}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <path
                            d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
                            fill="#317580"
                          />
                          <path
                            d="M13.4121 12.4727L10.627 10.459V5.625C10.627 5.53906 10.5566 5.46875 10.4707 5.46875H9.53125C9.44531 5.46875 9.375 5.53906 9.375 5.625V11.0039C9.375 11.0547 9.39844 11.1016 9.43945 11.1309L12.6699 13.4863C12.7402 13.5371 12.8379 13.5215 12.8887 13.4531L13.4473 12.6914C13.498 12.6191 13.4824 12.5215 13.4121 12.4727Z"
                            fill="#317580"
                          />
                        </svg>
                      </div>
                      {/* {calendarVisible && (
                        <div className="z-[9999]">
                          <CalenderDesk
                            setOnDay={selectedDate}
                            onDateSelect={handleDateSelect}
                          />
                        </div>  
                      )} */}
                    </div>

                    {isTimeModalOpen && (
                      <TimePicker
                        onTimeChange={setSelectedTime}
                        value={selectedTime}
                        isVisible={isTimeModalOpen}
                        onCancel={() => setIsTimeModalOpen(false)}
                      />
                    )}
                  </div>

                  <div className="gap-3 flex flex-col">
                    <div className="text-[#111111] text-[13px] font-semibold leading-[19px] tracking-[-0.325px]">
                      내원이유(CC)
                    </div>
                    <div className="text-[#111111] text-[13px] font-normal leading-[19px] tracking-[-0.325px]">
                      <div class="relative w-full">
                        <input
                          type="text"
                          class="h-[143px]  border-[1px] placeholder-[#767676] border-[#D4D4D8] bg-[#FFF] text-[13px] font-normal leading-[19px] tracking-[-0.325px] rounded-lg block w-full py-2 px-3 focus:outline-none placeholder-[#111111]"
                          placeholder="내원이유를 입력해주세요."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 justify-center pt-[20px]">
            <button className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
              취소
            </button>

            <button
              className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]"
              onClick={OpenModal}
            >
              확인
            </button>
          </div>
          {modalOpen === true && (
            <StoreAlertBoxSignUp
              title="구환추가"
              subtitle="홍지형님 접수 완료되었습니다."
              onConfirm={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AddoldMoney;
