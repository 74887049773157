import React, { useEffect, useState } from "react";
import frame from "../../Assets/images/Frame.png";
import user from "../../Assets/images/user.png";
import Group from "../../Assets/images/Group.png";
import tooth from "../../Assets/images/tooth.png";
import denture1 from "../../Assets/images/denture1.png";
import paper from "../../Assets/images/paper.png";
import target from "../../Assets/images/target.png";
import dental from "../../Assets/images/dental.png";
import chat from "../../Assets/images/chat.png";
import shield from "../../Assets/images/shield.png";
import barchart from "../../Assets/images/barchart.png";
import file from "../../Assets/images/file.png";
import { useContext } from "react";
import DataContext from "../../context/DataProvider";
import { Link } from "react-router-dom";
import "./index.css";
function LoggedHeader({ onIconSelect }) {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const { isHome, setIsHome } = useContext(DataContext);
  const headerIcons = [
    {
      icon: frame,
      title: "전자차트",
      onClick: () => {
        handleIconClick(frame, "전자차트");
        setIsHome(true);
      },
    },
    {
      icon: user,
      title: "데스크",
      onClick: () => {
        handleIconClick(user, "데스크");
        setIsHome(false);
      },
    },
    {
      icon: Group,
      title: "예약",
      onClick: () => {
        // handleIconClick(Group, "예약");
        // setIsHome(false);
        // window.localStorage.setItem("scenario", 5);
        localStorage.setItem("completed", "false");
      },
    },
    {
      icon: file,
      title: "환자상담",
      onClick: () => {
        // handleIconClick(file, "환자상담");
        // setIsHome(false);
        window.localStorage.setItem("scenario", 9);
        localStorage.setItem("completed", "false");
      },
    },
    {
      icon: tooth,
      title: "사진보기",
      onClick: () => {
        // handleIconClick(tooth,  "사진보기");
        // setIsHome(false);
        window.localStorage.setItem("scenario", 10);
        localStorage.setItem("completed", "false");
      },
    },
    {
      icon: denture1,
      title: "X-ray",
      class: "disabled",
      onClick: () => {
        // handleIconClick(denture1, "X-ray");
        //   setIsHome(false);

        window.localStorage.setItem("scenario", 8);
        localStorage.setItem("completed", "false");
      },
    },
    {
      icon: paper,
      title: "문서발급",
      class: "diabled",
      onClick: () => {
        // handleIconClick(paper, "문서발급");
        //   setIsHome(false);
        window.localStorage.setItem("scenario", 9);
        localStorage.setItem("completed", "false");
      },
    },
    {
      icon: target,
      title: "고객관리",
      class: "diabled",
      onClick: () => {
        // handleIconClick(target, "고객관리");
        //   setIsHome(false);
        window.localStorage.setItem("scenario", 10);
        localStorage.setItem("completed", "false");
      },
    },
    {
      icon: dental,
      title: "구강검진",
      class: "diabled",
      onClick: () => {
        // handleIconClick(dental,"구강검진" );
        //   setIsHome(false);
      },
    },
    {
      icon: chat,
      title: "메신저",
      class: "diabled",
      onClick: () => {
        // handleIconClick(chat, "메신저");
        //   setIsHome(false);

        localStorage.setItem("completed", "true");
      },
    },
    {
      icon: shield,
      title: "청구/EDI",
      class: "diabled",
      onClick: () => {
        // handleIconClick(shield, "청구/EDI");
        //   setIsHome(false);
      },
    },
    {
      icon: barchart,
      title: "경영/통계",
      class: "diabled click",
      onClick: () => {
        // Clear session storage
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
      },
    },
  ];

  const handleIconClick = (icon, title) => {
    setSelectedIcon(icon);
    // onIconSelect(icon, title);
  };

  useEffect(() => {
    setSelectedIcon(user);
    setIsHome(false);
  }, []);
  const getLink = (title) => {
    switch (title) {
      case "예약":
        // return "/doctor/receptionwaitingDesk";
        return "/scenario12";
      case "환자상담":
        return "/doctor/scene9/receptionwaiting";
      case "사진보기":
        return "/doctor/scenario10";
      case "X-ray":
        return "/doctor/scene8/receptionwaiting";
      case "문서발급":
        return "/doctor/scene9/receptionwaiting";
      case "고객관리":
        return "/doctor/scenario10";

      case "구강검진":
        return "/scenario12";
      case "메신저":
        return "/scenario122";
    }
  };

  const logout = () => {
    window.location.replace("/");
    localStorage.clear();
  };
  return (
    <div className="w-full" key={window.location.pathname}>
      <header className="bg-[#154D57]  mx-auto flex justify-between 2xl:flex-row flex-col items-center px-8">
        <div className="2xl:hidden flex  justify-between items-center w-full">
          <div className="text-white text-[28px] leading-[48px] font-semibold items-center mr-[28px] items-center flex">
            DEN2BIO
          </div>
          <div className="text-white text-xs">
            환경설정 <span className="ml-4 mr-4">|</span>{" "}
            <span className="text-white">로그아웃</span>
          </div>
        </div>

        <div className="text-white flex flex-wrap ">
          <div className="hidden 2xl:flex text-white text-[28px] leading-[48px] font-semibold items-center mr-[28px] items-center flex">
            DEN2BIO
          </div>

          {headerIcons.map((iconObj, index) => (
            <Link to={getLink(iconObj.title)} state={true}>
              <div
                key={index}
                className={`flex flex items-center justify-center ${
                  iconObj?.class
                } ${selectedIcon === iconObj.icon ? "bg-[#003840]" : ""}`}
                onClick={iconObj.onClick}
                style={
                  selectedIcon === iconObj.icon
                    ? { width: "122px", height: "100px" }
                    : {}
                }
              >
                <div className="w-[92px]  py-[20px] flex-wrap">
                  <div className="hover:text-blue-300 flex justify-center">
                    <img
                      src={iconObj.icon}
                      alt={iconObj.title}
                      width="32"
                      height="32"
                    />
                  </div>
                  <div className="text-[16px] leading-[24px] text-center mt-1">
                    {iconObj.title}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="text-white text-xs hidden 2xl:flex">
          환경설정 <span className="ml-4 mr-4">|</span>{" "}
          <button onClick={logout}>로그아웃</button>
        </div>
      </header>
    </div>
  );
}

export default LoggedHeader;
