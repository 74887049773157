import React, { useEffect, useState } from "react";
import "./index.css";
import { useRef } from "react";
function TimePicker({ onTimeChange, value, isVisible, onCancel }) {
  const [selectedTime, setSelectedTime] = useState();
  const [ampm, setAmpm] = useState("AM");
  const [relativePosition, setRelativePosition] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const timeItems = [
    ["", ""],
    ["12", "00"],
    ["12", "30"],
    ["1", "00"],
    ["1", "30"],
    ["2", "00"],
    ["2", "30"],
    ["3", "00"],
    ["3", "30"],
    ["4", "00"],
    ["4", "30"],
    ["5", "00"],
    ["5", "30"],
    ["6", "00"],
    ["6", "30"],
    ["7", "00"],
    ["7", "30"],
    ["8", "00"],
    ["8", "30"],
    ["9", "00"],
    ["9", "30"],
    ["10", "00"],
    ["10", "30"],
    ["11", "00"],
    ["11", "30"],
    ["", ""],
  ];
  const digitContainer = useRef(null);
  // useEffect(() => {
  //   onTimeChange(selectedTime);
  // }, [selectedTime]);
  function parseTimeString(timeString) {
    const timeParts = timeString.match(/(\d+):(\d+)([APap][Mm])/);
    if (timeParts) {
      let hours = parseInt(timeParts[1], 10);
      const minutes = parseInt(timeParts[2], 10);
      const ampm = timeParts[3].toLowerCase();

      if (ampm === "pm" && hours !== 12) {
        hours += 12;
      } else if (ampm === "am" && hours === 12) {
        hours = 0;
      }

      const date = new Date();
      date.setHours(hours, minutes, 0, 0);

      const formattedHours = hours.toString(); // Remove leading zero

      return date;
    } else {
      // Handle invalid input
      return null;
    }
  }

  function parseBackTimeString(inputString) {
    const date = new Date(inputString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString(); // Remove leading zero
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return [[formattedHours, formattedMinutes], ampm];
  }

  function areArraysEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }

  function handleMouseDown(e) {
    setIsDragging(true);
    setStartScrollTop(digitContainer?.current?.scrollTop);
    setRelativePosition(
      e.clientY - digitContainer.current.getBoundingClientRect().top
    );
    digitContainer.current.style.cursor = "grabbing";
  }

  useEffect(() => {
    function handleMouseUp() {
      digitContainer.current.style.cursor = "pointer";
      setIsDragging(false);
    }
    document.addEventListener("mouseup", handleMouseUp);
    function handleMouseMove(e) {
      let whereAmI =
        e.clientY - digitContainer.current.getBoundingClientRect().top;
      let deltaY =
        relativePosition -
        (e.clientY - digitContainer.current.getBoundingClientRect().top);
      if (isDragging) {
        if (whereAmI >= 0 && whereAmI <= 156) {
          digitContainer.current.scrollTop = startScrollTop + deltaY;
        }
      }
    }

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    const [dataTime, dataAMPM] = parseBackTimeString(value);
    setAmpm(dataAMPM);
    const dataIndex = timeItems.findIndex((item) =>
      areArraysEqual(item, dataTime)
    );
    digitContainer.current.scrollTop = dataIndex * 52 - 52;
  }, [value]);

  useEffect(() => {
    const items = digitContainer.current.children;
    for (let index = 0; index < items.length; index++) {
      const childRect = items[index].getBoundingClientRect();
      const parentRect = digitContainer.current.getBoundingClientRect();

      const relativeTop = childRect.top - parentRect.top;
      console.log(childRect.top - parentRect.top);
      if (relativeTop > 50 && relativeTop < 54) {
        items[index].classList.add("active");
        setSelectedTime(
          parseTimeString(
            `${timeItems[items[index].getAttribute("data-key")][0]}:${
              timeItems[items[index].getAttribute("data-key")][1]
            }${ampm}`
          )
        );
      } else {
        items[index].classList.remove("active");
      }
    }
    function handleScroll(e) {
      // console.log(e.target.scrollTop)
      const items = e.target.children;

      for (let index = 0; index < items.length; index++) {
        const childRect = items[index].getBoundingClientRect();
        const parentRect = e.target.getBoundingClientRect();

        const relativeTop = childRect.top - parentRect.top;
        console.log(childRect.top - parentRect.top, "relativetop");

        if (relativeTop > 50 && relativeTop < 54) {
          items[index].classList.add("active");
          //   console.log(
          //     `${timeItems[items[index].getAttribute("data-key")][0]}:${
          //       timeItems[items[index].getAttribute("data-key")][1]
          //     }${ampm}`
          //   );

          setSelectedTime(
            parseTimeString(
              `${timeItems[items[index].getAttribute("data-key")][0]}:${
                timeItems[items[index].getAttribute("data-key")][1]
              }${ampm}`
            )
          );
        } else {
          items[index].classList.remove("active");
        }
      }
    }
    digitContainer?.current?.addEventListener("scroll", handleScroll);

    return () =>
      digitContainer?.current?.removeEventListener("scroll", handleScroll);
  }, [ampm]);

  return (
    <div className="bg-white max-w-[419px] w-full flex flex-col p-8 justify-center items-center gap-8 time-overlay">
      <div className="time-container">
        <div className="am-pm-container">
          <div></div>
          <div
            onClick={() => setAmpm("AM")}
            className={ampm === "AM" ? "active" : ""}
          >
            오후
          </div>
          <div
            onClick={() => setAmpm("PM")}
            className={ampm === "PM" ? "active" : ""}
          >
            오전
          </div>
        </div>
        <div
          className="digits"
          ref={digitContainer}
          onMouseDown={handleMouseDown}
        >
          {timeItems.map((item, index) => {
            return (
              <div className="time-item" data-key={index} key={index}>
                {item[0]}
                <span className="seperator">:</span>
                {item[1]}
              </div>
            );
          })}
        </div>
      </div>

      <div className="">
        <div className="gap-2 justify-center flex">
          <button
            onClick={() => {
              onTimeChange(value);
              onCancel();
            }}
            className="bg-[#EBF5F2] text-[#154D57] leading-[19px] font-semibold tracking-[-0.325px] px-[44px] py-[9px] rounded-[8px]"
          >
            닫기
          </button>
          <button
            onClick={() => {
              onTimeChange(selectedTime);
              onCancel();
            }}
            className="bg-[#317580] text-[#FFFFFF] leading-[19px] font-semibold tracking-[-0.325px] px-[44px] py-[9px] rounded-[8px]"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default TimePicker;
