import React, { useState } from "react";
import {
  WaitingList,
  WaitingListScenario6,
  WaitingListScenario7,
  WaitingListScene6,
  WaitingListScene8,
  WaitingListScene9,
} from "./WaitingList";

import {
  TreatmentList,
  TreatmentListScenario6,
  TreatmentListScenario7,
  TreatmentListScene6,
  TreatmentListScene8,
  TreatmentListScene9,
} from "./TreatmentList";
import {
  Storage,
  StorageScenario6,
  StorageScenario7,
  StorageScene6,
  StorageScene8,
  StorageScene9,
} from "./Storage";
import {
  PreparingPatient,
  PreparingPatientScenario6,
  PreparingPatientScenario7,
  PreparingPatientScene6,
  PreparingPatientScene8,
  PreparingPatientScene9,
} from "./PreparingPatient";
import {
  TreatmentComplete,
  TreatmentCompleteScenario6,
  TreatmentCompleteScenario7,
  TreatmentCompleteScene6,
  TreatmentCompleteScene8,
  TreatmentCompleteScene9,
} from "./TreatmentComplete";

function EntirePatientListScenario6({ setData, data }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingListScenario6 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatientScenario6 setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentListScenario6 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <StorageScenario6 setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentCompleteScenario6 setData={setData} data={data} />
      </div>
    </div>
  );
}
function EntirePatientList({ setData }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingList setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatient setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentList setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <Storage setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentComplete setData={setData} />
      </div>
    </div>
  );
}
function EntirePatientListScenario7({ setData }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingListScenario7 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatientScenario7 setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentListScenario7 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <StorageScenario7 setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentCompleteScenario7 setData={setData} />
      </div>
    </div>
  );
}
function EntirePatientListScene6({ setData }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingListScene6 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatientScene6 setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentListScene6 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <StorageScene6 setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentCompleteScene6 setData={setData} />
      </div>
    </div>
  );
}

function EntirePatientListScene9({ setData }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingListScene9 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatientScene9 setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentListScene9 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <StorageScene9 setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentCompleteScene9 setData={setData} />
      </div>
    </div>
  );
}

function EntirePatientListScene8({ setData }) {
  return (
    <div>
      {" "}
      <div className="flex 2xl:flex-row flex-col gap-[40px] w-full">
        <div className=" 2xl:w-[50%] w-full ">
          <WaitingListScene8 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full ">
          <PreparingPatientScene8 setData={setData} />
        </div>
      </div>
      <div className="flex  2xl:flex-row flex-col  gap-[40px] pt-10">
        <div className="  2xl:w-[50%] w-full">
          <TreatmentListScene8 setData={setData} />
        </div>
        <div className="  2xl:w-[50%] w-full">
          <StorageScene8 setData={setData} />
        </div>
      </div>
      <div>
        <TreatmentCompleteScene8 setData={setData} />
      </div>
    </div>
  );
}
export {
  EntirePatientListScenario6,
  EntirePatientList,
  EntirePatientListScenario7,
  EntirePatientListScene6,
  EntirePatientListScene9,
  EntirePatientListScene8,
};
