import React from "react";
import { Link } from "react-router-dom";
import SubHeader from "../SubHeader";

function Header() {
  return (
    <div>
      <nav className="bg-[#154D57]">
        <div className="w-full flex flex-wrap items-center justify-between mx-auto px-[32px] py-[30px]">
          <Link to="/" className="flex items-center">
            <span className="font-['Pretendard'] text-[#FFF] text-[28px] font-semibold leading-[40px]">
              DEN2BIO
            </span>
          </Link>

          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul
              className="flex flex-row gap-[16px] items-center
            "
            >
              <li>
                <Link
                  to="/findid"
                  className="text-[12px] font-['Pretendard'] font-normal leading-[18px] tracking-[-0.3px] text-[#D4D4D8]"
                  aria-current="page"
                >
                  환경설정
                </Link>
              </li>
              <span className="text-[#767676] h-[9px]">
                <svg
                  width="1"
                  height="10"
                  viewBox="0 0 1 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="0.5" width="1" height="9" fill="#767676" />
                </svg>
              </span>
              <li>
                <Link
                  to="/signup"
                  className="text-[12px] font-['Pretendard'] font-normal leading-[18px] tracking-[-0.3px] text-[#D4D4D8]"
                  aria-current="page"
                >
                  회원가입
                </Link>
              </li>
              <span className="text-[#767676] h-[9px]">
                <svg
                  width="1"
                  height="10"
                  viewBox="0 0 1 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="0.5" width="1" height="9" fill="#767676" />
                </svg>
              </span>
              <li>
                <Link
                  to="/"
                  className="text-[12px] font-['Pretendard'] font-normal leading-[18px] tracking-[-0.3px] text-[#D4D4D8]"
                  aria-current="page"
                >
                  로그인
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
