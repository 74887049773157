import React, { useEffect, useState } from "react";

function NextTreatmentModal({onToothSelect,reset,setReset}) {
  useEffect(()=>{
    if(reset){
      setClickedArray(clickedArray.map((item) => false));
      setClickedArray1(clickedArray1.map((item) => false));
      setClickedArray2(clickedArray2.map((item) => false));
      setClickedArray3(clickedArray3.map((item) => false));
      setClickedArray4(clickedArray4.map((item) => false));
      setClickedArray5(clickedArray5.map((item) => false));
      setClickedArray6(clickedArray6.map((item) => false));
      setClickedArray7(clickedArray7.map((item) => false));
      setReset(false)
    }
  },[reset])
  // const imageArray = [
  //   { src: "/teeth18.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth17.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth16.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth15.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth14.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth13.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth12.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth11.png", alt: "Image 1", width: "20px", height: "26px" },
  // ];

  // const imageArrayleftdown = [
  //   { src: "/tooth55.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth54.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth53.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth52.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth51.png", alt: "Image 1", width: "20px", height: "26px" },
  // ];
  // const imageArrayRight = [
  //   { src: "/tooth21.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth22.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth23.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth24.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth25.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth26.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth27.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth28.png", alt: "Image 1", width: "33px", height: "25px" },
  // ];
  // const imageArrayRightdown = [
  //   { src: "/tooth61.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth62.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth63.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth64.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth65.png", alt: "Image 1", width: "33px", height: "25px" },
  // ];

  // const imageArrayLeftBottom = [
  //   { src: "/tooth85.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth84.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth83.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth82.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth81.png", alt: "Image 1", width: "20px", height: "26px" },
  // ];
  // const imageArrayLeftBottomDown = [
  //   { src: "/tooth48.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth47.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth46.png", alt: "Image 1", width: "33px", height: "25px" },
  //   { src: "/tooth45.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth44.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth43.png", alt: "Image 1", width: "22px", height: "28px" },
  //   { src: "/tooth42.png", alt: "Image 1", width: "20px", height: "26px" },
  //   { src: "/tooth41.png", alt: "Image 1", width: "20px", height: "26px" },
  // ];

  const svgDataArray = [
    {
      width: 33,
      height: 25,
      number: 18,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 17,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 16,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 15,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 14,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 13,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 12,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 28,
      number: 11,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
  ];
  const svgDataArray1 = [
    {
      width: 33,
      height: 25,
      number: 55,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 54,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 53,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 26,
      number: 52,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 26,
      number: 51,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
  ];

  const svgDataArray2 = [
    {
      width: 20,
      height: 28,
      number: 21,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 28,
      number: 22,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 23,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 24,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 25,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 26,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 27,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 28,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
  ];

  const svgDataArray3 = [
    {
      width: 20,
      height: 28,
      number: 61,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 20,
      height: 28,
      number: 62,
      path: "M13.7498 26L6.2502 26C2.80359 26 -4.03593e-06 23.0828 -3.40956e-06 19.5004C-2.67265e-06 15.2858 1.56089 7.23059 2.21722 4.03451C2.47876 2.75781 3.33333 1.70111 4.49609 1.21087C6.31684 0.441736 8.83558 1.54486e-06 10 1.74846e-06C11.1644 1.95205e-06 13.6832 0.441737 15.5039 1.20914C16.6683 1.70112 17.5229 2.75608 17.7844 4.03452C18.4408 7.23233 20.0017 15.2858 20.0017 19.5004C20.0017 23.0846 17.1964 26 13.7515 26L13.7498 26Z", // Replace with your actual path data
    },
    {
      width: 22,
      height: 28,
      number: 63,
      path: "M20.9953 12.2397C19.1698 8.15495 15.0087 0.345065 9.74656 0.012143C4.60005 -0.313881 1.54088 6.29713 0.149036 11.7947C0.160938 11.6636 0.173692 11.5333 0.186447 11.404C-0.209767 15.3387 -0.0303681 19.6123 1.2671 21.6021C3.27537 24.6829 8.21952 28.6478 10.9905 27.9104C13.6764 27.1962 21.4416 22.9165 21.9535 19.4993C22.1533 18.1616 21.6942 15.3016 20.9936 12.2337C20.9936 12.2354 20.9944 12.238 20.9953 12.2397Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 64,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
    {
      width: 33,
      height: 25,
      number: 65,
      path: "M32.5262 8.59923C31.339 7.00449 28.6998 4.8231 22.8754 3.59855C12.9486 1.51169 10.6275 0.352522 6.48638 3.59855C3.83425 5.67745 1.86472 6.90024 0.612154 8.4243C1.10733 5.65978 1.80626 2.71504 2.33152 1.13357e-06C1.15548 6.07945 -0.892295 13.311 0.428181 17.0836C2.12004 21.9155 4.15061 27.2484 12.9495 24.0024C14.6078 23.3901 16.7845 20.7564 19.2665 22.6109C21.7484 24.4654 26.7767 26.7413 30.2412 22.9378C33.2346 19.6484 33.4719 12.7942 32.4609 8.29972C32.4832 8.39868 32.5056 8.49763 32.5262 8.59923Z", // Replace with your actual path data
    },
  ];

  const svgDataArray4 = [
    {
      width: 33,
      height: 25,
      number: 85,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 84,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },

    {
      width: 22,
      height: 28,
      number: 83,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 20,
      height: 26,
      number: 82,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 81,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
  ];

  const svgDataArray5 = [
    {
      width: 33,
      height: 25,
      number: 48,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 47,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 46,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },

    {
      width: 22,
      height: 28,
      number: 45,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 44,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 43,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 20,
      height: 26,
      number: 42,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 41,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
  ];

  const svgDataArray6 = [
    {
      width: 20,
      height: 26,
      number: 71,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 72,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 22,
      height: 28,
      number: 73,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 33,
      height: 25,
      number: 74,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 75,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
  ];

  const svgDataArray7 = [
    {
      width: 20,
      height: 26,
      number: 31,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 20,
      height: 26,
      number: 32,
      path: "M6.25021 7.05304e-07L13.7498 1.36094e-06C17.1964 1.66225e-06 20 2.91718 20 6.49957C20 10.7142 18.4391 18.7694 17.7828 21.9655C17.5212 23.2422 16.6667 24.2989 15.5039 24.7891C13.6832 25.5583 11.1644 26 10 26C8.83558 26 6.31684 25.5583 4.49609 24.7909C3.33167 24.2989 2.47709 23.2439 2.21556 21.9655C1.55922 18.7677 -0.00166378 10.7142 -0.00166341 6.49957C-0.0016631 2.91545 2.8036 4.03991e-07 6.24854 7.05158e-07L6.25021 7.05304e-07Z",
    },
    {
      width: 22,
      height: 28,
      number: 33,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 34,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 22,
      height: 28,
      number: 35,
      path: "M1.00474 15.7603C2.83021 19.8451 6.99129 27.6549 12.2534 27.9879C17.4 28.3139 20.4591 21.7029 21.851 16.2053C21.8391 16.3364 21.8263 16.4667 21.8136 16.596C22.2098 12.6613 22.0304 8.38769 20.7329 6.39792C18.7246 3.31709 13.7805 -0.647796 11.0095 0.0896359C8.32362 0.803781 0.558368 5.08347 0.0465227 8.50068C-0.153284 9.8384 0.305846 12.6984 1.00645 15.7663C1.00645 15.7646 1.0056 15.762 1.00474 15.7603Z",
    },
    {
      width: 33,
      height: 25,
      number: 36,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 37,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
    {
      width: 33,
      height: 25,
      number: 38,
      path: "M0.473772 16.4008C1.661 17.9955 4.30023 20.1769 10.1246 21.4015C20.0514 23.4883 22.3725 24.6475 26.5136 21.4015C29.1657 19.3225 31.1353 18.0998 32.3878 16.5757C31.8927 19.3402 31.1937 22.285 30.6685 25C31.8445 18.9205 33.8923 11.689 32.5718 7.91639C30.88 3.08446 28.8494 -2.24843 20.0505 0.9976C18.3922 1.60987 16.2155 4.24363 13.7335 2.38913C11.2516 0.534639 6.22334 -1.74129 2.75881 2.06223C-0.234608 5.35155 -0.471881 12.2058 0.539108 16.7003C0.516757 16.6013 0.494405 16.5024 0.473772 16.4008Z",
    },
  ];

  const [clickedArray, setClickedArray] = useState(
    Array(svgDataArray.length).fill(false)
  );
  const [clickedArray1, setClickedArray1] = useState(
    Array(svgDataArray1.length).fill(false)
  );
  const [clickedArray2, setClickedArray2] = useState(
    Array(svgDataArray2.length).fill(false)
  );
  const [clickedArray3, setClickedArray3] = useState(
    Array(svgDataArray3.length).fill(false)
  );

  const [clickedArray4, setClickedArray4] = useState(
    Array(svgDataArray4.length).fill(false)
  );

  const [clickedArray5, setClickedArray5] = useState(
    Array(svgDataArray5.length).fill(false)
  );
  const [clickedArray6, setClickedArray6] = useState(
    Array(svgDataArray6.length).fill(false)
  );
  const [clickedArray7, setClickedArray7] = useState(
    Array(svgDataArray7.length).fill(false)
  );
  const toggleColor = (index) => {
    const newClickedArray = [...clickedArray];
    newClickedArray[index] = !newClickedArray[index];
    setClickedArray(newClickedArray);
  };

  const toggleColor1 = (index) => {
    const newClickedArray1 = [...clickedArray1];
    newClickedArray1[index] = !newClickedArray1[index];
    setClickedArray1(newClickedArray1);
  };
  const toggleColor2 = (index) => {
    const newClickedArray2 = [...clickedArray2];
    newClickedArray2[index] = !newClickedArray2[index];
    setClickedArray2(newClickedArray2);
  };

  const toggleColor3 = (index) => {
    const newClickedArray3 = [...clickedArray3];
    newClickedArray3[index] = !newClickedArray3[index];
    setClickedArray3(newClickedArray3);
  };

  const toggleColor4 = (index) => {
    const newClickedArray4 = [...clickedArray4];
    newClickedArray4[index] = !newClickedArray4[index];
    setClickedArray4(newClickedArray4);
  };

  const toggleColor5 = (index) => {
    const newClickedArray5 = [...clickedArray5];
    newClickedArray5[index] = !newClickedArray5[index];
    setClickedArray5(newClickedArray5);
  };

  const toggleColor6 = (index) => {
    const newClickedArray6 = [...clickedArray6];
    newClickedArray6[index] = !newClickedArray6[index];
    setClickedArray6(newClickedArray6);
  };

  const toggleColor7 = (index) => {
    const newClickedArray7 = [...clickedArray7];
    newClickedArray7[index] = !newClickedArray7[index];
    setClickedArray7(newClickedArray7);
  };
  return (
    <div className="flex flex-col  w-full max-w-[914px] h-auto  ">
      <div className="flex gap-[16px] flex-col">
        <div className="flex flex-row items-center justify-center   ">
          <div className="modal-title flex-1 font-['pretendard'] text-[18px] font-[600] tracking-[-0.45] leading-[26px]">
            치료계획 입력
          </div>
        </div>
        <div className="flex  border rounded-md border-gray-300 p-[31px]">
          <table className=" h-[41px] text-[#154D57] text-[12px] leading-[18px] font-normal">
            <tr className="h-[113px] text-center">
              <td className="border-r w-[246px] border-[#D4D4D8] border-[#999]">
                <div class="flex flex-col">
                  <div class="flex items-center">
                    {svgDataArray.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div class="flex items-center pt-[9px] pb-[12px] ">
                    <div className="flex flex-col gap-1 mr-6">
                      <button className="py-1 px-[22px] bg-[#C7EAE0] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                        전악
                      </button>
                      <button className="py-1 px-[22px] bg-[#FAF6D9] rounded-[4px]  text-[#C29800] text-[12px] leading-[18px] tracking-[-0.3px]">
                        상악
                      </button>
                    </div>
                    <div class="flex items-center">
                      {svgDataArray1.map((svgData, index) => (
                        <div
                          key={index}
                          style={{ position: "relative", marginRight: "4px" }}
                          className="flex items-center"
                        >
                          <svg
                            width={svgData.width}
                            height={svgData.height}
                            viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                            onClick={() => toggleColor1(index)}
                          >
                            <path
                              d={svgData.path}
                              fill={
                                clickedArray1[index]
                                  ? "var(--400, #5A97A3)"
                                  : "url(#paint0_linear_2178_75779)"
                              }
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2178_75779"
                                x1="16.5"
                                y1="25"
                                x2="16.4996"
                                y2="2.27283"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#A0AFB2" />
                                <stop
                                  offset="1"
                                  stop-color="#A0AFB2"
                                  stop-opacity="0.3"
                                />
                              </linearGradient>
                            </defs>
                          </svg>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "13px", // Adjust the font size as needed
                              color: "#FFF", // Adjust the color as needed
                              fontWeight: 600,
                              lineHeight: "19px",
                              fontWeight: "bold",
                            }}
                            className="cursor-pointer"
                            onClick={() => toggleColor1(index)}
                          >
                            {svgData.number}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </td>
              <td className="border-l w-[245px] border-[#D4D4D8]  border-[#999]">
                <div class="flex flex-col ">
                  <div class="flex items-center pl-3">
                    {" "}
                    {svgDataArray2.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor2(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray2[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor2(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div class="flex items-center pt-[9px] pb-[12px] ml-3">
                    {svgDataArray3.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor3(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray3[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor3(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                    <div className="flex flex-col gap-1 ml-6 ">
                      <button className="py-1 px-[12px] bg-[#EBF5F2] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                        유치전악
                      </button>
                      <button className="py-1 px-[12px] bg-[#FAF6D9] rounded-[4px]  text-[#C29800] text-[12px] leading-[18px] tracking-[-0.3px]">
                        유치상악
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="h-[113px]">
              <td className="border-t border-r w-[245px] border-[#D4D4D8]  border-[#999]">
                <div class="flex flex-col">
                  <div class="flex items-center pt-[9px] pb-[12px] ">
                    <div className="flex flex-col gap-1 mr-6">
                      <button className="py-1 px-[22px] bg-[#EBF5F2] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                        하악
                      </button>
                      <button
                        onClick={() => {
                          setClickedArray(clickedArray.map((item) => true));
                          setClickedArray1(clickedArray1.map((item) => true));
                          setClickedArray2(clickedArray2.map((item) => true));
                          setClickedArray3(clickedArray3.map((item) => true));
                          setClickedArray4(clickedArray4.map((item) => true));
                          setClickedArray5(clickedArray5.map((item) => true));
                          setClickedArray6(clickedArray6.map((item) => true));
                          setClickedArray7(clickedArray7.map((item) => true));
                        }}
                        className="py-1 px-[12px] bg-[#DBEEF9] rounded-[4px]  text-[#1778B0] text-[12px] leading-[18px] tracking-[-0.3px]"
                      >
                        전체선택
                      </button>
                    </div>
                    {svgDataArray4.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor4(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray4[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor4(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div class="flex items-center">
                    {svgDataArray5.map((svgData, index) => (
                      <div
                        key={index}
                        style={{ position: "relative", marginRight: "4px" }}
                        className="flex items-center"
                      >
                        <svg
                          width={svgData.width}
                          height={svgData.height}
                          viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="cursor-pointer"
                          onClick={() => toggleColor4(index)}
                        >
                          <path
                            d={svgData.path}
                            fill={
                              clickedArray5[index]
                                ? "var(--400, #5A97A3)"
                                : "url(#paint0_linear_2178_75779)"
                            }
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_2178_75779"
                              x1="16.5"
                              y1="25"
                              x2="16.4996"
                              y2="2.27283"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#A0AFB2" />
                              <stop
                                offset="1"
                                stop-color="#A0AFB2"
                                stop-opacity="0.3"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "13px", // Adjust the font size as needed
                            color: "#FFF", // Adjust the color as needed
                            fontWeight: 600,
                            lineHeight: "19px",
                            fontWeight: "bold",
                          }}
                          className="cursor-pointer"
                          onClick={() => toggleColor5(index)}
                        >
                          {svgData.number}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </td>
              <td className="border-t border-l border-[#D4D4D8]  w-[245px] border-[#999]">
                <div class="flex items-center pt-[9px] pb-[12px]  ml-3">
                  {svgDataArray6.map((svgData, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", marginRight: "4px" }}
                      className="flex items-center"
                    >
                      <svg
                        width={svgData.width}
                        height={svgData.height}
                        viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                        onClick={() => toggleColor6(index)}
                      >
                        <path
                          d={svgData.path}
                          fill={
                            clickedArray6[index]
                              ? "var(--400, #5A97A3)"
                              : "url(#paint0_linear_2178_75779)"
                          }
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2178_75779"
                            x1="16.5"
                            y1="25"
                            x2="16.4996"
                            y2="2.27283"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#A0AFB2" />
                            <stop
                              offset="1"
                              stop-color="#A0AFB2"
                              stop-opacity="0.3"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "13px", // Adjust the font size as needed
                          color: "#FFF", // Adjust the color as needed
                          fontWeight: 600,
                          lineHeight: "19px",
                          fontWeight: "bold",
                        }}
                        className="cursor-pointer"
                        onClick={() => toggleColor6(index)}
                      >
                        {svgData.number}
                      </div>
                    </div>
                  ))}
                  <div className="flex flex-col gap-1 ml-6">
                    <button className="py-1 px-[12px] bg-[#EBF5F2] rounded-[4px] text-[#154D57] text-[12px] leading-[18px] tracking-[-0.3px]">
                      유치하악
                    </button>
                    <button
                      onClick={() => {
                        setClickedArray(clickedArray.map((item) => false));
                        setClickedArray1(clickedArray1.map((item) => false));
                        setClickedArray2(clickedArray2.map((item) => false));
                        setClickedArray3(clickedArray3.map((item) => false));
                        setClickedArray4(clickedArray4.map((item) => false));
                        setClickedArray5(clickedArray5.map((item) => false));
                        setClickedArray6(clickedArray6.map((item) => false));
                        setClickedArray7(clickedArray7.map((item) => false));
                      }}
                      className="py-1 px-[12px] bg-[#FFEBDE] rounded-[4px]  text-[#DA5F1A] text-[12px] leading-[18px] tracking-[-0.3px]"
                    >
                      선택해제
                    </button>
                  </div>
                </div>
                <div class="flex items-center ml-3">
                  {svgDataArray7.map((svgData, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", marginRight: "4px" }}
                      className="flex items-center"
                    >
                      <svg
                        width={svgData.width}
                        height={svgData.height}
                        viewBox={`0 0 ${svgData.width} ${svgData.height}`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                        onClick={() => toggleColor7(index)}
                      >
                        <path
                          d={svgData.path}
                          fill={
                            clickedArray7[index]
                              ? "var(--400, #5A97A3)"
                              : "url(#paint0_linear_2178_75779)"
                          }
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2178_75779"
                            x1="16.5"
                            y1="25"
                            x2="16.4996"
                            y2="2.27283"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#A0AFB2" />
                            <stop
                              offset="1"
                              stop-color="#A0AFB2"
                              stop-opacity="0.3"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontSize: "13px", // Adjust the font size as needed
                          color: "#FFF", // Adjust the color as needed
                          fontWeight: 600,
                          lineHeight: "19px",
                          fontWeight: "bold",
                        }}
                        className="cursor-pointer"
                        onClick={() => toggleColor7(index)}
                      >
                        {svgData.number}
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="">
        <hr className="max-w-[491px]  flex justify-center mx-auto mt-[20px] bg-[#F1F1F5]" />
      </div>

      {/* <div className="flex gap-2 justify-center pt-8">
        <button className="bg-[#EBF5F2] rounded-[8px] text-[#154D57] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
          이전
        </button>
        <button className="bg-[#317580] rounded-[8px] text-[#FFFFFF] text-[13px] font-semibold leading-[19px] tracking-[-0.325px] py-[9px] px-[44px]">
          저장
        </button>
      </div> */}
    </div>
  );
}

export default NextTreatmentModal;
